import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import { P2P_TICKET_RESET_SEARCH } from 'src/actions/P2pTicketActions';
import { P2P_TICKET_SCREEN } from 'src/optics/p2pTicket';
import { P2pTicketScreenAction } from 'src/types/P2pTicketScreenAction';
import { P2pTicketState } from 'src/types/P2pTicketState';

export function p2pTicketResetSearchReducer(state: P2pTicketState, action: Action): P2pTicketState {
  if (P2P_TICKET_RESET_SEARCH.isPending(action)) {
    return pipe(
      state,
      modifyAction(P2pTicketScreenAction.RESET_SEARCH),
    );
  }
  if (P2P_TICKET_RESET_SEARCH.isSuccess(action)) {
    return { ...state, screen: action.data };
  }
  if (P2P_TICKET_RESET_SEARCH.isFailure(action)) {
    return pipe(
      state,
      modifyAction(null),
    );
  }

  return state;
}

function modifyAction(action: P2pTicketScreenAction | null): (state: P2pTicketState) => P2pTicketState {
  return pipe(
    P2P_TICKET_SCREEN,
    O.prop('action'),
    O.modify(() => action),
  );
}
