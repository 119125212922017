import { SagaIterator } from 'redux-saga';
import { LogicError } from 'src/errors/LogicError';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchP2pTripOffer } from 'src/sagas/p2p/utils/fetchP2pTripOffer';
import { Language } from 'src/types/Language';
import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketTripDetails } from 'src/types/P2pTicketTripDetails';
import { Uuid } from 'src/types/Uuid';
import { call } from 'typed-redux-saga';

export function* refreshP2pTripOffer(
  language: Language,
  travelers: ReadonlyArray<P2pTicketFormDataTraveler>,
  conversationId: Uuid,
  tripDetails: P2pTicketTripDetails,
): SagaIterator<P2pTicketTripDetails> {
  try {
    const tripOffer = yield* call(
      fetchP2pTripOffer,
      language,
      travelers,
      conversationId,
      tripDetails.selected.offer.trip,
    );
    for (const traveler of travelers) {
      const offer = tripOffer.offers.find((it) => (
        it.qualityOfService === tripDetails.selected.class &&
        it.passengerId === traveler.id
      ));
      if (!offer) {
        throw new LogicError(`Could not refresh traveler "${traveler.id}" offer`);
      }
    }

    return {
      tripInfo: tripDetails.tripInfo,
      selected: {
        class: tripDetails.selected.class,
        offer: tripOffer,
      },
    };
  } catch (error) {
    throw new RuntimeError(
      'Could not refresh P2P Ticket trip offers',
      { language, conversationId, travelers, tripDetails },
      NativeError.wrap(error),
    );
  }
}
