import { Decimal } from 'decimal.js-light';
import { createSelector } from 'reselect';
import { getBasketData } from 'src/selectors/getBasketData';
import { getBasketProductList } from 'src/selectors/getBasketProductList';
import { getBasketShiftedProducts } from 'src/selectors/getBasketShiftedProducts';
import { getBasketUnavailableProducts } from 'src/selectors/getBasketUnavailableProducts';
import { BasketUpgradeListItem } from 'src/types/BasketUpgradeListItem';
import { isNotNull } from 'src/utils/guard';

export const getBasketUpgradeList = createSelector([
  getBasketData,
  getBasketProductList,
  getBasketShiftedProducts,
  getBasketUnavailableProducts,
], (data, products, shifted, unavailable): ReadonlyArray<BasketUpgradeListItem> => {
  if (shifted.size > 0) {
    return [];
  }
  if (unavailable.size > 0) {
    return [];
  }

  return products.map((product) => {
    if (product.type !== 'product') {
      return null;
    }

    const upgrade = data.upgrades.find((it) => it.code === product.code);
    if (!upgrade) {
      return null;
    }

    if (product.totalPrice.isZero()) {
      return null;
    }

    const totalPrice = upgrade.prices.reduce((sum, it) => sum.add(it.price), new Decimal(0));
    const priceDiff = totalPrice.sub(product.totalPrice);

    return priceDiff.isPositive()
      ? { code: product.code, price: priceDiff }
      : null;
  }).filter(isNotNull);
});
