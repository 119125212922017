import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { LocalTime } from 'src/types/LocalTime';

const PATTERN = /^\d\d:\d\d:\d\d(\.\d\d\d)?$/;

const decoder: D.Decoder<unknown, LocalTime> = pipe(
  D.string,
  D.parse((input) => {
    if (!PATTERN.test(input)) {
      return D.failure(input, 'LocalTime');
    }

    const [hh = 24, mm = 60, ss = 60] = input.split(':').map((segment) => parseInt(segment, 10));
    if (hh > 23 || mm > 59 || ss > 59) {
      return D.failure(input, 'LocalTime');
    }

    return D.success({ mm, hh, ss });
  }),
);

const encoder: E.Encoder<string, LocalTime> = {
  encode: (value) => {
    const hh = value.hh.toString().padStart(2, '0');
    const mm = value.mm.toString().padStart(2, '0');
    const ss = value.ss.toString().padStart(2, '0');

    return `${hh}:${mm}:${ss}`;
  },
};
export const LOCAL_TIME: C.Codec<unknown, string, LocalTime> = C.make(decoder, encoder);
