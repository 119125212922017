import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchP2pTripFrame } from 'src/sagas/p2p/utils/fetchP2pTripFrame';
import { fetchP2pTripOffer } from 'src/sagas/p2p/utils/fetchP2pTripOffer';
import { Language } from 'src/types/Language';
import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketTripInfo } from 'src/types/P2pTicketTripInfo';
import { P2pTicketTripList } from 'src/types/P2pTicketTripList';
import { Uuid } from 'src/types/Uuid';
import { getTripFrameItems } from 'src/utils/p2p/tripFrame';
import { all, call } from 'typed-redux-saga';

export function* fetchP2pTripList(
  language: Language,
  travelers: ReadonlyArray<P2pTicketFormDataTraveler>,
  conversationId: Uuid,
  tripInfo: P2pTicketTripInfo,
): SagaIterator<P2pTicketTripList> {
  try {
    const tripFrame = yield* call(fetchP2pTripFrame, language, tripInfo);
    const tripItems = yield* call(getTripFrameItems, tripFrame);
    const tripOffers = yield* all(tripItems.map((tripItem) => callEffect(
      fetchP2pTripOffer,
      language,
      travelers,
      conversationId,
      tripItem,
    )));

    if (tripOffers.every((it) => it.offers.length === 0)) {
      // no offers on initial search
      return NO_DATA;
    }

    return {
      trips: tripOffers,
      frame: tripFrame,
    } satisfies P2pTicketTripList;
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch P2P Ticket trips list',
      { language, tripInfo, travelers },
      NativeError.wrap(error),
    );
  }
}

const NO_DATA: P2pTicketTripList = {
  trips: [],
  frame: {
    top: 0,
    bottom: 0,
    collection: {
      trips: [],

      prevTripsCollectionId: null,
      nextTripsCollectionId: null,
    },
  },
};
