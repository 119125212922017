import { Eq } from 'fp-ts/Eq';
import { useEffect, useRef } from 'react';

type ChangeEvent<T> = {
  readonly nextValue: T;
  readonly prevValue: T;
};
type ChangeHandler<T> = {
  (event: ChangeEvent<T>): void;
};

export function useOnChange<T>(handler: ChangeHandler<T>, eq: Eq<T>, value: T): void {
  const previous = useRef<T>(value);

  useEffect(() => {
    if (eq.equals(previous.current, value)) {
      return;
    }

    handler({ nextValue: value, prevValue: previous.current });
    previous.current = value;
  }, [eq, value, handler]);
}
