import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_INITIAL_CHANGE } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { saveP2pTicketData } from 'src/sagas/p2p/utils/saveP2pTicketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* p2pTicketInitialChangeSaga(
  action: GetRequestActionType<typeof P2P_TICKET_INITIAL_CHANGE>,
): SagaIterator<void> {
  const formData = action.data;

  try {
    yield* call(logDebug, 'Changing P2P Ticket initial data…', formData);
    yield* put(P2P_TICKET_INITIAL_CHANGE.pending());

    yield* delay(300);

    const currentScreen = yield* select(getP2pTicketScreen);
    const updatedScreen = currentScreen.type === 'initial'
      ? { ...currentScreen, form: formData }
      : currentScreen;

    yield* call(saveP2pTicketData, updatedScreen);

    yield* call(logDebug, 'Changing P2P Ticket initial data… done', updatedScreen);
    yield* put(P2P_TICKET_INITIAL_CHANGE.success(updatedScreen));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not change P2P Ticket initial data',
      { formData },
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Changing P2P Ticket initial data… error', error);
    yield* put(P2P_TICKET_INITIAL_CHANGE.failure(NativeError.wrap(error)));
  }
}
