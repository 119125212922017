import { Action } from 'redux';
import { P2P_TICKET_FETCH, P2P_TICKET_RESET } from 'src/actions/P2pTicketActions';
import { P2pTicketState } from 'src/types/P2pTicketState';

export function p2pTicketDataReducer(state: P2pTicketState, action: Action): P2pTicketState {
  if (P2P_TICKET_FETCH.is(action)) {
    return { ...state, screen: action.data };
  }
  if (P2P_TICKET_RESET.is(action)) {
    return { ...state, screen: null };
  }

  return state;
}
