import './CheckoutPaymentScreen.scss';
import { FormController } from '@form-ts/core';
import { useForm, useFormWatch } from '@form-ts/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckoutNavigation } from 'src/components/checkout/CheckoutNavigation';
import { CheckoutPaymentAgreementFormView } from 'src/components/checkout/CheckoutPaymentAgreementFormView';
import { CheckoutPaymentCancellationFormView } from 'src/components/checkout/CheckoutPaymentCancellationFormView';
import { CheckoutPaymentTicketDetailsFormView } from 'src/components/checkout/CheckoutPaymentTicketDetailsFormView';
import { CheckoutPaymentTotalPriceFormView } from 'src/components/checkout/CheckoutPaymentTotalPriceFormView';
import { CheckoutPaymentTypeSelectFormView } from 'src/components/checkout/CheckoutPaymentTypeSelectFormView';
import { Typography } from 'src/components/common/Typography';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { useFormValidator } from 'src/forms/hooks/useFormValidator';
import { FormError } from 'src/forms/types/FormError';
import { FormRule } from 'src/forms/types/FormRule';
import { validate } from 'src/forms/utils/validate';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { CheckoutPaymentFormData, CheckoutPersonalFormData } from 'src/types/CheckoutFormData';
import { CheckoutProduct } from 'src/types/CheckoutProduct';
import { CheckoutTotal } from 'src/types/CheckoutTotal';
import { Currency } from 'src/types/Currency';
import { PaymentType } from 'src/types/PaymentType';

type Props = {
  readonly currency: Currency;
  readonly personal:CheckoutPersonalFormData;
  readonly products: ReadonlyArray<CheckoutProduct>;
  readonly loading: boolean;

  readonly formData: CheckoutPaymentFormData;
  readonly formRule: FormRule<CheckoutPaymentFormData>;

  readonly onChange: (form: FormController<CheckoutPaymentFormData, FormError>) => void;
  readonly onSubmit: (form: FormController<CheckoutPaymentFormData, FormError>) => void;

  readonly totalPrice: CheckoutTotal;
  readonly paymentTypes: ReadonlyArray<PaymentType>;
  readonly optInControl: boolean;
};

export function CheckoutPaymentScreen({
  currency,
  products,
  personal,
  loading,
  formData,
  formRule,
  onChange,
  onSubmit,
  totalPrice,
  paymentTypes,
  optInControl,
}: Props): React.ReactElement {
  const initialErrors = useMemo(() => validate(formData, formRule), [formData, formRule]);
  const form = useForm('checkout.payment', {
    reinitialize: false,
    initialValues: formData,
    initialErrors: initialErrors,
  });

  // FIXME: use field-based validation
  useFormValidator(form, formRule);

  useEffect(() => form.subscribe((prevState) => {
    const nextState = form.currentState;
    if (prevState.values !== nextState.values) {
      onChange(form);
    }
  }), [form, onChange]);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(form);
  }, [form, onSubmit]);

  const errors = useFormWatch(form, form.errors.get);
  const invalid = Object.keys(errors).length > 0;

  return (
    <div className="sts-ui-checkout-payment-screen">
      <div className="sts-ui-checkout-payment-screen__navigation">
        <CheckoutNavigation
          current="payment"
        />
      </div>

      <div className="sts-ui-checkout-payment-screen__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="Form.Checkout.Payment.BookingInformation"/>
        </Typography>
      </div>

      <form className="sts-ui-checkout-payment-screen__content" onSubmit={handleSubmit}>
        <div className="sts-ui-checkout-payment-screen__block">
          <div className="sts-ui-checkout-payment-screen__form">
            <div className="sts-ui-checkout-payment-screen__delivery-info">
              <FormattedMessage id="Payment.TicketDelivery"/>
            </div>

            <div className="sts-ui-checkout-payment-screen__block-title">
              <Typography variant="block-title" as="div">
                <FormattedMessage id="Form.Checkout.BillingAddress.TravelersInformation"/>
              </Typography>
            </div>
            <div className="sts-ui-checkout-payment-screen__traveler-list">
              <CheckoutPaymentTicketDetailsFormView
                price={totalPrice}
                personal={personal}
                products={products}
                currency={currency}
              />
            </div>
          </div>
        </div>

        <div className="sts-ui-checkout-payment-screen__block">
          <div className="sts-ui-checkout-payment-screen__block-title">
            <Typography variant="block-title" as="h2">
              <FormattedMessage id="Form.Checkout.Payment.CancellationProtection"/>
            </Typography>
          </div>
          <div className="sts-ui-checkout-payment-screen__form">
            <CheckoutPaymentCancellationFormView
              form={form}
              price={totalPrice.cancellationProtection}
            />
          </div>
        </div>

        <div className="sts-ui-checkout-payment-screen__block">
          <div className="sts-ui-checkout-payment-screen__block-title">
            <Typography variant="block-title" as="h2">
              <FormattedMessage id="Form.Checkout.Payment.PaymentData"/>
            </Typography>
          </div>
          <div className="sts-ui-checkout-payment-screen__form">
            <div className="sts-ui-checkout-payment-screen__type-select">
              <CheckoutPaymentTypeSelectFormView
                form={form}
                paymentTypes={paymentTypes}
              />
            </div>
            <div className="sts-ui-checkout-payment-screen__total-price">
              <CheckoutPaymentTotalPriceFormView
                price={totalPrice}
              />
            </div>
            <div className="sts-ui-checkout-payment-screen__agreement">
              <CheckoutPaymentAgreementFormView
                form={form}
                optInControl={optInControl}
              />
            </div>
          </div>
        </div>

        <div className="sts-ui-checkout-payment-screen__actions">
          <RouteLink route="checkout:personal" className="sts-ui-primary-button">
            <FormattedMessage id="Form.Checkout.Payment.BackToPersonalData"/>
          </RouteLink>

          <button
            type="submit"
            className="sts-ui-primary-button"
            disabled={invalid || loading}
          >
            <FormattedMessage id="Form.Checkout.Payment.Checkout"/>
            {loading ? <LoadingIcon/> : <ArrowRightIcon/>}
          </button>
        </div>
      </form>
    </div>
  );
}
