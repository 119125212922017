import './BasketProductView.scss';
import classNames from 'classnames';
import { constNull, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { DiscountList } from 'src/components/common/DiscountList';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { ProductName } from 'src/components/common/ProductName';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { RemoveIcon } from 'src/components/icons/RemoveIcon';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { BasketProductListItemProduct } from 'src/types/BasketProductListItem';
import { TicketAge } from 'src/types/TicketAge';
import { TicketType } from 'src/types/TicketType';
import { bind } from 'src/utils/bind';

type Props = {
  readonly product: BasketProductListItemProduct;
  readonly onRemove: (product: BasketProductListItemProduct) => void;
  readonly removing: boolean;
};

export class BasketProductView extends React.Component<Props> {
  public render(): React.ReactElement {
    const { product, removing } = this.props;

    return (
      <div className={classNames(
        'sts-ui-basket-product-view',
        product.invalid ? 'sts-ui-basket-product-view--invalid' : null,
      )}
      >
        <div className="sts-ui-basket-product-view__title">
          <span className="sts-ui-basket-product-view__name">
            <ProductName type={product.code}/>
          </span>

          {product.invalid && (
            <span className="sts-ui-basket-product-view__alert">
              <FormattedMessage id="Basket.Invalid"/>
            </span>
          )}
        </div>

        <div className="sts-ui-basket-product-view__details">
          <div className="sts-ui-basket-product-view__details-column">
            {product.form.startDate !== null && (
              <div className="sts-ui-basket-product-view__details-line">
                <div className="sts-ui-basket-product-view__details-line-label">
                  <FormattedMessage id="Basket.StartDate"/>
                </div>
                <div className="sts-ui-basket-product-view__details-line-value">
                  <FormattedDate value={product.form.startDate}/>
                </div>
              </div>
            )}
            {product.form.duration !== null && (
              <div className="sts-ui-basket-product-view__details-line">
                <div className="sts-ui-basket-product-view__details-line-label">
                  <FormattedMessage id="Basket.ValidityLabel"/>
                </div>
                <div className="sts-ui-basket-product-view__details-line-value">
                  <FormattedHTMLMessage
                    id="Basket.Validity"
                    values={{
                      PRODUCT: product.code,
                      DURATION: product.form.duration.replace(/\D/g, ''),
                    }}
                  />
                </div>
              </div>
            )}
            {product.form.class !== null && (
              <div className="sts-ui-basket-product-view__details-line">
                <div className="sts-ui-basket-product-view__details-line-label">
                  <FormattedMessage id="Basket.ClassLabel"/>
                </div>
                <div className="sts-ui-basket-product-view__details-line-value">
                  <FormattedHTMLMessage
                    id="Basket.Class"
                    values={{ class: product.form.class }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="sts-ui-basket-product-view__details-column">
            {product.form.adult !== null && (
              <div className="sts-ui-basket-product-view__details-line">
                <div className="sts-ui-basket-product-view__details-line-label">
                  {this.getBasketProductFieldTitle(TicketAge.ADULT)}
                </div>
                <div className="sts-ui-basket-product-view__details-line-value">
                  {product.form.adult} {this.renderDiscount(TicketAge.ADULT)}
                </div>
              </div>
            )}
            {product.form.youth !== null && (
              <div className="sts-ui-basket-product-view__details-line">
                <div className="sts-ui-basket-product-view__details-line-label">
                  {this.getBasketProductFieldTitle(TicketAge.YOUTH)}
                </div>
                <div className="sts-ui-basket-product-view__details-line-value">
                  {product.form.youth} {this.renderDiscount(TicketAge.YOUTH)}
                </div>
              </div>
            )}
            {pipe(
              this.renderChildrenAmount(),
              O.fromNullable,
              O.fold(constNull, (childrenInfo) => (
                <div className="sts-ui-basket-product-view__details-line">
                  <div className="sts-ui-basket-product-view__details-line-label">
                    {this.getBasketProductFieldTitle(TicketAge.CHILD)}
                  </div>
                  <div className="sts-ui-basket-product-view__details-line-value">
                    {childrenInfo}
                  </div>
                </div>
              )),
            )}
          </div>
        </div>

        <div className="sts-ui-basket-product-view__price">
          <div className="sts-ui-basket-product-view__price-label">
            <FormattedMessage id="Basket.Price"/>
          </div>
          <div className="sts-ui-basket-product-view__price-value">
            <FormattedPrice value={product.totalPrice}/>
          </div>
        </div>

        <div className="sts-ui-basket-product-view__actions">
          <button
            type="button"
            onClick={this.handleRemoveProduct}
            disabled={removing}
            className="sts-ui-ghost-button"
          >
            {removing
              ? <LoadingIcon/>
              : <RemoveIcon/>}
            <span><FormattedMessage id="Basket.RemoveTicket"/></span>
          </button>

          {product.unavailable && (
            <button
              type="button"
              disabled={true}
              className="sts-ui-ghost-button"
            >
              <span><FormattedMessage id="Basket.ChangeTicket"/></span>
              <ArrowRightIcon/>
            </button>
          )}
          {!product.unavailable && (
            <RouteLink
              route={`product:${product.code}`}
              className="sts-ui-ghost-button"
            >
              <span><FormattedMessage id="Basket.ChangeTicket"/></span>
              <ArrowRightIcon/>
            </RouteLink>
          )}
        </div>
      </div>
    );
  }

  private getBasketProductFieldTitle(tariff: TicketAge): React.ReactNode {
    const { product } = this.props;
    const isSwissHalfFareCard = product.code === TicketType.SWISS_HALF_FARE_CARD;

    switch (tariff) {
    case TicketAge.ADULT:
      return isSwissHalfFareCard
        ? <FormattedMessage id="Basket.HalfFareCard"/>
        : <FormattedMessage id="Basket.Adults"/>;

    case TicketAge.YOUTH:
      return <FormattedMessage id="Basket.Youth"/>;

    case TicketAge.CHILD:
      return isSwissHalfFareCard
        ? <FormattedMessage id="Basket.HalfFareFamilyCard"/>
        : <FormattedMessage id="Basket.Children"/>;

    default:
      return null;
    }
  }

  private renderDiscount(tariff: TicketAge): React.ReactNode {
    const { product } = this.props;

    const price = product.prices.find((it) => it.tariff === tariff);
    if (!price) {
      return null;
    }

    const discounts = price.discounts.filter((it, ix, arr) => arr.indexOf(it) === ix);
    if (discounts.length === 0) {
      return null;
    }

    return (
      <FormattedMessage
        id="Payment.DiscountCard"
        values={{
          discountCard: (
            <DiscountList
              product={product.code}
              discounts={discounts}
            />
          ),
        }}
      />
    );
  }

  private renderChildrenAmount(): React.ReactElement | null {
    const { product } = this.props;
    if (product.familyCardDiscount && product.familyCardCount > 0) {
      return (
        <FormattedMessage
          id="Form.Product.UpToXChildTravelFree"
          values={{ countFamilyCards: product.familyCardCount }}
        />
      );
    }

    if (product.form.child !== null) {
      return (
        <>
          {product.form.child} {this.renderDiscount(TicketAge.CHILD)}
        </>
      );
    }

    return null;
  }

  @bind
  private handleRemoveProduct(): void {
    const { product, onRemove } = this.props;
    onRemove(product);
  }
}
