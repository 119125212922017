import './CheckoutPaymentP2pTicketPriceList.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { FormattedTime } from 'src/components/common/FormattedTime';
import { P2pTicketName } from 'src/components/common/P2pTicketName';
import { CheckoutProductP2pTicket } from 'src/types/CheckoutProduct';
import { Currency } from 'src/types/Currency';

type Props = {
  readonly product: CheckoutProductP2pTicket;
  readonly currency: Currency;
};

export function CheckoutPaymentP2pTicketPriceList({ product, currency }: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-payment-p2p-ticket-price-list">
      <div className="sts-ui-checkout-payment-p2p-ticket-price-list__header">
        <div className="sts-ui-checkout-payment-p2p-ticket-price-list__title">
          <P2pTicketName
            from={product.ticket.from}
            to={product.ticket.to}
          />
        </div>
        <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info">
          <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info-line">
            <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info-line-label">
              <FormattedMessage id="Checkout.P2P.Ticket.Date"/>
            </div>
            <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info-line-value">
              <FormattedDate value={product.ticket.date.date}/>
            </div>
          </div>
          <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info-line">
            <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info-line-label">
              <FormattedMessage id="Checkout.P2P.Ticket.Time"/>
            </div>
            <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info-line-value">
              <FormattedTime value={product.ticket.date.time}/>
            </div>
          </div>
          <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info-line">
            <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info-line-label">
              <FormattedMessage id="Checkout.P2P.Ticket.Class"/>
            </div>
            <div className="sts-ui-checkout-payment-p2p-ticket-price-list__info-line-value">
              <FormattedMessage id="P2P.Common.ClassName" values={{ class: product.ticket.class }}/>
            </div>
          </div>
        </div>
      </div>

      {product.ticket.offers.map(({ offer, traveler }) => {
        return (
          <div key={traveler.id} className="sts-ui-checkout-payment-p2p-ticket-price-list__tariff">
            <div className="sts-ui-checkout-payment-p2p-ticket-price-list__tariff-title">
              <FormattedMessage
                id="P2P.Common.AgeGroup"
                values={{ ageGroup: traveler.ageGroup }}
              />
              {' '}
              <FormattedMessage
                id="P2P.Common.Discount"
                values={{ discount: traveler.discount.replace(/-/g, '_') }}
              />
            </div>
            <div className="sts-ui-checkout-payment-p2p-ticket-price-list__tariff-value">
              <FormattedPrice value={offer.prices[currency]}/>
            </div>
          </div>
        );
      })}
    </div>
  );
}
