import { SagaIterator } from 'redux-saga';
import { CHECKOUT_FETCH } from 'src/actions/CheckoutActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { redirectToCheckoutPage } from 'src/routing/sagas/utils/redirectToCheckoutPage';
import { Transition } from 'src/routing/types/Transition';
import { fetchCheckoutPaymentData } from 'src/sagas/checkout/utils/fetchCheckoutPaymentData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getBasketContent } from 'src/selectors/getBasketContent';
import { Language } from 'src/types/Language';
import { call, put, select } from 'typed-redux-saga';

export function* fetchCheckoutPayment(language: Language, transition: Transition): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching Checkout Payment data…');

    const currency = transition.to.currency;
    const basket = yield* select(getBasketContent);

    const checkout = yield* call(
      fetchCheckoutPaymentData,
      currency,
      language,
      basket,
    );
    if (checkout === null) {
      yield* call(logDebug, 'Fetching Checkout Payment data… skip', { reason: 'non-deliverable' });
      yield* call(redirectToCheckoutPage, currency, language);
      return;
    }

    yield* call(logDebug, 'Fetching Checkout Payment data… done', checkout);
    yield* put(CHECKOUT_FETCH.trigger(checkout));
  } catch (error) {
    yield* call(logError, 'Fetching Checkout Payment data… error', error);
    throw new RuntimeError('Could not fetch Checkout Payment data', {}, NativeError.wrap(error));
  }
}
