import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { P2pTicketFormDestination } from 'src/components/p2p/P2pTicketFormDestination';
import { FormError } from 'src/forms/types/FormError';
import { P2pTicketStation } from 'src/types/P2pTicketStation';
import { P2pTicketStationList } from 'src/types/P2pTicketStationList';

type Props<TData> = {
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly field: FieldPath<TData, FormError, P2pTicketStation | null>;
  readonly exclude: P2pTicketStation | null;
  readonly stations: P2pTicketStationList | null;
  readonly onInput: (term: string) => void;
  readonly clearable?: boolean;
  readonly disabled?: boolean;
  readonly placeholder?: React.ReactNode;
  readonly formatError?: (error: FormError) => string;
};

export function P2pTicketFormDestinationField<TData>({
  layout,
  border,
  label,
  field,
  exclude,
  stations,
  onInput,
  clearable,
  disabled,
  placeholder,
  formatError,
}: Props<TData>): React.ReactElement {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);
  const validity = error !== undefined && formatError !== undefined ? formatError(error) : undefined;

  const handleChange = useCallback((nextValue: P2pTicketStation | null) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <P2pTicketFormDestination
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      layout={layout}
      border={border}
      label={label}
      value={value}
      exclude={exclude}
      stations={stations}
      onBlur={handleBlur}
      onChange={handleChange}
      onInput={onInput}
      invalid={invalid}
      validity={validity}
      disabled={disabled}
      clearable={clearable}
      placeholder={placeholder}
    />
  );
}
