import './TimelineConnector.scss';
import classNames from 'classnames';
import * as React from 'react';

type Props = {
  readonly start: 'solid' | 'outlined' | 'none';
  readonly end: 'solid' | 'outlined' | 'none';
};

export function TimelineConnector({ start, end }: Props): React.ReactElement {
  return (
    <div className={classNames(
      'sts-ui-timeline-connector',
      `sts-ui-timeline-connector--start-${start}`,
      `sts-ui-timeline-connector--end-${end}`,
    )}
    />
  );
}
