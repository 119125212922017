import React from 'react';

type Props = {
  readonly children: React.ReactElement | null | undefined | boolean;
};
type State = {
  readonly children: React.ReactElement | null | undefined | boolean;
};

export class PreserveContent extends React.Component<Props, State> {
  public readonly state: State = {
    // eslint-disable-next-line react/destructuring-assignment
    children: this.props.children,
  };

  public static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State,
  ): State {
    const isEmpty = (
      nextProps.children === null ||
      nextProps.children === undefined ||
      nextProps.children === true ||
      nextProps.children === false
    );

    return isEmpty ? prevState : nextProps;
  }

  public render(): React.ReactNode {
    const { children } = this.state;
    return children;
  }
}
