import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { uuidRandom } from 'src/utils/uuid';

export function createTraveler(): P2pTicketFormDataTraveler {
  return {
    id: uuidRandom(),
    ageGroup: 'adult',
    discount: 'none',
    firstName: '',
    lastName: '',
    birthDate: null,
  };
}
