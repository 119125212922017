import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { Select } from 'src/forms/components/Select';
import { FormError } from 'src/forms/types/FormError';
import { ListOption } from 'src/types/ListOption';

type Props<TData, TValue> = {
  readonly field: FieldPath<TData, FormError, TValue | null>;
  readonly options: ReadonlyArray<ListOption<TValue>>;
  readonly clearable?: boolean;
  readonly searchable?: boolean;
  readonly disabled?: boolean;
  readonly placeholder?: React.ReactNode;
  readonly formatError?: (error: FormError) => string;
};

export function SelectField<TData, TValue>({
  field,
  options,
  clearable,
  searchable,
  disabled,
  placeholder,
  formatError,
}: Props<TData, TValue>): React.ReactElement {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);
  const validity = error !== undefined && formatError !== undefined ? formatError(error) : undefined;

  const handleChange = useCallback((nextValue: TValue | null) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <Select<TValue>
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      value={value}
      options={options}
      onBlur={handleBlur}
      onChange={handleChange}
      invalid={invalid}
      validity={validity}
      disabled={disabled}
      clearable={clearable}
      searchable={searchable}
      placeholder={placeholder}
    />
  );
}
