import { addDays } from 'date-fns/addDays';
import { subYears } from 'date-fns/subYears';
import { Decimal } from 'decimal.js-light';
import { pipe } from 'fp-ts/function';
import { min, max } from 'fp-ts/Ord';
import { Currency } from 'src/types/Currency';
import { DateOnly } from 'src/types/DateOnly';
import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketTripDetails } from 'src/types/P2pTicketTripDetails';
import { P2pTicketTripItem } from 'src/types/P2pTicketTripItem';
import { assertDefined } from 'src/utils/assert';
import { fromDate, Ord, toDate } from 'src/utils/dateOnly';
import { getTripDepartureTime } from 'src/utils/p2p/getTripDetails';

export function getTravelerMinBirthDate(
  trips: ReadonlyArray<P2pTicketTripItem>,
  traveler: P2pTicketFormDataTraveler,
): DateOnly {
  if (traveler.ageGroup === 'adult') {
    return ABS_DATE_MIN;
  }

  return pipe(
    getMaxTripDate(trips),
    toDate,
    (d) => subYears(d, CHILD_AGE_MAX),
    (d) => addDays(d, 1),
    fromDate,
  );
}

export function getTravelerMaxBirthDate(
  trips: ReadonlyArray<P2pTicketTripItem>,
  traveler: P2pTicketFormDataTraveler,
): DateOnly {
  if (traveler.ageGroup === 'adult') {
    return pipe(
      getMinTripDate(trips),
      toDate,
      (d) => subYears(d, CHILD_AGE_MAX),
      fromDate,
    );
  }

  return pipe(
    getMinTripDate(trips),
    toDate,
    (d) => subYears(d, CHILD_AGE_MIN),
    fromDate,
  );
}

export function getTravelerTicketPrice(
  trip: P2pTicketTripDetails,
  traveler: P2pTicketFormDataTraveler,
  currency: Currency,
): Decimal {
  const offer = assertDefined(
    trip.selected.offer.offers.find((it) => (
      it.passengerId === traveler.id &&
      it.qualityOfService === trip.selected.class
    )),
    'Could not find traveler offer',
    { trip, traveler },
  );

  return offer.prices[currency];
}

function getMinTripDate(
  trips: ReadonlyArray<P2pTicketTripItem>,
): DateOnly {
  return trips
    .map(getTripDepartureTime)
    .map(({ date }) => date)
    .reduce(min(Ord));
}

function getMaxTripDate(
  trips: ReadonlyArray<P2pTicketTripItem>,
): DateOnly {
  return trips
    .map(getTripDepartureTime)
    .map(({ date }) => date)
    .reduce(max(Ord));
}

const CHILD_AGE_MIN = 6;
const CHILD_AGE_MAX = 16;

const ABS_DATE_MIN: DateOnly = {
  yy: 1900,
  mm: 1,
  dd: 1,
};
