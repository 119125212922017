import { SagaIterator } from 'redux-saga';
import { createP2pTicketScreenUid } from 'src/sagas/p2p/utils/createP2pTicketScreenUid';
import { P2pTicketScreenData, P2pTicketScreenDataTripReturn } from 'src/types/P2pTicketScreenData';
import { P2pTicketTripSelection } from 'src/types/P2pTicketTripSelection';
import { call } from 'typed-redux-saga';

export function* submitTripReturnForm(
  screen: P2pTicketScreenDataTripReturn,
  selection: P2pTicketTripSelection,
): SagaIterator<P2pTicketScreenData> {
  return {
    uid: yield* call(createP2pTicketScreenUid),
    type: 'summary',
    form: screen.form,

    outward: screen.outward,
    return: {
      tripInfo: screen.tripInfo,
      selected: selection,
    },

    action: null,
  } satisfies P2pTicketScreenData;
}
