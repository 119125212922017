import { p2pTicketDataReducer } from 'src/reducers/p2p/p2pTicketDataReducer';
import { p2pTicketInitialChangeReducer } from 'src/reducers/p2p/p2pTicketInitialChangeReducer';
import { p2pTicketNavigateBackReducer } from 'src/reducers/p2p/p2pTicketNavigateBackReducer';
import { p2pTicketNavigateToReducer } from 'src/reducers/p2p/p2pTicketNavigateToReducer';
import { p2pTicketResetSearchReducer } from 'src/reducers/p2p/p2pTicketResetSearchReducer';
import { p2pTicketScreenSubmitReducer } from 'src/reducers/p2p/p2pTicketScreenSubmitReducer';
import { p2pTicketStationSearchReducer } from 'src/reducers/p2p/p2pTicketStationSearchReducer';
import { p2pTicketSummaryChangeReducer } from 'src/reducers/p2p/p2pTicketSummaryChangeReducer';
import { p2pTicketTripChangeReducer } from 'src/reducers/p2p/p2pTicketTripChangeReducer';
import { p2pTicketTripScrollReducer } from 'src/reducers/p2p/p2pTicketTripScrollReducer';
import { P2pTicketState } from 'src/types/P2pTicketState';
import { chainReducers } from 'src/utils/chainReducers';

const INITIAL_STATE: P2pTicketState = {
  screen: null,
};

export const p2pTicketReducer = chainReducers(INITIAL_STATE, [
  p2pTicketDataReducer,
  p2pTicketStationSearchReducer,
  p2pTicketInitialChangeReducer,
  p2pTicketTripScrollReducer,
  p2pTicketTripChangeReducer,
  p2pTicketSummaryChangeReducer,
  p2pTicketScreenSubmitReducer,
  p2pTicketNavigateBackReducer,
  p2pTicketNavigateToReducer,
  p2pTicketResetSearchReducer,
]);
