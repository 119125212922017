import './P2pTicketFormDropdownAnimate.scss';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

type Props = {
  readonly show: boolean;
  readonly content: React.Ref<HTMLDivElement>;
  readonly children: React.ReactNode;
};

export function P2pTicketFormDropdownAnimate({ show, content, children }: Props): React.ReactElement {
  return (
    <CSSTransition
      in={show}
      nodeRef={content}
      classNames="sts-ui-p2p-ticket-form-dropdown-animate"
      timeout={150}
      appear={true}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {children}
    </CSSTransition>
  );
}
