import './P2pTicketScreenSummaryTrip.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { Col, Row } from 'src/components/common/Grid';
import { Typography } from 'src/components/common/Typography';
import { ChevronLeftIcon } from 'src/components/icons/ChevronLeftIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { P2pTicketScreenSummaryTripDetails } from 'src/components/p2p/P2pTicketScreenSummaryTripDetails';
import { P2pTicketScreenAction } from 'src/types/P2pTicketScreenAction';
import { P2pTicketScreenDataSummary } from 'src/types/P2pTicketScreenData';

type Props = {
  readonly screen: P2pTicketScreenDataSummary;
  readonly onBack: () => void;
};

export function P2pTicketScreenSummaryTrip({ screen, onBack }: Props): React.ReactElement {
  const navigating = screen.action === P2pTicketScreenAction.GO_BACK;

  return (
    <div className="sts-ui-p2p-ticket-screen-summary-trip">
      <div className="sts-ui-p2p-ticket-screen-summary-trip__header">
        <div className="sts-ui-p2p-ticket-screen-summary-trip__header-title">
          <Typography as="p" variant="block-title">
            <FormattedMessage id="P2P.Summary.Trip.Title"/>
          </Typography>
        </div>
        <div className="sts-ui-p2p-ticket-screen-summary-trip__header-reset">
          <Button
            as="button"
            variant="outlined"
            intent="primary"
            size="sm"
            type="button"
            onClick={onBack}
            disabled={navigating}
          >
            {navigating ? <LoadingIcon/> : <ChevronLeftIcon/>}
            <span className="sts-ui-p2p-ticket-screen-summary-trip__header-reset-button-text">
              {screen.return !== null
                ? <FormattedMessage id="P2P.Summary.Trip.BackToReturn"/>
                : <FormattedMessage id="P2P.Summary.Trip.BackToOutward"/>}
            </span>
          </Button>
        </div>
      </div>

      <Row className="sts-ui-p2p-ticket-screen-summary-trip__row">
        <Col
          xs={12}
          sm={12}
          md={screen.return ? 6 : 12}
          lg={screen.return ? 6 : 12}
          className="sts-ui-p2p-ticket-screen-summary-trip__block"
        >
          <div className="sts-ui-p2p-ticket-screen-summary-trip__block-title">
            <FormattedMessage id="P2P.Summary.Trip.Outward"/>
          </div>
          <div className="sts-ui-p2p-ticket-screen-summary-trip__block-details">
            <P2pTicketScreenSummaryTripDetails trip={screen.outward}/>
          </div>
        </Col>
        {screen.return && (
          <Col xs={12} sm={12} md={6} lg={6} className="sts-ui-p2p-ticket-screen-summary-trip__block">
            <div className="sts-ui-p2p-ticket-screen-summary-trip__block-title">
              <FormattedMessage id="P2P.Summary.Trip.Return"/>
            </div>
            <div className="sts-ui-p2p-ticket-screen-summary-trip__block-details">
              <P2pTicketScreenSummaryTripDetails trip={screen.return}/>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}
