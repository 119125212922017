import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'src/hooks/useAction';
import { NETWORK_ERROR_MODAL_RETRY } from 'src/network/actions/NetworkActions';
import { getNetworkError } from 'src/network/selectors/getNetworkError';
import { NetworkErrorModal } from 'src/network/views/NetworkErrorModal';

export function NetworkErrorContainer(): React.ReactElement {
  const error = useSelector(getNetworkError);
  const retryAction = useAction(NETWORK_ERROR_MODAL_RETRY.trigger);
  const handleRetry = useCallback(() => {
    if (error) {
      retryAction(error.transition);
    }
  }, [error, retryAction]);

  return (
    <NetworkErrorModal
      shown={error !== null}
      onRetry={handleRetry}
      retrying={error?.retrying === true}
    />
  );
}
