import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { BirthDate } from 'src/forms/components/BirthDate';
import { FormError } from 'src/forms/types/FormError';
import { DateOnly } from 'src/types/DateOnly';

type Props<TData> = {
  readonly field: FieldPath<TData, FormError, DateOnly | null>;
  readonly disabled?: boolean;
  readonly formatError?: (error: FormError) => string;
  readonly min: DateOnly;
  readonly max: DateOnly;
};

export function BirthDateField<TData>({
  field,
  disabled,
  formatError,
  min,
  max,
}: Props<TData>): React.ReactElement {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);
  const validity = error !== undefined && formatError !== undefined ? formatError(error) : undefined;

  const handleChange = useCallback((nextValue: DateOnly | null) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <BirthDate
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      min={min}
      max={max}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      invalid={invalid}
      disabled={disabled}
      validity={validity}
    />
  );
}
