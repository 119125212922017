import { pipe } from 'fp-ts/function';
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import { P2P_TICKET_NAVIGATE_TO } from 'src/actions/P2pTicketActions';
import { P2P_TICKET_SCREEN } from 'src/optics/p2pTicket';
import { P2pTicketScreenAction } from 'src/types/P2pTicketScreenAction';
import { P2pTicketScreenType } from 'src/types/P2pTicketScreenType';
import { P2pTicketState } from 'src/types/P2pTicketState';

export function p2pTicketNavigateToReducer(state: P2pTicketState, action: Action): P2pTicketState {
  if (P2P_TICKET_NAVIGATE_TO.isPending(action)) {
    return pipe(
      state,
      modifyAction(SCREEN_ACTION_MAP[action.meta]),
    );
  }
  if (P2P_TICKET_NAVIGATE_TO.isSuccess(action)) {
    return { ...state, screen: action.data };
  }
  if (P2P_TICKET_NAVIGATE_TO.isFailure(action)) {
    return pipe(
      state,
      modifyAction(null),
    );
  }

  return state;
}

function modifyAction(action: P2pTicketScreenAction | null): (state: P2pTicketState) => P2pTicketState {
  return pipe(
    P2P_TICKET_SCREEN,
    O.prop('action'),
    O.modify(() => action),
  );
}

const SCREEN_ACTION_MAP: ReadonlyRecord<P2pTicketScreenType, P2pTicketScreenAction> = {
  'initial': P2pTicketScreenAction.NAVIGATE_SEARCH,
  'trip-outward': P2pTicketScreenAction.NAVIGATE_OUTWARD,
  'trip-return': P2pTicketScreenAction.NAVIGATE_RETURN,
  'summary': P2pTicketScreenAction.NAVIGATE_SUMMARY,
};
