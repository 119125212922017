import './P2pTicketScreenTripCommonList.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { ChevronDownIcon } from 'src/components/icons/ChevronDownIcon';
import { ChevronUpIcon } from 'src/components/icons/ChevronUpIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { P2pTicketScreenTripCommonListItem } from 'src/components/p2p/P2pTicketScreenTripCommonListItem';
import { Currency } from 'src/types/Currency';
import { DateOnly } from 'src/types/DateOnly';
import { P2pTicketScreenAction } from 'src/types/P2pTicketScreenAction';
import { P2pTicketScreenDataTripOutward, P2pTicketScreenDataTripReturn } from 'src/types/P2pTicketScreenData';
import { P2pTicketTripOffer } from 'src/types/P2pTicketTripOffer';
import { P2pTicketTripSelection } from 'src/types/P2pTicketTripSelection';
import { TicketClass } from 'src/types/TicketClass';
import { Ord } from 'src/utils/dateOnly';
import { canScrollTripFrame } from 'src/utils/p2p/tripFrame';

type Props = {
  readonly currency: Currency;

  readonly screen: P2pTicketScreenDataTripOutward | P2pTicketScreenDataTripReturn;
  readonly onSubmit: (value: P2pTicketTripSelection) => void;

  readonly onScrollForward: () => void;
  readonly onScrollBackward: () => void;

  readonly renderTripInfo: (selection: P2pTicketTripSelection) => React.ReactElement;
};

export function P2pTicketScreenTripCommonList({
  currency,
  screen,
  onSubmit,
  onScrollForward,
  onScrollBackward,
  renderTripInfo,
}: Props): React.ReactElement {
  const [selected, setSelected] = React.useState<P2pTicketTripSelection | null>(null);
  const handleSubmit = React.useCallback(() => {
    if (selected) {
      onSubmit(selected);
    }
  }, [selected, onSubmit]);

  return (
    <div className="sts-ui-p2p-ticket-screen-trip-common-list">
      <div className="sts-ui-p2p-ticket-screen-trip-common-list__scroll">
        <Button
          as="button"
          variant="outlined"
          intent="primary"
          size="md"
          type="button"
          onClick={onScrollBackward}
          disabled={(
            !canScrollTripFrame(screen.tripList.frame, 'previous') ||
            screen.scrolling === 'previous'
          )}
        >
          {screen.scrolling === 'previous'
            ? <LoadingIcon/>
            : <ChevronUpIcon/>}
          <FormattedMessage id="P2P.TripList.Scroll.Backward"/>
        </Button>
      </div>

      <div className="sts-ui-p2p-ticket-screen-trip-common-list__content">
        {groupByDate(screen.tripList.trips).map((tripGroup) => (
          <React.Fragment key={`${tripGroup.date.yy}-${tripGroup.date.mm}-${tripGroup.date.dd}`}>
            <div className="sts-ui-p2p-ticket-screen-trip-common-list__date">
              <div className="sts-ui-p2p-ticket-screen-trip-common-list__date-title">
                <FormattedDate value={tripGroup.date}/>
              </div>
              <div className="sts-ui-p2p-ticket-screen-trip-common-list__date-class">
                <FormattedMessage
                  id="P2P.Common.ClassName"
                  values={{ class: TicketClass.FIRST }}
                />
              </div>
              <div className="sts-ui-p2p-ticket-screen-trip-common-list__date-class">
                <FormattedMessage
                  id="P2P.Common.ClassName"
                  values={{ class: TicketClass.SECOND }}
                />
              </div>
            </div>
            {tripGroup.trips.map((tripOffer) => (
              <div key={tripOffer.trip.id} className="sts-ui-p2p-ticket-screen-trip-common-list__trip">
                <P2pTicketScreenTripCommonListItem
                  currency={currency}
                  formData={screen.form}
                  tripOffer={tripOffer}
                  onSelect={setSelected}
                  onSubmit={handleSubmit}
                  selected={selected}
                  submitting={screen.action === P2pTicketScreenAction.SUBMIT_SCREEN}
                  renderTripInfo={renderTripInfo}
                />
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>

      <div className="sts-ui-p2p-ticket-screen-trip-common-list__scroll">
        <Button
          as="button"
          variant="outlined"
          intent="primary"
          size="md"
          type="button"
          onClick={onScrollForward}
          disabled={(
            !canScrollTripFrame(screen.tripList.frame, 'next') ||
            screen.scrolling === 'next'
          )}
        >
          {screen.scrolling === 'next'
            ? <LoadingIcon/>
            : <ChevronDownIcon/>}
          <FormattedMessage id="P2P.TripList.Scroll.Forward"/>
        </Button>
      </div>
    </div>
  );
}

type TripsToday = {
  readonly date: DateOnly;
  readonly trips: P2pTicketTripOffer[];
};

function groupByDate(trips: ReadonlyArray<P2pTicketTripOffer>): TripsToday[] {
  if (trips.length === 0) {
    return [];
  }

  return trips.reduce<TripsToday[]>((result, trip) => {
    if (trip.trip.startTime === null) {
      return result;
    }

    const tripDate = trip.trip.startTime.date;
    const lastResult = result[result.length - 1];
    if (lastResult && Ord.equals(tripDate, lastResult.date)) {
      lastResult.trips.push(trip);
    } else {
      result.push({ date: tripDate, trips: [trip] });
    }

    return result;
  }, []);
}
