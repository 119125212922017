import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { mapP2pTicketStore } from 'src/sagas/p2p/utils/mapP2pTicketStore';
import { loadP2pTicketStore } from 'src/sagas/p2p/utils/storeP2pTicketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { Language } from 'src/types/Language';
import { P2pTicketScreenData } from 'src/types/P2pTicketScreenData';
import { Uuid } from 'src/types/Uuid';
import { call } from 'typed-redux-saga';

export function* loadP2pTicketData(
  language: Language,
  conversationId: Uuid,
): SagaIterator<P2pTicketScreenData | null> {
  try {
    yield* call(logDebug, 'Loading P2P Ticket data…');

    const store = yield* call(loadP2pTicketStore);
    const screen = store === null ? null : yield* call(mapP2pTicketStore, language, conversationId, store);

    yield* call(logDebug, 'Loading P2P Ticket data… done', screen);
    return screen;
  } catch (error) {
    yield* call(logError, 'Loading P2P Ticket data… error', error);
    throw new RuntimeError('Could not load P2P Ticket data', {}, NativeError.wrap(error));
  }
}
