import { identity, pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { Uuid } from 'src/types/Uuid';

const REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const GUARD = (value: string): value is Uuid => REGEX.test(value);

const DECODER: D.Decoder<unknown, Uuid> = pipe(
  D.string,
  D.refine(GUARD, 'Uuid'),
);

const ENCODER: E.Encoder<string, Uuid> = {
  encode: identity,
};

export const UUID: C.Codec<unknown, string, Uuid> = C.make(DECODER, ENCODER);
