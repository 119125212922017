import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_TRIP_LIST_SIZE } from 'src/constants/p2pTicket';
import { fetchP2pTripCollection } from 'src/sagas/p2p/utils/fetchP2pTripCollection';
import { Language } from 'src/types/Language';
import { P2pTicketTripFrame } from 'src/types/P2pTicketTripFrame';
import { P2pTicketTripInfo } from 'src/types/P2pTicketTripInfo';
import { P2pTicketTripScrollDirection } from 'src/types/P2pTicketTripScroll';
import { concatTripCollection } from 'src/utils/p2p/tripCollection';
import { getTripFrameItems, removeNextTripFrameContext, removePrevTripFrameContext } from 'src/utils/p2p/tripFrame';
import { call } from 'typed-redux-saga';

export function* scrollP2pTripFrame(
  language: Language,
  tripInfo: P2pTicketTripInfo,
  tripFrame: P2pTicketTripFrame,
  scrollDirection: P2pTicketTripScrollDirection,
): SagaIterator<P2pTicketTripFrame> {
  let currCollection = tripFrame.collection;
  if (scrollDirection === 'next') {
    const nextTop = tripFrame.bottom;
    while (currCollection.trips.length - nextTop < P2P_TICKET_TRIP_LIST_SIZE) {
      const nextContext = currCollection.nextTripsCollectionId;
      if (nextContext === null) {
        break;
      }

      const nextCollection = yield* call(fetchP2pTripCollection, language, tripInfo, {
        context: nextContext,
        direction: 'next',
      });

      currCollection = yield* call(
        concatTripCollection,
        currCollection,
        nextCollection,
      );
    }

    const resultFrame: P2pTicketTripFrame = {
      top: nextTop,
      bottom: Math.min(nextTop + P2P_TICKET_TRIP_LIST_SIZE, currCollection.trips.length),
      collection: currCollection,
    };

    const resultItems = yield* call(getTripFrameItems, resultFrame);
    return resultItems.length === 0
      ? yield* call(removeNextTripFrameContext, tripFrame)
      : resultFrame;
  } else {
    let nextBottom = tripFrame.top;
    while (nextBottom < P2P_TICKET_TRIP_LIST_SIZE) {
      const prevContext = currCollection.prevTripsCollectionId;
      if (prevContext === null) {
        break;
      }

      const prevCollection = yield* call(fetchP2pTripCollection, language, tripInfo, {
        context: prevContext,
        direction: 'previous',
      });

      currCollection = yield* call(
        concatTripCollection,
        prevCollection,
        currCollection,
      );

      nextBottom += prevCollection.trips.length;
    }

    const resultFrame: P2pTicketTripFrame = {
      top: Math.max(nextBottom - P2P_TICKET_TRIP_LIST_SIZE, 0),
      bottom: nextBottom,
      collection: currCollection,
    };

    const resultItems = yield* call(getTripFrameItems, resultFrame);
    return resultItems.length === 0
      ? yield* call(removePrevTripFrameContext, tripFrame)
      : resultFrame;
  }
}
