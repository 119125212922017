import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_NAVIGATE_BACK } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { createP2pTicketScreenUid } from 'src/sagas/p2p/utils/createP2pTicketScreenUid';
import { createOutwardTripInfo, createReturnTripInfo } from 'src/sagas/p2p/utils/createTripInfo';
import { fetchP2pTripList } from 'src/sagas/p2p/utils/fetchP2pTripList';
import { getP2pMinimalTripDate } from 'src/sagas/p2p/utils/getP2pMinimalTripDate';
import { saveP2pTicketData } from 'src/sagas/p2p/utils/saveP2pTicketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getP2pTicketConversationId } from 'src/selectors/getP2pTicketConversationId';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { P2pTicketScreenData } from 'src/types/P2pTicketScreenData';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* p2pTicketNavigateBackSaga(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Navigating to previous P2P Ticket screen…');
    yield* put(P2P_TICKET_NAVIGATE_BACK.pending());
    yield* delay(500);

    const language = yield* select(getCurrentLanguage);
    const conversationId = yield* select(getP2pTicketConversationId);

    const currentScreen = yield* select(getP2pTicketScreen);
    let updatedScreen: P2pTicketScreenData;
    if (currentScreen.type === 'summary') {
      if (currentScreen.return === null) {
        const tripInfo = yield* call(createOutwardTripInfo, currentScreen.form);
        const tripList = yield* call(
          fetchP2pTripList,
          language,
          currentScreen.form.travelers,
          conversationId,
          tripInfo,
        );

        updatedScreen = {
          uid: yield* call(createP2pTicketScreenUid),
          type: 'trip-outward',
          form: currentScreen.form,

          outward: null,
          return: null,

          minDate: yield* call(getP2pMinimalTripDate),
          stations: null,
          scrolling: null,

          tripInfo: tripInfo,
          tripList: tripList,

          action: null,
        };
      } else {
        const tripInfo = yield* call(createReturnTripInfo, currentScreen.form);
        const tripList = yield* call(
          fetchP2pTripList,
          language,
          currentScreen.form.travelers,
          conversationId,
          tripInfo,
        );

        updatedScreen = {
          uid: yield* call(createP2pTicketScreenUid),
          type: 'trip-return',
          form: currentScreen.form,

          outward: currentScreen.outward,
          return: null,

          minDate: yield* call(getP2pMinimalTripDate),
          stations: null,
          scrolling: null,

          tripInfo: tripInfo,
          tripList: tripList,

          action: null,
        };
      }
    } else if (currentScreen.type === 'trip-return') {
      const tripInfo = yield* call(createOutwardTripInfo, currentScreen.form);
      const tripList = yield* call(
        fetchP2pTripList,
        language,
        currentScreen.form.travelers,
        conversationId,
        tripInfo,
      );

      updatedScreen = {
        uid: yield* call(createP2pTicketScreenUid),
        type: 'trip-outward',
        form: currentScreen.form,

        outward: null,
        return: null,

        minDate: yield* call(getP2pMinimalTripDate),
        stations: null,
        scrolling: null,

        tripInfo: tripInfo,
        tripList: tripList,

        action: null,
      };
    } else if (currentScreen.type === 'trip-outward') {
      updatedScreen = {
        uid: yield* call(createP2pTicketScreenUid),
        type: 'initial',
        form: currentScreen.form,

        outward: null,
        return: null,

        minDate: yield* call(getP2pMinimalTripDate),
        stations: null,

        action: null,
      };
    } else {
      updatedScreen = currentScreen;
    }

    yield* call(saveP2pTicketData, updatedScreen);

    yield* call(logDebug, 'Navigating to previous P2P Ticket screen… done', updatedScreen);
    yield* put(P2P_TICKET_NAVIGATE_BACK.success(updatedScreen));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not navigate to previous P2P Ticket screen',
      {},
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Navigating to previous P2P Ticket screen… error', error);
    yield* put(P2P_TICKET_NAVIGATE_BACK.failure(NativeError.wrap(error)));
  }
}
