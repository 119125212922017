import { Decimal } from 'decimal.js-light';
import { JsonRecord } from 'fp-ts/Json';
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import * as C from 'io-ts/Codec';
import { Optional } from 'src/codecs/combinators/Optional';
import { INTEGER } from 'src/codecs/Integer';
import { LOCAL_DATE } from 'src/codecs/LocalDate';
import { LOCAL_TIME } from 'src/codecs/LocalTime';
import { ORDER_AMOUNT } from 'src/codecs/OrderAmount';
import { P2P_TICKET_TRIP_DIRECTION } from 'src/codecs/P2pTicketTripDirection';
import { TICKET_CLASS } from 'src/codecs/TicketClass';
import { Currency } from 'src/types/Currency';
import {
  P2pTicketOffer,
  P2pTicketOfferArea,
  P2pTicketOfferDateTime,
  P2pTicketOfferRoute,
  P2pTicketOfferRouteValidity,
  P2pTicketOfferTicketValidity,
  P2pTicketOfferValidityArea,
  P2pTicketOfferZoneValidityArea,
} from 'src/types/P2pTicketOffer';

const OFFER_DATE_TIME: C.Codec<unknown, JsonRecord, P2pTicketOfferDateTime> = C.struct({
  date: LOCAL_DATE,
  time: Optional(LOCAL_TIME),
});

const OFFER_ROUTE: C.Codec<unknown, JsonRecord, P2pTicketOfferRoute> = C.struct({
  viaText: Optional(C.string),
});

const OFFER_ROUTE_VALIDITY: C.Codec<unknown, JsonRecord, P2pTicketOfferRouteValidity> = C.struct({
  direction: P2P_TICKET_TRIP_DIRECTION,
  routes: C.readonly(C.array(OFFER_ROUTE)),
});

const OFFER_TICKET_VALIDITY: C.Codec<unknown, JsonRecord, P2pTicketOfferTicketValidity> = C.struct({
  from: OFFER_DATE_TIME,
  to: OFFER_DATE_TIME,
});

const OFFER_PRICE_MAP: C.Codec<unknown, JsonRecord, ReadonlyRecord<Currency, Decimal>> = C.struct({
  CHF: ORDER_AMOUNT,
  EUR: ORDER_AMOUNT,
  GBP: ORDER_AMOUNT,
  USD: ORDER_AMOUNT,
});

const OFFER_ZONE_VALIDITY_AREA: C.Codec<unknown, JsonRecord, P2pTicketOfferZoneValidityArea> = C.struct({
  allZones: C.boolean,
  networkId: C.number,
  zones: C.readonly(C.array(C.string)),
});

const OFFER_AREA: C.Codec<unknown, JsonRecord, P2pTicketOfferArea> = C.struct({
  areaCode: C.number,
  areaLabel: Optional(C.string),
  areaName: C.string,
  zoneCodes: C.readonly(C.array(C.string)),
});

const OFFER_VALIDITY_AREA: C.Codec<unknown, JsonRecord, P2pTicketOfferValidityArea> = C.struct({
  leadingArea: Optional(OFFER_AREA),
  tailingArea: Optional(OFFER_AREA),
  zoneValidityArea: Optional(OFFER_ZONE_VALIDITY_AREA),
});

export const P2P_TICKET_OFFER: C.Codec<unknown, JsonRecord, P2pTicketOffer> = C.struct({
  offerIdentifier: C.string,
  offerType: C.string,

  productId: INTEGER,
  passengerId: C.string,

  title: C.string,
  description: C.string,

  prices: OFFER_PRICE_MAP,
  qualityOfService: TICKET_CLASS,

  routeValidity: OFFER_ROUTE_VALIDITY,
  ticketValidity: OFFER_TICKET_VALIDITY,
  validityArea: Optional(OFFER_VALIDITY_AREA),
});
