import * as C from 'io-ts/Codec';
import { ProductMap as Type } from 'src/types/ProductMap';

export function ProductMap<T, O>(codec: C.Codec<unknown, O, T>): C.Codec<unknown, Type<O>, Type<T>> {
  return C.partial({
    swissPass: codec,
    swissFlexiPass: codec,
    swissHalfFareCard: codec,

    excursionJungfraujoch: codec,
    excursionGornergrat: codec,
    excursionMatterhornParadise: codec,
    excursionPilatus: codec,
    excursionSchilthornMurren: codec,
    excursionSchilthornFromStechelberg: codec,
    excursionStanserhorn: codec,
    excursionTitlis: codec,
    excursionGlacier: codec,
    excursionRochersDeNaye: codec,

    regionalBerneseOberlandPass: codec,
    regionalCentralSwissPass: codec,
    regionalJungfrauTravelPass: codec,
  });
}
