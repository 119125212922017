import './P2pTicketScreenCommonTripLegList.scss';
import { not } from 'fp-ts/Predicate';
import React from 'react';
import { P2pTicketScreenCommonTripLegItem } from 'src/components/p2p/P2pTicketScreenCommonTripLegItem';
import { P2pTicketTripItem } from 'src/types/P2pTicketTripItem';
import { getTripLegsWithChanges, isTripLegChange } from 'src/utils/p2p/getTripDetails';

type Props = {
  readonly trip: P2pTicketTripItem;
};

export function P2pTicketScreenCommonTripLegList({ trip }: Props): React.ReactElement {
  const legsWithChanges = getTripLegsWithChanges(trip);
  const firstTransportLeg = legsWithChanges.find(not(isTripLegChange));
  const lastTransportLeg = legsWithChanges.slice().reverse().find(not(isTripLegChange));

  return (
    <div className="sts-ui-p2p-ticket-screen-common-trip-leg-list">
      {getTripLegsWithChanges(trip).map((tripLeg, index) => (
        <P2pTicketScreenCommonTripLegItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          tripLeg={tripLeg}
          isFirst={tripLeg === firstTransportLeg}
          isLast={tripLeg === lastTransportLeg}
        />
      ))}
    </div>
  );
}
