import './P2pTicketScreenTripCommonFormTravelerList.scss';
import { FieldPath } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import { modify } from 'monocle-ts/Lens';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { PlusCircleIcon } from 'src/components/icons/PlusCircleIcon';
import { P2pTicketScreenTripCommonFormTravelerItem } from 'src/components/p2p/P2pTicketScreenTripCommonFormTravelerItem';
import { FormError } from 'src/forms/types/FormError';
import { P2pTicketFormData, P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { createTraveler } from 'src/utils/p2p/createTraveler';

type Props = {
  readonly field: FieldPath<P2pTicketFormData, FormError, P2pTicketFormData['travelers']>;
};

export function P2pTicketScreenTripCommonFormTravelerList({ field }: Props): React.ReactElement {
  const travelers = useFormWatch(field.form, field.value.get);

  const handleAddTraveler = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      pipe(field.value, modify((list) => list.concat(createTraveler()))),
    ));
  }, [field]);

  const handleRemoveTraveler = useCallback((traveler: P2pTicketFormDataTraveler) => {
    field.form.change(pipe(
      field.form.currentState,
      pipe(field.value, modify((list) => list.filter((it) => it.id !== traveler.id))),
    ));
  }, [field]);

  return (
    <div className="sts-ui-p2p-ticket-screen-trip-common-form-traveler-list">
      {travelers.map((traveler, index) => (
        <div key={traveler.id} className="sts-ui-p2p-ticket-screen-trip-common-form-traveler-list__traveler">
          <P2pTicketScreenTripCommonFormTravelerItem
            field={field.at(index)}
            onRemove={index === 0 ? undefined : handleRemoveTraveler}
          />
        </div>
      ))}
      <div className="sts-ui-p2p-ticket-screen-trip-common-form-traveler-list__action">
        <Button
          as="button"
          variant="link"
          intent="primary"
          size="md"
          type="button"
          onClick={handleAddTraveler}
          disabled={travelers.length >= MAX_TRAVELERS}
        >
          <PlusCircleIcon/>
          <FormattedMessage id="P2P.Form.Traveler.AddTraveler"/>
        </Button>
      </div>
    </div>
  );
}

const MAX_TRAVELERS = 9;
