import { createSelector } from 'reselect';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { P2pTicketScreenDataSummary } from 'src/types/P2pTicketScreenData';
import { assertNever } from 'src/utils/assert';

export const getP2pTicketSummaryScreen = createSelector([
  getP2pTicketScreen,
], (screen): P2pTicketScreenDataSummary => {
  if (screen.type !== 'summary') {
    return assertNever('Unexpected P2P Ticket Summary screen', { screen });
  }

  return screen;
});
