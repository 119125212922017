import { FormRule } from 'src/forms/types/FormRule';
import * as R from 'src/forms/utils/rules';
import { P2pTicketFormData } from 'src/types/P2pTicketFormData';
import { P2pTicketTripItem } from 'src/types/P2pTicketTripItem';
import { createTravelerFormRule } from 'src/utils/p2p/createTravelerFormRule';

export function createSummaryFormRule(
  trips: ReadonlyArray<P2pTicketTripItem>,
): FormRule<P2pTicketFormData> {
  return R.struct({
    trip: R.pass(),
    travelers: R.array(R.lazy((traveler) => createTravelerFormRule(trips, traveler))),
  });
}
