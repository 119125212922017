import './ProductSecondaryCard.scss';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProductCardDescription } from 'src/components/common/ProductCardDescription';
import { ProductName } from 'src/components/common/ProductName';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { ProductCardInformation } from 'src/components/product-list/ProductCardInformation';
import { ProductCardPriceValue } from 'src/components/product-list/ProductCardPriceValue';
import { SEASON } from 'src/constants/season';
import { OVERVIEW_IMAGE } from 'src/constants/tickets';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { ProductCode } from 'src/types/ProductCode';

type Props = {
  readonly code: ProductCode;
  readonly price: Decimal;
  readonly fixPrice: boolean;
  readonly promo?: React.ReactElement;
};

export function ProductSecondaryCard({ code, price, fixPrice, promo }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-secondary-card">
      <RouteLink route={`product:${code}`} className="sts-ui-product-secondary-card__image">
        <figure>
          <img {...OVERVIEW_IMAGE[SEASON][code]} alt=""/>
        </figure>
      </RouteLink>

      <h2 className="sts-ui-product-secondary-card__title">
        <RouteLink route={`product:${code}`}>
          <ProductName type={code}/>
        </RouteLink>
      </h2>

      <div className="sts-ui-product-secondary-card__price">
        <div className="sts-ui-product-secondary-card__value">
          <ProductCardPriceValue
            price={price}
            title={<FormattedMessage id="ProductCard.Primary.PriceFrom"/>}
            fixPrice={fixPrice}
          />
        </div>

        <div className="sts-ui-product-secondary-card__info">
          <ProductCardInformation
            code={code}
            title={<FormattedMessage id="ProductCard.Primary.Info"/>}
          />
        </div>
      </div>

      <div className="sts-ui-product-secondary-card__description">
        <ProductCardDescription type={code}/>
      </div>

      <div className="sts-ui-product-secondary-card__link">
        <RouteLink route={`product:${code}`} className="sts-ui-product-secondary-card__more">
          <FormattedMessage id="ProductCard.Secondary.View"/>
          <ArrowRightIcon/>
        </RouteLink>
      </div>

      {promo && (
        <div className="sts-ui-product-secondary-card__promo">
          {promo}
        </div>
      )}
    </div>
  );
}
