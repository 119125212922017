import './P2pTicketScreenTripCommonListItem.scss';
import classNames from 'classnames';
import { Decimal } from 'decimal.js-light';
import { not } from 'fp-ts/Predicate';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'src/components/common/Button';
import { Collapsible } from 'src/components/common/Collapsible';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { FormattedTime } from 'src/components/common/FormattedTime';
import { PreserveContent } from 'src/components/common/PreserveContent';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { P2pTicketFormRadio } from 'src/components/p2p/P2pTicketFormRadio';
import { P2pTicketScreenCommonTripDuration } from 'src/components/p2p/P2pTicketScreenCommonTripDuration';
import { P2pTicketScreenCommonTripLegList } from 'src/components/p2p/P2pTicketScreenCommonTripLegList';
import { P2pTicketScreenCommonTripLegService } from 'src/components/p2p/P2pTicketScreenCommonTripLegService';
import { P2pTicketScreenCommonTripVehicleType } from 'src/components/p2p/P2pTicketScreenCommonTripVehicleType';
import { Currency } from 'src/types/Currency';
import { P2pTicketFormData } from 'src/types/P2pTicketFormData';
import { P2pTicketTripOffer } from 'src/types/P2pTicketTripOffer';
import { P2pTicketTripSelection } from 'src/types/P2pTicketTripSelection';
import { TicketClass } from 'src/types/TicketClass';
import {
  getTripArrivalTime,
  getTripChangeCount,
  getTripDepartureTime,
  isTripLegChange,
} from 'src/utils/p2p/getTripDetails';

type Props = {
  readonly currency: Currency;
  readonly formData: P2pTicketFormData;
  readonly tripOffer: P2pTicketTripOffer;
  readonly selected: P2pTicketTripSelection | null;
  readonly onSelect: (value: P2pTicketTripSelection) => void;
  readonly submitting: boolean;
  readonly onSubmit: () => void;
  readonly renderTripInfo: (selection: P2pTicketTripSelection) => React.ReactElement;
};

export const P2pTicketScreenTripCommonListItem = React.memo(({
  currency,
  formData,
  tripOffer,
  selected,
  onSelect,
  submitting,
  onSubmit,
  renderTripInfo,
}: Props): React.ReactElement => {
  const summary = useRef<HTMLDivElement>(null);

  const price1stClass = getTotalPrice(
    tripOffer,
    formData,
    currency,
    TicketClass.FIRST,
  );
  const price2ndClass = getTotalPrice(
    tripOffer,
    formData,
    currency,
    TicketClass.SECOND,
  );

  const departureDate = getTripDepartureTime(tripOffer.trip);
  const arrivalDate = getTripArrivalTime(tripOffer.trip);
  const firstTransportLeg = tripOffer.trip.legs.find(not(isTripLegChange));

  const selectedClass = selected?.offer.trip.id === tripOffer.trip.id
    ? selected.class
    : null;

  const handleSelect1st = useCallback(() => onSelect({
    class: TicketClass.FIRST,
    offer: tripOffer,
  }), [onSelect, tripOffer]);
  const handleSelect2nd = useCallback(() => onSelect({
    class: TicketClass.SECOND,
    offer: tripOffer,
  }), [onSelect, tripOffer]);

  const mainClassOption = selectedClass !== null
    ? selectedClass
    : price2ndClass !== null
      ? TicketClass.SECOND
      : price1stClass !== null
        ? TicketClass.FIRST
        : null;

  return (
    <div className="sts-ui-p2p-ticket-screen-trip-common-list-item">
      <label
        htmlFor={mainClassOption ? `${tripOffer.trip.id}-${mainClassOption}` : undefined}
        className={classNames(
          'sts-ui-p2p-ticket-screen-trip-common-list-item__header',
          selectedClass ? 'sts-ui-p2p-ticket-screen-trip-common-list-item__header--open' : null,
        )}
      >
        <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-title">
          <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-time">
            <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-time-value">
              <FormattedTime value={departureDate.time}/>
            </div>
            <div>
              <ArrowRightIcon/>
            </div>
            <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-time-value">
              <FormattedTime value={arrivalDate.time}/>
            </div>
          </div>
          <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-duration">
            <P2pTicketScreenCommonTripDuration
              duration={tripOffer.trip.duration}
            />
            {', '}
            <FormattedMessage
              id="P2P.TripInfo.ChangeCount"
              values={{ count: getTripChangeCount(tripOffer.trip) }}
            />
          </div>
        </div>
        <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-transport">
          {firstTransportLeg?.timedLeg && (
            <span
              title={firstTransportLeg.timedLeg.service.mode.name}
              className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-transport-vehicle"
            >
              <P2pTicketScreenCommonTripVehicleType mode={firstTransportLeg.timedLeg.service.mode}/>
            </span>
          )}
          {firstTransportLeg?.timedLeg && (
            <span
              title={firstTransportLeg.timedLeg.service.publishedServiceName}
              className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-transport-service"
            >
              <P2pTicketScreenCommonTripLegService service={firstTransportLeg.timedLeg.service}/>
            </span>
          )}
        </div>
        <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-price">
          {price1stClass !== null && (
            <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-class">
              <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-class-name">
                <FormattedMessage
                  id="P2P.Common.ClassName"
                  values={{ class: TicketClass.FIRST }}
                />
              </div>

              <P2pTicketFormRadio
                id={`${tripOffer.trip.id}-${TicketClass.FIRST}`}
                name={tripOffer.trip.id}
                label={<FormattedPrice value={price1stClass}/>}
                value={selectedClass === TicketClass.FIRST}
                onChange={handleSelect1st}
                disabled={submitting}
              />
            </div>
          )}
          {price2ndClass !== null && (
            <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-class">
              <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__header-class-name">
                <FormattedMessage
                  id="P2P.Common.ClassName"
                  values={{ class: TicketClass.SECOND }}
                />
              </div>

              <P2pTicketFormRadio
                id={`${tripOffer.trip.id}-${TicketClass.SECOND}`}
                name={tripOffer.trip.id}
                label={<FormattedPrice value={price2ndClass}/>}
                value={selectedClass === TicketClass.SECOND}
                onChange={handleSelect2nd}
                disabled={submitting}
              />
            </div>
          )}
        </div>
      </label>
      <Collapsible expanded={selectedClass !== null} duration={700}>
        <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__content">
          <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__details">
            <P2pTicketScreenCommonTripLegList trip={tripOffer.trip}/>
          </div>

          <CSSTransition
            in={selectedClass !== null}
            nodeRef={summary}
            classNames="sts-ui-p2p-ticket-screen-trip-common-list-item__summary--animate"
            timeout={200}
            appear={true}
            mountOnEnter={false}
            unmountOnExit={false}
          >
            <div ref={summary} className="sts-ui-p2p-ticket-screen-trip-common-list-item__summary">
              <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__summary-details">
                <PreserveContent>
                  {selectedClass
                    ? renderTripInfo({ class: selectedClass, offer: tripOffer })
                    : null}
                </PreserveContent>
              </div>
              <Button
                as="button"
                variant="solid"
                intent="primary"
                size="lg"
                type="submit"
                onClick={onSubmit}
                disabled={submitting}
                className="sts-ui-p2p-ticket-screen-trip-common-list-item__summary-action"
              >
                <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__summary-action-text">
                  <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__summary-action-text-header">
                    <FormattedMessage id="P2P.TripInfo.Submit.Title"/>
                  </div>
                  <div className="sts-ui-p2p-ticket-screen-trip-common-list-item__summary-action-text-details">
                    <FormattedMessage id="P2P.TripInfo.Submit.Description"/>
                  </div>
                </div>

                {submitting
                  ? <LoadingIcon/>
                  : <ArrowRightIcon/>}
              </Button>
            </div>
          </CSSTransition>
        </div>
      </Collapsible>
    </div>
  );
});

function getTotalPrice(
  tripOffer: P2pTicketTripOffer,
  formData: P2pTicketFormData,
  currency: Currency,
  ticketClass: TicketClass,
): Decimal | null {
  const offers = tripOffer.offers.filter((it) => it.qualityOfService === ticketClass);
  return offers.length === formData.travelers.length
    ? offers.reduce((sum, it) => sum.add(it.prices[currency]), new Decimal(0))
    : null;
}
