import './ProductFeatureList.scss';
import React from 'react';
import { FormattedHTMLMessage } from 'src/components/common/FormattedHTMLMessage';
import { BulletPointIcon } from 'src/components/icons/BulletPointIcon';
import { TranslationKey } from 'src/types/TranslationKey';

type Props = {
  readonly features: ReadonlyArray<TranslationKey>;
};

export function ProductFeatureList({ features }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-feature-list">
      {features.map((feature, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="sts-ui-product-feature-list__item">
          <div className="sts-ui-product-feature-list__icon">
            <BulletPointIcon/>
          </div>
          <div className="sts-ui-product-feature-list__text">
            <FormattedHTMLMessage id={feature}/>
          </div>
        </div>
      ))}
    </div>
  );
}
