import { pipe } from 'fp-ts/function';
import { LocalDateTime } from 'src/types/LocalDateTime';
import { P2pTicketTripItem, P2pTicketTripLeg } from 'src/types/P2pTicketTripItem';
import { assertNever, assertNotEmpty } from 'src/utils/assert';
import { isNotEmpty } from 'src/utils/guard';
import { fromDate, toDate } from 'src/utils/localDateTime';
import { getDurationISO8601 } from 'src/utils/p2p/duration';

export function getTripMinDate(minDate: LocalDateTime): LocalDateTime {
  const msIn10mins = 60 * 10 * 1000;
  return pipe(
    minDate,
    toDate,
    (nativeDate) => nativeDate.getTime(),
    (timeStamp) => (Math.floor(timeStamp / msIn10mins) + 1) * msIn10mins,
    (timeStamp) => new Date(timeStamp),
    fromDate,
  );
}

export function getTripDepartureTime(trip: P2pTicketTripItem): LocalDateTime {
  return assertNotEmpty(
    trip.startTime,
    'Missing trip departure date',
    { trip },
  );
}

export function getTripArrivalTime(trip: P2pTicketTripItem): LocalDateTime {
  if (isNotEmpty(trip.endTime)) {
    return trip.endTime;
  }

  const leg = trip.legs[trip.legs.length - 1];
  return assertNotEmpty(
    leg ? getLegEndTime(leg) : null,
    'Could not get last leg end time of the trip',
    { trip },
  );
}

export function getTripLegDepartureTime(tripLeg: P2pTicketTripLeg): LocalDateTime {
  if (isNotEmpty(tripLeg.timedLeg)) {
    return assertNotEmpty(
      tripLeg.timedLeg.start.serviceDeparture.timetabledTime,
      'Missing trip segment departure date',
      { tripLeg },
    );
  }

  return assertNever('Could not resolve leg departure time');
}

export function getTripLegArrivalTime(tripLeg: P2pTicketTripLeg): LocalDateTime {
  if (isNotEmpty(tripLeg.timedLeg)) {
    return assertNotEmpty(
      tripLeg.timedLeg.end.serviceArrival.timetabledTime,
      'Missing trip segment arrival date',
      { tripLeg },
    );
  }

  return assertNever('Could not resolve leg arrival time');
}

export function getLegEndTime(tripLeg: P2pTicketTripLeg): LocalDateTime | null {
  if (isNotEmpty(tripLeg.timedLeg)) {
    return tripLeg.timedLeg.end.serviceArrival.timetabledTime;
  }
  if (isNotEmpty(tripLeg.transferLeg)) {
    return tripLeg.transferLeg.timeWindowEnd;
  }
  return null;
}

export function getLegStartTime(tripLeg: P2pTicketTripLeg): LocalDateTime | null {
  if (isNotEmpty(tripLeg.timedLeg)) {
    return tripLeg.timedLeg.start.serviceDeparture.timetabledTime;
  }
  return null;
}

export function getTripLegDuration(tripLeg: P2pTicketTripLeg): string | null {
  if (isNotEmpty(tripLeg.timedLeg)) {
    return tripLeg.timedLeg.duration;
  }
  if (isNotEmpty(tripLeg.transferLeg)) {
    return tripLeg.transferLeg.duration;
  }
  if (isNotEmpty(tripLeg.continuousLeg)) {
    return tripLeg.continuousLeg.duration;
  }

  return null;
}

export function getTripChangeCount(trip: P2pTicketTripItem): number {
  return trip.transfers;
}

export function getTripLegsWithChanges(trip: P2pTicketTripItem): P2pTicketTripLeg[] {
  return trip.legs.reduce<P2pTicketTripLeg[]>((result, tripLeg, index, array) => {
    if (isTripLegChange(tripLeg)) {
      result.push(tripLeg);
      return result;
    }

    const previous = array[index - 1];
    if (previous == null || isTripLegChange(previous)) {
      result.push(tripLeg);
      return result;
    }

    const prevLegEndTime = getLegEndTime(previous);
    const currentLedStartTime = getLegStartTime(tripLeg);

    if (isNotEmpty(prevLegEndTime) && isNotEmpty(currentLedStartTime)) {
      const durationISO8601 = getDurationISO8601(prevLegEndTime, currentLedStartTime);
      const change: P2pTicketTripLeg = {
        timedLeg: null,
        transferLeg: {
          transferMode: 'FOOT',
          duration: durationISO8601,
          timeWindowEnd: null,
        },
        continuousLeg: null,
      };

      result.push(change, tripLeg);
      return result;
    }

    result.push(tripLeg);
    return result;
  }, []);
}

export function isTripLegChange(tripLeg: P2pTicketTripLeg): boolean {
  return isNotEmpty(tripLeg.transferLeg);
}
