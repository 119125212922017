import './P2pTicketScreenInitial.scss';
import { useForm } from '@form-ts/react';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Typography } from 'src/components/common/Typography';
import { P2pTicketScreenInitialForm } from 'src/components/p2p/P2pTicketScreenInitialForm';
import { ProductFaqList } from 'src/components/product/ProductFaqList';
import { useFormValidator } from 'src/forms/hooks/useFormValidator';
import { FormError } from 'src/forms/types/FormError';
import { validate } from 'src/forms/utils/validate';
import { P2pTicketAttibutes } from 'src/types/P2pTicketAttibutes';
import { P2pTicketFormData } from 'src/types/P2pTicketFormData';
import { P2pTicketScreenAction } from 'src/types/P2pTicketScreenAction';
import { P2pTicketScreenDataInitial } from 'src/types/P2pTicketScreenData';
import { P2pTicketStationRequest } from 'src/types/P2pTicketStationRequest';
import { createInitialFormRule } from 'src/utils/p2p/createInitialFormRule';

type Props = {
  readonly screen: P2pTicketScreenDataInitial;
  readonly onStationSearch: (request: P2pTicketStationRequest) => void;
  readonly onChange: (formData: P2pTicketFormData) => void;
  readonly onSubmit: (formData: P2pTicketFormData) => void;
  readonly attributes: P2pTicketAttibutes;
};

export function P2pTicketScreenInitial({
  screen,
  onStationSearch,
  onChange,
  onSubmit,
  attributes,
}: Props): React.ReactElement {
  const formRule = useMemo(() => createInitialFormRule(), []);
  const formErrors = useMemo(() => validate(screen.form, formRule), [screen.form, formRule]);

  const form = useForm<P2pTicketFormData, FormError>('p2p.ticket.initial', {
    reinitialize: true,
    initialValues: screen.form,
    initialErrors: formErrors,
  });
  useFormValidator(form, formRule);

  useEffect(() => form.subscribe((prevState) => {
    const nextState = form.currentState;
    if (prevState.values !== nextState.values) {
      onChange(nextState.values);
    }
  }), [form, onChange]);

  const handleSubmit = useCallback(() => {
    onSubmit(form.currentState.values);
  }, [form, onSubmit]);

  return (
    <div className="sts-ui-p2p-ticket-screen-initial">
      <div className="sts-ui-p2p-ticket-screen-initial__page-title">
        <Typography variant="page-title" as="h1">
          <FormattedMessage id="P2P.Page.Title"/>
        </Typography>
      </div>
      <div className="sts-ui-p2p-ticket-screen-initial__page-subtitle">
        <Typography variant="page-subtitle" as="div">
          <FormattedHTMLMessage id="P2P.Page.Subtitle"/>
        </Typography>
      </div>

      <div className="sts-ui-p2p-ticket-screen-initial__block">
        <div className="sts-ui-p2p-ticket-screen-initial__form">
          <P2pTicketScreenInitialForm
            form={form}
            onSubmit={handleSubmit}
            submitting={screen.action === P2pTicketScreenAction.SUBMIT_SCREEN}
            minDate={screen.minDate}
            stations={screen.stations}
            onStationSearch={onStationSearch}
          />
        </div>
      </div>

      <div className="sts-ui-p2p-ticket-screen-initial__block">
        <div className="sts-ui-p2p-ticket-screen-initial__block-title">
          <Typography variant="block-title" as="div">
            <FormattedHTMLMessage id="P2P.Search.FAQ.Title"/>
          </Typography>
        </div>

        <ProductFaqList sections={attributes.faqSections}/>
      </div>
    </div>
  );
}
