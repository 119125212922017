import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_FETCH } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { ensureBasketConversationId } from 'src/sagas/basket/utils/ensureBasketConversationId';
import { fetchP2pTicketData } from 'src/sagas/p2p/utils/fetchP2pTicketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { Language } from 'src/types/Language';
import { call, put } from 'typed-redux-saga';

export function* fetchP2pTicket(language: Language): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Fetching P2P Ticket…');

    yield* call(ensureBasketConversationId);
    const product = yield* call(fetchP2pTicketData, language);

    yield* call(logDebug, 'Fetching P2P Ticket… done', product);
    yield* put(P2P_TICKET_FETCH.trigger(product));
  } catch (error) {
    yield* call(logError, 'Fetching P2P Ticket… error', error);
    throw new RuntimeError('Could not fetch P2P Ticket', {}, NativeError.wrap(error));
  }
}
