import './P2pTicketFormTextbox.scss';
import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly value: string;
  readonly onBlur?: () => void;
  readonly onChange: (value: string) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly placeholder?: string;
};

export function P2pTicketFormTextbox({
  id,
  name,
  type,
  layout,
  border,
  label,
  value,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  placeholder,
}: Props): React.ReactElement {
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  return (
    <div
      data-invalid={invalid}
      data-disabled={disabled}
      className={classNames(
        'sts-ui-p2p-ticket-form-textbox',
        `sts-ui-p2p-ticket-form-textbox--layout-${layout}`,
        `sts-ui-p2p-ticket-form-textbox--border-${border}`,
      )}
    >
      <label htmlFor={id} className="sts-ui-p2p-ticket-form-textbox__label">
        {label}
      </label>
      <input
        ref={inputRef}
        id={id}
        name={name}
        type={type}
        className="sts-ui-p2p-ticket-form-textbox__input"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
}
