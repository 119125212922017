import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { UUID } from 'src/codecs/Uuid';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { BasketP2pTicketDetails } from 'src/types/BasketP2pTicketDetails';
import { P2pTicketProduct } from 'src/types/P2pTicketProduct';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchBasketP2pTicketDetails(
  p2pTicket: P2pTicketProduct,
): SagaIterator<BasketP2pTicketDetails> {
  try {
    const request = yield* call(REQUEST.encode, {
      conversationId: p2pTicket.conversationId,
      offerIds: p2pTicket.offers.map((it) => it.offer.offerIdentifier),
    });
    const response = yield* call(makeGetRequest, `${HOST_API}/p2p-offers-validity`, request);
    const validity = yield* call(decodeOrThrow, RESPONSE, response.content);

    return {
      ticket: p2pTicket,
      invalid: pipe(validity, R.some((valid) => !valid)),
    } satisfies BasketP2pTicketDetails;
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Basket P2P ticket details',
      { p2pTicket },
      NativeError.wrap(error),
    );
  }
}

const REQUEST = E.struct({
  conversationId: UUID,
  offerIds: E.array(E.id<string>()),
});
const RESPONSE = D.record(D.boolean);
