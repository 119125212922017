import { HOST_API } from 'src/constants/application';

export async function checkNetworkConnection(): Promise<boolean> {
  try {
    await fetch(`${HOST_API}/noop`, {
      method: 'HEAD',
      mode: 'no-cors',
      credentials: 'omit',
      cache: 'no-store',
    });
    return true;
  } catch {
    return false;
  }
}
