import './P2pTicketScreenCommonTripLegService.scss';
import React from 'react';
import Manifest from 'src/assets/sprite/leg-service/manifest.json';
import Sprite from 'src/assets/sprite/leg-service/sprite.svg';
import { P2pTicketTripLegService } from 'src/types/P2pTicketTripItem';
import { isNotNull } from 'src/utils/guard';

type Props = {
  readonly service: P2pTicketTripLegService;
};

export function P2pTicketScreenCommonTripLegService({ service }: Props): React.ReactElement {
  const iconType = service.mode.shortName.toLowerCase();
  const iconNames = service.lineNumbers
    .filter(isNotNull)
    .map((line) => `${iconType}-${line}`)
    .concat(iconType);

  for (const iconId of iconNames) {
    const meta = ICONS_METADATA.get(iconId);
    if (!meta) {
      continue;
    }

    return (
      <span className="sts-ui-p2p-ticket-screen-common-trip-leg-service">
        <svg
          className="sts-ui-p2p-ticket-screen-common-trip-leg-service__icon"
          viewBox={`0 0 ${meta.width} ${meta.height}`}
        >
          <use href={`${Sprite}#${encodeURIComponent(iconId)}`}/>
        </svg>
      </span>
    );
  }

  const serviceLine = service.lineNumbers.find(isNotNull) ?? '';
  const serviceName = `${service.mode.shortName}${serviceLine}`.trim();

  return (
    <span className="sts-ui-p2p-ticket-screen-common-trip-leg-service">
      <span className="sts-ui-p2p-ticket-screen-common-trip-leg-service__name">
        {serviceName}
      </span>
    </span>
  );
}

const ICONS_METADATA: ReadonlyMap<string, { width: number; height: number }> = new Map(Object.entries(Manifest));
