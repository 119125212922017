import './ProductTeaserCrossProduct.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { ProductAttibutesTeaserCrossProduct } from 'src/types/ProductAttibutes';

type Props = {
  readonly crossProduct: ProductAttibutesTeaserCrossProduct;
};

export function ProductTeaserCrossProduct({ crossProduct }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-teaser-cross-product">
      <div className="sts-ui-product-teaser-cross-product__content">
        <div className="sts-ui-product-teaser-cross-product__title">
          <FormattedMessage id={crossProduct.title}/>
        </div>
        <div className="sts-ui-product-teaser-cross-product__description">
          <FormattedHTMLMessage id={crossProduct.description}/>
        </div>
      </div>
      <div className="sts-ui-product-teaser-cross-product__link">
        <RouteLink route={`product:${crossProduct.code}`}>
          <FormattedMessage id={crossProduct.linkText}/>
          <ArrowRightIcon/>
        </RouteLink>
      </div>
    </div>
  );
}
