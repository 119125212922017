import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { saveP2pTicketStore } from 'src/sagas/p2p/utils/storeP2pTicketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { P2pTicketScreenData } from 'src/types/P2pTicketScreenData';
import { P2pTicketStore } from 'src/types/P2pTicketStore';
import { call } from 'typed-redux-saga';

export function* saveP2pTicketData(screen: P2pTicketScreenData | null): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Saving P2P Ticket data…', screen);

    const store = screen === null ? null : yield* call(mapStorageData, screen);
    yield* call(saveP2pTicketStore, store);

    yield* call(logDebug, 'Saving P2P Ticket data… done');
  } catch (error) {
    yield* call(logError, 'Saving P2P Ticket data… error', error);
    throw new RuntimeError('Could not save P2P Ticket data', { screen }, NativeError.wrap(error));
  }
}

function mapStorageData(screen: P2pTicketScreenData): P2pTicketStore {
  return {
    screen: screen.type,
    form: screen.form,

    outward: screen.outward?.selected ?? null,
    return: screen.return?.selected ?? null,
  };
}
