import { SagaIterator } from 'redux-saga';
import {
  NETWORK_ERROR_MODAL_CLOSE,
  NETWORK_ERROR_MODAL_OPEN,
  NETWORK_ERROR_MODAL_RETRY,
} from 'src/network/actions/NetworkActions';
import { networkErrorRetrySaga } from 'src/network/sagas/networkErrorRetrySaga';
import { networkErrorWatchSaga } from 'src/network/sagas/networkErrorWatchSaga';
import { cancel, fork, take, takeLatest } from 'typed-redux-saga';

export function* networkSaga(): SagaIterator<void> {
  yield* takeLatest(NETWORK_ERROR_MODAL_RETRY.is, networkErrorRetrySaga);

  while (true) {
    const { data: transition } = yield* take(NETWORK_ERROR_MODAL_OPEN.is);

    const watchTask = yield* fork(networkErrorWatchSaga, transition);

    yield* take(NETWORK_ERROR_MODAL_CLOSE.is);
    yield* cancel(watchTask);
  }
}
