import { SagaIterator } from 'redux-saga';
import { BaseError } from 'src/errors/BaseError';
import { hideNetworkErrorModal } from 'src/network/sagas/utils/hideNetworkErrorModal';
import { reloadWhenNetworkAvailable } from 'src/network/sagas/utils/reloadWhenNetworkAvailable';
import { showNetworkErrorModal } from 'src/network/sagas/utils/showNetworkErrorModal';
import { Transition } from 'src/routing/types/Transition';
import { isNetworkError } from 'src/utils/error';
import { sentryLog } from 'src/utils/sentryLog';
import { call, cancel, spawn } from 'typed-redux-saga';

export function* networkErrorHandler(transition: Transition, error: BaseError): SagaIterator<void> {
  if (!isNetworkError(error)) {
    yield* call(hideNetworkErrorModal);
    return;
  }

  if (transition.from === null) {
    const targetUrl = transition.to.location.pathname;
    yield* call(sentryLog, `Network error page shown while navigating to "${targetUrl}"`, {
      level: 'warning',
      context: {
        transition,
        error,
      },
      fingerprint: [
        'network',
        'error',
        'page',
      ],
    });

    // show full page error on initial transition
    yield* spawn(reloadWhenNetworkAvailable);
    return;
  }

  const targetUrl = transition.to.location.pathname;
  yield* call(sentryLog, `Network error modal shown while navigating to "${targetUrl}"`, {
    level: 'warning',
    context: {
      transition,
      error,
    },
    fingerprint: [
      'network',
      'error',
      'modal',
    ],
  });

  yield* call(showNetworkErrorModal, transition);
  yield* cancel();
}
