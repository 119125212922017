import { combineReducers, Reducer } from 'redux';
import { RouterState } from 'redux-first-history';
import { networkReducer } from 'src/network/reducers/networkReducer';
import { affiliateReducer } from 'src/reducers/affiliateReducer';
import { basketReducer } from 'src/reducers/basketReducer';
import { checkoutReducer } from 'src/reducers/checkoutReducer';
import { configReducer } from 'src/reducers/configReducer';
import { excursionsReducer } from 'src/reducers/excursionsReducer';
import { homepageReducer } from 'src/reducers/homepageReducer';
import { localizationReducer } from 'src/reducers/localizationReducer';
import { oneTrustReducer } from 'src/reducers/oneTrustReducer';
import { p2pTicketReducer } from 'src/reducers/p2pTicketReducer';
import { paymentReducer } from 'src/reducers/paymentReducer';
import { productReducer } from 'src/reducers/productReducer';
import { regionalPassesReducer } from 'src/reducers/regionalPassesReducer';
import { routingReducer } from 'src/routing/reducers/routingReducer';
import { RootState } from 'src/types/RootState';

export function rootReducer(routerReducer: Reducer<RouterState>): Reducer<RootState> {
  return combineReducers({
    network: networkReducer,
    router: routerReducer,
    routing: routingReducer,
    oneTrust: oneTrustReducer,

    homepage: homepageReducer,
    excursions: excursionsReducer,
    regionalPasses: regionalPassesReducer,

    basket: basketReducer,
    product: productReducer,
    p2pTicket: p2pTicketReducer,
    checkout: checkoutReducer,
    payment: paymentReducer,

    config: configReducer,
    affiliate: affiliateReducer,
    localization: localizationReducer,
  });
}
