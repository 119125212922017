import './P2pTicketScreenTripCommonFormTravelerItem.scss';
import { FieldPath } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { TrashIcon } from 'src/components/icons/TrashIcon';
import { P2pTicketFormSelectField } from 'src/components/p2p/P2pTicketFormSelectField';
import { FormError } from 'src/forms/types/FormError';
import { ensureNonNull } from 'src/forms/utils/transform';
import { useAgeGroupOptions } from 'src/hooks/p2p/useAgeGroupOptions';
import { useDiscountOptions } from 'src/hooks/p2p/useDiscountOptions';
import { P2pTicketFormData, P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { RESOLVE_TRAVELER_DISCOUNT } from 'src/utils/p2p/resolveTravelerDiscount';

type Props = {
  readonly field: FieldPath<P2pTicketFormData, FormError, P2pTicketFormDataTraveler>;
  readonly onRemove?: (traveler: P2pTicketFormDataTraveler) => void;
};

export function P2pTicketScreenTripCommonFormTravelerItem({ field, onRemove }: Props): React.ReactElement {
  const traveler = useFormWatch(field.form, field.value.get);
  const handleRemove = useCallback(() => {
    onRemove?.(traveler);
  }, [traveler, onRemove]);

  const ageGroupOptions = useAgeGroupOptions();
  const discountOptions = useDiscountOptions();

  return (
    <div className="sts-ui-p2p-ticket-screen-trip-common-form-traveler-item">
      <div className="sts-ui-p2p-ticket-screen-trip-common-form-traveler-item__age-group">
        <P2pTicketFormSelectField
          layout="horizontal"
          border="thin"
          label={null}
          field={field.transform(RESOLVE_TRAVELER_DISCOUNT).at('ageGroup').transform(ensureNonNull())}
          options={ageGroupOptions}
        />
      </div>

      <div className="sts-ui-p2p-ticket-screen-trip-common-form-traveler-item__discount">
        {traveler.ageGroup === 'adult' && (
          <P2pTicketFormSelectField
            layout="horizontal"
            border="thin"
            label={null}
            field={field.transform(RESOLVE_TRAVELER_DISCOUNT).at('discount').transform(ensureNonNull())}
            options={discountOptions}
          />
        )}
      </div>

      <div
        className="sts-ui-p2p-ticket-screen-trip-common-form-traveler-item__action"
        style={{ visibility: onRemove ? 'visible' : 'hidden' }}
      >
        <Button
          as="button"
          variant="link"
          intent="primary"
          size="md"
          type="button"
          onClick={handleRemove}
        >
          <TrashIcon/>
          <FormattedMessage id="P2P.Form.Traveler.RemoveTraveler"/>
        </Button>
      </div>
    </div>
  );
}
