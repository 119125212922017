import './P2pTicketScreenTripReturn.scss';
import { Decimal } from 'decimal.js-light';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { Typography } from 'src/components/common/Typography';
import { ChevronLeftIcon } from 'src/components/icons/ChevronLeftIcon';
import { EditIcon } from 'src/components/icons/EditIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { P2pTicketScreenCommonNavigation } from 'src/components/p2p/P2pTicketScreenCommonNavigation';
import { P2pTicketScreenTripCommonForm } from 'src/components/p2p/P2pTicketScreenTripCommonForm';
import { P2pTicketScreenTripCommonList } from 'src/components/p2p/P2pTicketScreenTripCommonList';
import { Currency } from 'src/types/Currency';
import { P2pTicketFormData } from 'src/types/P2pTicketFormData';
import { P2pTicketScreenAction } from 'src/types/P2pTicketScreenAction';
import { P2pTicketScreenDataTripReturn } from 'src/types/P2pTicketScreenData';
import { P2pTicketScreenType } from 'src/types/P2pTicketScreenType';
import { P2pTicketStationRequest } from 'src/types/P2pTicketStationRequest';
import { P2pTicketTripSelection } from 'src/types/P2pTicketTripSelection';

type Props = {
  readonly currency: Currency;
  readonly screen: P2pTicketScreenDataTripReturn;
  readonly onGoBack: () => void;
  readonly onReset: () => void;
  readonly onNavigate: (screen: P2pTicketScreenType) => void;
  readonly onChange: (formData: P2pTicketFormData) => void;
  readonly onSubmit: (selection: P2pTicketTripSelection) => void;
  readonly onStationSearch: (request: P2pTicketStationRequest) => void;
  readonly onScrollForward: () => void;
  readonly onScrollBackward: () => void;
};

export function P2pTicketScreenTripReturn({
  currency,
  screen,
  onGoBack,
  onReset,
  onNavigate,
  onChange,
  onSubmit,
  onStationSearch,
  onScrollForward,
  onScrollBackward,
}: Props): React.ReactElement {
  const resetting = screen.action === P2pTicketScreenAction.RESET_SEARCH;
  const navigatingBack = screen.action === P2pTicketScreenAction.GO_BACK;
  const renderTripInfo = useCallback((returnTrip: P2pTicketTripSelection) => renderReturnTripInfo(
    currency,
    screen.form,
    screen.outward.selected,
    returnTrip,
  ), [currency, screen.form, screen.outward]);

  return (
    <div className="sts-ui-p2p-ticket-screen-trip-return">
      <div className="sts-ui-p2p-ticket-screen-trip-return__navigation">
        <P2pTicketScreenCommonNavigation
          current="trip-return"
          loading={NAV_ACTION_LOADING.get(screen.action) ?? null}
          oneWay={screen.form.trip.oneWay}
          onNavigate={onNavigate}
        />
      </div>
      <div className="sts-ui-p2p-ticket-screen-trip-return__overview">
        <P2pTicketScreenTripCommonForm
          screen={screen}
          onSubmit={onChange}
          onStationSearch={onStationSearch}
        />
      </div>
      <div className="sts-ui-p2p-ticket-screen-trip-return__reset">
        <Button
          as="button"
          variant="outlined"
          intent="primary"
          size="lg"
          type="button"
          disabled={resetting}
          onClick={onReset}
        >
          {resetting ? <LoadingIcon/> : <EditIcon/>}
          <FormattedMessage id="P2P.Return.EditSearch"/>
        </Button>
      </div>

      <div className="sts-ui-p2p-ticket-screen-trip-return__form">
        <div className="sts-ui-p2p-ticket-screen-trip-return__header">
          <div className="sts-ui-p2p-ticket-screen-trip-return__header-caption">
            <div className="sts-ui-p2p-ticket-screen-trip-return__title">
              <Typography as="h1" variant="block-title">
                <FormattedMessage id="P2P.Return.Title"/>
              </Typography>
            </div>
            <div className="sts-ui-p2p-ticket-screen-trip-return__direction">
              <Typography as="p" variant="text-primary">
                <FormattedMessage
                  id="P2P.Return.TripDirection"
                  values={{
                    from: screen.tripInfo.from.name,
                    to: screen.tripInfo.to.name,
                  }}
                />
              </Typography>
            </div>
          </div>
          <div className="sts-ui-p2p-ticket-screen-trip-return__header-back">
            <Button
              as="button"
              variant="outlined"
              intent="primary"
              size="sm"
              type="button"
              disabled={navigatingBack}
              onClick={onGoBack}
            >
              {navigatingBack ? <LoadingIcon/> : <ChevronLeftIcon/>}
              <span className="sts-ui-p2p-ticket-screen-trip-return__header-back-button-text">
                <FormattedMessage id="P2P.Return.BackToOutward"/>
              </span>
            </Button>
          </div>
        </div>
        <div className="sts-ui-p2p-ticket-screen-trip-return__info">
          <Typography as="p" variant="text-secondary">
            <FormattedMessage id="P2P.Return.InfoBlock"/>
          </Typography>
        </div>

        {screen.tripList.trips.length === 0 && (
          <div className="sts-ui-p2p-ticket-screen-trip-return__empty">
            <FormattedMessage id="P2P.Return.NoTicketsFound"/>
          </div>
        )}
        {screen.tripList.trips.length !== 0 && (
          <div className="sts-ui-p2p-ticket-screen-trip-return__content">
            <P2pTicketScreenTripCommonList
              currency={currency}
              screen={screen}
              onSubmit={onSubmit}
              onScrollForward={onScrollForward}
              onScrollBackward={onScrollBackward}
              renderTripInfo={renderTripInfo}
            />
          </div>
        )}
      </div>
      <div className="sts-ui-p2p-ticket-screen-trip-return__footer">
        <div className="sts-ui-p2p-ticket-screen-trip-return__footer-back">
          <Button
            as="button"
            variant="outlined"
            intent="primary"
            size="lg"
            type="button"
            disabled={navigatingBack}
            onClick={onGoBack}
          >
            {navigatingBack ? <LoadingIcon/> : <ChevronLeftIcon/>}
            <span className="sts-ui-p2p-ticket-screen-trip-return__back-button-text">
              <FormattedMessage id="P2P.Return.BackToOutward"/>
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}

function renderReturnTripInfo(
  currency: Currency,
  formData: P2pTicketFormData,
  outwardTrip: P2pTicketTripSelection,
  returnTrip: P2pTicketTripSelection,
): React.ReactElement {
  const outwardPrice = outwardTrip.offer.offers
    .filter((it) => it.qualityOfService === outwardTrip.class)
    .reduce((sum, it) => sum.add(it.prices[currency]), new Decimal(0));
  const returnPrice = returnTrip.offer.offers
    .filter((it) => it.qualityOfService === returnTrip.class)
    .reduce((sum, it) => sum.add(it.prices[currency]), new Decimal(0));
  const totalPrice = outwardPrice.add(returnPrice);

  return (
    <div className="sts-ui-p2p-ticket-screen-trip-return__selection">
      <div className="sts-ui-p2p-ticket-screen-trip-return__selection-direction">
        <FormattedMessage
          id="P2P.Return.TripInfo.Title"
          values={{ outwardPrice: <FormattedPrice value={outwardPrice}/> }}
        />
      </div>
      <div className="sts-ui-p2p-ticket-screen-trip-return__selection-price">
        <FormattedMessage
          id="P2P.Return.TripInfo.Price"
          values={{ returnPrice: <FormattedPrice value={returnPrice}/> }}
        />
      </div>
      <div className="sts-ui-p2p-ticket-screen-trip-return__selection-travelers">
        <FormattedMessage
          id="P2P.TripInfo.Passengers"
          values={{
            discount: formData.travelers.filter((it) => it.discount !== 'none').length,
            adult: formData.travelers.filter((it) => it.ageGroup === 'adult').length,
            child: formData.travelers.filter((it) => it.ageGroup === 'child').length,
          }}
        />
      </div>
      <div className="sts-ui-p2p-ticket-screen-trip-return__selection-total">
        <FormattedMessage
          id="P2P.Return.TripInfo.Total"
          values={{ totalPrice: <FormattedPrice value={totalPrice}/> }}
        />
      </div>
    </div>
  );
}

const NAV_ACTION_LOADING: ReadonlyMap<P2pTicketScreenAction | null, P2pTicketScreenType> = new Map([
  [P2pTicketScreenAction.NAVIGATE_SEARCH, 'initial'],
  [P2pTicketScreenAction.NAVIGATE_OUTWARD, 'trip-outward'],
]);
