import { pipe } from 'fp-ts/function';
import { BasketProduct } from 'src/types/BasketProduct';
import { ProductFormData } from 'src/types/ProductFormData';
import { ProductMap } from 'src/types/ProductMap';
import { TicketFields } from 'src/types/TicketFields';
import { TicketValidity } from 'src/types/TicketValidity';

export function updateProductFormData(
  productFields: TicketFields,
  productValidity: TicketValidity,
  basketProducts: ProductMap<BasketProduct>,
  prevFormData: ProductFormData,
  nextFormData: ProductFormData,
): ProductFormData {
  return pipe(
    nextFormData,
    resolveFamilyCard,
    resolveDuration,
  );

  function resolveFamilyCard(formData: ProductFormData): ProductFormData {
    if (!productFields.displayFields.familyCard) {
      return { ...formData, familyCard: null };
    }

    const prevValue = prevFormData.child ?? 0;
    const nextValue = formData.child ?? 0;
    if (nextValue > 0 && prevValue === 0) {
      return { ...formData, familyCard: true };
    }
    if (nextValue === 0 && prevValue > 0) {
      return { ...formData, familyCard: false };
    }

    return formData;
  }

  function resolveDuration(formData: ProductFormData): ProductFormData {
    if (
      !productFields.displayFields.duration ||
      productValidity.duration === null ||
      !productValidity.duration[0]
    ) {
      return { ...formData, duration: null };
    }

    if (productValidity.duration.every((it) => it.value !== formData.duration)) {
      return { ...formData, duration: productValidity.duration[0].value };
    }

    return formData;
  }
}
