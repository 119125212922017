import { BaseError } from 'src/errors/BaseError';

export class NetworkError extends BaseError {
  public constructor(
    public readonly onLine: boolean,
    previous: BaseError,
  ) {
    super(`Network error "${previous.name}: ${previous.message}"`, undefined, previous);
  }

  public get name(): string {
    return 'NetworkError';
  }

  public toJSON(): Record<string, unknown> {
    return {
      ...super.toJSON(),
      onLine: this.onLine,
    };
  }
}
