import { pipe } from 'fp-ts/function';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { BASKET_REQUEST } from 'src/codecs/BasketRequest';
import { CHECKOUT_TOTAL } from 'src/codecs/CheckoutTotal';
import { CURRENCY } from 'src/codecs/Currency';
import { JSON_STRING } from 'src/codecs/JsonString';
import { LANGUAGE } from 'src/codecs/Language';
import { ORDER_REQUEST } from 'src/codecs/OrderRequest';
import { P2P_TICKET_REQUEST } from 'src/codecs/P2pTicketRequest';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { BasketContent } from 'src/types/BasketContent';
import { CheckoutFormData } from 'src/types/CheckoutFormData';
import { CheckoutTotal } from 'src/types/CheckoutTotal';
import { Currency } from 'src/types/Currency';
import { Language } from 'src/types/Language';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchCheckoutTotalPrice(
  currency: Currency,
  language: Language,
  basket: BasketContent,
  formData: CheckoutFormData,
): SagaIterator<CheckoutTotal> {
  try {
    const request = yield* call(REQUEST.encode, {
      language: language,
      currency: currency,
      basket: basket.products,
      point2point: basket.p2pTickets,
      order: formData,
    });
    const response = yield* call(makeGetRequest, `${HOST_API}/basket-total`, request);
    return yield* call(decodeOrThrow, CHECKOUT_TOTAL, response.content);
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Checkout total price',
      { currency, language, basket, formData },
      NativeError.wrap(error),
    );
  }
}

const REQUEST = E.struct({
  currency: CURRENCY,
  language: LANGUAGE,
  basket: pipe(BASKET_REQUEST, E.compose(JSON_STRING)),
  order: pipe(ORDER_REQUEST, E.compose(JSON_STRING)),
  point2point: P2P_TICKET_REQUEST,
});
