import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLocation } from 'src/routing/selectors/getCurrentLocation';

type Props = {
  readonly children: React.ReactElement;
};

export function RouteScroller({ children }: Props): React.ReactElement {
  const location = useSelector(getCurrentLocation);
  const anchor = useRef(location?.hash ?? '');

  useEffect(() => {
    try {
      window.scrollTo({
        top: getScrollTarget(anchor.current),
        behavior: 'instant',
      });
    } catch {
      window.scrollTo(
        window.screenLeft,
        getScrollTarget(anchor.current),
      );
    }
  }, []);

  return children;
}

function getScrollTarget(anchor: string): number {
  if (!anchor || !anchor.startsWith('#')) {
    return 0;
  }

  const target = window.document.getElementById(anchor.substring(1));
  if (!target) {
    return 0;
  }

  return target.offsetTop;
}
