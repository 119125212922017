import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { P2pTicketFormDatePicker } from 'src/components/p2p/P2pTicketFormDatePicker';
import { FormError } from 'src/forms/types/FormError';
import { DateOnly } from 'src/types/DateOnly';

type Props<TData> = {
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly field: FieldPath<TData, FormError, DateOnly | null>;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly formatError?: (error: FormError) => string;
  readonly min?: DateOnly;
  readonly max?: DateOnly;
  readonly navigation: 'narrow' | 'broad';
  readonly defaultDate: DateOnly;
};

export function P2pTicketFormDatePickerField<TData>({
  layout,
  border,
  label,
  field,
  disabled,
  placeholder,
  formatError,
  min,
  max,
  navigation,
  defaultDate,
}: Props<TData>): React.ReactElement {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);
  const validity = error !== undefined && formatError !== undefined ? formatError(error) : undefined;

  const handleChange = useCallback((nextValue: DateOnly | null) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <P2pTicketFormDatePicker
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      layout={layout}
      border={border}
      label={label}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={disabled}
      navigation={navigation}
      defaultDate={defaultDate}
      invalid={invalid}
      validity={validity}
      placeholder={placeholder}
      min={min}
      max={max}
    />
  );
}
