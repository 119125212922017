import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_STATION_SEARCH } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { searchStationList } from 'src/sagas/p2p/utils/searchStationList';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put } from 'typed-redux-saga';

export function* p2pTicketStationSearchSaga(
  action: GetRequestActionType<typeof P2P_TICKET_STATION_SEARCH>,
): SagaIterator<void> {
  const request = action.meta;
  const query = request.query.trim();

  try {
    yield* call(logDebug, 'Searching P2P Ticket stations…', query);
    yield* put(P2P_TICKET_STATION_SEARCH.pending(request));

    if (query.length < 2) {
      yield* call(logDebug, 'Searching P2P Ticket stations… skip');
      yield* put(P2P_TICKET_STATION_SEARCH.success([], request));
      return;
    }

    yield* delay(500);
    const stations = yield* call(searchStationList, query);

    yield* call(logDebug, 'Searching P2P Ticket stations… done', stations);
    yield* put(P2P_TICKET_STATION_SEARCH.success(stations, request));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not search P2P Ticket station',
      { request },
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Searching P2P Ticket stations… error', error);
    yield* put(P2P_TICKET_STATION_SEARCH.failure(NativeError.wrap(error), request));
  }
}
