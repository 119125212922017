import { SagaIterator } from 'redux-saga';
import { LOCAL_DATE_TIME } from 'src/codecs/LocalDateTime';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { LocalDateTime } from 'src/types/LocalDateTime';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* getP2pMinimalTripDate(): SagaIterator<LocalDateTime> {
  try {
    const response = yield* call(makeGetRequest, `${HOST_API}/p2p-minimal-trip-date`);
    return yield* call(decodeOrThrow, LOCAL_DATE_TIME, response.content);
  } catch (error) {
    throw new RuntimeError('Could not get minimal P2P trip date', {}, NativeError.wrap(error));
  }
}
