import { BaseError } from 'src/errors/BaseError';

export class CancelledError extends BaseError {
  public constructor(
    message: string,
    context?: unknown,
    previous?: BaseError,
  ) {
    super(message, context, previous);
  }

  public get name(): string {
    return 'CancelledError';
  }
}
