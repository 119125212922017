import './Button.scss';
import classNames from 'classnames';
import React from 'react';

type OwnProps = {
  readonly variant: 'solid' | 'outlined' | 'plain' | 'link';
  readonly intent: 'primary' | 'secondary' | 'neutral';
  readonly size: 'lg' | 'md' | 'sm';
};

type Props<T extends React.ElementType> =
  & Omit<React.ComponentProps<T>, 'as'>
  & { readonly as: T }
  & OwnProps;

export function Button<T extends React.ElementType>({
  as,
  variant,
  intent,
  size,
  ...props
}: Props<T>): React.ReactElement {
  const component = as ?? 'button';
  const className = classNames(
    props.className,
    'sts-ui-button',
    `sts-ui-button--variant-${variant}`,
    `sts-ui-button--intent-${intent}`,
    `sts-ui-button--size-${size}`,
  );

  return React.createElement(component, { ...props, className });
}
