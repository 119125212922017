import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { P2pTicketFormDateTimePicker } from 'src/components/p2p/P2pTicketFormDateTimePicker';
import { FormError } from 'src/forms/types/FormError';
import { LocalDateTime } from 'src/types/LocalDateTime';

type Props<TData> = {
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly field: FieldPath<TData, FormError, LocalDateTime | null>;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly formatError?: (error: FormError) => string;
  readonly min?: LocalDateTime;
  readonly max?: LocalDateTime;
  readonly timeComment: React.ReactNode;
  readonly navigation: 'narrow' | 'broad';
  readonly defaultDate: LocalDateTime;
};

export function P2pTicketFormDateTimePickerField<TData>({
  layout,
  border,
  label,
  field,
  disabled,
  placeholder,
  formatError,
  min,
  max,
  timeComment,
  navigation,
  defaultDate,
}: Props<TData>): React.ReactElement {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);
  const validity = error !== undefined && formatError !== undefined ? formatError(error) : undefined;

  const handleChange = useCallback((nextValue: LocalDateTime | null) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <P2pTicketFormDateTimePicker
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      layout={layout}
      border={border}
      label={label}
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={disabled}
      invalid={invalid}
      validity={validity}
      placeholder={placeholder}
      min={min}
      max={max}
      timeComment={timeComment}
      navigation={navigation}
      defaultDate={defaultDate}
    />
  );
}
