import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchP2pTripOffer } from 'src/sagas/p2p/utils/fetchP2pTripOffer';
import { scrollP2pTripFrame } from 'src/sagas/p2p/utils/scrollP2pTripFrame';
import { Language } from 'src/types/Language';
import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketTripInfo } from 'src/types/P2pTicketTripInfo';
import { P2pTicketTripList } from 'src/types/P2pTicketTripList';
import { P2pTicketTripScrollDirection } from 'src/types/P2pTicketTripScroll';
import { Uuid } from 'src/types/Uuid';
import { getTripFrameItems } from 'src/utils/p2p/tripFrame';
import { all, call } from 'typed-redux-saga';

export function* scrollP2pTripList(
  language: Language,
  travelers: ReadonlyArray<P2pTicketFormDataTraveler>,
  conversationId: Uuid,
  tripList: P2pTicketTripList,
  tripInfo: P2pTicketTripInfo,
  scrollDirection: P2pTicketTripScrollDirection,
): SagaIterator<P2pTicketTripList> {
  try {
    const tripFrame = yield* call(
      scrollP2pTripFrame,
      language,
      tripInfo,
      tripList.frame,
      scrollDirection,
    );

    const tripItems = yield* call(getTripFrameItems, tripFrame);
    const tripOffers = yield* all(tripItems.map((tripItem) => callEffect(
      fetchP2pTripOffer,
      language,
      travelers,
      conversationId,
      tripItem,
    )));

    return {
      trips: tripOffers,
      frame: tripFrame,
    } satisfies P2pTicketTripList;
  } catch (error) {
    throw new RuntimeError(
      'Could not scroll P2P Ticket trips list',
      { tripList, travelers, scrollDirection },
      NativeError.wrap(error),
    );
  }
}
