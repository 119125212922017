import { format } from 'date-fns/format';
import { isValid } from 'date-fns/isValid';
import { parse } from 'date-fns/parse';
import { pipe } from 'fp-ts/function';
import * as C from 'io-ts/Codec';
import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { LocalDateTime } from 'src/types/LocalDateTime';
import { fromDate, toDate } from 'src/utils/localDateTime';

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
const REFERENCE_DATE = new Date(0);

const DECODER: D.Decoder<unknown, LocalDateTime> = pipe(
  D.string,
  D.parse((input) => {
    const date = parse(input.substring(0, 19), DATE_FORMAT, REFERENCE_DATE);
    if (!isValid(date)) {
      return D.failure(input, 'LocalDateTime');
    }

    return D.success(fromDate(date));
  }),
);

const ENCODER: E.Encoder<string, LocalDateTime> = {
  encode: (value) => {
    return format(toDate(value), DATE_FORMAT);
  },
};

export const LOCAL_DATE_TIME = C.make(DECODER, ENCODER);
