import { SagaIterator } from 'redux-saga';
import { BASKET_UPGRADE } from 'src/actions/BasketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { fetchBasketData } from 'src/sagas/basket/utils/fetchBasketData';
import { saveBasketData } from 'src/sagas/basket/utils/saveBasketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getBasketContent } from 'src/selectors/getBasketContent';
import { BasketContent } from 'src/types/BasketContent';
import { upgradeBasketProduct } from 'src/utils/basket/upgradeBasketProduct';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* basketUpgradeSaga(
  action: GetRequestActionType<typeof BASKET_UPGRADE>,
): SagaIterator<void> {
  const productType = action.meta;

  try {
    yield* call(logDebug, `Upgrading basket product "${productType}"…`);
    yield* put(BASKET_UPGRADE.pending(productType));

    yield* call(delay, 500);
    const currency = yield* select(getCurrentCurrency);
    const language = yield* select(getCurrentLanguage);

    const currentBasket = yield* select(getBasketContent);
    const updatedBasket: BasketContent = {
      ...currentBasket,
      products: yield* call(upgradeBasketProduct, productType, currentBasket.products),
    };

    const basket = yield* call(
      fetchBasketData,
      currency,
      language,
      updatedBasket,
    );

    yield* call(logDebug, `Upgrading basket product "${productType}"… done`, basket);
    yield* put(BASKET_UPGRADE.success(basket, productType));
    yield* call(saveBasketData);
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not upgrade basket product',
      { productType },
      NativeError.wrap(error),
    ));

    yield* call(logError, `Upgrading basket product "${productType}"… error`, error);
    yield* put(BASKET_UPGRADE.failure(NativeError.wrap(error), productType));
  }
}
