import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/ReadonlyArray';
import { createSelector } from 'reselect';
import { BASKET_PRODUCT_ORD } from 'src/constants/ord';
import { getCheckoutFields } from 'src/selectors/getCheckoutFields';
import { getCheckoutP2pTickets } from 'src/selectors/getCheckoutP2pTickets';
import { getCheckoutProducts } from 'src/selectors/getCheckoutProducts';
import { CheckoutProduct, CheckoutProductP2pTicket, CheckoutProductProduct } from 'src/types/CheckoutProduct';
import { assertNotEmpty } from 'src/utils/assert';

export const getCheckoutProductList = createSelector([
  getCheckoutProducts,
  getCheckoutP2pTickets,
  getCheckoutFields,
], (products, p2pTickets, fields): ReadonlyArray<CheckoutProduct> => {
  return pipe(
    products,
    A.sort(BASKET_PRODUCT_ORD),
    A.map((product): CheckoutProductProduct => ({
      type: 'product',
      uuid: `product-${product.code}`,

      code: product.code,
      form: product.form,

      fields: assertNotEmpty(
        fields[product.code],
        `No traveler fields defined for "${product.code}"`,
        { product, fields },
      ),

      familyCardCount: product.familyCardCount,
      familyCardDiscount: product.familyCardDiscount,
    })),
    A.concatW(pipe(
      p2pTickets,
      A.map((p2pTicket): CheckoutProductP2pTicket => ({
        type: 'p2p-ticket',
        uuid: p2pTicket.ticket.ticketIdentifier,

        ticket: p2pTicket.ticket,
      })),
    )),
  );
});
