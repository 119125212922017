import { Action } from 'redux';
import { SagaIterator } from 'redux-saga';
import {
  P2P_TICKET_INITIAL_CHANGE,
  P2P_TICKET_INITIAL_SUBMIT,
  P2P_TICKET_STATION_SEARCH,
  P2P_TICKET_TRIP_CHANGE,
  P2P_TICKET_TRIP_OUTWARD_SUBMIT,
  P2P_TICKET_TRIP_RETURN_SUBMIT,
  P2P_TICKET_SUMMARY_CHANGE,
  P2P_TICKET_SUMMARY_SUBMIT,
  P2P_TICKET_NAVIGATE_TO,
  P2P_TICKET_NAVIGATE_BACK,
  P2P_TICKET_RESET_SEARCH,
  P2P_TICKET_TRIP_SCROLL,
} from 'src/actions/P2pTicketActions';
import { p2pTicketInitialChangeSaga } from 'src/sagas/p2p/p2pTicketInitialChangeSaga';
import { p2pTicketInitialSubmitSaga } from 'src/sagas/p2p/p2pTicketInitialSubmitSaga';
import { p2pTicketNavigateBackSaga } from 'src/sagas/p2p/p2pTicketNavigateBackSaga';
import { p2pTicketNavigateToSaga } from 'src/sagas/p2p/p2pTicketNavigateToSaga';
import { p2pTicketResetSearchSaga } from 'src/sagas/p2p/p2pTicketResetSearchSaga';
import { p2pTicketStationSearchSaga } from 'src/sagas/p2p/p2pTicketStationSearchSaga';
import { p2pTicketSummaryChangeSaga } from 'src/sagas/p2p/p2pTicketSummaryChangeSaga';
import { p2pTicketSummarySubmitSaga } from 'src/sagas/p2p/p2pTicketSummarySubmitSaga';
import { p2pTicketTripChangeSaga } from 'src/sagas/p2p/p2pTicketTripChangeSaga';
import { p2pTicketTripOutwardSubmitSaga } from 'src/sagas/p2p/p2pTicketTripOutwardSubmitSaga';
import { p2pTicketTripReturnSubmitSaga } from 'src/sagas/p2p/p2pTicketTripReturnSubmitSaga';
import { p2pTicketTripScrollSaga } from 'src/sagas/p2p/p2pTicketTripScrollSaga';
import { P2pTicketScreenData } from 'src/types/P2pTicketScreenData';
import { call, takeLatest } from 'typed-redux-saga';

export function* p2pTicketScreenSaga(screen: P2pTicketScreenData): SagaIterator<void> {
  if (screen.type === 'initial') {
    yield* takeLatest(P2P_TICKET_STATION_SEARCH.isRequest, p2pTicketStationSearchSaga);

    yield* takeLatest([
      P2P_TICKET_INITIAL_CHANGE.isRequest,
      P2P_TICKET_INITIAL_SUBMIT.isRequest,
    ], p2pTicketScreenInitialSaga);
  }
  if (screen.type === 'trip-outward') {
    yield* takeLatest(P2P_TICKET_STATION_SEARCH.isRequest, p2pTicketStationSearchSaga);
    yield* takeLatest(P2P_TICKET_TRIP_SCROLL.isRequest, p2pTicketTripScrollSaga);

    yield* takeLatest([
      P2P_TICKET_RESET_SEARCH.isRequest,
      P2P_TICKET_NAVIGATE_TO.isRequest,
      P2P_TICKET_NAVIGATE_BACK.isRequest,
      P2P_TICKET_TRIP_CHANGE.isRequest,
      P2P_TICKET_TRIP_OUTWARD_SUBMIT.isRequest,
    ], p2pTicketScreenOutwardSaga);
  }
  if (screen.type === 'trip-return') {
    yield* takeLatest(P2P_TICKET_STATION_SEARCH.isRequest, p2pTicketStationSearchSaga);
    yield* takeLatest(P2P_TICKET_TRIP_SCROLL.isRequest, p2pTicketTripScrollSaga);

    yield* takeLatest([
      P2P_TICKET_RESET_SEARCH.isRequest,
      P2P_TICKET_NAVIGATE_TO.isRequest,
      P2P_TICKET_NAVIGATE_BACK.isRequest,
      P2P_TICKET_TRIP_CHANGE.isRequest,
      P2P_TICKET_TRIP_RETURN_SUBMIT.isRequest,
    ], p2pTicketScreenReturnSaga);
  }
  if (screen.type === 'summary') {
    yield* takeLatest([
      P2P_TICKET_RESET_SEARCH.isRequest,
      P2P_TICKET_NAVIGATE_TO.isRequest,
      P2P_TICKET_NAVIGATE_BACK.isRequest,
      P2P_TICKET_SUMMARY_CHANGE.isRequest,
      P2P_TICKET_SUMMARY_SUBMIT.isRequest,
    ], p2pTicketScreenSummarySaga);
  }
}

function* p2pTicketScreenInitialSaga(action: Action): SagaIterator<void> {
  if (P2P_TICKET_INITIAL_CHANGE.isRequest(action)) {
    yield* call(p2pTicketInitialChangeSaga, action);
  }
  if (P2P_TICKET_INITIAL_SUBMIT.isRequest(action)) {
    yield* call(p2pTicketInitialSubmitSaga, action);
  }
}

function* p2pTicketScreenOutwardSaga(action: Action): SagaIterator<void> {
  if (P2P_TICKET_RESET_SEARCH.isRequest(action)) {
    yield* call(p2pTicketResetSearchSaga, action);
  }
  if (P2P_TICKET_NAVIGATE_TO.isRequest(action)) {
    yield* call(p2pTicketNavigateToSaga, action);
  }
  if (P2P_TICKET_NAVIGATE_BACK.isRequest(action)) {
    yield* call(p2pTicketNavigateBackSaga, action);
  }
  if (P2P_TICKET_TRIP_CHANGE.isRequest(action)) {
    yield* call(p2pTicketTripChangeSaga, action);
  }
  if (P2P_TICKET_TRIP_OUTWARD_SUBMIT.isRequest(action)) {
    yield* call(p2pTicketTripOutwardSubmitSaga, action);
  }
}

function* p2pTicketScreenReturnSaga(action: Action): SagaIterator<void> {
  if (P2P_TICKET_RESET_SEARCH.isRequest(action)) {
    yield* call(p2pTicketResetSearchSaga, action);
  }
  if (P2P_TICKET_NAVIGATE_TO.isRequest(action)) {
    yield* call(p2pTicketNavigateToSaga, action);
  }
  if (P2P_TICKET_NAVIGATE_BACK.isRequest(action)) {
    yield* call(p2pTicketNavigateBackSaga, action);
  }
  if (P2P_TICKET_TRIP_CHANGE.isRequest(action)) {
    yield* call(p2pTicketTripChangeSaga, action);
  }
  if (P2P_TICKET_TRIP_RETURN_SUBMIT.isRequest(action)) {
    yield* call(p2pTicketTripReturnSubmitSaga, action);
  }
}

function* p2pTicketScreenSummarySaga(action: Action): SagaIterator<void> {
  if (P2P_TICKET_RESET_SEARCH.isRequest(action)) {
    yield* call(p2pTicketResetSearchSaga, action);
  }
  if (P2P_TICKET_NAVIGATE_TO.isRequest(action)) {
    yield* call(p2pTicketNavigateToSaga, action);
  }
  if (P2P_TICKET_NAVIGATE_BACK.isRequest(action)) {
    yield* call(p2pTicketNavigateBackSaga, action);
  }
  if (P2P_TICKET_SUMMARY_CHANGE.isRequest(action)) {
    yield* call(p2pTicketSummaryChangeSaga, action);
  }
  if (P2P_TICKET_SUMMARY_SUBMIT.isRequest(action)) {
    yield* call(p2pTicketSummarySubmitSaga, action);
  }
}
