import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchProductsMinimalPrices } from 'src/sagas/common/utils/fetchProductsMinimalPrices';
import { Currency } from 'src/types/Currency';
import { ProductCode } from 'src/types/ProductCode';
import { TicketPrice } from 'src/types/TicketPrice';
import { assertDefined } from 'src/utils/assert';
import { call } from 'typed-redux-saga';

export function* fetchProductMinPrice(
  code: ProductCode,
  currency: Currency,
): SagaIterator<TicketPrice | null> {
  try {
    const content = yield* call(
      fetchProductsMinimalPrices,
      currency,
      [code],
    );

    return assertDefined(
      content[code],
      `No ticket "${code}" minimal price data`,
      { content },
    );
  } catch (error) {
    throw new RuntimeError('Could not fetch product minimal price', { code, currency }, NativeError.wrap(error));
  }
}
