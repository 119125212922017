import './P2pTicketScreenInitialFormTraveler.scss';
import { FieldPath } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React, { useCallback } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { InfoModal } from 'src/components/common/InfoModal';
import { Typography } from 'src/components/common/Typography';
import { TrashIcon } from 'src/components/icons/TrashIcon';
import { P2pTicketFormSelectField } from 'src/components/p2p/P2pTicketFormSelectField';
import { FormError } from 'src/forms/types/FormError';
import { ensureNonNull } from 'src/forms/utils/transform';
import { useAgeGroupOptions } from 'src/hooks/p2p/useAgeGroupOptions';
import { useDiscountOptions } from 'src/hooks/p2p/useDiscountOptions';
import { Integer } from 'src/types/Integer';
import { P2pTicketFormData, P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { RESOLVE_TRAVELER_DISCOUNT } from 'src/utils/p2p/resolveTravelerDiscount';

type Props = {
  readonly field: FieldPath<P2pTicketFormData, FormError, P2pTicketFormDataTraveler>;
  readonly number: Integer;
  readonly onRemove?: (traveler: P2pTicketFormDataTraveler) => void;
};

export function P2pTicketScreenInitialFormTraveler({ field, number, onRemove }: Props): React.ReactElement {
  const traveler = useFormWatch(field.form, field.value.get);
  const handleRemove = useCallback(() => {
    onRemove?.(traveler);
  }, [traveler, onRemove]);

  const ageGroupOptions = useAgeGroupOptions();
  const discountOptions = useDiscountOptions();

  return (
    <div className="sts-ui-p2p-ticket-screen-initial-form-traveler">
      <div className="sts-ui-p2p-ticket-screen-initial-form-traveler__label">
        <Typography as="div" variant="form-label">
          <FormattedMessage id="P2P.Form.Traveler.Title" values={{ number }}/>
        </Typography>
      </div>

      <div className="sts-ui-p2p-ticket-screen-initial-form-traveler__field">
        <P2pTicketFormSelectField
          layout="horizontal"
          border="thin"
          label={null}
          field={field.transform(RESOLVE_TRAVELER_DISCOUNT).at('ageGroup').transform(ensureNonNull())}
          options={ageGroupOptions}
        />
      </div>
      {traveler.ageGroup === 'adult' && (
        <div className="sts-ui-p2p-ticket-screen-initial-form-traveler__field sts-ui-p2p-ticket-screen-initial-form-traveler__field--discount">
          <P2pTicketFormSelectField
            layout="horizontal"
            border="thin"
            label={null}
            field={field.transform(RESOLVE_TRAVELER_DISCOUNT).at('discount').transform(ensureNonNull())}
            options={discountOptions}
          />
        </div>
      )}
      {traveler.ageGroup === 'child' && (
        <div className="sts-ui-p2p-ticket-screen-initial-form-traveler__info">
          <InfoModal>
            <FormattedHTMLMessage id="P2P.Form.Traveler.ChildInformation"/>
          </InfoModal>
        </div>
      )}
      <div className="sts-ui-p2p-ticket-screen-initial-form-traveler__action">
        {onRemove && (
          <Button
            as="button"
            variant="link"
            intent="primary"
            size="md"
            type="button"
            onClick={handleRemove}
          >
            <TrashIcon/>
            <FormattedMessage id="P2P.Form.Traveler.RemoveTraveler"/>
          </Button>
        )}
      </div>
    </div>
  );
}
