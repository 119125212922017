import { SagaIterator } from 'redux-saga';
import { BASKET_SET_CONVERSATION_ID } from 'src/actions/BasketActions';
import { UUID } from 'src/codecs/Uuid';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { saveBasketData } from 'src/sagas/basket/utils/saveBasketData';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { getBasketConversationId } from 'src/selectors/getBasketConversationId';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call, put, select } from 'typed-redux-saga';

export function* ensureBasketConversationId(): SagaIterator<void> {
  try {
    const storedValue = yield* select(getBasketConversationId);
    if (storedValue !== null) {
      return;
    }

    const response = yield* call(makeGetRequest, `${HOST_API}/p2p-conversation-id`);
    const conversationId = yield* call(decodeOrThrow, UUID, response.content);

    yield* put(BASKET_SET_CONVERSATION_ID.trigger(conversationId));
    yield* call(saveBasketData);
  } catch (error) {
    throw new RuntimeError('Could not create conversation ID', {}, NativeError.wrap(error));
  }
}
