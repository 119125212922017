import './P2pTicketFormRadio.scss';
import React, { useCallback, useRef } from 'react';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly value: boolean;
  readonly label: React.ReactNode;
  readonly onBlur?: () => void;
  readonly onChange: (value: boolean) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
};

export function P2pTicketFormRadio({
  id,
  name,
  value,
  label,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
}: Props): React.ReactElement {
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  }, [onChange]);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  return (
    <div
      data-invalid={invalid}
      data-disabled={disabled}
      className="sts-ui-p2p-ticket-form-radio"
    >
      <div className="sts-ui-p2p-ticket-form-radio__text">
        <label htmlFor={id} className="sts-ui-p2p-ticket-form-radio__label">
          {label}
        </label>
      </div>
      <label className="sts-ui-p2p-ticket-form-radio__check">
        <input
          ref={inputRef}
          id={id}
          name={name}
          className="sts-ui-p2p-ticket-form-radio__input"
          type="radio"
          checked={value}
          disabled={disabled}
          onChange={handleChange}
          onBlur={onBlur}
        />
        <div className="sts-ui-p2p-ticket-form-radio__icon"/>
      </label>
    </div>
  );
}
