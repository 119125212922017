import { SagaIterator } from 'redux-saga';
import { loaderSaga } from 'src/loader/sagas/loaderSaga';
import { networkSaga } from 'src/network/sagas/networkSaga';
import { routingSaga } from 'src/routing/sagas/routingSaga';
import { basketSaga } from 'src/sagas/basket/basketSaga';
import { checkoutSaga } from 'src/sagas/checkout/checkoutSaga';
import { p2pTicketSaga } from 'src/sagas/p2p/p2pTicketSaga';
import { productSaga } from 'src/sagas/product/productSaga';
import { searchSaga } from 'src/sagas/search/searchSaga';
import { fork } from 'typed-redux-saga';

export function* rootSaga(): SagaIterator<void> {
  yield* fork(loaderSaga);
  yield* fork(networkSaga);
  yield* fork(routingSaga);
  yield* fork(basketSaga);
  yield* fork(productSaga);
  yield* fork(checkoutSaga);
  yield* fork(searchSaga);
  yield* fork(p2pTicketSaga);
}
