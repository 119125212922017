import './BirthDate.scss';
import * as Eq from 'fp-ts/Eq';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'src/forms/components/Select';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { useDateList } from 'src/hooks/useDateList';
import { useMonthList } from 'src/hooks/useMonthList';
import { useOnChange } from 'src/hooks/useOnChange';
import { DateInput } from 'src/types/DateInput';
import { DateOnly } from 'src/types/DateOnly';
import { Integer } from 'src/types/Integer';
import { ListOption } from 'src/types/ListOption';
import { normalizeBirthDate } from 'src/utils/birthDate';
import { getDaysInMonth } from 'src/utils/dateOnly';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly value: DateOnly | null;
  readonly onBlur?: () => void;
  readonly onChange: (value: DateOnly | null) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly min: DateOnly;
  readonly max: DateOnly;
};

export function BirthDate({
  id,
  name,
  value,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  min,
  max,
}: Props): React.ReactElement {
  const touched = useRef(new Set<string>());
  const dateList = useDateList();
  const monthList = useMonthList();

  const [viewValue, setViewValue] = useState(() => fromDate(value));
  useOnChange(({ nextValue, prevValue }) => {
    if (nextValue.propValue === prevValue.propValue) {
      return;
    }
    if (nextValue.propValue !== null) {
      setViewValue(fromDate(value));
    } else if (toDate(nextValue.viewValue) !== null) {
      setViewValue(fromDate(value));
    }
  }, EQ_CHANGE_EVENT, { propValue: value, viewValue: viewValue });

  const yyOptions = useMemo(() => getYearList(
    min,
    max,
  ), [min, max]);

  const mmOptions = useMemo(() => getMonthList(
    viewValue,
    monthList,
    min,
    max,
  ), [min, max, viewValue, monthList]);

  const ddOptions = useMemo(() => getDateList(
    viewValue,
    dateList,
    min,
    max,
  ), [min, max, viewValue, dateList]);

  const handleBlur = useCallback(() => {
    if (touched.current.size === 3) {
      onBlur?.();
    }
  }, [onBlur]);

  const handleBlurDD = useCallback(() => {
    touched.current.add('dd');
    handleBlur();
  }, [handleBlur]);
  const handleBlurMM = useCallback(() => {
    touched.current.add('mm');
    handleBlur();
  }, [handleBlur]);
  const handleBlurYY = useCallback(() => {
    touched.current.add('yy');
    handleBlur();
  }, [handleBlur]);

  const handleChange = useCallback((nextViewValue: DateInput) => {
    const normViewValue = normalizeBirthDate(nextViewValue, min, max);
    setViewValue(normViewValue);

    const nextValue = toDate(normViewValue);
    onChange(nextValue);
  }, [min, max, onChange]);

  const handleChangeDD = useCallback((dd: Integer | null) => {
    handleChange({ ...viewValue, dd });
  }, [viewValue, handleChange]);
  const handleChangeMM = useCallback((mm: Integer | null) => {
    handleChange({ ...viewValue, mm });
  }, [viewValue, handleChange]);
  const handleChangeYY = useCallback((yy: Integer | null) => {
    handleChange({ ...viewValue, yy });
  }, [viewValue, handleChange]);

  const inputRef = useRef<HTMLButtonElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  return (
    <div className="sts-ui-form-birth-date" data-invalid={invalid}>
      <div className="sts-ui-form-birth-date__dd">
        <div className="sts-ui-form-birth-date__label">
          <label htmlFor={`${id}.dd`}>
            <FormattedMessage id="FormElements.BirthdayPicker.SelectDayFromList"/>
          </label>
        </div>
        <div className="sts-ui-form-birth-date__field">
          <Select
            id={`${id}.dd`}
            name={`${name}.dd`}
            value={viewValue.dd}
            options={ddOptions}
            onBlur={handleBlurDD}
            onChange={handleChangeDD}
            invalid={invalid}
            disabled={disabled}
            clearable={false}
            validity={validity}
            placeholder={<FormattedMessage id="FormElements.BirthdayPicker.SelectDayFromList"/>}
          />
        </div>
      </div>
      <div className="sts-ui-form-birth-date__mm">
        <div className="sts-ui-form-birth-date__label">
          <label htmlFor={`${id}.mm`}>
            <FormattedMessage id="FormElements.BirthdayPicker.SelectMonth"/>
          </label>
        </div>
        <div className="sts-ui-form-birth-date__field">
          <Select
            id={`${id}.mm`}
            name={`${name}.mm`}
            value={viewValue.mm}
            options={mmOptions}
            onBlur={handleBlurMM}
            onChange={handleChangeMM}
            invalid={invalid}
            disabled={disabled}
            clearable={false}
            placeholder={<FormattedMessage id="FormElements.BirthdayPicker.SelectMonth"/>}
          />
        </div>
      </div>

      <div className="sts-ui-form-birth-date__yy">
        <div className="sts-ui-form-birth-date__label">
          <label htmlFor={`${id}.yy`}>
            <FormattedMessage id="FormElements.BirthdayPicker.SelectYear"/>
          </label>
        </div>
        <div className="sts-ui-form-birth-date__field">
          <Select
            id={`${id}.yy`}
            name={`${name}.yy`}
            value={viewValue.yy}
            options={yyOptions}
            onBlur={handleBlurYY}
            onChange={handleChangeYY}
            invalid={invalid}
            disabled={disabled}
            clearable={false}
            placeholder={<FormattedMessage id="FormElements.BirthdayPicker.SelectYear"/>}
          />
        </div>
      </div>
    </div>
  );
}

function fromDate(date: DateOnly | null): DateInput {
  return date === null
    ? { dd: null, mm: null, yy: null }
    : date;
}

function toDate(date: DateInput): DateOnly | null {
  if (date.dd === null || date.mm === null || date.yy === null) {
    return null;
  }

  return {
    dd: date.dd,
    mm: date.mm,
    yy: date.yy,
  };
}

function getYearList(
  minDate: DateOnly,
  maxDate: DateOnly,
): ReadonlyArray<ListOption<Integer>> {
  const minYY = minDate.yy;
  const maxYY = maxDate.yy;

  return Array.from({ length: maxYY - minYY + 1 }, (_, i) => ({
    value: maxYY - i,
    title: (maxYY - i).toString(),
  }));
}

function getMonthList(
  value: DateInput,
  options: ReadonlyArray<ListOption<Integer>>,
  minDate: DateOnly,
  maxDate: DateOnly,
): ReadonlyArray<ListOption<Integer>> {
  if (value.yy === null) {
    return options;
  }

  const valYY = value.yy;
  const minYY = minDate.yy;
  const maxYY = maxDate.yy;

  if (valYY < maxYY && valYY > minYY) {
    return options;
  }
  if (valYY > maxYY || valYY < minYY) {
    return [];
  }

  const minMM = minDate.mm;
  const maxMM = maxDate.mm;

  return options.filter((option) => !(
    (valYY === minYY && option.value < minMM) ||
    (valYY === maxYY && option.value > maxMM)
  ));
}

function getDateList(
  value: DateInput,
  options: ReadonlyArray<ListOption<Integer>>,
  minDate: DateOnly,
  maxDate: DateOnly,
): ReadonlyArray<ListOption<Integer>> {
  if (value.yy === null || value.mm === null) {
    return options;
  }

  const daysInMonth = getDaysInMonth(value.yy, value.mm);

  const valMMYY = value.yy * 1000 + value.mm;
  const minMMYY = minDate.yy * 1000 + minDate.mm;
  const maxMMYY = maxDate.yy * 1000 + maxDate.mm;

  if (valMMYY < maxMMYY && valMMYY > minMMYY) {
    return options.slice(0, daysInMonth);
  }
  if (valMMYY > maxMMYY || valMMYY < minMMYY) {
    return [];
  }

  const minDD = minDate.dd;
  const maxDD = maxDate.dd;

  return options.slice(0, daysInMonth).filter((option) => !(
    (valMMYY === minMMYY && option.value < minDD) ||
    (valMMYY === maxMMYY && option.value > maxDD)
  ));
}

const EQ_VIEW_VALUE: Eq.Eq<DateInput> = Eq.struct({
  yy: Eq.eqStrict,
  mm: Eq.eqStrict,
  dd: Eq.eqStrict,
});

const EQ_CHANGE_EVENT = Eq.struct({
  propValue: Eq.eqStrict,
  viewValue: EQ_VIEW_VALUE,
});
