import './CheckoutNavigation.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Stepper, StepperItem } from 'src/components/common/Stepper';

type Props = {
  readonly current: 'personal' | 'payment';
};

export function CheckoutNavigation({ current }: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-navigation">
      <Stepper>
        {current === 'personal' && (
          <StepperItem
            icon={1}
            title={<FormattedMessage id="Menu.Checkout.BillingInformation"/>}
            state="current"
          />
        )}
        {current !== 'personal' && (
          <StepperItem
            icon={1}
            title={<FormattedMessage id="Menu.Checkout.BillingInformation"/>}
            state="completed"
            route="checkout:personal"
          />
        )}

        <StepperItem
          icon={2}
          title={<FormattedMessage id="Menu.Checkout.Payment"/>}
          state={current === 'payment' ? 'current' : 'disabled'}
        />
      </Stepper>
    </div>
  );
}
