import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getAffiliateData } from 'src/selectors/getAffiliateData';
import { PaymentStore } from 'src/types/PaymentStore';
import { createPurchaseEventCustom, createPurchaseEventDefault } from 'src/utils/payment/purchaseEvent';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, select } from 'typed-redux-saga';

export function* pushPurchaseEvent(payment: PaymentStore): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Pushing purchase event…', payment);

    const defaultEvent = yield* call(
      createPurchaseEventDefault,
      payment.order,
      payment.total,
      payment.basket,
      payment.checkout.personal.billing,
    );
    window.dataLayer?.push(defaultEvent);

    const affiliate = yield* select(getAffiliateData);
    if (affiliate.gtmCustomParams !== null && Object.keys(affiliate.gtmCustomParams).length > 0) {
      const customEvent = yield* call(
        createPurchaseEventCustom,
        payment.order,
        payment.total,
        payment.basket,
        affiliate.gtmCustomParams,
      );
      window.dataLayer?.push(customEvent);
    }
  } catch (error) {
    yield* call(logError, 'Pushing purchase event… error', error);
    yield* call(sentryCatch, new RuntimeError(
      'Could not push purchase event',
      { payment },
      NativeError.wrap(error),
    ));
  }
}
