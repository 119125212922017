import { Decimal } from 'decimal.js-light';
import { Currency } from 'src/types/Currency';
import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketScreenDataSummary } from 'src/types/P2pTicketScreenData';
import { P2pTicketTripItem } from 'src/types/P2pTicketTripItem';
import { isDefined } from 'src/utils/guard';
import { getTravelerTicketPrice } from 'src/utils/p2p/getTravelerDetails';

export function getSummaryTicketPrice(
  screen: P2pTicketScreenDataSummary,
  traveler: P2pTicketFormDataTraveler,
  currency: Currency,
): Decimal {
  const outwardPrice = getTravelerTicketPrice(screen.outward, traveler, currency);
  const returnPrice = screen.return !== null
    ? getTravelerTicketPrice(screen.return, traveler, currency)
    : new Decimal(0);

  return outwardPrice.add(returnPrice);
}

export function getSummaryTotalPrice(
  screen: P2pTicketScreenDataSummary,
  currency: Currency,
): Decimal {
  return screen.form.travelers
    .map((traveler) => getSummaryTicketPrice(screen, traveler, currency))
    .reduce((sum, it) => sum.add(it), new Decimal(0));
}

export function getSummaryTrips(
  screen: P2pTicketScreenDataSummary,
): P2pTicketTripItem[] {
  return [
    screen.outward.selected.offer.trip,
    screen.return?.selected.offer.trip,
  ].filter(isDefined);
}
