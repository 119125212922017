import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { LOCAL_DATE } from 'src/codecs/LocalDate';
import { P2P_TICKET_AGE_GROUP } from 'src/codecs/P2pTicketAgeGroup';
import { P2P_TICKET_DISCOUNT } from 'src/codecs/P2pTicketDiscount';
import { UUID } from 'src/codecs/Uuid';
import { P2pTicketTraveler } from 'src/types/P2pTicketTraveler';

export const P2P_TICKET_TRAVELER: C.Codec<unknown, JsonRecord, P2pTicketTraveler> = C.struct({
  id: UUID,

  ageGroup: P2P_TICKET_AGE_GROUP,
  discount: P2P_TICKET_DISCOUNT,

  firstName: C.string,
  lastName: C.string,
  birthDate: LOCAL_DATE,
});
