import FACEBOOK from 'src/assets/img/social/facebook.png';
import INSTAGRAM from 'src/assets/img/social/instagram.png';
import LINKEDIN from 'src/assets/img/social/linkedin.png';
import { SocialLink } from 'src/types/SocialLink';

export const SOCIAL_LINKS: ReadonlyArray<SocialLink> = [
  {
    img: FACEBOOK,
    url: 'https://www.facebook.com/switzerlandtravelcentre',
  },
  {
    img: INSTAGRAM,
    url: 'https://www.instagram.com/switzerlandtravelcentre',
  },
  {
    img: LINKEDIN,
    url: 'https://www.linkedin.com/company/stc-switzerland-travel-centre-ag',
  },
] as const;
