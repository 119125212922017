import React from 'react';
import { FormattedMessage } from 'react-intl';
import { parseDuration } from 'src/utils/p2p/duration';

type Props = {
  readonly duration: string;
};

export function P2pTicketScreenCommonTripDuration({ duration }: Props): React.ReactElement {
  try {
    const parsedDuration = parseDuration(duration);
    return (
      <FormattedMessage
        id="P2P.TripInfo.Duration"
        values={{ hours: parsedDuration.hh, minutes: parsedDuration.mm }}
      />
    );
  } catch (e) {
    return <span>{duration}</span>;
  }
}
