import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_RESET_SEARCH } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { createP2pTicketScreenUid } from 'src/sagas/p2p/utils/createP2pTicketScreenUid';
import { getP2pMinimalTripDate } from 'src/sagas/p2p/utils/getP2pMinimalTripDate';
import { saveP2pTicketData } from 'src/sagas/p2p/utils/saveP2pTicketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { P2pTicketScreenData } from 'src/types/P2pTicketScreenData';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* p2pTicketResetSearchSaga(): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Resetting P2P Ticket search…');
    yield* put(P2P_TICKET_RESET_SEARCH.pending());
    yield* delay(500);

    const currentScreen = yield* select(getP2pTicketScreen);
    const updatedScreen: P2pTicketScreenData = {
      uid: yield* call(createP2pTicketScreenUid),
      type: 'initial',
      form: currentScreen.form,

      outward: null,
      return: null,

      minDate: yield* call(getP2pMinimalTripDate),
      stations: null,

      action: null,
    };

    yield* call(saveP2pTicketData, updatedScreen);

    yield* call(logDebug, 'Resetting P2P Ticket search… done', updatedScreen);
    yield* put(P2P_TICKET_RESET_SEARCH.success(updatedScreen));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not reset P2P Ticket search',
      {},
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Resetting P2P Ticket search… error', error);
    yield* put(P2P_TICKET_RESET_SEARCH.failure(NativeError.wrap(error)));
  }
}
