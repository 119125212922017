import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { DECIMAL } from 'src/codecs/Decimal';
import { TICKET_PRICE } from 'src/codecs/TicketPrice';
import { CheckoutTotal } from 'src/types/CheckoutTotal';

export const CHECKOUT_TOTAL: C.Codec<unknown, JsonRecord, CheckoutTotal> = C.struct({
  bookingFee: DECIMAL,
  paymentFee: DECIMAL,

  total: DECIMAL,
  subtotal: DECIMAL,

  offerPrice: C.nullable(DECIMAL),
  ticketPrices: ProductMap(C.readonly(C.array(TICKET_PRICE))),
  cancellationProtection: DECIMAL,
});
