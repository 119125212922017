import { P2pTicketTripDuration } from 'src/codecs/P2pTicketTripDuration';
import { LogicError } from 'src/errors/LogicError';
import { LocalDateTime } from 'src/types/LocalDateTime';
import { toDate } from 'src/utils/localDateTime';

export function getTripDuration(departure: LocalDateTime, arrival: LocalDateTime): P2pTicketTripDuration {
  const departureMs = toDate(departure).getTime();
  const arrivalMs = toDate(arrival).getTime();
  if (departureMs > arrivalMs) {
    throw new LogicError('Departure date should not be after arrival', { departure, arrival });
  }

  let remainingSeconds = Math.ceil((arrivalMs - departureMs) / 1000);
  const hh = Math.floor(remainingSeconds / (60 * 60));

  remainingSeconds -= hh * (60 * 60);
  const mm = Math.ceil(remainingSeconds / 60);

  return { hh, mm };
}

export function parseDuration(duration: string): P2pTicketTripDuration {
  const durationRegexp = /P(?:([.,\d]+)D)?(?:T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?)?/;
  const matches = duration.match(durationRegexp);

  if (matches) {
    const days = parseInt(matches[1] ?? '0', 10);
    let hours = parseInt(matches[2] ?? '0', 10);
    let minutes = parseInt(matches[3] ?? '0', 10);

    if (days > 0) {
      hours += 24 * days;
    }

    if (hours === 0 && minutes === 0) {
      minutes += 1;
    }

    return {
      hh: hours,
      mm: minutes,
    };
  }

  throw new LogicError('Could not parse duration', { duration });
}

export function getDurationISO8601(from: LocalDateTime, to: LocalDateTime): string {
  const duration = getTripDuration(from, to);

  if (duration.hh === 0) {
    return `PT${duration.mm}M`;
  }

  if (duration.mm === 0) {
    return `PT${duration.hh}H`;
  }

  return `PT${duration.hh}H${duration.mm}M`;
}
