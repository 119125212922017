import * as C from 'io-ts/Codec';
import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_STATION } from 'src/codecs/P2pTicketStation';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { P2pTicketStation } from 'src/types/P2pTicketStation';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { isHttpError } from 'src/utils/error';
import { call } from 'typed-redux-saga';

export function* searchStationList(searchTerm: string): SagaIterator<P2pTicketStation[]> {
  try {
    const request = yield* call(REQUEST.encode, { searchTerm });
    const response = yield* call(makeGetRequest, `${HOST_API}/p2p-places`, request);
    return yield* call(decodeOrThrow, RESPONSE, response.content);
  } catch (error) {
    if (isHttpError(NativeError.wrap(error), 404)) {
      return [];
    }

    throw new RuntimeError('Could not search station list', { searchTerm }, NativeError.wrap(error));
  }
}

const REQUEST = C.struct({ searchTerm: C.string });
const RESPONSE = C.array(P2P_TICKET_STATION);
