import { RefObject, useEffect } from 'react';

export function useOutsideFocus(ref: RefObject<HTMLElement | null>, handler: () => void): void {
  useEffect(() => {
    const listener = (event: FocusEvent): void => {
      const element = ref.current;
      if (!element) {
        return;
      }

      const target = event.target;
      if (target instanceof Element) {
        if (element.contains(target) || !target.isConnected) {
          return;
        }
      }

      handler();
    };

    window.addEventListener('focusin', listener, { passive: true });
    return () => window.removeEventListener('focusin', listener);
  }, [ref, handler]);
}
