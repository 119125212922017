import { SagaIterator } from 'redux-saga';
import { p2pTicketScreenSaga } from 'src/sagas/p2p/p2pTicketScreenSaga';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { cancel, fork, select, take } from 'typed-redux-saga';

export function* p2pTicketPageSaga(): SagaIterator<void> {
  while (true) {
    const currScreen = yield* select(getP2pTicketScreen);

    const task = yield* fork(p2pTicketScreenSaga, currScreen);
    while (true) {
      yield* take('*');

      const nextScreen = yield* select(getP2pTicketScreen);
      if (nextScreen.type !== currScreen.type) {
        yield* cancel(task);
        break;
      }
    }
  }
}
