import * as C from 'io-ts/Codec';
import { SagaIterator } from 'redux-saga';
import { CHECKOUT_STORE } from 'src/codecs/CheckoutStore';
import { STORAGE_CHECKOUT_KEY, STORAGE_CHECKOUT_VERSION } from 'src/constants/storage';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageGet, storageSet } from 'src/sagas/utils/storage';
import { CheckoutStore } from 'src/types/CheckoutStore';
import { decodeOrGet } from 'src/utils/decodeOrGet';
import { call } from 'typed-redux-saga';

export function* loadCheckoutStore(): SagaIterator<CheckoutStore | null> {
  try {
    yield* call(logDebug, 'Loading checkout store…');

    const storage = yield* call(storageGet, STORAGE_CHECKOUT_KEY);
    const decoded = yield* call(decodeOrGet, STORAGE_VAL, storage, null);

    yield* call(logDebug, 'Loading checkout store… done', decoded);
    return decoded?.content ?? null;
  } catch (error) {
    yield* call(logError, 'Loading checkout store… error', error);
    throw new RuntimeError('Could not load checkout store', {}, NativeError.wrap(error));
  }
}

export function* saveCheckoutStore(store: CheckoutStore | null): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Saving checkout store…', store);

    const encoded = store === null
      ? undefined
      : yield* call(STORAGE_VAL.encode, { version: STORAGE_CHECKOUT_VERSION, content: store });
    yield* call(storageSet, STORAGE_CHECKOUT_KEY, encoded);

    yield* call(logDebug, 'Saving checkout store… done');
  } catch (error) {
    yield* call(logError, 'Saving checkout store… error', error);
    throw new RuntimeError('Could not save checkout stor', {}, NativeError.wrap(error));
  }
}

const STORAGE_VAL = C.struct({
  version: C.literal(STORAGE_CHECKOUT_VERSION),
  content: CHECKOUT_STORE,
});
