import './ProductCardPriceValue.scss';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { FormattedPrice } from 'src/components/common/FormattedPrice';

type Props = {
  readonly price: Decimal;
  readonly title: React.ReactNode;
  readonly fixPrice: boolean;
};

export function ProductCardPriceValue({ price, title, fixPrice }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-card-price-value">
      {!fixPrice && <><i>{title}</i>&nbsp;</>}
      <b><FormattedPrice value={price}/></b>
    </div>
  );
}
