import { pipe } from 'fp-ts/function';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import {
  P2P_TICKET_INITIAL_SUBMIT,
  P2P_TICKET_SUMMARY_SUBMIT,
  P2P_TICKET_TRIP_OUTWARD_SUBMIT,
  P2P_TICKET_TRIP_RETURN_SUBMIT,
} from 'src/actions/P2pTicketActions';
import { P2P_TICKET_SCREEN } from 'src/optics/p2pTicket';
import { P2pTicketScreenAction } from 'src/types/P2pTicketScreenAction';
import { P2pTicketState } from 'src/types/P2pTicketState';

export function p2pTicketScreenSubmitReducer(state: P2pTicketState, action: Action): P2pTicketState {
  if (
    P2P_TICKET_INITIAL_SUBMIT.isPending(action) ||
    P2P_TICKET_SUMMARY_SUBMIT.isPending(action) ||
    P2P_TICKET_TRIP_OUTWARD_SUBMIT.isPending(action) ||
    P2P_TICKET_TRIP_RETURN_SUBMIT.isPending(action)
  ) {
    return pipe(
      state,
      modifyAction(P2pTicketScreenAction.SUBMIT_SCREEN),
    );
  }
  if (
    P2P_TICKET_INITIAL_SUBMIT.isFailure(action) ||
    P2P_TICKET_SUMMARY_SUBMIT.isFailure(action) ||
    P2P_TICKET_TRIP_OUTWARD_SUBMIT.isFailure(action) ||
    P2P_TICKET_TRIP_RETURN_SUBMIT.isFailure(action)
  ) {
    return pipe(
      state,
      modifyAction(null),
    );
  }
  if (
    P2P_TICKET_INITIAL_SUBMIT.isSuccess(action) ||
    // P2P_TICKET_SUMMARY_SUBMIT.isSuccess(action) ||
    P2P_TICKET_TRIP_OUTWARD_SUBMIT.isSuccess(action) ||
    P2P_TICKET_TRIP_RETURN_SUBMIT.isSuccess(action)
  ) {
    return {
      ...state,
      screen: action.data,
    };
  }

  return state;
}

function modifyAction(action: P2pTicketScreenAction | null): (state: P2pTicketState) => P2pTicketState {
  return pipe(
    P2P_TICKET_SCREEN,
    O.prop('action'),
    O.modify(() => action),
  );
}
