import './CheckoutPaymentTicketPriceList.scss';
import { Decimal } from 'decimal.js-light';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { DiscountList } from 'src/components/common/DiscountList';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { ProductName } from 'src/components/common/ProductName';
import { TARIFF_LIST } from 'src/constants/tariff';
import { CheckoutProductProduct } from 'src/types/CheckoutProduct';
import { Integer } from 'src/types/Integer';
import { ProductCode } from 'src/types/ProductCode';
import { TicketAge } from 'src/types/TicketAge';
import { TicketPrice } from 'src/types/TicketPrice';
import { TicketType } from 'src/types/TicketType';
import { assertNever } from 'src/utils/assert';

type Props = {
  readonly product: CheckoutProductProduct;
  readonly tickets: ReadonlyArray<TicketPrice>;
};

export function CheckoutPaymentTicketPriceList({
  product,
  tickets,
}: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-payment-ticket-price-list">
      <div className="sts-ui-checkout-payment-ticket-price-list__header">
        <div className="sts-ui-checkout-payment-ticket-price-list__title">
          <ProductName type={product.code}/>
        </div>
        <div className="sts-ui-checkout-payment-ticket-price-list__info">
          {product.form.startDate && (
            <div className="sts-ui-checkout-payment-ticket-price-list__column">
              <FormattedMessage
                id="Payment.Success.StartDate"
                values={{ date: <FormattedDate value={product.form.startDate}/> }}
              />
            </div>
          )}
          {product.form.returnDate && (
            <div className="sts-ui-checkout-payment-ticket-price-list__column">
              <FormattedMessage
                id="Payment.Success.ReturnDate"
                values={{ date: <FormattedDate value={product.form.returnDate}/> }}
              />
            </div>
          )}
          {product.form.duration && (
            <div className="sts-ui-checkout-payment-ticket-price-list__column">
              <FormattedMessage
                id="Form.Checkout.Payment.DurationValue"
                values={{ PRODUCT: product.code, DURATION: product.form.duration.replace(/\D/g, '') }}
              />
            </div>
          )}
          {product.form.class && (
            <div className="sts-ui-checkout-payment-ticket-price-list__column">
              <FormattedHTMLMessage
                id="Form.Checkout.Payment.Class"
                values={{ class: product.form.class }}
              />
            </div>
          )}
        </div>
      </div>

      {TARIFF_LIST.map((tariff) => {
        const tariffTickets = tickets.filter((it) => it.tariff === tariff);
        if (tariffTickets.length === 0) {
          return null;
        }

        const tariffTotal = tariffTickets.reduce((sum, it) => sum.add(it.price), new Decimal(0));

        return (
          <div key={tariff} className="sts-ui-checkout-payment-ticket-price-list__tariff">
            <div className="sts-ui-checkout-payment-ticket-price-list__tariff-title">
              {renderTariffMessage(product.code, tariff, tariffTickets.length)}
            </div>
            <div className="sts-ui-checkout-payment-ticket-price-list__tariff-value">
              <FormattedPrice value={tariffTotal}/> {renderDiscountName(product.code, tariff, tariffTickets)}
            </div>
          </div>
        );
      })}
      {product.familyCardDiscount && product.familyCardCount > 0 && (
        <div className="sts-ui-checkout-payment-ticket-price-list__tariff">
          <div className="sts-ui-checkout-payment-ticket-price-list__tariff-title">
            <FormattedMessage
              id="Form.Product.UpToXChildTravelFree"
              values={{ countFamilyCards: product.familyCardCount }}
            />
          </div>
          <div className="sts-ui-checkout-payment-ticket-price-list__tariff-value">
            <FormattedPrice value={0}/>
          </div>
        </div>
      )}
    </div>
  );
}

function renderTariffMessage(
  product: ProductCode,
  tariff: TicketAge,
  ticketCount: Integer,
): React.ReactElement {
  if (product === TicketType.SWISS_HALF_FARE_CARD && tariff === TicketAge.ADULT) {
    return (
      <FormattedMessage
        id="Payment.HalfFareCard"
        values={{ count: ticketCount }}
      />
    );
  }
  if (product === TicketType.SWISS_HALF_FARE_CARD && tariff === TicketAge.CHILD) {
    return (
      <FormattedMessage
        id="Payment.FamilyCard"
        values={{ count: ticketCount }}
      />
    );
  }

  switch (tariff) {
  case TicketAge.ADULT: {
    return (
      <FormattedMessage
        id="Payment.TariffAdult"
        values={{ count: ticketCount }}
      />
    );
  }

  case TicketAge.YOUTH: {
    return (
      <FormattedMessage
        id="Payment.TariffYouth"
        values={{ count: ticketCount }}
      />
    );
  }

  case TicketAge.CHILD: {
    return (
      <FormattedMessage
        id="Payment.TariffChildren"
        values={{ count: ticketCount }}
      />
    );
  }

  default:
    return assertNever('Unknown tariff name', { tariff });
  }
}

function renderDiscountName(
  product: ProductCode,
  tariff: TicketAge,
  tickets: ReadonlyArray<TicketPrice>,
): React.ReactNode {
  const discounts = tickets
    .map((it) => it.discounts)
    .flat(1)
    .filter((it, ix, arr) => arr.indexOf(it) === ix);
  if (discounts.length === 0) {
    return null;
  }

  switch (tariff) {
  case TicketAge.ADULT:
    return (
      <FormattedMessage
        id="Payment.DiscountCard"
        values={{
          discountCard: (
            <DiscountList
              product={product}
              discounts={discounts}
            />
          ),
        }}
      />
    );

  case TicketAge.CHILD:
    return (
      <FormattedMessage id="Payment.DiscountFamilyCard"/>
    );

  default:
    return null;
  }
}
