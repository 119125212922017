import './P2pTicketFormDateTimePicker.scss';
import classNames from 'classnames';
import { format } from 'date-fns/format';
import { constVoid } from 'fp-ts/function';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { P2pTicketFormCalendar } from 'src/components/p2p/P2pTicketFormCalendar';
import { P2pTicketFormDropdownAnimate } from 'src/components/p2p/P2pTicketFormDropdownAnimate';
import { P2pTicketFormTimeInput } from 'src/components/p2p/P2pTicketFormTimeInput';
import { FORMAT_DATE_SHORT, FORMAT_TIME } from 'src/constants/format';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { useOutsideClick } from 'src/hooks/useOutsideClick';
import { useOutsideFocus } from 'src/hooks/useOutsideFocus';
import { DateOnly } from 'src/types/DateOnly';
import { LocalDateTime } from 'src/types/LocalDateTime';
import { LocalTime } from 'src/types/LocalTime';
import { toDate } from 'src/utils/localDateTime';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly value: LocalDateTime | null;
  readonly onBlur?: () => void;
  readonly onChange: (value: LocalDateTime | null) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly min?: LocalDateTime;
  readonly max?: LocalDateTime;
  readonly timeComment: React.ReactNode;
  readonly navigation: 'narrow' | 'broad';
  readonly defaultDate: LocalDateTime;
};

export function P2pTicketFormDateTimePicker({
  id,
  name,
  layout,
  border,
  label,
  value,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  placeholder,
  min,
  max,
  timeComment,
  navigation,
  defaultDate,
}: Props): React.ReactElement {
  const viewValue = useMemo(() => {
    if (value === null) {
      return '';
    }

    const native = toDate(value);
    return `${format(native, FORMAT_DATE_SHORT)}, ${format(native, FORMAT_TIME)}`;
  }, [value]);

  const [showOverlay, toggleOverlay] = useState(false);
  const [timeValue, setTimeValue] = useState(() => value?.time ?? defaultDate.time);

  useEffect(() => {
    if (value !== null) {
      setTimeValue(value.time);
    }
  }, [value]);

  const handleInputFocus = useCallback(() => {
    toggleOverlay(true);
  }, []);

  const handleSelectDate = useCallback((date: DateOnly) => {
    const nextValue = { date: date, time: timeValue };
    toggleOverlay(false);

    onChange(nextValue);
    onBlur?.();
  }, [onChange, onBlur, timeValue]);

  const handleSelectTime = useCallback((time: LocalTime) => {
    if (value !== null) {
      onChange({ ...value, time });
    } else {
      setTimeValue(time);
    }
  }, [value, onChange]);

  const container = useRef<HTMLDivElement>(null);
  const outsideClickHandler = useCallback(() => {
    if (!showOverlay) {
      return;
    }

    toggleOverlay(false);
    onBlur?.();
  }, [showOverlay, onBlur]);

  useOutsideClick(container, outsideClickHandler);
  useOutsideFocus(container, outsideClickHandler);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={container}
      data-invalid={invalid}
      data-disabled={disabled}
      className={classNames(
        'sts-ui-p2p-ticket-form-date-time-picker',
        `sts-ui-p2p-ticket-form-date-time-picker--layout-${layout}`,
        `sts-ui-p2p-ticket-form-date-time-picker--border-${border}`,
        showOverlay ? 'sts-ui-p2p-ticket-form-date-time-picker--open' : null,
      )}
    >
      <label htmlFor={id} className="sts-ui-p2p-ticket-form-date-time-picker__label">
        {label}
      </label>
      <input
        ref={inputRef}
        id={id}
        name={name}
        type="text"
        className="sts-ui-p2p-ticket-form-date-time-picker__input"
        value={viewValue}
        onChange={constVoid}
        onBlur={onBlur}
        onFocus={handleInputFocus}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={true}
      />

      <P2pTicketFormDropdownAnimate show={showOverlay} content={menuRef}>
        <div ref={menuRef} className="sts-ui-p2p-ticket-form-date-time-picker__menu">
          <div className="sts-ui-p2p-ticket-form-date-time-picker__calendar">
            <P2pTicketFormCalendar
              value={value?.date ?? null}
              onChange={handleSelectDate}
              navigation={navigation}
              defaultDate={defaultDate.date}
              minDate={min?.date}
              maxDate={max?.date}
            />
          </div>
          <div className="sts-ui-p2p-ticket-form-date-time-picker__time-picker">
            <div className="sts-ui-p2p-ticket-form-date-time-picker__time-comment">
              {timeComment}
            </div>
            <div className="sts-ui-p2p-ticket-form-date-time-picker__time-input">
              <P2pTicketFormTimeInput
                value={timeValue}
                onChange={handleSelectTime}
              />
            </div>
          </div>
        </div>
      </P2pTicketFormDropdownAnimate>
    </div>
  );
}
