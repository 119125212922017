import './ProductTeaserP2pTicket.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { ProductAttibutesTeaserP2pTicket } from 'src/types/ProductAttibutes';

type Props = {
  readonly p2pTicket: ProductAttibutesTeaserP2pTicket;
};

export function ProductTeaserP2pTicket({ p2pTicket }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-teaser-p2p-ticket">
      <div className="sts-ui-product-teaser-p2p-ticket__content">
        <div className="sts-ui-product-teaser-p2p-ticket__title">
          <FormattedMessage id={p2pTicket.title}/>
        </div>
        <div className="sts-ui-product-teaser-p2p-ticket__description">
          <FormattedHTMLMessage id={p2pTicket.description}/>
        </div>
      </div>
      <div className="sts-ui-product-teaser-p2p-ticket__link">
        <RouteLink route="p2p-ticket">
          <FormattedMessage id={p2pTicket.linkText}/>
          <ArrowRightIcon/>
        </RouteLink>
      </div>
    </div>
  );
}
