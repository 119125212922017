import * as D from 'io-ts/Decoder';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { CURRENCY } from 'src/codecs/Currency';
import { PAYMENT_TYPE } from 'src/codecs/PaymentType';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { BasketContent } from 'src/types/BasketContent';
import { Currency } from 'src/types/Currency';
import { PaymentCode } from 'src/types/PaymentCode';
import { PaymentType } from 'src/types/PaymentType';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchCheckoutPaymentTypes(
  currency: Currency,
  basket: BasketContent,
): SagaIterator<ReadonlyArray<PaymentType>> {
  try {
    const request = yield* call(REQUEST.encode, { currency });
    const response = yield* call(makeGetRequest, `${HOST_API}/payment-types`, request);
    const { group1: paymentTypes } = yield* call(decodeOrThrow, RESPONSE, response.content);

    return filterPaymentTypes(paymentTypes, basket);
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Checkout payment types',
      { currency },
      NativeError.wrap(error),
    );
  }
}

function filterPaymentTypes(
  paymentTypes: ReadonlyArray<PaymentType>,
  basketContent: BasketContent,
): ReadonlyArray<PaymentType> {
  return basketContent.p2pTickets.length > 0
    ? paymentTypes.filter((it) => !UNAVAILABLE_FOR_P2P_TICKETS.has(it.name))
    : paymentTypes;
}

const UNAVAILABLE_FOR_P2P_TICKETS: ReadonlySet<PaymentCode> = new Set([
  'ALIPAY',
]);

const REQUEST = E.struct({
  currency: CURRENCY,
});

const RESPONSE = D.struct({
  group1: D.array(PAYMENT_TYPE),
});
