import './P2pTicketScreenCommonNavigation.scss';
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Stepper, StepperItem } from 'src/components/common/Stepper';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { P2pTicketScreenType } from 'src/types/P2pTicketScreenType';

type Props = {
  readonly current: P2pTicketScreenType;
  readonly loading: P2pTicketScreenType | null;
  readonly oneWay: boolean;
  readonly onNavigate: (to: P2pTicketScreenType) => void;
};

export function P2pTicketScreenCommonNavigation({
  current,
  loading,
  oneWay,
  onNavigate,
}: Props): React.ReactElement {
  const screenList = oneWay ? SCREEN_LIST_ONE_WAY : SCREEN_LIST_TWO_WAY;
  const currentIndex = screenList.indexOf(current);

  return (
    <div className="sts-ui-p2p-ticket-screen-common-navigation">
      <Stepper>
        {screenList.map((screen, screenIndex) => (
          <StepperItem
            key={screen}
            icon={loading === screen ? <LoadingIcon/> : screenIndex + 1}
            title={SCREEN_TITLES[screen]}
            state={currentIndex > screenIndex ? 'completed' : currentIndex < screenIndex ? 'disabled' : 'current'}
            onClick={currentIndex > screenIndex ? (): void => onNavigate(screen) : undefined}
          />
        ))}
      </Stepper>
    </div>
  );
}

const SCREEN_TITLES: ReadonlyRecord<P2pTicketScreenType, React.ReactElement> = {
  'initial': <FormattedMessage id="P2P.Navigation.Search"/>,
  'trip-outward': <FormattedMessage id="P2P.Navigation.OutwardTrip"/>,
  'trip-return': <FormattedMessage id="P2P.Navigation.ReturnTrip"/>,
  'summary': <FormattedMessage id="P2P.Navigation.Summary"/>,
};

const SCREEN_LIST_ONE_WAY: ReadonlyArray<P2pTicketScreenType> = [
  'initial',
  'trip-outward',
  'summary',
];

const SCREEN_LIST_TWO_WAY: ReadonlyArray<P2pTicketScreenType> = [
  'initial',
  'trip-outward',
  'trip-return',
  'summary',
];
