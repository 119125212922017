import { SagaIterator } from 'redux-saga';
import { createP2pTicketScreenUid } from 'src/sagas/p2p/utils/createP2pTicketScreenUid';
import { createReturnTripInfo } from 'src/sagas/p2p/utils/createTripInfo';
import { fetchP2pTripList } from 'src/sagas/p2p/utils/fetchP2pTripList';
import { Language } from 'src/types/Language';
import { P2pTicketScreenData, P2pTicketScreenDataTripOutward } from 'src/types/P2pTicketScreenData';
import { P2pTicketTripSelection } from 'src/types/P2pTicketTripSelection';
import { Uuid } from 'src/types/Uuid';
import { call } from 'typed-redux-saga';

export function* submitTripOutwardForm(
  screen: P2pTicketScreenDataTripOutward,
  selection: P2pTicketTripSelection,
  language: Language,
  conversationId: Uuid,
): SagaIterator<P2pTicketScreenData> {
  if (screen.form.trip.oneWay) {
    return {
      uid: yield* call(createP2pTicketScreenUid),
      type: 'summary',
      form: screen.form,

      outward: {
        tripInfo: screen.tripInfo,
        selected: selection,
      },
      return: null,

      action: null,
    } satisfies P2pTicketScreenData;
  }

  const returnTripInfo = yield* call(createReturnTripInfo, screen.form);
  const returnTripList = yield* call(
    fetchP2pTripList,
    language,
    screen.form.travelers,
    conversationId,
    returnTripInfo,
  );

  return {
    uid: yield* call(createP2pTicketScreenUid),
    type: 'trip-return',
    form: screen.form,

    tripInfo: returnTripInfo,
    tripList: returnTripList,

    outward: {
      tripInfo: screen.tripInfo,
      selected: selection,
    },
    return: null,

    minDate: screen.minDate,
    stations: null,
    scrolling: null,

    action: null,
  } satisfies P2pTicketScreenData;
}
