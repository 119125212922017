import { format } from 'date-fns/format';
import React from 'react';
import { useSelector } from 'react-redux';
import { FORMAT_DATE_LONG } from 'src/constants/format';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getDateLocale } from 'src/selectors/getDateLocale';
import { DateOnly } from 'src/types/DateOnly';
import { toDate } from 'src/utils/dateOnly';

type Props = {
  readonly value: DateOnly;
};

export const FormattedDate = React.memo(({ value }: Props) => {
  const locale = useSelector(getDateLocale);
  const language = useSelector(getCurrentLanguage);
  const formatted = format(toDate(value), FORMAT_DATE_LONG[language], { locale });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formatted}</>;
});
