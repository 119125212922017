import { isEmpty } from 'fp-ts/ReadonlyRecord';
import { push } from 'redux-first-history';
import { SagaIterator } from 'redux-saga';
import { BASKET_SET_P2P_TICKET } from 'src/actions/BasketActions';
import { P2P_TICKET_SUMMARY_SUBMIT } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { validate } from 'src/forms/utils/validate';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { getRouteFullPath } from 'src/routing/utils/getRouteFullPath';
import { saveBasketData } from 'src/sagas/basket/utils/saveBasketData';
import { createP2pTicketProduct } from 'src/sagas/p2p/utils/createP2pTicketProduct';
import { refreshP2pTripOffer } from 'src/sagas/p2p/utils/refreshP2pTripOffer';
import { saveP2pTicketData } from 'src/sagas/p2p/utils/saveP2pTicketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getP2pTicketConversationId } from 'src/selectors/getP2pTicketConversationId';
import { getP2pTicketSummaryFormRule } from 'src/selectors/getP2pTicketSummaryFormRule';
import { getP2pTicketSummaryScreen } from 'src/selectors/getP2pTicketSummaryScreen';
import { GetRequestActionType } from 'src/utils/createActions';
import { isNotNull } from 'src/utils/guard';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* p2pTicketSummarySubmitSaga(
  action: GetRequestActionType<typeof P2P_TICKET_SUMMARY_SUBMIT>,
): SagaIterator<void> {
  const formData = action.data;

  const formRule = yield* select(getP2pTicketSummaryFormRule);
  const formErrors = yield* call(validate, formData, formRule);
  if (!isEmpty(formErrors)) {
    return;
  }

  try {
    yield* call(logDebug, 'Submitting P2P Ticket summary data…', formData);
    yield* put(P2P_TICKET_SUMMARY_SUBMIT.pending());
    yield* delay(500);

    const language = yield* select(getCurrentLanguage);
    const currentScreen = yield* select(getP2pTicketSummaryScreen);
    const conversationId = yield* select(getP2pTicketConversationId);

    const outwardTrip = yield* call(
      refreshP2pTripOffer,
      language,
      currentScreen.form.travelers,
      conversationId,
      currentScreen.outward,
    );
    const returnTrip = currentScreen.return === null ? null : yield* call(
      refreshP2pTripOffer,
      language,
      currentScreen.form.travelers,
      conversationId,
      currentScreen.return,
    );

    const outwardProduct = yield* call(
      createP2pTicketProduct,
      conversationId,
      currentScreen.form.travelers,
      outwardTrip,
    );
    const returnProduct = returnTrip === null ? null : yield* call(
      createP2pTicketProduct,
      conversationId,
      currentScreen.form.travelers,
      returnTrip,
    );
    const p2pTicketProducts = [
      outwardProduct,
      returnProduct,
    ].filter(isNotNull);

    yield* call(logDebug, 'Submitting P2P Ticket summary data… done', p2pTicketProducts);
    yield* put(P2P_TICKET_SUMMARY_SUBMIT.success());
    yield* call(saveP2pTicketData, null);

    yield* put(BASKET_SET_P2P_TICKET.trigger(p2pTicketProducts));
    yield* call(saveBasketData);

    const pathname = yield* call(getRouteFullPath, language, 'basket');
    yield* put(push(pathname));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not submit P2P Ticket summary data',
      {},
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Submitting P2P Ticket summary data… error', error);
    yield* put(P2P_TICKET_SUMMARY_SUBMIT.failure(NativeError.wrap(error)));
  }
}
