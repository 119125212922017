import { createSelector } from 'reselect';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { P2pTicketScreenDataTripOutward, P2pTicketScreenDataTripReturn } from 'src/types/P2pTicketScreenData';
import { assertNever } from 'src/utils/assert';

export const getP2pTicketTripScreen = createSelector([
  getP2pTicketScreen,
], (screen): P2pTicketScreenDataTripOutward | P2pTicketScreenDataTripReturn => {
  if (screen.type === 'trip-outward' || screen.type === 'trip-return') {
    return screen;
  }

  return assertNever('Unexpected P2P Ticket screen with trip list', { screen });
});
