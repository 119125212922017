import { SagaIterator } from 'redux-saga';
import { createP2pTicketScreenUid } from 'src/sagas/p2p/utils/createP2pTicketScreenUid';
import { getP2pMinimalTripDate } from 'src/sagas/p2p/utils/getP2pMinimalTripDate';
import { loadP2pTicketData } from 'src/sagas/p2p/utils/loadP2pTicketData';
import { getP2pTicketConversationId } from 'src/selectors/getP2pTicketConversationId';
import { getP2pTicketState } from 'src/selectors/getP2pTicketState';
import { Language } from 'src/types/Language';
import { P2pTicketScreenData, P2pTicketScreenDataInitial } from 'src/types/P2pTicketScreenData';
import { uuidRandom } from 'src/utils/uuid';
import { call, select } from 'typed-redux-saga';

export function* fetchP2pTicketData(language: Language): SagaIterator<P2pTicketScreenData> {
  const state = yield* select(getP2pTicketState);
  if (state.screen) {
    return state.screen;
  }

  const conversationId = yield* select(getP2pTicketConversationId);
  const stored = yield* call(loadP2pTicketData, language, conversationId);
  if (stored) {
    return stored;
  }

  return {
    uid: yield* call(createP2pTicketScreenUid),
    type: 'initial',
    form: {
      trip: {
        from: null,
        to: null,

        departDate: null,
        returnDate: null,

        oneWay: true,
      },
      travelers: [{
        id: yield* call(uuidRandom),
        ageGroup: 'adult',
        discount: 'none',

        firstName: '',
        lastName: '',

        birthDate: null,
      }],
    },

    outward: null,
    return: null,

    minDate: yield* call(getP2pMinimalTripDate),
    stations: null,

    action: null,
  } satisfies P2pTicketScreenDataInitial;
}
