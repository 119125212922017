import { SagaIterator } from 'redux-saga';
import { call as callEffect } from 'redux-saga/effects';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { fetchBasketP2pTicketDetails } from 'src/sagas/basket/utils/fetchBasketP2pTicketDetails';
import { BasketP2pTicketDetails } from 'src/types/BasketP2pTicketDetails';
import { P2pTicketProduct } from 'src/types/P2pTicketProduct';
import { all } from 'typed-redux-saga';

export function* fetchBasketP2pTickets(
  basket: ReadonlyArray<P2pTicketProduct>,
): SagaIterator<BasketP2pTicketDetails[]> {
  try {
    return yield* all(
      basket.map((p2pTicket) => callEffect(
        fetchBasketP2pTicketDetails,
        p2pTicket,
      )),
    );
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Basket P2P tickets',
      { basket },
      NativeError.wrap(error),
    );
  }
}
