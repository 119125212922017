import { P2pTicketTripCollection } from 'src/types/P2pTicketTripCollection';

export function concatTripCollection(
  earlier: P2pTicketTripCollection,
  later: P2pTicketTripCollection,
): P2pTicketTripCollection {
  return {
    trips: earlier.trips.concat(later.trips),

    prevTripsCollectionId: earlier.prevTripsCollectionId,
    nextTripsCollectionId: later.nextTripsCollectionId,
  };
}
