import { Refinement } from 'fp-ts/Refinement';
import { P2pTicketScreenData } from 'src/types/P2pTicketScreenData';

type ScreenMap = {
  readonly [K in P2pTicketScreenData['type']]: Extract<P2pTicketScreenData, { readonly type: K }>;
};

export function p2pTicketScreenGuard<T extends keyof ScreenMap>(
  type: T,
): Refinement<P2pTicketScreenData, ScreenMap[T]> {
  return (screen): screen is ScreenMap[T] => screen.type === type;
}
