import * as C from 'io-ts/Codec';
import { SagaIterator } from 'redux-saga';
import { AFFILIATE_STORE } from 'src/codecs/AffiliateStore';
import { STORAGE_AFFILIATE_KEY, STORAGE_AFFILIATE_VERSION } from 'src/constants/storage';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageGet, storageSet } from 'src/sagas/utils/storage';
import { AffiliateStore } from 'src/types/AffiliateStore';
import { decodeOrGet } from 'src/utils/decodeOrGet';
import { call } from 'typed-redux-saga';

export function* loadAffiliateStore(): SagaIterator<AffiliateStore | null> {
  try {
    yield* call(logDebug, 'Loading affiliate store…');

    const storage = yield* call(storageGet, STORAGE_AFFILIATE_KEY);
    const decoded = yield* call(decodeOrGet, STORAGE_VAL, storage, null);

    yield* call(logDebug, 'Loading affiliate store… done');
    return decoded?.content ?? null;
  } catch (error) {
    yield* call(logError, 'Loading affiliate store… error', error);
    throw new RuntimeError('Could not load affiliate store', {}, NativeError.wrap(error));
  }
}

export function* saveAffiliateStore(store: AffiliateStore | null): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Saving affiliate store…', store);

    const encoded = store === null
      ? undefined
      : yield* call(STORAGE_VAL.encode, { version: STORAGE_AFFILIATE_VERSION, content: store });
    yield* call(storageSet, STORAGE_AFFILIATE_KEY, encoded);

    yield* call(logDebug, 'Saving affiliate store… done');
  } catch (error) {
    yield* call(logError, 'Saving affiliate store… error', error);
    throw new RuntimeError('Could not save affiliate store', {}, NativeError.wrap(error));
  }
}

const STORAGE_VAL = C.struct({
  version: C.literal(STORAGE_AFFILIATE_VERSION),
  content: AFFILIATE_STORE,
});
