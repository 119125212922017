import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Ord';
import { LocalDateTime } from 'src/types/LocalDateTime';
import * as D from 'src/utils/dateOnly';
import * as T from 'src/utils/localTime';

export function fromDate(date: Date): LocalDateTime {
  return {
    date: {
      yy: date.getFullYear(),
      mm: date.getMonth() + 1,
      dd: date.getDate(),
    },
    time: {
      hh: date.getHours(),
      mm: date.getMinutes(),
      ss: date.getSeconds(),
    },
  };
}

export function toDate(date: LocalDateTime): Date {
  return new Date(
    date.date.yy,
    date.date.mm - 1,
    date.date.dd,
    date.time.hh,
    date.time.mm,
    date.time.ss,
    0,
  );
}

export function isValid(date: LocalDateTime): boolean {
  return D.isValid(date.date) && T.isValid(date.time);
}

export const Ord: O.Ord<LocalDateTime> = O.getSemigroup<LocalDateTime>().concat(
  pipe(D.Ord, O.contramap((value) => value.date)),
  pipe(T.Ord, O.contramap((value) => value.time)),
);
