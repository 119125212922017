import { pipe } from 'fp-ts/function';
import { or } from 'fp-ts/Refinement';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import { P2P_TICKET_TRIP_SCROLL } from 'src/actions/P2pTicketActions';
import { p2pTicketScreenGuard } from 'src/guards/p2pTicket';
import { P2P_TICKET_SCREEN } from 'src/optics/p2pTicket';
import { P2pTicketState } from 'src/types/P2pTicketState';
import { P2pTicketTripList } from 'src/types/P2pTicketTripList';
import { P2pTicketTripScrollDirection } from 'src/types/P2pTicketTripScroll';

export function p2pTicketTripScrollReducer(state: P2pTicketState, action: Action): P2pTicketState {
  if (P2P_TICKET_TRIP_SCROLL.isPending(action)) {
    return pipe(
      state,
      modifyStatus(action.meta),
    );
  }
  if (P2P_TICKET_TRIP_SCROLL.isSuccess(action)) {
    return pipe(
      state,
      modifyState(action.data),
      modifyStatus(null),
    );
  }
  if (P2P_TICKET_TRIP_SCROLL.isFailure(action)) {
    return pipe(
      state,
      modifyStatus(null),
    );
  }

  return state;
}

const screenGuard = pipe(
  p2pTicketScreenGuard('trip-outward'),
  or(p2pTicketScreenGuard('trip-return')),
);

function modifyState(tripList: P2pTicketTripList): (state: P2pTicketState) => P2pTicketState {
  return pipe(
    P2P_TICKET_SCREEN,
    O.filter(screenGuard),
    O.prop('tripList'),
    O.modify(() => tripList),
  );
}

function modifyStatus(direction: P2pTicketTripScrollDirection | null): (state: P2pTicketState) => P2pTicketState {
  return pipe(
    P2P_TICKET_SCREEN,
    O.filter(screenGuard),
    O.prop('scrolling'),
    O.modify(() => direction),
  );
}
