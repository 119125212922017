import {
  BASKET_EMPTY,
  BASKET_FETCH,
  BASKET_INIT_STORAGE,
  BASKET_REMOVE,
  BASKET_RESET,
  BASKET_SET_CURRENCY,
  BASKET_SET_PRODUCT,
  BASKET_SET_P2P_TICKET,
  BASKET_UPGRADE,
  BASKET_SET_CONVERSATION_ID,
} from 'src/actions/BasketActions';
import { BasketState } from 'src/types/BasketState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: BasketState = {
  currency: null,
  conversationId: null,
  products: {},
  p2pTickets: [],
  details: null,
};

export const basketReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (BASKET_INIT_STORAGE.is(action)) {
    return {
      ...state,
      currency: action.data.currency,
      conversationId: action.data.conversationId,
      products: action.data.products,
      p2pTickets: action.data.p2pTickets,
    };
  }

  if (BASKET_SET_CURRENCY.is(action)) {
    return { ...state, currency: action.data };
  }
  if (BASKET_SET_CONVERSATION_ID.is(action)) {
    return { ...state, conversationId: action.data };
  }

  if (BASKET_SET_PRODUCT.is(action)) {
    const prevProduct = state.products[action.meta];
    const nextPosition = prevProduct
      ? prevProduct.position
      : Object.values(state.products).reduce((max, it) => Math.max(max, it.position), 0) + 1;

    return {
      ...state,
      products: {
        ...state.products,
        [action.meta]: {
          formData: action.data,
          position: nextPosition,
        },
      },
    };
  }
  if (BASKET_SET_P2P_TICKET.is(action)) {
    return {
      ...state,
      p2pTickets: state.p2pTickets.concat(action.data),
    };
  }
  if (BASKET_EMPTY.is(action)) {
    return {
      ...state,
      conversationId: null,
      products: {},
      p2pTickets: [],
      details: null,
    };
  }

  if (BASKET_REMOVE.isPending(action)) {
    return {
      ...state,
      details: state.details
        ? { ...state.details, removing: action.meta, upgrading: null }
        : state.details,
    };
  }
  if (BASKET_REMOVE.isSuccess(action)) {
    const details = action.data;

    return {
      ...state,
      details: details,
      products: Object.fromEntries(
        details.products.map((product) => [product.code, {
          position: product.position,
          formData: product.form,
        }]),
      ),
      p2pTickets: details.p2pTickets.map((p2pTicket) => p2pTicket.ticket),
    };
  }
  if (BASKET_REMOVE.isFailure(action)) {
    return {
      ...state,
      details: state.details
        ? { ...state.details, removing: null, upgrading: null }
        : state.details,
    };
  }

  if (BASKET_UPGRADE.isPending(action)) {
    return {
      ...state,
      details: state.details
        ? { ...state.details, upgrading: action.meta, removing: null }
        : state.details,
    };
  }
  if (BASKET_UPGRADE.isSuccess(action)) {
    const details = action.data;

    return {
      ...state,
      details: details,
      products: Object.fromEntries(
        details.products.map((product) => [product.code, {
          position: product.position,
          formData: product.form,
        }]),
      ),
      p2pTickets: details.p2pTickets.map((p2pTicket) => p2pTicket.ticket),
    };
  }
  if (BASKET_UPGRADE.isFailure(action)) {
    return {
      ...state,
      details: state.details
        ? { ...state.details, removing: null, upgrading: null }
        : state.details,
    };
  }

  if (BASKET_FETCH.is(action)) {
    return { ...state, details: action.data };
  }
  if (BASKET_RESET.is(action)) {
    return { ...state, details: null };
  }

  return state;
});
