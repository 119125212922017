import './HomepageScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Col, Row } from 'src/components/common/Grid';
import { P2pTicketBanner } from 'src/components/common/P2pTicketBanner';
import { Typography } from 'src/components/common/Typography';
import { HomepageBackgroundImage } from 'src/components/homepage/HomepageBackgroundImage';
import { HomepageBanner } from 'src/components/homepage/HomepageBanner';
import { HomepageSearchForm } from 'src/components/homepage/HomepageSearchForm';
import { PageContainer } from 'src/components/layout/PageContainer';
import { PageContent } from 'src/components/layout/PageContent';
import { ProductGridView } from 'src/components/product-list/ProductGridView';
import { ProductPrimaryCard } from 'src/components/product-list/ProductPrimaryCard';
import { ProductSecondaryCard } from 'src/components/product-list/ProductSecondaryCard';
import { FIX_PRICE_PRODUCTS } from 'src/constants/fixPriceProducts';
import { HOMEPAGE_PRODUCTS } from 'src/constants/homepage';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { ProductCode } from 'src/types/ProductCode';
import { ProductListItem } from 'src/types/ProductListItem';
import { PromotionService } from 'src/types/PromotionService';
import { SearchQuery } from 'src/types/SearchQuery';
import { TicketGroup } from 'src/types/TicketGroup';
import { isDefined } from 'src/utils/guard';

type Props = {
  readonly products: ReadonlyArray<ProductListItem>;
  readonly isBannerShown: boolean;
  readonly isHalfFareCardBooked: boolean;
  readonly promotionService: PromotionService;
  readonly onSearchProduct: (query: SearchQuery) => void;
};

export class HomepageScreen extends React.Component<Props> {
  public render(): React.ReactElement {
    const { products, promotionService, isBannerShown, isHalfFareCardBooked, onSearchProduct } = this.props;

    return (
      <div className="sts-ui-homepage-screen">
        <div className="sts-ui-homepage-screen__header">
          <div className="sts-ui-homepage-screen__header-image">
            <HomepageBackgroundImage/>
          </div>
          <div className="sts-ui-homepage-screen__header-shadow"/>
          <div className="sts-ui-homepage-screen__header-block">
            <PageContainer>
              <div className="sts-ui-homepage-screen__header-text">
                <h1><FormattedMessage id="Homepage.Title"/></h1>
                <p><FormattedHTMLMessage id="Homepage.Description"/></p>
              </div>
            </PageContainer>
          </div>
        </div>

        <PageContainer>
          <PageContent>
            <div className="sts-ui-homepage-screen__main-tickets">
              {HOMEPAGE_PRODUCTS[TicketGroup.PUBLIC_TRANSPORT_PASSES].map((type) => {
                const product = products.find((it) => it.code === type);
                if (product === undefined) {
                  return null;
                }

                return (
                  <div key={product.code} className="sts-ui-homepage-screen__main-ticket">
                    <ProductPrimaryCard
                      code={product.code}
                      price={product.price}
                      fixPrice={FIX_PRICE_PRODUCTS.includes(product.code)}
                      promo={promotionService.getPromotionPackage(product.code)?.name}
                    />
                  </div>
                );
              })}
            </div>

            {isBannerShown && (
              <div className="sts-ui-homepage-screen__banner">
                <HomepageBanner/>
              </div>
            )}

            <div className="sts-ui-homepage-screen__search" id="search">
              <Row>
                <Col xs={12} sm={12} md={8} lg={8} className="sts-ui-homepage-screen__search-col">
                  <div className="sts-ui-homepage-screen__text-block">
                    <div className="sts-ui-homepage-screen__text-block-title">
                      <Typography as="h3" variant="block-title">
                        <FormattedMessage id="Homepage.Search.Title"/>
                      </Typography>
                    </div>
                    <div className="sts-ui-homepage-screen__text-block-description">
                      <Typography as="p" variant="text-secondary">
                        <FormattedHTMLMessage id="Homepage.Search.Description"/>
                      </Typography>
                    </div>

                    <HomepageSearchForm onSubmit={onSearchProduct}/>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} className="sts-ui-homepage-screen__search-col">
                  <div className="sts-ui-homepage-screen__text-block">
                    <div className="sts-ui-homepage-screen__text-block-title">
                      <Typography as="h3" variant="block-title">
                        <FormattedMessage id="Homepage.WhyBookWithUs.Title"/>
                      </Typography>
                    </div>
                    <div className="sts-ui-homepage-screen__text-block-content">
                      <FormattedHTMLMessage id="Homepage.WhyBookWithUs.Content"/>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {this.renderGroup(
              HOMEPAGE_PRODUCTS[TicketGroup.MOUNTAIN_EXCURSION],
              <FormattedMessage id="Homepage.Group.MountainExcursion"/>,
              <RouteLink className="sts-ui-primary-button" route="excursions">
                <FormattedMessage id="Homepage.Tickets.MoreExcursions"/>
              </RouteLink>,
            )}

            <div className="sts-ui-homepage-screen__promo-banner">
              <P2pTicketBanner>
                {isHalfFareCardBooked
                  ? <FormattedMessage id="Homepage.P2P.Banner.HalfFareCardInBasket"/>
                  : <FormattedMessage id="Homepage.P2P.Banner.NoHalfFareCardInBasket"/>}
              </P2pTicketBanner>
            </div>

            {this.renderGroup(
              HOMEPAGE_PRODUCTS[TicketGroup.REGIONAL_PASSES],
              <FormattedMessage id="Homepage.Group.RegionalPasses"/>,
              <RouteLink className="sts-ui-primary-button" route="regionalpasses">
                <FormattedMessage id="Homepage.Tickets.MoreRegionalPasses"/>
              </RouteLink>,
            )}
          </PageContent>
        </PageContainer>
      </div>
    );
  }

  private renderGroup(
    types: ReadonlyArray<ProductCode>,
    groupHeader: React.ReactNode,
    groupButton: React.ReactNode,
  ): React.ReactElement | null {
    const { products, promotionService } = this.props;

    const groupProducts = types
      .map((type) => products.find((it) => it.code === type))
      .filter(isDefined);
    if (groupProducts.length === 0) {
      return null;
    }

    return (
      <div className="sts-ui-homepage-screen__group">
        <div className="sts-ui-homepage-screen__group-title">
          <Typography as="h2" variant="block-title">
            {groupHeader}
          </Typography>
        </div>
        <div className="sts-ui-homepage-screen__group-products">
          <ProductGridView>
            {groupProducts.map((product) => (
              <ProductSecondaryCard
                key={product.code}
                code={product.code}
                price={product.price}
                fixPrice={FIX_PRICE_PRODUCTS.includes(product.code)}
                promo={promotionService.getPromotionPackage(product.code)?.name}
              />
            ))}
          </ProductGridView>
        </div>
        <div className="sts-ui-homepage-screen__group-more upsell__more">
          {groupButton}
        </div>
      </div>
    );
  }
}
