import './P2pTicketScreenTripCommonForm.scss';
import { useForm, useFormWatch } from '@form-ts/react';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { SearchIcon } from 'src/components/icons/SearchIcon';
import { SwapIcon } from 'src/components/icons/SwapIcon';
import { P2pTicketFormDateTimePickerField } from 'src/components/p2p/P2pTicketFormDateTimePickerField';
import { P2pTicketFormDestinationField } from 'src/components/p2p/P2pTicketFormDestinationField';
import { P2pTicketFormDropdown } from 'src/components/p2p/P2pTicketFormDropdown';
import { P2pTicketScreenTripCommonFormTravelerList } from 'src/components/p2p/P2pTicketScreenTripCommonFormTravelerList';
import { useFormValidator } from 'src/forms/hooks/useFormValidator';
import { FormError } from 'src/forms/types/FormError';
import { P2pTicketFormData } from 'src/types/P2pTicketFormData';
import { P2pTicketScreenAction } from 'src/types/P2pTicketScreenAction';
import { P2pTicketScreenDataTripOutward, P2pTicketScreenDataTripReturn } from 'src/types/P2pTicketScreenData';
import { P2pTicketStationRequest } from 'src/types/P2pTicketStationRequest';
import { createInitialFormRule } from 'src/utils/p2p/createInitialFormRule';
import { getTripMinDate } from 'src/utils/p2p/getTripDetails';

type Props = {
  readonly screen: P2pTicketScreenDataTripOutward | P2pTicketScreenDataTripReturn;
  readonly onSubmit: (formData: P2pTicketFormData) => void;
  readonly onStationSearch: (request: P2pTicketStationRequest) => void;
};

export function P2pTicketScreenTripCommonForm({ screen, onSubmit, onStationSearch }: Props): React.ReactElement {
  const intl = useIntl();

  const rule = useMemo(() => createInitialFormRule(), []);
  const form = useForm<P2pTicketFormData, FormError>('p2p.ticket.trip', {
    reinitialize: true,
    initialValues: screen.form,
  });
  useFormValidator(form, rule);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(form.currentState.values);
  }, [form, onSubmit]);

  const minDate = screen.minDate;
  const stations = screen.stations;
  const submitting = screen.action === P2pTicketScreenAction.EDIT_TRIP_FORM;
  const travelers = useFormWatch(form, form.field.at('travelers').value.get);

  const oneWay = useFormWatch(form, form.field.at('trip').at('oneWay').value.get);
  const departDate = useFormWatch(form, form.field.at('trip').at('departDate').value.get);
  const defaultDate = useMemo(() => getTripMinDate(minDate), [minDate]);

  const handleSearchOutwardStation = useCallback((term: string) => {
    onStationSearch({ query: term, direction: 'outward' });
  }, [onStationSearch]);
  const handleSearchReturnStation = useCallback((term: string) => {
    onStationSearch({ query: term, direction: 'return' });
  }, [onStationSearch]);

  const outwardStation = useFormWatch(form, form.field.at('trip').at('from').value.get);
  const returnStation = useFormWatch(form, form.field.at('trip').at('to').value.get);

  return (
    <form className="sts-ui-p2p-ticket-screen-trip-common-form" onSubmit={handleSubmit}>
      <div className="sts-ui-p2p-ticket-screen-trip-common-form__direction">
        <div className="sts-ui-p2p-ticket-screen-trip-common-form__direction-field">
          <P2pTicketFormDestinationField
            layout="vertical"
            border="none"
            label={<FormattedMessage id="P2P.Form.Trip.From.Label"/>}
            field={form.field.at('trip').at('from')}
            exclude={returnStation}
            stations={stations?.direction === 'outward' ? stations : null}
            onInput={handleSearchOutwardStation}
            placeholder={<FormattedMessage id="P2P.Form.Trip.From.Placeholder"/>}
            clearable={true}
          />
        </div>
        <div className="sts-ui-p2p-ticket-screen-trip-common-form__direction-icon">
          {oneWay
            ? <ArrowRightIcon/>
            : <SwapIcon/>}
        </div>
        <div className="sts-ui-p2p-ticket-screen-trip-common-form__direction-field">
          <P2pTicketFormDestinationField
            layout="vertical"
            border="none"
            label={<FormattedMessage id="P2P.Form.Trip.To.Label"/>}
            field={form.field.at('trip').at('to')}
            exclude={outwardStation}
            stations={stations?.direction === 'return' ? stations : null}
            onInput={handleSearchReturnStation}
            placeholder={<FormattedMessage id="P2P.Form.Trip.To.Placeholder"/>}
            clearable={true}
          />
        </div>
      </div>

      {!oneWay && (
        <div className="sts-ui-p2p-ticket-screen-trip-common-form__date">
          <div className="sts-ui-p2p-ticket-screen-trip-common-form__date-field">
            <P2pTicketFormDateTimePickerField
              layout="vertical"
              border="none"
              label={<FormattedMessage id="P2P.Form.Trip.DepartDate.Label"/>}
              field={form.field.at('trip').at('departDate')}
              placeholder={intl.formatMessage({ id: 'P2P.Form.Trip.DepartDate.Placeholder' })}
              min={defaultDate}
              navigation="narrow"
              timeComment={<FormattedMessage id="P2P.Form.Trip.DepartDate.TimeComment"/>}
              defaultDate={defaultDate}
            />
          </div>
          <div className="sts-ui-p2p-ticket-screen-trip-common-form__date-icon">
            <div className="sts-ui-p2p-ticket-screen-trip-common-form__divider"/>
          </div>
          <div className="sts-ui-p2p-ticket-screen-trip-common-form__date-field">
            <P2pTicketFormDateTimePickerField
              layout="vertical"
              border="none"
              label={<FormattedMessage id="P2P.Form.Trip.ReturnDate.Label"/>}
              field={form.field.at('trip').at('returnDate')}
              placeholder={intl.formatMessage({ id: 'P2P.Form.Trip.ReturnDate.Placeholder' })}
              min={departDate ?? defaultDate}
              navigation="narrow"
              timeComment={<FormattedMessage id="P2P.Form.Trip.ReturnDate.TimeComment"/>}
              defaultDate={departDate ?? defaultDate}
            />
          </div>
        </div>
      )}
      {oneWay && (
        <div className="sts-ui-p2p-ticket-screen-trip-common-form__date">
          <div className="sts-ui-p2p-ticket-screen-trip-common-form__date-field">
            <P2pTicketFormDateTimePickerField
              layout="vertical"
              border="none"
              label={<FormattedMessage id="P2P.Form.Trip.DepartDate.Label"/>}
              field={form.field.at('trip').at('departDate')}
              placeholder={intl.formatMessage({ id: 'P2P.Form.Trip.DepartDate.Placeholder' })}
              min={defaultDate}
              navigation="narrow"
              timeComment={<FormattedMessage id="P2P.Form.Trip.DepartDate.TimeComment"/>}
              defaultDate={defaultDate}
            />
          </div>
        </div>
      )}

      <div className="sts-ui-p2p-ticket-screen-trip-common-form__travelers">
        <P2pTicketFormDropdown
          layout="vertical"
          border="none"
          label={<FormattedMessage id="P2P.Form.Traveler.DropdownTitle"/>}
          value={(
            <FormattedMessage
              id="P2P.Common.Passengers"
              values={{
                adult: travelers.filter((it) => it.ageGroup === 'adult').length,
                child: travelers.filter((it) => it.ageGroup === 'child').length,
              }}
            />
          )}
        >
          <P2pTicketScreenTripCommonFormTravelerList
            field={form.field.at('travelers')}
          />
        </P2pTicketFormDropdown>
      </div>

      <Button
        as="button"
        variant="solid"
        intent="primary"
        size="lg"
        type="submit"
        disabled={submitting}
        className="sts-ui-p2p-ticket-screen-trip-common-form__edit"
      >
        {submitting ? <LoadingIcon/> : <SearchIcon/>}
      </Button>
    </form>
  );
}
