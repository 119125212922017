import './NetworkErrorModal.scss';
import { constVoid } from 'fp-ts/function';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactModal, { Classes, Styles } from 'react-modal';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { NetworkErrorIcon } from 'src/components/icons/NetworkErrorIcon';

type Props = {
  readonly shown: boolean;
  readonly onRetry: () => void;
  readonly retrying: boolean;
};

export function NetworkErrorModal({ shown, onRetry, retrying }: Props): React.ReactElement {
  return (
    <ReactModal
      isOpen={shown}
      style={NO_STYLE}
      className={CONTENT_CLASES}
      overlayClassName={OVERLAY_CLASES}
      bodyOpenClassName="sts-ui-root--no-scroll"
      htmlOpenClassName="sts-ui-root--no-scroll"
      preventScroll={true}
      shouldCloseOnOverlayClick={false}
      onRequestClose={constVoid}
    >
      <div className="sts-ui-network-error-modal__body">
        <div className="sts-ui-network-error-modal__icon">
          <NetworkErrorIcon/>
        </div>
        <div className="sts-ui-network-error-modal__title">
          <FormattedMessage id="NetworkError.Modal.Title"/>
        </div>
        <div className="sts-ui-network-error-modal__description">
          <FormattedMessage id="NetworkError.Modal.Description"/>
        </div>
        <div className="sts-ui-network-error-modal__retry">
          <button
            type="button"
            onClick={onRetry}
            disabled={retrying}
            className="sts-ui-ghost-button"
          >
            <FormattedMessage id="NetworkError.Modal.Retry"/>
            {retrying && <LoadingIcon/>}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

const NO_STYLE: Styles = {
  overlay: {},
  content: {},
};
const OVERLAY_CLASES: Classes = {
  base: 'sts-ui-network-error-modal__overlay',
  afterOpen: 'sts-ui-network-error-modal__overlay--open',
  beforeClose: 'sts-ui-network-error-modal__overlay--close',
};
const CONTENT_CLASES: Classes = {
  base: 'sts-ui-network-error-modal__content',
  afterOpen: 'sts-ui-network-error-modal__content--open',
  beforeClose: 'sts-ui-network-error-modal__content--close',
};
