import type { Day, Locale, Month } from 'date-fns';
import { createSelector } from 'reselect';
import { INTL_LOCALES } from 'src/constants/locale';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { assertNever } from 'src/utils/assert';

export const getDateLocale = createSelector([
  getCurrentLanguage,
], (language): Locale => {
  const intlDay = new Intl.DateTimeFormat(INTL_LOCALES[language], { weekday: 'short' });
  const intlMonth = new Intl.DateTimeFormat(INTL_LOCALES[language], { month: 'long' });
  const intlMonthShort = new Intl.DateTimeFormat(INTL_LOCALES[language], { month: 'short' });

  // NOTE: localize only needed parts
  // - "day of week" is used in DatePicker
  // - "month" is used in FormattedDate (MMMM)
  return {
    code: INTL_LOCALES[language],
    match: {
      day: notImplemented('match.day'),
      dayPeriod: notImplemented('match.dayPeriod'),
      era: notImplemented('match.era'),
      month: notImplemented('match.month'),
      ordinalNumber: notImplemented('match.ordinalNumber'),
      quarter: notImplemented('match.quarter'),
    },
    localize: {
      day: (d) => intlDay.format(getDateForWeekDay(d)),
      month: (m, options): string => {
        return options?.width === 'abbreviated'
          ? intlMonthShort.format(getDateForMonth(m))
          : intlMonth.format(getDateForMonth(m));
      },

      dayPeriod: notImplemented('localize.dayPeriod'),
      era: notImplemented('localize.era'),
      ordinalNumber: notImplemented('localize.ordinalNumber'),
      quarter: notImplemented('localize.quarter'),
    },
    formatLong: {
      date: notImplemented('formatLong.date'),
      dateTime: notImplemented('formatLong.dateTime'),
      time: notImplemented('formatLong.time'),
    },
    formatRelative: notImplemented('formatRelative'),
    formatDistance: notImplemented('formatDistance'),
  };
});

const notImplemented = (method: string) => (): never => assertNever(`Locale.${method} not implemnted`);

// 2001-01-01 was Monday, so it's fine for the 0...6 range
const getDateForWeekDay = (day: Day): Date => new Date(2001, 0, day, 12, 0, 0);

// random year since any year is ok for the 0...11 range
const getDateForMonth = (month: Month): Date => new Date(2004, month, 1, 12, 0, 0);
