import { pipe } from 'fp-ts/function';
import { DateInput } from 'src/types/DateInput';
import { DateOnly } from 'src/types/DateOnly';

export function normalizeBirthDate(
  value: DateInput,
  minDate: DateOnly,
  maxDate: DateOnly,
): DateInput {
  return pipe(
    value,
    (v) => normalizeYear(v, minDate, maxDate),
    (v) => normalizeMonth(v, minDate, maxDate),
    (v) => normalizeDate(v, minDate, maxDate),
  );
}

function normalizeYear(
  value: DateInput,
  minDate: DateOnly,
  maxDate: DateOnly,
): DateInput {
  if (value.yy === null) {
    return value;
  }

  const minYY = minDate.yy;
  if (value.yy < minYY) {
    return { yy: null, mm: null, dd: null };
  }

  const maxYY = maxDate.yy;
  if (value.yy > maxYY) {
    return { yy: null, mm: null, dd: null };
  }

  return value;
}

function normalizeMonth(
  value: DateInput,
  minDate: DateOnly,
  maxDate: DateOnly,
): DateInput {
  if (
    value.yy === null ||
    value.mm === null
  ) {
    return value;
  }

  const minYY = minDate.yy;
  const minMM = minDate.mm;
  if (value.yy === minYY && value.mm < minMM) {
    return { yy: value.yy, mm: null, dd: null };
  }

  const maxYY = maxDate.yy;
  const maxMM = maxDate.mm;
  if (value.yy === maxYY && value.mm > maxMM) {
    return { yy: value.yy, mm: null, dd: null };
  }

  return value;
}

function normalizeDate(
  value: DateInput,
  minDate: DateOnly,
  maxDate: DateOnly,
): DateInput {
  if (
    value.yy === null ||
    value.mm === null ||
    value.dd === null
  ) {
    return value;
  }

  const minYY = minDate.yy;
  const minMM = minDate.mm;
  const minDD = minDate.dd;
  if (value.yy === minYY && value.mm === minMM && value.dd < minDD) {
    return { yy: value.yy, mm: value.mm, dd: null };
  }

  const maxYY = maxDate.yy;
  const maxMM = maxDate.mm;
  const maxDD = maxDate.dd;
  if (value.yy === maxYY && value.mm === maxMM && value.dd > maxDD) {
    return { yy: value.yy, mm: value.mm, dd: null };
  }

  return value;
}
