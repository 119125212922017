import { SagaIterator } from 'redux-saga';
import { BASKET_REMOVE } from 'src/actions/BasketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { fetchBasketData } from 'src/sagas/basket/utils/fetchBasketData';
import { saveBasketData } from 'src/sagas/basket/utils/saveBasketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getBasketContent } from 'src/selectors/getBasketContent';
import { BasketContent } from 'src/types/BasketContent';
import { BasketData } from 'src/types/BasketData';
import { removeBasketP2pTicket } from 'src/utils/basket/removeBasketP2pTicket';
import { removeBasketProduct } from 'src/utils/basket/removeBasketProduct';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* basketRemoveSaga(
  action: GetRequestActionType<typeof BASKET_REMOVE>,
): SagaIterator<void> {
  const product = action.meta;

  try {
    yield* call(logDebug, `Removing basket product "${product.uuid}"…`);
    yield* put(BASKET_REMOVE.pending(product));

    yield* call(delay, 500);
    const currency = yield* select(getCurrentCurrency);
    const language = yield* select(getCurrentLanguage);

    let basketData: BasketData;
    if (product.type === 'product') {
      const currentBasket = yield* select(getBasketContent);
      const updatedBasket: BasketContent = {
        ...currentBasket,
        products: yield* call(removeBasketProduct, product.code, currentBasket.products),
      };

      basketData = yield* call(
        fetchBasketData,
        currency,
        language,
        updatedBasket,
      );
    } else {
      const currentBasket = yield* select(getBasketContent);
      const updatedBasket: BasketContent = {
        ...currentBasket,
        p2pTickets: yield* call(removeBasketP2pTicket, product.ticket.ticketIdentifier, currentBasket.p2pTickets),
      };

      basketData = yield* call(
        fetchBasketData,
        currency,
        language,
        updatedBasket,
      );
    }

    yield* call(logDebug, `Removing basket product "${product.uuid}"… done`, basketData);
    yield* put(BASKET_REMOVE.success(basketData, product));
    yield* call(saveBasketData);
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not remove basket product',
      { product },
      NativeError.wrap(error),
    ));

    yield* call(logError, `Removing basket product "${product.uuid}"… error`, error);
    yield* put(BASKET_REMOVE.failure(NativeError.wrap(error), product));
  }
}
