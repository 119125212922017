import './ProductScreen.scss';
import { Either } from 'fp-ts/Either';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Alert } from 'src/components/common/Alert';
import { ProductName } from 'src/components/common/ProductName';
import { Typography } from 'src/components/common/Typography';
import { ProductFaqList } from 'src/components/product/ProductFaqList';
import { ProductFeatureList } from 'src/components/product/ProductFeatureList';
import { ProductFormView } from 'src/components/product/ProductFormView';
import { ProductPriceTable } from 'src/components/product/ProductPriceTable';
import { ProductTeaserList } from 'src/components/product/ProductTeaserList';
import { Integer } from 'src/types/Integer';
import { ProductAttibutes } from 'src/types/ProductAttibutes';
import { ProductCode } from 'src/types/ProductCode';
import { ProductFormData } from 'src/types/ProductFormData';
import { ProductPriceErrorCode } from 'src/types/ProductPriceErrorCode';
import { ProductPriceList } from 'src/types/ProductPriceList';
import { TicketFields } from 'src/types/TicketFields';
import { TicketPrice } from 'src/types/TicketPrice';
import { TicketPricePeriod } from 'src/types/TicketPricePeriod';
import { TicketValidity } from 'src/types/TicketValidity';

type Props = {
  readonly type: ProductCode;
  readonly attributes: ProductAttibutes;
  readonly fields: TicketFields;
  readonly dates: ReadonlyArray<TicketPricePeriod>;
  readonly validity: TicketValidity;
  readonly priceTable: ReadonlyArray<TicketPrice>;
  readonly ticketPrice: Either<ProductPriceErrorCode, ProductPriceList>;

  readonly showFamilyCardOption: boolean;
  readonly bookedFamilyCardCount: Integer;

  readonly invalid: boolean;
  readonly loading: boolean;
  readonly formData: ProductFormData;

  readonly onChange: (formData: ProductFormData) => void;
  readonly onSubmit: (formData: ProductFormData) => void;

  readonly inBasket: boolean;
  readonly isUnavailable: boolean;
  readonly isUndeliverable: boolean;
};

export function ProductScreen({
  type,
  attributes,
  fields,
  dates,
  validity,
  priceTable,
  ticketPrice,
  showFamilyCardOption,
  bookedFamilyCardCount,
  invalid,
  loading,
  formData,
  onChange,
  onSubmit,
  inBasket,
  isUnavailable,
  isUndeliverable,
}: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-screen">
      <div className="sts-ui-product-screen__page-title">
        <Typography variant="page-title" as="h1">
          <ProductName type={type}/>
        </Typography>
      </div>
      <div className="sts-ui-product-screen__page-subtitle">
        <Typography variant="page-subtitle" as="p">
          <FormattedHTMLMessage id={attributes.formDescription}/>
        </Typography>
      </div>

      {!isUnavailable && (
        <div className="sts-ui-product-screen__block">
          <ProductFormView
            type={type}
            fields={fields}
            dates={dates}
            validity={validity}
            ticketPrice={ticketPrice}
            showFamilyCardOption={showFamilyCardOption}
            bookedFamilyCardCount={bookedFamilyCardCount}
            invalid={invalid}
            loading={loading}
            formData={formData}
            inBasket={inBasket}
            isUndeliverable={isUndeliverable}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        </div>
      )}

      {isUnavailable && (
        <div className="sts-ui-product-screen__block">
          <Alert>
            <FormattedMessage id="ProductPage.TicketUnavailable"/>
          </Alert>
        </div>
      )}
      {isUndeliverable && (
        <div className="sts-ui-product-screen__block">
          <Alert>
            <FormattedHTMLMessage id="Form.Product.UnbookableAlert"/>
          </Alert>
        </div>
      )}

      {attributes.formFeatures.length > 0 && (
        <div className="sts-ui-product-screen__block">
          <ProductFeatureList features={attributes.formFeatures}/>
        </div>
      )}

      {attributes.formTeasers && (
        <div className="sts-ui-product-screen__block">
          <ProductTeaserList
            teasers={attributes.formTeasers}
          />
        </div>
      )}

      {!isUnavailable && (
        <div className="sts-ui-product-screen__block">
          <div className="sts-ui-product-screen__block-title">
            <Typography variant="block-title" as="h2">
              <FormattedMessage id={attributes.priceTableTitle}/>
            </Typography>
          </div>

          <ProductPriceTable
            type={type}
            priceTable={priceTable}
          />
        </div>
      )}

      <div className="sts-ui-product-screen__block">
        <div className="sts-ui-product-screen__block-title">
          <Typography variant="block-title" as="h2">
            <FormattedMessage id={attributes.faqSectionTitle}/>
          </Typography>
        </div>

        <ProductFaqList sections={attributes.faqSections}/>
      </div>
    </div>
  );
}
