import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_RESET } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { RouteMatch } from 'src/routing/types/RouteMatch';
import { Transition } from 'src/routing/types/Transition';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { call, put } from 'typed-redux-saga';

export function* resetP2pTicket(transition: Transition): SagaIterator<void> {
  if (isOnSameView(transition.to.match)) {
    // preserve other product that may be loaded
    return;
  }

  try {
    yield* call(logDebug, 'Resetting P2P Ticket…');
    yield* put(P2P_TICKET_RESET.trigger());
    yield* call(logDebug, 'Resetting P2P Ticket… done');
  } catch (error) {
    yield* call(logError, 'Resetting P2P Ticket… error', error);
    throw new RuntimeError('Could not reset P2P Ticket data', {}, NativeError.wrap(error));
  }
}

function isOnSameView(match: RouteMatch): boolean {
  if (match.state.name.startsWith('sts:ui:p2p-ticket:')) {
    return true;
  }

  return match.parent
    ? isOnSameView(match.parent)
    : false;
}
