import React from 'react';

export function NetworkErrorIcon(): React.ReactElement {
  return (
    <svg width="1em" height="1em" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M14.245 11.042a2.531 2.531 0 0 0-4.061-3.022C6.291 13.253 4 19.582 4 26.406c0 6.825 2.29 13.154 6.184 18.386a2.531 2.531 0 1 0 4.062-3.022c-3.289-4.42-5.184-9.7-5.184-15.364 0-5.662 1.895-10.944 5.184-15.364ZM70.379 8.02a2.531 2.531 0 1 0-4.062 3.022c3.289 4.42 5.183 9.702 5.183 15.364 0 5.663-1.894 10.945-5.183 15.364a2.531 2.531 0 0 0 4.062 3.022c3.893-5.232 6.183-11.56 6.183-18.386 0-6.824-2.29-13.153-6.183-18.386Zm-44.82 9.79a2.531 2.531 0 1 0-4.036-3.057C19 18.086 17.5 22.083 17.5 26.406c0 4.324 1.5 8.322 4.028 11.655a2.531 2.531 0 0 0 4.033-3.06c-1.926-2.539-2.998-5.48-2.998-8.595s1.072-6.056 2.996-8.596Zm33.476-3.058A2.531 2.531 0 1 0 55 17.812c1.928 2.538 3 5.479 3 8.593 0 3.115-1.072 6.056-2.999 8.596a2.531 2.531 0 0 0 4.034 3.06c2.528-3.334 4.028-7.332 4.028-11.656 0-4.323-1.5-8.321-4.029-11.654Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M40.281 17.125a9.281 9.281 0 0 0-6.143 16.238l-13.11 36.053a2.531 2.531 0 0 0 4.757 1.73l4.303-11.833h17.725l5-8.38-6.388-17.57a9.281 9.281 0 0 0-6.144-16.238Zm-2.983 6.298a4.219 4.219 0 1 1 5.966 5.967 4.219 4.219 0 0 1-5.966-5.967ZM31.93 54.25l6.798-18.693a9.277 9.277 0 0 0 3.109 0l6.797 18.693H31.93Z"
      />
      <path
        fill="#D32029"
        d="m61.743 51.253 14.91 24.987a2.445 2.445 0 0 1 0 2.507 2.55 2.55 0 0 1-.948.917c-.394.22-.84.336-1.295.336H44.59c-.455 0-.901-.116-1.295-.336a2.55 2.55 0 0 1-.948-.917 2.445 2.445 0 0 1 0-2.507l14.91-24.987c.996-1.67 3.488-1.67 4.486 0ZM59.5 71.057c-.458 0-.897.176-1.221.49a1.644 1.644 0 0 0-.506 1.181c0 .443.182.868.506 1.182.324.313.763.489 1.221.489.458 0 .897-.176 1.221-.49.324-.313.506-.738.506-1.18 0-.444-.182-.87-.506-1.182a1.757 1.757 0 0 0-1.221-.49Zm0-11.696c-.423 0-.831.15-1.147.422a1.654 1.654 0 0 0-.568 1.053l-.012.196v6.683c0 .426.17.836.471 1.145a1.765 1.765 0 0 0 2.364.134c.338-.273.555-.66.607-1.083l.012-.196v-6.684c0-.443-.182-.868-.506-1.181a1.757 1.757 0 0 0-1.221-.49Z"
      />
    </svg>
  );
}
