import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ListOption } from 'src/types/ListOption';
import { P2pTicketAgeGroup } from 'src/types/P2pTicketAgeGroup';

export function useAgeGroupOptions(): ReadonlyArray<ListOption<P2pTicketAgeGroup>> {
  const intl = useIntl();

  return useMemo(() => [
    {
      value: 'adult',
      title: intl.formatMessage({ id: 'P2P.Common.AgeGroup' }, { ageGroup: 'adult' }),
    },
    {
      value: 'child',
      title: intl.formatMessage({ id: 'P2P.Common.AgeGroup' }, { ageGroup: 'child' }),
    },
  ], [intl]);
}
