import { SagaIterator } from 'redux-saga';
import { checkNetworkConnection } from 'src/network/utils/checkNetworkConnection';
import { forcePageReload } from 'src/network/utils/forcePageReload';
import { call, cancel, delay } from 'typed-redux-saga';

export function* reloadWhenNetworkAvailable(): SagaIterator<void> {
  while (true) {
    yield* delay(10_000);

    const connected = yield* call(checkNetworkConnection);
    if (connected) {
      yield* call(forcePageReload);
      yield* cancel();
    }
  }
}
