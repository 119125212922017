import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_INITIAL_SUBMIT } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { createP2pTicketScreenUid } from 'src/sagas/p2p/utils/createP2pTicketScreenUid';
import { createOutwardTripInfo } from 'src/sagas/p2p/utils/createTripInfo';
import { fetchP2pTripList } from 'src/sagas/p2p/utils/fetchP2pTripList';
import { getP2pMinimalTripDate } from 'src/sagas/p2p/utils/getP2pMinimalTripDate';
import { saveP2pTicketData } from 'src/sagas/p2p/utils/saveP2pTicketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getP2pTicketConversationId } from 'src/selectors/getP2pTicketConversationId';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { P2pTicketScreenDataTripOutward } from 'src/types/P2pTicketScreenData';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* p2pTicketInitialSubmitSaga(
  action: GetRequestActionType<typeof P2P_TICKET_INITIAL_SUBMIT>,
): SagaIterator<void> {
  const formData = action.data;

  try {
    yield* call(logDebug, 'Submitting P2P Ticket initial data…', formData);
    yield* put(P2P_TICKET_INITIAL_SUBMIT.pending());
    yield* delay(500);

    const language = yield* select(getCurrentLanguage);
    const conversationId = yield* select(getP2pTicketConversationId);

    const tripInfo = yield* call(createOutwardTripInfo, formData);
    const tripList = yield* call(
      fetchP2pTripList,
      language,
      formData.travelers,
      conversationId,
      tripInfo,
    );

    const currentScreen = yield* select(getP2pTicketScreen);
    const updatedScreen: P2pTicketScreenDataTripOutward = {
      uid: yield* call(createP2pTicketScreenUid),
      type: 'trip-outward',
      form: formData,

      tripInfo: tripInfo,
      tripList: tripList,

      outward: null,
      return: null,

      minDate: currentScreen.type === 'initial'
        ? currentScreen.minDate
        : yield* call(getP2pMinimalTripDate),
      stations: null,
      scrolling: null,

      action: null,
    };
    yield* call(saveP2pTicketData, updatedScreen);

    yield* call(logDebug, 'Submitting P2P Ticket initial data… done', updatedScreen);
    yield* put(P2P_TICKET_INITIAL_SUBMIT.success(updatedScreen));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not submit P2P Ticket initial data',
      { formData },
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Submitting P2P Ticket initial data… error', error);
    yield* put(P2P_TICKET_INITIAL_SUBMIT.failure(NativeError.wrap(error)));
  }
}
