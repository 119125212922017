import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_FETCH } from 'src/actions/P2pTicketActions';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { p2pTicketPageSaga } from 'src/sagas/p2p/p2pTicketPageSaga';
import { cancel, fork, take } from 'typed-redux-saga';

export function* p2pTicketSaga(): SagaIterator<void> {
  while (true) {
    yield* take(P2P_TICKET_FETCH.is);

    const task = yield* fork(p2pTicketPageSaga);

    // freeze the page state when user triggers navigation
    yield* take(ROUTE_CHANGE.isPending);
    yield* cancel(task);
  }
}
