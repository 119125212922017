import './P2pTicketScreenInitialFormTrip.scss';
import { FieldPath } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { MinusCircleIcon } from 'src/components/icons/MinusCircleIcon';
import { PlusCircleIcon } from 'src/components/icons/PlusCircleIcon';
import { SwapIcon } from 'src/components/icons/SwapIcon';
import { P2pTicketFormDateTimePickerField } from 'src/components/p2p/P2pTicketFormDateTimePickerField';
import { P2pTicketFormDestinationField } from 'src/components/p2p/P2pTicketFormDestinationField';
import { FormError } from 'src/forms/types/FormError';
import { LocalDateTime } from 'src/types/LocalDateTime';
import { P2pTicketFormData, P2pTicketFormDataTrip } from 'src/types/P2pTicketFormData';
import { P2pTicketStationList } from 'src/types/P2pTicketStationList';
import { P2pTicketStationRequest } from 'src/types/P2pTicketStationRequest';
import { getTripMinDate } from 'src/utils/p2p/getTripDetails';

type Props = {
  readonly field: FieldPath<P2pTicketFormData, FormError, P2pTicketFormDataTrip>;
  readonly minDate: LocalDateTime;
  readonly stations: P2pTicketStationList | null;
  readonly onStationSearch: (request: P2pTicketStationRequest) => void;
};

export function P2pTicketScreenInitialFormTrip({
  field,
  minDate,
  stations,
  onStationSearch,
}: Props): React.ReactElement {
  const intl = useIntl();

  const oneWay = useFormWatch(field.form, field.at('oneWay').value.get);
  const departDate = useFormWatch(field.form, field.at('departDate').value.get);
  const defaultDate = useMemo(() => getTripMinDate(minDate), [minDate]);

  const handleAddReturnDate = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.form.field.at('trip').at('oneWay').value.set(false),
      field.form.field.at('trip').at('returnDate').value.set(null),
    ));
  }, [field]);

  const handleRemoveReturnDate = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.form.field.at('trip').at('oneWay').value.set(true),
      field.form.field.at('trip').at('returnDate').value.set(null),
    ));
  }, [field]);

  const handleSearchOutwardStation = useCallback((term: string) => {
    onStationSearch({ query: term, direction: 'outward' });
  }, [onStationSearch]);
  const handleSearchReturnStation = useCallback((term: string) => {
    onStationSearch({ query: term, direction: 'return' });
  }, [onStationSearch]);

  const outwardStation = useFormWatch(field.form, field.at('from').value.get);
  const returnStation = useFormWatch(field.form, field.at('to').value.get);

  return (
    <div className="sts-ui-p2p-ticket-screen-initial-form-trip">
      <div className="sts-ui-p2p-ticket-screen-initial-form-trip__row">
        <div className="sts-ui-p2p-ticket-screen-initial-form-trip__field">
          <P2pTicketFormDestinationField
            layout="horizontal"
            border="thin"
            label={<FormattedMessage id="P2P.Form.Trip.From.Label"/>}
            field={field.at('from')}
            stations={stations?.direction === 'outward' ? stations : null}
            exclude={returnStation}
            onInput={handleSearchOutwardStation}
            placeholder={<FormattedMessage id="P2P.Form.Trip.From.Placeholder"/>}
            clearable={true}
          />
        </div>

        <div className="sts-ui-p2p-ticket-screen-initial-form-trip__direction">
          {oneWay
            ? <ArrowRightIcon/>
            : <SwapIcon/>}
        </div>

        <div className="sts-ui-p2p-ticket-screen-initial-form-trip__field">
          <P2pTicketFormDestinationField
            layout="horizontal"
            border="thin"
            label={<FormattedMessage id="P2P.Form.Trip.To.Label"/>}
            field={field.at('to')}
            stations={stations?.direction === 'return' ? stations : null}
            exclude={outwardStation}
            onInput={handleSearchReturnStation}
            placeholder={<FormattedMessage id="P2P.Form.Trip.To.Placeholder"/>}
            clearable={true}
          />
        </div>
      </div>

      <div className="sts-ui-p2p-ticket-screen-initial-form-trip__row">
        <div className="sts-ui-p2p-ticket-screen-initial-form-trip__field">
          <P2pTicketFormDateTimePickerField
            layout="horizontal"
            border="thin"
            label={<FormattedMessage id="P2P.Form.Trip.DepartDate.Label"/>}
            field={field.at('departDate')}
            placeholder={intl.formatMessage({ id: 'P2P.Form.Trip.DepartDate.Placeholder' })}
            min={defaultDate}
            navigation="narrow"
            timeComment={<FormattedMessage id="P2P.Form.Trip.DepartDate.TimeComment"/>}
            defaultDate={defaultDate}
          />
        </div>

        <div className="sts-ui-p2p-ticket-screen-initial-form-trip__direction"/>

        {!oneWay && (
          <div className="sts-ui-p2p-ticket-screen-initial-form-trip__field">
            <P2pTicketFormDateTimePickerField
              layout="horizontal"
              border="thin"
              label={<FormattedMessage id="P2P.Form.Trip.ReturnDate.Label"/>}
              field={field.at('returnDate')}
              placeholder={intl.formatMessage({ id: 'P2P.Form.Trip.ReturnDate.Placeholder' })}
              min={departDate ?? defaultDate}
              navigation="narrow"
              timeComment={<FormattedMessage id="P2P.Form.Trip.ReturnDate.TimeComment"/>}
              defaultDate={departDate ?? defaultDate}
            />
          </div>
        )}
        <div className="sts-ui-p2p-ticket-screen-initial-form-trip__action">
          {oneWay && (
            <Button
              as="button"
              variant="link"
              intent="primary"
              size="md"
              type="button"
              onClick={handleAddReturnDate}
            >
              <PlusCircleIcon/>
              <FormattedMessage id="P2P.Form.Trip.ReturnDate.Add"/>
            </Button>
          )}
          {!oneWay && (
            <Button
              as="button"
              variant="link"
              intent="primary"
              size="md"
              type="button"
              onClick={handleRemoveReturnDate}
            >
              <MinusCircleIcon/>
              <FormattedMessage id="P2P.Form.Trip.ReturnDate.Remove"/>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
