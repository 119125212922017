import './NetworkErrorPage.scss';
import React from 'react';
import { NetworkErrorIcon } from 'src/components/icons/NetworkErrorIcon';
import { forcePageReload } from 'src/network/utils/forcePageReload';

export function NetworkErrorPage(): React.ReactElement {
  return (
    <div className="sts-ui-network-error-page">
      <div className="sts-ui-network-error-page__icon">
        <NetworkErrorIcon/>
      </div>
      <h1 className="sts-ui-network-error-page__title">
        Oops! No internet!
      </h1>
      <h2 className="sts-ui-network-error-page__description">
        Looks like you are facing a temporary network interruption.<br/>
        Or check your network connection.
      </h2>
      <div className="sts-ui-network-error-page__retry">
        <button type="button" onClick={forcePageReload} className="sts-ui-primary-button">
          Reload page
        </button>
      </div>
    </div>
  );
}
