import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { PRODUCT_CHANGE, PRODUCT_SUBMIT } from 'src/actions/ProductActions';
import { ProductScreen } from 'src/components/product/ProductScreen';
import { useAction } from 'src/hooks/useAction';
import { getLocalizationProductAttributes } from 'src/selectors/getLocalizationProductAttributes';
import { getProductData } from 'src/selectors/getProductData';
import { getProductFamilyCardCount } from 'src/selectors/getProductFamilyCardCount';
import { getProductFamilyCardVisible } from 'src/selectors/getProductFamilyCardVisible';
import { getProductFields } from 'src/selectors/getProductFields';
import { getProductFormData } from 'src/selectors/getProductFormData';
import { getProductInBasket } from 'src/selectors/getProductInBasket';
import { getProductInvalid } from 'src/selectors/getProductInvalid';
import { getProductLoading } from 'src/selectors/getProductLoading';
import { getProductPrice } from 'src/selectors/getProductPrice';
import { getProductSchemaOrg } from 'src/selectors/getProductSchemaOrg';
import { getProductType } from 'src/selectors/getProductType';
import { getProductUnavailable } from 'src/selectors/getProductUnavailable';
import { getProductUndeliverable } from 'src/selectors/getProductUndeliverable';
import { getProductValidity } from 'src/selectors/getProductValidity';
import { ProductFormData } from 'src/types/ProductFormData';

export function ProductPage(): React.ReactElement {
  const schemaOrg = useSelector(getProductSchemaOrg);

  const type = useSelector(getProductType);
  const attributes = useSelector(getLocalizationProductAttributes)[type];
  const fields = useSelector(getProductFields);
  const validity = useSelector(getProductValidity);
  const { pricePeriods, priceTable } = useSelector(getProductData);

  const inBasket = useSelector(getProductInBasket);
  const isUnavailable = useSelector(getProductUnavailable);
  const isUndeliverable = useSelector(getProductUndeliverable);

  const invalid = useSelector(getProductInvalid);
  const loading = useSelector(getProductLoading);
  const formData = useSelector(getProductFormData);
  const ticketPrice = useSelector(getProductPrice);

  const showFamilyCardOption = useSelector(getProductFamilyCardVisible);
  const bookedFamilyCardCount = useSelector(getProductFamilyCardCount);

  const changeProduct = useAction(PRODUCT_CHANGE.request);
  const onChange = useCallback((values: ProductFormData) => {
    changeProduct(values, type);
  }, [changeProduct, type]);

  const submitProduct = useAction(PRODUCT_SUBMIT.request);
  const onSubmit = useCallback((values: ProductFormData) => {
    submitProduct(values, type);
  }, [submitProduct, type]);

  return (
    <>
      {schemaOrg && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(schemaOrg)}
          </script>
        </Helmet>
      )}

      <ProductScreen
        key={type}
        type={type}
        attributes={attributes}
        fields={fields}
        dates={pricePeriods}
        validity={validity}
        priceTable={priceTable}
        ticketPrice={ticketPrice}
        showFamilyCardOption={showFamilyCardOption}
        bookedFamilyCardCount={bookedFamilyCardCount}
        invalid={invalid}
        loading={loading}
        formData={formData}
        inBasket={inBasket}
        isUnavailable={isUnavailable}
        isUndeliverable={isUndeliverable}
        onChange={onChange}
        onSubmit={onSubmit}
      />
    </>
  );
}
