import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { BASKET_PRODUCT } from 'src/codecs/BasketProduct';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { CURRENCY } from 'src/codecs/Currency';
import { P2P_TICKET_PRODUCT } from 'src/codecs/P2pTicketProduct';
import { UUID } from 'src/codecs/Uuid';
import { BasketStore } from 'src/types/BasketStore';

export const BASKET_STORE: C.Codec<unknown, JsonRecord, BasketStore> = C.struct({
  currency: CURRENCY,
  conversationId: C.nullable(UUID),
  products: ProductMap(BASKET_PRODUCT),
  p2pTickets: C.readonly(C.array(P2P_TICKET_PRODUCT)),
});
