import { LogicError } from 'src/errors/LogicError';
import { P2pTicketFormData } from 'src/types/P2pTicketFormData';
import { P2pTicketTripInfo } from 'src/types/P2pTicketTripInfo';
import { assertNotEmpty } from 'src/utils/assert';

export function createOutwardTripInfo(formData: P2pTicketFormData): P2pTicketTripInfo {
  return {
    direction: 'outward',
    from: assertNotEmpty(
      formData.trip.from,
      'Form.trip.from is not defined',
      { formData },
    ),
    to: assertNotEmpty(
      formData.trip.to,
      'Form.trip.to is not defined',
      { formData },
    ),
    date: assertNotEmpty(
      formData.trip.departDate,
      'Form.trip.departDate is not defined',
      { formData },
    ),
  };
}

export function createReturnTripInfo(formData: P2pTicketFormData): P2pTicketTripInfo {
  if (formData.trip.oneWay) {
    throw new LogicError('Return trip can not be created for one-way tickets', { formData });
  }

  return {
    direction: 'return',
    from: assertNotEmpty(
      formData.trip.to,
      'Form.trip.to is not defined',
      { formData },
    ),
    to: assertNotEmpty(
      formData.trip.from,
      'Form.trip.from is not defined',
      { formData },
    ),
    date: assertNotEmpty(
      formData.trip.returnDate,
      'Form.trip.returnDate is not defined',
      { formData },
    ),
  };
}
