import { pipe } from 'fp-ts/function';
import { isEmpty } from 'fp-ts/ReadonlyRecord';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { BASKET_REQUEST } from 'src/codecs/BasketRequest';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { JSON_STRING } from 'src/codecs/JsonString';
import { TRAVELER_FIELDS } from 'src/codecs/TravelerFields';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { BasketProduct } from 'src/types/BasketProduct';
import { CheckoutData } from 'src/types/CheckoutData';
import { ProductMap as ProductMapType } from 'src/types/ProductMap';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchCheckoutTravelerFields(
  basket: ProductMapType<BasketProduct>,
): SagaIterator<CheckoutData['travelerFields']> {
  if (isEmpty(basket)) {
    return {};
  }

  try {
    const request = yield* call(REQUEST.encode, { basket });
    const response = yield* call(makeGetRequest, `${HOST_API}/traveler-fields`, request);
    return yield* call(decodeOrThrow, ProductMap(TRAVELER_FIELDS), response.content);
  } catch (error) {
    throw new RuntimeError(
      'Could not fetch Checkout traveler fields',
      { basket },
      NativeError.wrap(error),
    );
  }
}

const REQUEST = E.struct({
  basket: pipe(BASKET_REQUEST, E.compose(JSON_STRING)),
});
