import './CheckoutPaymentP2pTicketTraveler.scss';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { P2pTicketName } from 'src/components/common/P2pTicketName';
import { P2pTicketProduct } from 'src/types/P2pTicketProduct';
import { P2pTicketTraveler } from 'src/types/P2pTicketTraveler';

type Props = {
  readonly product: P2pTicketProduct;
  readonly traveler: P2pTicketTraveler;
};

export function CheckoutPaymentP2pTicketTraveler({ product, traveler }: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-payment-p2p-ticket-traveler">
      <div className="sts-ui-checkout-payment-p2p-ticket-traveler__title">
        <strong>
          <P2pTicketName
            from={product.from}
            to={product.to}
          />
          {', '}
          <FormattedMessage
            id="P2P.Common.AgeGroup"
            values={{ ageGroup: traveler.ageGroup }}
          />
        </strong>
      </div>

      <div className="sts-ui-checkout-payment-p2p-ticket-traveler__details">
        <div className="sts-ui-checkout-payment-p2p-ticket-traveler__details-title">
          <FormattedMessage id="Checkout.P2P.Traveler.FirstName"/>
        </div>
        <div className="sts-ui-checkout-payment-p2p-ticket-traveler__details-value">
          {traveler.firstName}
        </div>
      </div>

      <div className="sts-ui-checkout-payment-p2p-ticket-traveler__details">
        <div className="sts-ui-checkout-payment-p2p-ticket-traveler__details-title">
          <FormattedMessage id="Checkout.P2P.Traveler.LastName"/>
        </div>
        <div className="sts-ui-checkout-payment-p2p-ticket-traveler__details-value">
          {traveler.lastName}
        </div>
      </div>

      <div className="sts-ui-checkout-payment-p2p-ticket-traveler__details">
        <div className="sts-ui-checkout-payment-p2p-ticket-traveler__details-title">
          <FormattedMessage id="Checkout.P2P.Traveler.BirthDate"/>
        </div>
        <div className="sts-ui-checkout-payment-p2p-ticket-traveler__details-value">
          <FormattedDate value={traveler.birthDate}/>
        </div>
      </div>
    </div>
  );
}
