import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { P2P_TICKET_FORM_DATA } from 'src/codecs/P2pTicketFormData';
import { P2P_TICKET_SCREEN_TYPE } from 'src/codecs/P2pTicketScreenType';
import { P2P_TICKET_TRIP_SELECTION } from 'src/codecs/P2pTicketTripSelection';
import { P2pTicketStore } from 'src/types/P2pTicketStore';

export const P2P_TICKET_STORE: C.Codec<unknown, JsonRecord, P2pTicketStore> = C.struct({
  screen: P2P_TICKET_SCREEN_TYPE,
  form: P2P_TICKET_FORM_DATA,

  outward: C.nullable(P2P_TICKET_TRIP_SELECTION),
  return: C.nullable(P2P_TICKET_TRIP_SELECTION),
});
