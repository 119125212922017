import { pipe } from 'fp-ts/function';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { BASKET_REQUEST } from 'src/codecs/BasketRequest';
import { CHECKOUT_ORDER } from 'src/codecs/CheckoutOrder';
import { CURRENCY } from 'src/codecs/Currency';
import { JSON_STRING } from 'src/codecs/JsonString';
import { LANGUAGE } from 'src/codecs/Language';
import { ORDER_REQUEST } from 'src/codecs/OrderRequest';
import { P2P_TICKET_REQUEST } from 'src/codecs/P2pTicketRequest';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { makePostRequest } from 'src/sagas/utils/makeRequest';
import { getAffiliateData } from 'src/selectors/getAffiliateData';
import { BasketContent } from 'src/types/BasketContent';
import { CheckoutFormData } from 'src/types/CheckoutFormData';
import { CheckoutOrder } from 'src/types/CheckoutOrder';
import { Currency } from 'src/types/Currency';
import { Language } from 'src/types/Language';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call, select } from 'typed-redux-saga';

export function* submitCheckoutOrder(
  currency: Currency,
  language: Language,
  basket: BasketContent,
  checkout: CheckoutFormData,
): SagaIterator<CheckoutOrder> {
  try {
    yield* call(logDebug, 'Submitting checkout order…');

    const affiliate = yield* select(getAffiliateData);
    const request = yield* call(REQUEST.encode, {
      currency: currency,
      language: language,
      storageAffiliateId: affiliate.affiliateId,
      basket: basket.products,
      point2point: basket.p2pTickets,
      order: checkout,
    });

    const response = yield* call(makePostRequest, `${HOST_API}/checkout`, request);
    const order = yield* call(decodeOrThrow, CHECKOUT_ORDER, response.content);

    yield* call(logDebug, 'Submitting checkout order… done', order);
    return order;
  } catch (error) {
    yield* call(logError, 'Submitting checkout order… error', error);
    throw new RuntimeError(
      'Could not submit checkout order',
      { currency, language, basket, checkout },
      NativeError.wrap(error),
    );
  }
}

const REQUEST = E.struct({
  currency: CURRENCY,
  language: LANGUAGE,
  storageAffiliateId: E.id<string>(),
  basket: pipe(BASKET_REQUEST, E.compose(JSON_STRING)),
  order: pipe(ORDER_REQUEST, E.compose(JSON_STRING)),
  point2point: pipe(P2P_TICKET_REQUEST, E.compose(JSON_STRING)),
});
