import { Action } from 'redux';
import { P2P_TICKET_SUMMARY_CHANGE } from 'src/actions/P2pTicketActions';
import { P2pTicketState } from 'src/types/P2pTicketState';

export function p2pTicketSummaryChangeReducer(state: P2pTicketState, action: Action): P2pTicketState {
  if (P2P_TICKET_SUMMARY_CHANGE.isSuccess(action)) {
    return { ...state, screen: action.data };
  }

  return state;
}
