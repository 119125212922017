import { SagaIterator } from 'redux-saga';
import { CHECKOUT_PAYMENT_SUBMIT } from 'src/actions/CheckoutActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { formValidate } from 'src/forms/sagas/utils/formValidate';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { resetBasketConversationId } from 'src/sagas/basket/utils/resetBasketConversationId';
import { fetchCheckoutTotalPrice } from 'src/sagas/checkout/utils/fetchCheckoutTotalPrice';
import { saveCheckoutStore } from 'src/sagas/checkout/utils/storeCheckoutData';
import { submitCheckoutOrder } from 'src/sagas/checkout/utils/submitCheckoutOrder';
import { savePaymentStore } from 'src/sagas/payment/utils/storePaymentData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getBasketContent } from 'src/selectors/getBasketContent';
import { getCheckoutData } from 'src/selectors/getCheckoutData';
import { getCheckoutPaymentFormRule } from 'src/selectors/getCheckoutPaymentFormRule';
import { CheckoutData } from 'src/types/CheckoutData';
import { CheckoutFormData } from 'src/types/CheckoutFormData';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* checkoutPaymentSubmitSaga(
  action: GetRequestActionType<typeof CHECKOUT_PAYMENT_SUBMIT>,
): SagaIterator<void> {
  const form = action.data;
  const formData = form.currentState.values;

  const formRule = yield* select(getCheckoutPaymentFormRule);
  const valid = yield* call(formValidate, form, formRule);
  if (!valid) {
    return;
  }

  try {
    yield* call(logDebug, 'Submitting Checkout Payment form…', formData);
    yield* put(CHECKOUT_PAYMENT_SUBMIT.pending());

    yield* delay(1_000);

    const currentCheckout = yield* select(getCheckoutData);
    const basketContent = yield* select(getBasketContent);

    const currency = yield* select(getCurrentCurrency);
    const language = yield* select(getCurrentLanguage);

    const updatedFormData: CheckoutFormData = {
      ...currentCheckout.formData,
      payment: formData,
    };
    const totalPrice = yield* call(
      fetchCheckoutTotalPrice,
      currency,
      language,
      basketContent,
      updatedFormData,
    );
    const updatedCheckout: CheckoutData = {
      ...currentCheckout,
      formData: updatedFormData,
      totalPrice: totalPrice,
    };
    const createdOrder = yield* call(
      submitCheckoutOrder,
      currency,
      language,
      basketContent,
      updatedFormData,
    );

    yield* call(resetBasketConversationId);
    yield* call(saveCheckoutStore, {
      formData: updatedFormData,
    });
    yield* call(savePaymentStore, {
      basket: basketContent,
      checkout: updatedFormData,
      order: createdOrder,
      total: totalPrice,
    });

    yield* call(logDebug, 'Submitting Checkout Payment form… done');
    yield* put(CHECKOUT_PAYMENT_SUBMIT.success(updatedCheckout));

    yield* call({
      fn: window.location.assign,
      context: window.location,
    }, createdOrder.url);
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not submit Checkout Payment form',
      { formData },
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Submitting Checkout Payment form… error', error);
    yield* put(CHECKOUT_PAYMENT_SUBMIT.failure(NativeError.wrap(error)));
  }
}
