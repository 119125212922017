function getFaviconList(): HTMLLinkElement[] {
  const nodes = window.document.getElementsByTagName('link');

  const result: HTMLLinkElement[] = [];
  for (const node of Array.from(nodes)) {
    const rel = node.getAttribute('rel');
    if (!rel) {
      continue;
    }
    if (rel.split(/\s+/).includes('icon')) {
      result.push(node);
    }
  }
  return result;
}

export function updateFavicons(faviconUrl: string): void {
  const favicons = getFaviconList();

  if (favicons.length) {
    favicons.forEach((favicon) => {
      // eslint-disable-next-line no-param-reassign
      favicon.href = faviconUrl;
    });
  } else {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = faviconUrl;

    const head = window.document.head;
    head.appendChild(link);
  }
}

export function setGlobalStyleProperty(cssVarName: string, cssVarValue: string): void {
  window.document.documentElement.style.setProperty(cssVarName, cssVarValue);
}
