import * as C from 'io-ts/Codec';
import { LOCAL_DATE_TIME } from 'src/codecs/LocalDateTime';
import { P2P_TICKET_LANGUAGE } from 'src/codecs/P2pTicketLanguage';
import { P2P_TICKET_TRIP_ITEM } from 'src/codecs/P2pTicketTripItem';

export const P2P_TICKET_TRIP_COLLECTION_REQUEST = C.struct({
  language: P2P_TICKET_LANGUAGE,

  origin: C.string,
  destination: C.string,
  date: LOCAL_DATE_TIME,

  scrollDirection: C.nullable(C.literal('next', 'previous')),
  scrollContext: C.nullable(C.string),
});

export const P2P_TICKET_TRIP_COLLECTION_RESPONSE = C.array(P2P_TICKET_TRIP_ITEM);
