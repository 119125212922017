import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Action } from 'redux';
import {
  P2P_TICKET_INITIAL_CHANGE,
  P2P_TICKET_INITIAL_SUBMIT,
  P2P_TICKET_NAVIGATE_BACK,
  P2P_TICKET_NAVIGATE_TO,
  P2P_TICKET_RESET_SEARCH,
  P2P_TICKET_STATION_SEARCH,
  P2P_TICKET_SUMMARY_CHANGE,
  P2P_TICKET_SUMMARY_SUBMIT,
  P2P_TICKET_TRIP_CHANGE,
  P2P_TICKET_TRIP_OUTWARD_SUBMIT,
  P2P_TICKET_TRIP_RETURN_SUBMIT,
  P2P_TICKET_TRIP_SCROLL,
} from 'src/actions/P2pTicketActions';
import { P2pTicketScreenInitial } from 'src/components/p2p/P2pTicketScreenInitial';
import { P2pTicketScreenSummary } from 'src/components/p2p/P2pTicketScreenSummary';
import { P2pTicketScreenTripOutward } from 'src/components/p2p/P2pTicketScreenTripOutward';
import { P2pTicketScreenTripReturn } from 'src/components/p2p/P2pTicketScreenTripReturn';
import { useAction } from 'src/hooks/useAction';
import { useBoundAction } from 'src/hooks/useBoundAction';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getP2pTicketAttributes } from 'src/selectors/getP2pTicketAttributes';
import { getP2pTicketSchemaOrg } from 'src/selectors/getP2pTicketSchemaOrg';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { getP2pTicketSummaryFormRule } from 'src/selectors/getP2pTicketSummaryFormRule';
import {
  P2pTicketScreenData,
  P2pTicketScreenDataInitial,
  P2pTicketScreenDataSummary,
  P2pTicketScreenDataTripOutward,
  P2pTicketScreenDataTripReturn,
} from 'src/types/P2pTicketScreenData';
import { P2pTicketScreenType } from 'src/types/P2pTicketScreenType';
import { P2pTicketStationRequest } from 'src/types/P2pTicketStationRequest';
import { P2pTicketTripScrollDirection } from 'src/types/P2pTicketTripScroll';
import { assertNever } from 'src/utils/assert';

export function P2pTicketPage(): React.ReactElement {
  const screen = useSelector(getP2pTicketScreen);
  const schemaOrg = useSelector(getP2pTicketSchemaOrg);

  useEffect(() => {
    try {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    } catch {
      window.scrollTo(
        window.screenLeft,
        0,
      );
    }
  }, [screen.type]);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaOrg)}
        </script>
      </Helmet>

      <P2pTicketPageContent
        screen={screen}
      />
    </>
  );
}

type ContentProps = {
  readonly screen: P2pTicketScreenData;
};

export function P2pTicketPageContent({ screen }: ContentProps): React.ReactElement | null {
  switch (screen.type) {
  case 'initial':
    return <P2pTicketPageInitial screen={screen}/>;

  case 'trip-outward':
    return <P2pTicketPageTripOutward screen={screen}/>;

  case 'trip-return':
    return <P2pTicketPageTripReturn screen={screen}/>;

  case 'summary':
    return <P2pTicketPageSummary screen={screen}/>;

  default:
    return assertNever('Invalid code branch', { screen });
  }
}

type InitialProps = {
  readonly screen: P2pTicketScreenDataInitial;
};

function P2pTicketPageInitial({ screen }: InitialProps): React.ReactElement {
  const schemaOrg = useSelector(getP2pTicketSchemaOrg);
  const attributes = useSelector(getP2pTicketAttributes);

  const searchStation = useAction(STATION_SEARCH_ACTION);
  const changeFormData = useAction(P2P_TICKET_INITIAL_CHANGE.request);
  const submitFormData = useAction(P2P_TICKET_INITIAL_SUBMIT.request);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaOrg)}
        </script>
      </Helmet>

      <P2pTicketScreenInitial
        key={screen.uid}
        screen={screen}
        onStationSearch={searchStation}
        onChange={changeFormData}
        onSubmit={submitFormData}
        attributes={attributes}
      />
    </>
  );
}

type TripOutwardProps = {
  readonly screen: P2pTicketScreenDataTripOutward;
};

function P2pTicketPageTripOutward({ screen }: TripOutwardProps): React.ReactElement {
  const currency = useSelector(getCurrentCurrency);

  const resetSearch = useAction(P2P_TICKET_RESET_SEARCH.request);
  const navigateBack = useAction(P2P_TICKET_NAVIGATE_BACK.request);
  const navigateTo = useAction(NAVIGATE_TO_ACTION);

  const changeForm = useAction(P2P_TICKET_TRIP_CHANGE.request);
  const submitScreen = useAction(P2P_TICKET_TRIP_OUTWARD_SUBMIT.request);

  const searchStation = useAction(STATION_SEARCH_ACTION);
  const scrollForward = useBoundAction(TRIP_SCROLL_ACTION, 'next');
  const scrollBackward = useBoundAction(TRIP_SCROLL_ACTION, 'previous');

  return (
    <P2pTicketScreenTripOutward
      key={screen.uid}
      currency={currency}
      screen={screen}
      onReset={resetSearch}
      onGoBack={navigateBack}
      onNavigate={navigateTo}
      onChange={changeForm}
      onSubmit={submitScreen}
      onStationSearch={searchStation}
      onScrollForward={scrollForward}
      onScrollBackward={scrollBackward}
    />
  );
}

type TripReturnProps = {
  readonly screen: P2pTicketScreenDataTripReturn;
};

function P2pTicketPageTripReturn({ screen }: TripReturnProps): React.ReactElement {
  const currency = useSelector(getCurrentCurrency);

  const resetSearch = useAction(P2P_TICKET_RESET_SEARCH.request);
  const navigateBack = useAction(P2P_TICKET_NAVIGATE_BACK.request);
  const navigateTo = useAction(NAVIGATE_TO_ACTION);

  const changeForm = useAction(P2P_TICKET_TRIP_CHANGE.request);
  const submitScreen = useAction(P2P_TICKET_TRIP_RETURN_SUBMIT.request);

  const searchStation = useAction(STATION_SEARCH_ACTION);
  const scrollForward = useBoundAction(TRIP_SCROLL_ACTION, 'next');
  const scrollBackward = useBoundAction(TRIP_SCROLL_ACTION, 'previous');

  return (
    <P2pTicketScreenTripReturn
      key={screen.uid}
      currency={currency}
      screen={screen}
      onReset={resetSearch}
      onGoBack={navigateBack}
      onNavigate={navigateTo}
      onChange={changeForm}
      onSubmit={submitScreen}
      onStationSearch={searchStation}
      onScrollForward={scrollForward}
      onScrollBackward={scrollBackward}
    />
  );
}

type SummaryProps = {
  readonly screen: P2pTicketScreenDataSummary;
};

function P2pTicketPageSummary({ screen }: SummaryProps): React.ReactElement {
  const currency = useSelector(getCurrentCurrency);
  const formRule = useSelector(getP2pTicketSummaryFormRule);

  const resetSearch = useAction(P2P_TICKET_RESET_SEARCH.request);
  const navigateBack = useAction(P2P_TICKET_NAVIGATE_BACK.request);
  const navigateTo = useAction(NAVIGATE_TO_ACTION);

  const changeForm = useAction(P2P_TICKET_SUMMARY_CHANGE.request);
  const submitScreen = useAction(P2P_TICKET_SUMMARY_SUBMIT.request);

  return (
    <P2pTicketScreenSummary
      key={screen.uid}
      currency={currency}
      screen={screen}
      formRule={formRule}
      onReset={resetSearch}
      onGoBack={navigateBack}
      onNavigate={navigateTo}
      onChange={changeForm}
      onSubmit={submitScreen}
    />
  );
}

const NAVIGATE_TO_ACTION = (screen: P2pTicketScreenType): Action => {
  return P2P_TICKET_NAVIGATE_TO.request(undefined, screen);
};
const STATION_SEARCH_ACTION = (request: P2pTicketStationRequest): Action => {
  return P2P_TICKET_STATION_SEARCH.request(undefined, request);
};
const TRIP_SCROLL_ACTION = (direction: P2pTicketTripScrollDirection): Action => {
  return P2P_TICKET_TRIP_SCROLL.request(undefined, direction);
};
