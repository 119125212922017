import './CheckoutPersonalP2pTicketFormView.scss';
import { constVoid } from 'fp-ts/function';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { FormattedTime } from 'src/components/common/FormattedTime';
import { Col, Row } from 'src/components/common/Grid';
import { P2pTicketName } from 'src/components/common/P2pTicketName';
import { Typography } from 'src/components/common/Typography';
import { BirthDate } from 'src/forms/components/BirthDate';
import { Textbox } from 'src/forms/components/Textbox';
import { CheckoutProductP2pTicket } from 'src/types/CheckoutProduct';
import { DateOnly } from 'src/types/DateOnly';

type Props = {
  readonly product: CheckoutProductP2pTicket;
};

export function CheckoutPersonalP2pTicketFormView({ product }: Props): React.ReactElement {
  return (
    <div className="sts-ui-checkout-personal-p2p-ticket-form-view">
      <div className="sts-ui-checkout-personal-p2p-ticket-form-view__header">
        <div className="sts-ui-checkout-personal-p2p-ticket-form-view__title">
          <P2pTicketName
            from={product.ticket.from}
            to={product.ticket.to}
          />
        </div>
        <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info">
          <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info-line">
            <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info-line-label">
              <FormattedMessage id="Checkout.P2P.Ticket.Date"/>
            </div>
            <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info-line-value">
              <FormattedDate value={product.ticket.date.date}/>
            </div>
          </div>
          <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info-line">
            <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info-line-label">
              <FormattedMessage id="Checkout.P2P.Ticket.Time"/>
            </div>
            <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info-line-value">
              <FormattedTime value={product.ticket.date.time}/>
            </div>
          </div>
          <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info-line">
            <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info-line-label">
              <FormattedMessage id="Checkout.P2P.Ticket.Class"/>
            </div>
            <div className="sts-ui-checkout-personal-p2p-ticket-form-view__info-line-value">
              <FormattedMessage id="P2P.Common.ClassName" values={{ class: product.ticket.class }}/>
            </div>
          </div>
        </div>
      </div>

      {product.ticket.offers.map(({ traveler }) => (
        <div key={traveler.id} className="sts-ui-checkout-personal-p2p-ticket-form-view__traveler">
          <div key={traveler.id} className="sts-ui-checkout-personal-p2p-ticket-form-view__traveler-title">
            <FormattedMessage
              id="P2P.Common.AgeGroup"
              values={{ ageGroup: traveler.ageGroup }}
            />
          </div>

          <div className="sts-ui-checkout-personal-p2p-ticket-form-view__traveler-field">
            <Row>
              <Col xs={12} sm={2} md={3} lg={3}>
                <div className="sts-ui-checkout-personal-p2p-ticket-form-view__traveler-label">
                  <Typography
                    as="label"
                    variant="form-label"
                    htmlFor={`${product.uuid}-${traveler.id}-firstName`}
                  >
                    <FormattedMessage id="Checkout.P2P.Traveler.FirstName"/>
                  </Typography>
                </div>
              </Col>
              <Col xs={12} sm={10} md={5} lg={5}>
                <Textbox
                  id={`${product.uuid}-${traveler.id}-firstName`}
                  name={`${product.uuid}-${traveler.id}-firstName`}
                  type="text"
                  value={traveler.firstName}
                  onChange={constVoid}
                  disabled={true}
                />
              </Col>
            </Row>
          </div>

          <div className="sts-ui-checkout-personal-p2p-ticket-form-view__traveler-field">
            <Row>
              <Col xs={12} sm={2} md={3} lg={3}>
                <div className="sts-ui-checkout-personal-p2p-ticket-form-view__traveler-label">
                  <Typography
                    as="label"
                    variant="form-label"
                    htmlFor={`${product.uuid}-${traveler.id}-lastName`}
                  >
                    <FormattedMessage id="Checkout.P2P.Traveler.LastName"/>
                  </Typography>
                </div>
              </Col>
              <Col xs={12} sm={10} md={5} lg={5}>
                <Textbox
                  id={`${product.uuid}-${traveler.id}-lastName`}
                  name={`${product.uuid}-${traveler.id}-lastName`}
                  type="text"
                  value={traveler.lastName}
                  onChange={constVoid}
                  disabled={true}
                />
              </Col>
            </Row>
          </div>

          <div className="sts-ui-checkout-personal-p2p-ticket-form-view__traveler-field">
            <Row>
              <Col xs={12} sm={2} md={3} lg={3}>
                <div className="sts-ui-checkout-personal-p2p-ticket-form-view__traveler-label">
                  <Typography
                    as="label"
                    variant="form-label"
                    htmlFor={`${product.uuid}-${traveler.id}-birthDate`}
                  >
                    <FormattedMessage id="Checkout.P2P.Traveler.BirthDate"/>
                  </Typography>
                </div>
              </Col>
              <Col xs={12} sm={10} md={5} lg={5}>
                <BirthDate
                  id={`${product.uuid}-${traveler.id}-birthDate`}
                  name={`${product.uuid}-${traveler.id}-birthDate`}
                  value={traveler.birthDate}
                  onChange={constVoid}
                  disabled={true}
                  min={ABS_MIN_DATE}
                  max={ABS_MAX_DATE}
                />
              </Col>
            </Row>
          </div>
        </div>
      ))}
    </div>
  );
}

const ABS_MIN_DATE: DateOnly = { yy: 1900, mm: 1, dd: 1 };
const ABS_MAX_DATE: DateOnly = { yy: 2999, mm: 12, dd: 31 };
