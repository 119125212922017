import { captureException, SeverityLevel, withScope } from '@sentry/browser';
import type { Primitive } from '@sentry/types/types/misc';
import { AssertionError } from 'src/errors/AssertionError';
import { BaseError } from 'src/errors/BaseError';
import { DecodingError } from 'src/errors/DecodingError';
import { HttpError } from 'src/errors/HttpError';
import { LogicError } from 'src/errors/LogicError';
import { NativeError } from 'src/errors/NativeError';
import { NetworkError } from 'src/errors/NetworkError';

export function sentryCatch(
  error: unknown,
  level: SeverityLevel = 'error',
): void {
  withScope((scope) => {
    // TODO: don't we need a user/affiliate/language/currency info here?
    const wrapped = NativeError.wrap(error);

    scope.setLevel(level);
    scope.setTags(collectErrorTags(wrapped));
    scope.setExtra('error_context', wrapped.toJSON());

    captureException(error);
  });
}

function collectErrorTags(error: BaseError): Record<string, Primitive> {
  const tags: Record<string, Primitive> = {};

  let current: BaseError | undefined = error;
  do {
    Object.assign(tags, getErrorTags(current));
    current = current.previous;
  } while (current);

  return tags;
}

function getErrorTags(error: BaseError): Record<string, Primitive> {
  if (error instanceof NetworkError) {
    return {
      'network_error.caught': true,
      'network_error.online': error.onLine,
    };
  }
  if (error instanceof HttpError) {
    return {
      'http_error.caught': true,
      'http_error.status': error.status,
    };
  } else if (error instanceof DecodingError) {
    return {
      'decoding_error.caught': true,
    };
  } else if (error instanceof AssertionError) {
    return {
      'assertion_error.caught': true,
    };
  } else if (error instanceof LogicError) {
    return {
      'logic_error.caught': true,
    };
  } else if (error instanceof NativeError) {
    return {
      'native_error.caught': true,
      'native_error.type': error.name,
    };
  }

  return {};
}
