import { BaseError } from 'src/errors/BaseError';
import { HttpError } from 'src/errors/HttpError';
import { NetworkError } from 'src/errors/NetworkError';
import { Integer } from 'src/types/Integer';

export function isHttpError(error: BaseError, status: Integer): boolean {
  if (error instanceof HttpError) {
    return error.status === status;
  }
  if (error.previous) {
    return isHttpError(error.previous, status);
  }

  return false;
}

export function isNetworkError(error: BaseError): boolean {
  if (error instanceof NetworkError) {
    return true;
  }
  if (error.previous) {
    return isNetworkError(error.previous);
  }

  return false;
}
