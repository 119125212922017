import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_TRIP_SCROLL } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { scrollP2pTripList } from 'src/sagas/p2p/utils/scrollP2pTripList';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getP2pTicketConversationId } from 'src/selectors/getP2pTicketConversationId';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { getP2pTicketTripInfo } from 'src/selectors/getP2pTicketTripInfo';
import { getP2pTicketTripList } from 'src/selectors/getP2pTicketTripList';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, put, select } from 'typed-redux-saga';

export function* p2pTicketTripScrollSaga(
  action: GetRequestActionType<typeof P2P_TICKET_TRIP_SCROLL>,
): SagaIterator<void> {
  const direction = action.meta;

  try {
    yield* call(logDebug, `Scrolling "${direction}" P2P Ticket trips…`);
    yield* put(P2P_TICKET_TRIP_SCROLL.pending(direction));

    const language = yield* select(getCurrentLanguage);
    const screen = yield* select(getP2pTicketScreen);
    const tripInfo = yield* select(getP2pTicketTripInfo);
    const conversationId = yield* select(getP2pTicketConversationId);

    const currentTripList = yield* select(getP2pTicketTripList);
    const updatedTripList = yield* call(
      scrollP2pTripList,
      language,
      screen.form.travelers,
      conversationId,
      currentTripList,
      tripInfo,
      direction,
    );

    yield* call(logDebug, `Scrolling "${direction}" P2P Ticket trips… done`, updatedTripList);
    yield* put(P2P_TICKET_TRIP_SCROLL.success(updatedTripList, direction));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      `Could not scroll "${direction}" P2P Ticket trips`,
      { direction },
      NativeError.wrap(error),
    ));

    yield* call(logError, `Scrolling "${direction}" P2P Ticket trips… error`, error);
    yield* put(P2P_TICKET_TRIP_SCROLL.failure(NativeError.wrap(error), direction));
  }
}
