import './CheckoutPaymentTicketDetailsFormView.scss';
import React from 'react';
import { CheckoutPaymentP2pTicketPriceList } from 'src/components/checkout/CheckoutPaymentP2pTicketPriceList';
import { CheckoutPaymentP2pTicketTraveler } from 'src/components/checkout/CheckoutPaymentP2pTicketTraveler';
import { CheckoutPaymentTicketPriceList } from 'src/components/checkout/CheckoutPaymentTicketPriceList';
import { CheckoutPaymentTicketTraveler } from 'src/components/checkout/CheckoutPaymentTicketTraveler';
import { TARIFF_LIST } from 'src/constants/tariff';
import { CheckoutPersonalFormData } from 'src/types/CheckoutFormData';
import { CheckoutProduct } from 'src/types/CheckoutProduct';
import { CheckoutTotal } from 'src/types/CheckoutTotal';
import { Currency } from 'src/types/Currency';
import { assertNever } from 'src/utils/assert';

type Props = {
  readonly price: CheckoutTotal;
  readonly personal: CheckoutPersonalFormData;
  readonly products: ReadonlyArray<CheckoutProduct>;
  readonly currency: Currency;
};

export class CheckoutPaymentTicketDetailsFormView extends React.Component<Props> {
  public render(): React.ReactElement {
    const { products } = this.props;

    return (
      <div className="sts-ui-checkout-payment-ticket-details-form-view">
        <div className="sts-ui-checkout-payment-ticket-details-form-view__traveler-list">
          {products.map((product) => this.renderProductTravelers(product))}
        </div>

        <div className="sts-ui-checkout-payment-ticket-details-form-view__ticket-list">
          {products.map((product) => this.renderProductPriceList(product))}
        </div>
      </div>
    );
  }

  private renderProductTravelers(product: CheckoutProduct): React.ReactNode {
    switch (product.type) {
    case 'product': {
      const { personal } = this.props;

      return TARIFF_LIST.map((tariff) => {
        const travelers = personal.travelers[product.code]?.[tariff];
        if (!travelers) {
          return null;
        }

        return travelers.map((traveler) => (
          <div
            key={`${traveler.product}-${traveler.tariff}-${traveler.position}`}
            className="sts-ui-checkout-payment-ticket-details-form-view__traveler-item"
          >
            <CheckoutPaymentTicketTraveler
              traveler={traveler}
            />
          </div>
        ));
      });
    }

    case 'p2p-ticket': {
      return product.ticket.offers.map(({ traveler }) => (
        <div
          key={`${product.uuid}-${traveler.id}`}
          className="sts-ui-checkout-payment-ticket-details-form-view__traveler-item"
        >
          <CheckoutPaymentP2pTicketTraveler
            product={product.ticket}
            traveler={traveler}
          />
        </div>
      ));
    }

    default:
      return assertNever('Unexpected product type', { product });
    }
  }

  private renderProductPriceList(product: CheckoutProduct): React.ReactNode {
    switch (product.type) {
    case 'product': {
      const { price } = this.props;

      const tickets = price.ticketPrices[product.code];
      if (!tickets) {
        return null;
      }

      return (
        <div key={product.uuid} className="sts-ui-checkout-payment-ticket-details-form-view__ticket-item">
          <CheckoutPaymentTicketPriceList
            product={product}
            tickets={tickets}
          />
        </div>
      );
    }

    case 'p2p-ticket': {
      const { currency } = this.props;

      return (
        <div key={product.uuid} className="sts-ui-checkout-payment-ticket-details-form-view__ticket-item">
          <CheckoutPaymentP2pTicketPriceList
            product={product}
            currency={currency}
          />
        </div>
      );
    }

    default:
      return assertNever('Unexpected product type', { product });
    }
  }
}
