import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { LOCAL_DATE_TIME } from 'src/codecs/LocalDateTime';
import { P2P_TICKET_OFFER } from 'src/codecs/P2pTicketOffer';
import { P2P_TICKET_STATION } from 'src/codecs/P2pTicketStation';
import { P2P_TICKET_TRAVELER } from 'src/codecs/P2pTicketTraveler';
import { P2P_TICKET_TRIP_DIRECTION } from 'src/codecs/P2pTicketTripDirection';
import { P2P_TICKET_TRIP_ITEM } from 'src/codecs/P2pTicketTripItem';
import { TICKET_CLASS } from 'src/codecs/TicketClass';
import { UUID } from 'src/codecs/Uuid';
import { P2pTicketProduct, P2pTicketProductOffer } from 'src/types/P2pTicketProduct';

const P2P_TICKET_PRODUCT_OFFER: C.Codec<unknown, JsonRecord, P2pTicketProductOffer> = C.struct({
  offer: P2P_TICKET_OFFER,
  traveler: P2P_TICKET_TRAVELER,
});

export const P2P_TICKET_PRODUCT: C.Codec<unknown, JsonRecord, P2pTicketProduct> = C.struct({
  ticketIdentifier: UUID,
  conversationId: UUID,
  direction: P2P_TICKET_TRIP_DIRECTION,
  from: P2P_TICKET_STATION,
  to: P2P_TICKET_STATION,
  date: LOCAL_DATE_TIME,
  class: TICKET_CLASS,
  trip: P2P_TICKET_TRIP_ITEM,
  offers: C.readonly(C.array(P2P_TICKET_PRODUCT_OFFER)),
});
