import { P2pTicketTripFrame } from 'src/types/P2pTicketTripFrame';
import { P2pTicketTripItem } from 'src/types/P2pTicketTripItem';
import { P2pTicketTripScrollDirection } from 'src/types/P2pTicketTripScroll';

export function getTripFrameItems(frame: P2pTicketTripFrame): P2pTicketTripItem[] {
  return frame.collection.trips.slice(
    frame.top,
    frame.bottom,
  );
}

export function canScrollTripFrame(
  frame: P2pTicketTripFrame,
  direction: P2pTicketTripScrollDirection,
): boolean {
  if (direction === 'next') {
    return (
      frame.bottom < frame.collection.trips.length ||
      frame.collection.nextTripsCollectionId !== null
    );
  } else {
    return (
      frame.top > 0 ||
      frame.collection.prevTripsCollectionId !== null
    );
  }
}

export function removePrevTripFrameContext(frame: P2pTicketTripFrame): P2pTicketTripFrame {
  return {
    ...frame,
    collection: {
      ...frame.collection,
      prevTripsCollectionId: null,
    },
  };
}

export function removeNextTripFrameContext(frame: P2pTicketTripFrame): P2pTicketTripFrame {
  return {
    ...frame,
    collection: {
      ...frame.collection,
      nextTripsCollectionId: null,
    },
  };
}
