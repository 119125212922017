import { SagaIterator } from 'redux-saga';
import { CHECKOUT_PAYMENT_CHANGE } from 'src/actions/CheckoutActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { fetchCheckoutTotalPrice } from 'src/sagas/checkout/utils/fetchCheckoutTotalPrice';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getBasketContent } from 'src/selectors/getBasketContent';
import { getCheckoutData } from 'src/selectors/getCheckoutData';
import { CheckoutData } from 'src/types/CheckoutData';
import { CheckoutFormData } from 'src/types/CheckoutFormData';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* checkoutPaymentChangeSaga(
  action: GetRequestActionType<typeof CHECKOUT_PAYMENT_CHANGE>,
): SagaIterator<void> {
  const form = action.data;
  const formData = form.currentState.values;

  try {
    yield* call(logDebug, 'Changing Checkout Payment form…', formData);
    yield* put(CHECKOUT_PAYMENT_CHANGE.pending());

    yield* delay(500);

    const currentCheckout = yield* select(getCheckoutData);
    const basketContent = yield* select(getBasketContent);

    const currency = yield* select(getCurrentCurrency);
    const language = yield* select(getCurrentLanguage);

    const updatedFormData: CheckoutFormData = {
      ...currentCheckout.formData,
      payment: formData,
    };
    const totalPrice = yield* call(
      fetchCheckoutTotalPrice,
      currency,
      language,
      basketContent,
      updatedFormData,
    );
    const updatedCheckout: CheckoutData = {
      ...currentCheckout,
      formData: updatedFormData,
      totalPrice: totalPrice,
    };

    yield* call(logDebug, 'Changing Checkout Payment form… done', updatedCheckout);
    yield* put(CHECKOUT_PAYMENT_CHANGE.success(updatedCheckout));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not change Checkout Payment form',
      { formData },
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Changing Checkout Payment form… error', error);
    yield* put(CHECKOUT_PAYMENT_CHANGE.failure(NativeError.wrap(error)));
  }
}
