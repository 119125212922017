import { Json, JsonRecord } from 'fp-ts/Json';

export function encodeQuery(query: JsonRecord): URLSearchParams {
  const pairs: [string, string][] = [];
  const traverse = (key: string, val: Json): void => {
    if (val === null || val === false) {
      return;
    }

    if (
      typeof val === 'number' ||
      typeof val === 'string' ||
      typeof val === 'boolean'
    ) {
      pairs.push([key, String(val)]);
    } else {
      Object.entries(val).forEach(([subKey, subVal]) => traverse(`${key}[${subKey}]`, subVal));
    }
  };

  Object.entries(query).forEach(([key, val]) => traverse(key, val));
  return new URLSearchParams(pairs);
}
