import { ProductCode } from 'src/types/ProductCode';
import { TicketType } from 'src/types/TicketType';

export const EXCURSION_PAGE_PRODUCTS: ReadonlyArray<ProductCode> = [
  TicketType.EXCURSION_SCHILTHORN_MURREN,
  TicketType.EXCURSION_SCHILTHORN_STECHELBERG,
  TicketType.EXCURSION_TITLIS,
  TicketType.EXCURSION_JUNGFRAUJOCH,
  TicketType.EXCURSION_GLACIER,
  TicketType.EXCURSION_STANSERHORN,
  TicketType.EXCURSION_GORNERGRAT,
  TicketType.EXCURSION_MATTERHORN,
  TicketType.EXCURSION_PILATUS,
  TicketType.EXCURSION_ROCHERS,
];
