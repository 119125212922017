import './CheckoutPersonalFormView.scss';
import { FormController } from '@form-ts/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckoutPersonalBillingFormView } from 'src/components/checkout/CheckoutPersonalBillingFormView';
import { CheckoutPersonalP2pTicketFormView } from 'src/components/checkout/CheckoutPersonalP2pTicketFormView';
import { CheckoutPersonalProductFormView } from 'src/components/checkout/CheckoutPersonalProductFormView';
import { FormError } from 'src/forms/types/FormError';
import { CheckoutPersonalFormData } from 'src/types/CheckoutFormData';
import { CheckoutProduct } from 'src/types/CheckoutProduct';
import { CountryCode } from 'src/types/CountryCode';
import { CustomerTitle } from 'src/types/CustomerTitle';
import { ListOption } from 'src/types/ListOption';

type Props = {
  readonly form: FormController<CheckoutPersonalFormData, FormError>;
  readonly products: ReadonlyArray<CheckoutProduct>;

  readonly customerTitles: ReadonlyArray<ListOption<CustomerTitle>>;
  readonly customerCountries: ReadonlyArray<ListOption<CountryCode>>;
  readonly travelerCountries: ReadonlyArray<ListOption<CountryCode>>;
};

export const CheckoutPersonalFormView = React.memo(({
  form,
  products,
  customerTitles,
  customerCountries,
  travelerCountries,
}: Props): React.ReactElement => {
  return (
    <div className="sts-ui-checkout-personal-form-view">
      <div className="sts-ui-checkout-personal-form-view__billing">
        <CheckoutPersonalBillingFormView
          form={form}
          customerTitles={customerTitles}
          customerCountries={customerCountries}
        />
      </div>

      {products.map((product, index) => (
        <div key={product.uuid} className="sts-ui-checkout-personal-form-view__ticket">
          {product.type === 'product' && (
            <CheckoutPersonalProductFormView
              form={form}
              product={product}
              customerCountries={customerCountries}
              travelerCountries={travelerCountries}
              ticketNotice={(
                index === products.length - 1
                  ? <FormattedMessage id="Form.Checkout.BillingAddress.NonRefundableTicketNotice"/>
                  : null
              )}
            />
          )}
          {product.type === 'p2p-ticket' && (
            <CheckoutPersonalP2pTicketFormView
              product={product}
            />
          )}
        </div>
      ))}
    </div>
  );
});
