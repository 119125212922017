import { createSelector } from 'reselect';
import { FAQPage, WithContext } from 'schema-dts';
import { getIntlMessages } from 'src/selectors/getIntlMessages';
import { getP2pTicketAttributes } from 'src/selectors/getP2pTicketAttributes';

export const getP2pTicketSchemaOrg = createSelector([
  getIntlMessages,
  getP2pTicketAttributes,
], (messages, attributes): ReadonlyArray<WithContext<FAQPage>> => {
  return [{
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': attributes.faqSections.map((section) => ({
      '@type': 'Question',
      'name': messages[section.header],
      'acceptedAnswer': {
        '@type': 'Answer',
        'text': messages[section.content],
      },
    })),
  }];
});
