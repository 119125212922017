import './P2pTicketFormTimeInput.scss';
import React, { useCallback } from 'react';
import { LocalTime } from 'src/types/LocalTime';

type Props = {
  readonly value: LocalTime;
  readonly onChange: (value: LocalTime) => void;
};

export function P2pTicketFormTimeInput({
  value,
  onChange,
}: Props): React.ReactElement {
  const handleHhChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const hh = parseInt(event.target.value, 10);
    onChange({ ...value, hh });
  }, [value, onChange]);

  const handleMmChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const mm = parseInt(event.target.value, 10);
    onChange({ ...value, mm });
  }, [value, onChange]);

  return (
    <div className="sts-ui-p2p-ticket-form-time-input">
      <select
        className="sts-ui-p2p-ticket-form-time-input__field"
        value={value.hh}
        onChange={handleHhChange}
      >
        {Array.from({ length: 24 }, (_, index) => (
          <option key={index} value={index}>
            {index.toString().padStart(2, '0')}
          </option>
        ))}
      </select>
      <select
        className="sts-ui-p2p-ticket-form-time-input__field"
        value={value.mm}
        onChange={handleMmChange}
      >
        {Array.from({ length: 6 }, (_, index) => (
          <option key={index} value={index * 10}>
            {(index * 10).toString().padStart(2, '0')}
          </option>
        ))}
      </select>
    </div>
  );
}
