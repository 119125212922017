import './P2pTicketScreenCommonTripLegItem.scss';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedTime } from 'src/components/common/FormattedTime';
import { TimelineConnector } from 'src/components/common/TimelineConnector';
import { TripChangeIcon } from 'src/components/icons/TripChangeIcon';
import { P2pTicketScreenCommonTripDuration } from 'src/components/p2p/P2pTicketScreenCommonTripDuration';
import { P2pTicketScreenCommonTripLegService } from 'src/components/p2p/P2pTicketScreenCommonTripLegService';
import { P2pTicketScreenCommonTripVehicleType } from 'src/components/p2p/P2pTicketScreenCommonTripVehicleType';
import { P2pTicketTripLeg } from 'src/types/P2pTicketTripItem';
import {
  getTripLegDuration,
  getTripLegArrivalTime,
  getTripLegDepartureTime,
  isTripLegChange,
} from 'src/utils/p2p/getTripDetails';

type Props = {
  readonly tripLeg: P2pTicketTripLeg;
  readonly isFirst: boolean;
  readonly isLast: boolean;
};

export function P2pTicketScreenCommonTripLegItem({ tripLeg, isFirst, isLast }: Props): React.ReactElement {
  const duration = getTripLegDuration(tripLeg);

  if (isTripLegChange(tripLeg)) {
    return (
      <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item">
        <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__change">
          <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__change-icon">
            <TripChangeIcon/>
          </div>
          {duration && (
            <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__change-duration">
              <P2pTicketScreenCommonTripDuration duration={duration}/>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    const departure = getTripLegDepartureTime(tripLeg);
    const arrival = getTripLegArrivalTime(tripLeg);

    return (
      <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item">
        <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport">
          <div className={classNames({
            'sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-line': true,
            'sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-line--highlight': isFirst,
          })}
          >
            <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-time">
              <FormattedTime value={departure.time}/>
            </div>
            <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-title">
              <FormattedMessage
                id="P2P.TripInfo.Stop"
                values={{
                  station: tripLeg.timedLeg?.start.stopPlaceName,
                  stopName: tripLeg.timedLeg?.start.plannedStopPointName ?? null,
                  track: tripLeg.timedLeg?.start.departureTrack ?? null,
                }}
              />
            </div>
          </div>
          {tripLeg.timedLeg && (
            <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-line">
              <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-time"/>
              <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-info">
                <div
                  className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-vehicle"
                  title={tripLeg.timedLeg.service.mode.name}
                >
                  <P2pTicketScreenCommonTripVehicleType mode={tripLeg.timedLeg.service.mode}/>
                </div>
                <div
                  className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-service"
                  title={tripLeg.timedLeg.service.publishedServiceName}
                >
                  <P2pTicketScreenCommonTripLegService service={tripLeg.timedLeg.service}/>
                </div>
                {tripLeg.timedLeg.direction && (
                  <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-direction">
                    {tripLeg.timedLeg.direction}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={classNames({
            'sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-line': true,
            'sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-line--highlight': isLast,
          })}
          >
            <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-time">
              <FormattedTime value={arrival.time}/>
            </div>
            <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-title">
              <FormattedMessage
                id="P2P.TripInfo.Stop"
                values={{
                  station: tripLeg.timedLeg?.end.stopPlaceName,
                  stopName: tripLeg.timedLeg?.end.plannedStopPointName ?? null,
                  track: tripLeg.timedLeg?.end.departureTrack ?? null,
                }}
              />
            </div>
          </div>

          <div className="sts-ui-p2p-ticket-screen-common-trip-leg-item__transport-connector">
            <TimelineConnector
              start={isFirst ? 'solid' : 'outlined'}
              end={isLast ? 'solid' : 'none'}
            />
          </div>
        </div>
      </div>
    );
  }
}
