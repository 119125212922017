import { FormRule } from 'src/forms/types/FormRule';
import * as R from 'src/forms/utils/rules';
import { LocalDateTime } from 'src/types/LocalDateTime';
import { P2pTicketFormData } from 'src/types/P2pTicketFormData';

export function createInitialFormRule(): FormRule<P2pTicketFormData> {
  return R.struct({
    travelers: R.pass(),
    trip: R.lazy((tripData) => R.struct({
      oneWay: R.pass(),

      from: R.required(),
      to: R.required(),

      departDate: R.allOf([
        R.required(),
        R.nullable(R.dateTime()),
      ]),
      returnDate: tripData.oneWay
        ? R.equals<LocalDateTime | null>(null)
        : R.allOf([
          R.required(),
          R.nullable(R.dateTime()),
        ]),
    })),
  });
}
