import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { LOCAL_DATE } from 'src/codecs/LocalDate';
import { LOCAL_DATE_TIME } from 'src/codecs/LocalDateTime';
import { P2P_TICKET_AGE_GROUP } from 'src/codecs/P2pTicketAgeGroup';
import { P2P_TICKET_DISCOUNT } from 'src/codecs/P2pTicketDiscount';
import { P2P_TICKET_STATION } from 'src/codecs/P2pTicketStation';
import { UUID } from 'src/codecs/Uuid';
import { P2pTicketFormData, P2pTicketFormDataTraveler, P2pTicketFormDataTrip } from 'src/types/P2pTicketFormData';

const P2P_TICKET_FORM_DATA_TRIP: C.Codec<unknown, JsonRecord, P2pTicketFormDataTrip> = C.struct({
  from: C.nullable(P2P_TICKET_STATION),
  to: C.nullable(P2P_TICKET_STATION),

  departDate: C.nullable(LOCAL_DATE_TIME),
  returnDate: C.nullable(LOCAL_DATE_TIME),

  oneWay: C.boolean,
});

const P2P_TICKET_FORM_DATA_TRAVELER: C.Codec<unknown, JsonRecord, P2pTicketFormDataTraveler> = C.struct({
  id: UUID,

  ageGroup: P2P_TICKET_AGE_GROUP,
  discount: P2P_TICKET_DISCOUNT,

  firstName: C.string,
  lastName: C.string,
  birthDate: C.nullable(LOCAL_DATE),
});

export const P2P_TICKET_FORM_DATA: C.Codec<unknown, JsonRecord, P2pTicketFormData> = C.struct({
  trip: P2P_TICKET_FORM_DATA_TRIP,
  travelers: C.readonly(C.array(P2P_TICKET_FORM_DATA_TRAVELER)),
});
