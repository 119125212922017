export enum P2pTicketScreenAction {
  GO_BACK = 'go-back',
  RESET_SEARCH = 'reset-rearch',
  EDIT_TRIP_FORM = 'edit-trip-form',
  SUBMIT_SCREEN = 'submit-screen',

  NAVIGATE_SEARCH = 'navigate-search',
  NAVIGATE_OUTWARD = 'navigate-outward',
  NAVIGATE_RETURN = 'navigate-return',
  NAVIGATE_SUMMARY = 'navigate-summary',
}
