import './Stepper.scss';
import classNames from 'classnames';
import React from 'react';
import { RouteLink } from 'src/routing/containers/RouteLink';
import { RouteName } from 'src/routing/types/RouteName';

type Props = {
  readonly children: React.ReactNode;
};

export function Stepper({ children }: Props): React.ReactElement {
  return (
    <ul className="sts-ui-stepper">
      {children}
    </ul>
  );
}

type ItemProps = {
  readonly icon: React.ReactNode;
  readonly title: React.ReactNode;
  readonly state: 'current' | 'completed' | 'disabled';
  readonly route?: RouteName;
  readonly onClick?: () => void;
};

export function StepperItem({ icon, title, state, route, onClick }: ItemProps): React.ReactElement {
  return (
    <li className={classNames(
      'sts-ui-stepper__step',
      `sts-ui-stepper__step--${state}`,
    )}
    >
      <div className="sts-ui-stepper__content">
        {route !== undefined && (
          <RouteLink className="sts-ui-stepper__item" route={route} onClick={onClick}>
            <div className="sts-ui-stepper__counter">
              {icon}
            </div>
            <div className="sts-ui-stepper__title">
              {title}
            </div>
          </RouteLink>
        )}
        {route === undefined && (
          <button className="sts-ui-stepper__item" type="button" onClick={onClick}>
            <div className="sts-ui-stepper__counter">
              {icon}
            </div>
            <div className="sts-ui-stepper__title">
              {title}
            </div>
          </button>
        )}
      </div>
    </li>
  );
}
