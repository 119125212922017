import {
  NETWORK_ERROR_MODAL_CLOSE,
  NETWORK_ERROR_MODAL_OPEN,
  NETWORK_ERROR_MODAL_RETRY,
} from 'src/network/actions/NetworkActions';
import { NetworkState } from 'src/network/types/NetworkState';
import { createReducer } from 'src/utils/reducer';

const INITIAL_STATE: NetworkState = {
  error: null,
};

export const networkReducer = createReducer(INITIAL_STATE, (state, action) => {
  if (NETWORK_ERROR_MODAL_OPEN.is(action)) {
    return {
      error: {
        transition: action.data,
        retrying: false,
      },
    };
  }
  if (NETWORK_ERROR_MODAL_RETRY.is(action)) {
    return {
      error: {
        transition: action.data,
        retrying: true,
      },
    };
  }
  if (NETWORK_ERROR_MODAL_CLOSE.is(action)) {
    return {
      error: null,
    };
  }

  return state;
});
