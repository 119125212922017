import { SagaIterator } from 'redux-saga';
import { BaseError } from 'src/errors/BaseError';
import { cancelledErrorHandler } from 'src/routing/hooks/errorHandlers/cancelledErrorHandler';
import { networkErrorHandler } from 'src/routing/hooks/errorHandlers/networkErrorHandler';
import { unknownErrorHandler } from 'src/routing/hooks/errorHandlers/unknownErrorHandler';
import { Transition } from 'src/routing/types/Transition';
import { call } from 'typed-redux-saga';

export function* errorTransitionHandling(transition: Transition, error: BaseError): SagaIterator<void> {
  yield* call(cancelledErrorHandler, error);
  yield* call(networkErrorHandler, transition, error);
  yield* call(unknownErrorHandler, error);
}
