import './P2pTicketFormCalendar.scss';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { ActiveModifiers, DayPicker } from 'react-day-picker';
import { useSelector } from 'react-redux';
import { getDateLocale } from 'src/selectors/getDateLocale';
import { DateOnly } from 'src/types/DateOnly';
import { fromDate, toDate } from 'src/utils/dateOnly';

type Props = {
  readonly value: DateOnly | null;
  readonly onChange: (value: DateOnly) => void;
  readonly defaultDate: DateOnly;
  readonly navigation: 'narrow' | 'broad';
  readonly minDate?: DateOnly;
  readonly maxDate?: DateOnly;
};

export function P2pTicketFormCalendar({
  value,
  onChange,
  defaultDate,
  navigation,
  minDate,
  maxDate,
}: Props): React.ReactElement {
  const locale = useSelector(getDateLocale);

  const defaultMonth = useMemo(() => {
    const current = value ?? defaultDate;
    return toDate({ ...current, dd: 1 });
  }, [value, defaultDate]);

  const handleChange = useCallback((_: unknown, date: Date, modifiers: ActiveModifiers) => {
    if (modifiers.disabled) {
      return;
    }

    const nextValue = fromDate(date);
    onChange(nextValue);
  }, [onChange]);

  return (
    <DayPicker
      mode="single"
      className={classNames(
        'sts-ui-p2p-ticket-form-calendar',
        `sts-ui-p2p-ticket-form-calendar--${navigation}`,
      )}
      classNames={CLASS_NAMES}
      onSelect={handleChange}
      selected={value ? toDate(value) : undefined}
      fromDate={minDate ? toDate(minDate) : undefined}
      toDate={maxDate ? toDate(maxDate) : undefined}
      defaultMonth={defaultMonth}
      showOutsideDays={true}
      locale={locale}
      captionLayout={navigation === 'narrow' ? 'buttons' : 'dropdown-buttons'}
    />
  );
}

const CLASS_NAMES = {
  root: 'sts-ui-p2p-ticket-form-calendar__root',
  multiple_months: 'sts-ui-p2p-ticket-form-calendar__multiple_months',
  with_weeknumber: 'sts-ui-p2p-ticket-form-calendar__with_weeknumber',
  vhidden: 'sts-ui-p2p-ticket-form-calendar__vhidden',
  button_reset: 'sts-ui-p2p-ticket-form-calendar__button_reset',
  button: 'sts-ui-p2p-ticket-form-calendar__button',
  caption: 'sts-ui-p2p-ticket-form-calendar__caption',
  caption_start: 'sts-ui-p2p-ticket-form-calendar__caption_start',
  caption_end: 'sts-ui-p2p-ticket-form-calendar__caption_end',
  caption_between: 'sts-ui-p2p-ticket-form-calendar__caption_between',
  caption_label: 'sts-ui-p2p-ticket-form-calendar__caption_label',
  caption_dropdowns: 'sts-ui-p2p-ticket-form-calendar__caption_dropdowns',
  dropdown: 'sts-ui-p2p-ticket-form-calendar__dropdown',
  dropdown_month: 'sts-ui-p2p-ticket-form-calendar__dropdown_month',
  dropdown_year: 'sts-ui-p2p-ticket-form-calendar__dropdown_year',
  dropdown_icon: 'sts-ui-p2p-ticket-form-calendar__dropdown_icon',
  months: 'sts-ui-p2p-ticket-form-calendar__months',
  month: 'sts-ui-p2p-ticket-form-calendar__month',
  table: 'sts-ui-p2p-ticket-form-calendar__table',
  tbody: 'sts-ui-p2p-ticket-form-calendar__tbody',
  tfoot: 'sts-ui-p2p-ticket-form-calendar__tfoot',
  head: 'sts-ui-p2p-ticket-form-calendar__head',
  head_row: 'sts-ui-p2p-ticket-form-calendar__head_row',
  head_cell: 'sts-ui-p2p-ticket-form-calendar__head_cell',
  nav: 'sts-ui-p2p-ticket-form-calendar__nav',
  nav_button: 'sts-ui-p2p-ticket-form-calendar__nav_button',
  nav_button_previous: 'sts-ui-p2p-ticket-form-calendar__nav_button_previous',
  nav_button_next: 'sts-ui-p2p-ticket-form-calendar__nav_button_next',
  nav_icon: 'sts-ui-p2p-ticket-form-calendar__nav_icon',
  row: 'sts-ui-p2p-ticket-form-calendar__row',
  weeknumber: 'sts-ui-p2p-ticket-form-calendar__weeknumber',
  cell: 'sts-ui-p2p-ticket-form-calendar__cell',
  day: 'sts-ui-p2p-ticket-form-calendar__day',
  day_outside: 'sts-ui-p2p-ticket-form-calendar__day_outside',
  day_selected: 'sts-ui-p2p-ticket-form-calendar__day_selected',
  day_disabled: 'sts-ui-p2p-ticket-form-calendar__day_disabled',
  day_hidden: 'sts-ui-p2p-ticket-form-calendar__day_hidden',
  day_range_start: 'sts-ui-p2p-ticket-form-calendar__day_range_start',
  day_range_end: 'sts-ui-p2p-ticket-form-calendar__day_range_end',
  day_range_middle: 'sts-ui-p2p-ticket-form-calendar__day_range_middle',
  day_today: 'sts-ui-p2p-ticket-form-calendar__day_today',
};
