import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import { TARIFF_LIST } from 'src/constants/tariff';
import { FormError } from 'src/forms/types/FormError';
import { FormRule } from 'src/forms/types/FormRule';
import * as R from 'src/forms/utils/rules';
import { BasketProductDetails } from 'src/types/BasketProductDetails';
import { CheckoutPersonalFormData, CheckoutTravelerFormData } from 'src/types/CheckoutFormData';
import { CountryCode } from 'src/types/CountryCode';
import { CustomerTitle } from 'src/types/CustomerTitle';
import { ProductMap } from 'src/types/ProductMap';
import { TravelFields } from 'src/types/TravelFields';
import { Ord } from 'src/utils/dateOnly';
import { isNotNull } from 'src/utils/guard';

export function createCheckoutPersonalFormRule(
  basketProducts: ReadonlyArray<BasketProductDetails>,
  customerTitles: ReadonlyRecord<CustomerTitle, string>,
  customerCountries: ReadonlyRecord<CountryCode, string>,
  travelerCountries: ReadonlyArray<CountryCode>,
  travelerFields: ProductMap<TravelFields>,
): FormRule<CheckoutPersonalFormData> {
  return R.struct({
    billing: createCheckoutBillingFormRule(
      customerTitles,
      customerCountries,
    ),
    travelers: createCheckoutTravelersFormRule(
      travelerFields,
      basketProducts,
      customerCountries,
      travelerCountries,
    ),
  });
}

function createCheckoutBillingFormRule(
  customerTitles: ReadonlyRecord<CustomerTitle, string>,
  customerCountries: ReadonlyRecord<CountryCode, string>,
): FormRule<CheckoutPersonalFormData['billing']> {
  const CUSTOMER_TITLE_RULE = R.oneOf(Object.keys(customerTitles));
  const CUSTOMER_COUNTRY_RULE = R.oneOf(Object.keys(customerCountries));

  return R.struct({
    email: R.allOf([
      R.nonEmpty(),
      R.maxLength(30),
      R.email(),
    ]),
    title: R.allOf([
      R.required(),
      R.nullable(CUSTOMER_TITLE_RULE),
    ]),
    country: R.allOf([
      R.required(),
      R.nullable(CUSTOMER_COUNTRY_RULE),
    ]),

    firstName: R.allOf([
      R.nonEmpty(),
      R.maxLength(30),
      R.name(),
    ]),
    lastName: R.allOf([
      R.nonEmpty(),
      R.maxLength(30),
      R.name(),
    ]),
  });
}

function createCheckoutTravelersFormRule(
  travelerFields: ProductMap<TravelFields>,
  basketProducts: ReadonlyArray<BasketProductDetails>,
  customerCountries: ReadonlyRecord<CountryCode, string>,
  travelerCountries: ReadonlyArray<CountryCode>,
): FormRule<CheckoutPersonalFormData['travelers']> {
  const CUSTOMER_COUNTRY_RULE = R.oneOf(Object.keys(customerCountries));
  const TRAVELER_COUNTRY_RULE = R.oneOf(travelerCountries);

  return (travelers, context) => {
    const result: FormError[] = [];

    for (const product of basketProducts) {
      for (const tariff of TARIFF_LIST) {
        const count = product.form[tariff];
        if (!count) {
          continue;
        }

        const config = travelerFields[product.code]?.[tariff];
        if (!config) {
          continue;
        }

        const value = travelers[product.code]?.[tariff];
        if (!value) {
          result.push({
            path: context.path.concat(product.code, tariff).join('.'),
            code: 'required',
            value: value,

            message: `No travelers defined for "${product.code}"/${tariff}`,
            context: {},
          });
          continue;
        }

        const BIRTH_DATE_RULE = R.allOf([
          R.date(),

          config.birthDate?.constraints?.min
            ? R.gte(config.birthDate.constraints.min, Ord)
            : null,
          config.birthDate?.constraints?.max
            ? R.lte(config.birthDate.constraints.max, Ord)
            : null,
        ].filter(isNotNull));

        const TRAVELER_RULE = R.array(R.struct<CheckoutTravelerFormData>({
          tariff: R.pass(),
          product: R.pass(),
          position: R.pass(),

          firstName: config.firstName?.required
            ? R.allOf([
              R.nonEmpty(),
              R.maxLength(30),
              R.name(),
            ])
            : R.allOf([
              R.maxLength(30),
              R.name(),
            ]),
          lastName: config.lastName?.required
            ? R.allOf([
              R.nonEmpty(),
              R.maxLength(30),
              R.name(),
            ])
            : R.allOf([
              R.maxLength(30),
              R.name(),
            ]),
          birthDate: config.birthDate?.required
            ? R.allOf([
              R.required(),
              R.nullable(BIRTH_DATE_RULE),
            ])
            : R.nullable(BIRTH_DATE_RULE),

          homeCountryCode: config.homeCountryCode?.required
            ? R.allOf([
              R.required(),
              R.nullable(TRAVELER_COUNTRY_RULE),
            ])
            : R.nullable(TRAVELER_COUNTRY_RULE),
          homeFullCountryCode: config.homeFullCountryCode?.required
            ? R.allOf([
              R.required(),
              R.nullable(CUSTOMER_COUNTRY_RULE),
            ])
            : R.nullable(CUSTOMER_COUNTRY_RULE),
        }));

        const errors = TRAVELER_RULE(value, {
          ...context,
          path: context.path.concat(product.code, tariff),
        });
        for (const error of errors) {
          result.push(error);
        }
      }
    }

    return result;
  };
}
