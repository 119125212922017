import { pipe } from 'fp-ts/function';
import * as N from 'fp-ts/number';
import * as O from 'fp-ts/Ord';
import { DateOnly } from 'src/types/DateOnly';
import { Integer } from 'src/types/Integer';

export function fromDate(date: Date): DateOnly {
  return {
    yy: date.getFullYear(),
    mm: date.getMonth() + 1,
    dd: date.getDate(),
  };
}

export function toDate(date: DateOnly): Date {
  return new Date(date.yy, date.mm - 1, date.dd, 0, 0, 0, 0);
}

export function getDaysInMonth(yy: Integer, mm: Integer): Integer {
  // new Date(2024, 8, 0) == new Date(2024, 7, 31)
  return new Date(yy, mm, 0, 0, 0, 0, 0).getDate();
}

export function isValid(date: DateOnly): boolean {
  if (date.yy < 1000 || date.yy > 3000) {
    return false;
  }
  if (date.mm < 1 || date.mm > 12) {
    return false;
  }
  if (date.dd < 1 || date.dd > getDaysInMonth(date.yy, date.mm)) {
    return false;
  }

  return true;
}

export const Ord: O.Ord<DateOnly> = pipe(
  N.Ord,
  O.contramap((value) => value.yy * 1_00_00 + value.mm * 1_00 + value.dd),
);
