import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { CURRENCY } from 'src/codecs/Currency';
import { DATE_ONLY } from 'src/codecs/DateOnly';
import { LANGUAGE } from 'src/codecs/Language';
import { PRODUCT_CODE } from 'src/codecs/ProductCode';
import { TICKET_VALIDITY } from 'src/codecs/TicketValidity';
import { HOST_API } from 'src/constants/application';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { Currency } from 'src/types/Currency';
import { DateOnly } from 'src/types/DateOnly';
import { Language } from 'src/types/Language';
import { ProductCode } from 'src/types/ProductCode';
import { TicketValidity } from 'src/types/TicketValidity';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { call } from 'typed-redux-saga';

export function* fetchProductValidity(
  code: ProductCode,
  currency: Currency,
  language: Language,
  startDate: DateOnly | null,
): SagaIterator<TicketValidity> {
  if (startDate === null) {
    return NO_VALIDITY_DATA;
  }

  try {
    const request = yield* call(REQUEST.encode, {
      type: code,
      currency: currency,
      language: language,
      startDate: startDate,
    });
    const response = yield* call(makeGetRequest, `${HOST_API}/ticket-validity`, request);
    return yield* call(decodeOrThrow, TICKET_VALIDITY, response.content);
  } catch (error) {
    throw new RuntimeError('Could not fetch product validity', { code, currency, language, startDate }, NativeError.wrap(error));
  }
}

const REQUEST = E.struct({
  type: PRODUCT_CODE,
  currency: CURRENCY,
  language: LANGUAGE,
  startDate: DATE_ONLY,
});

const NO_VALIDITY_DATA: TicketValidity = {
  class: null,
  duration: null,
  cardType: null,
};
