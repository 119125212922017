import { SagaIterator } from 'redux-saga';
import { saveBasketStore } from 'src/sagas/basket/utils/storeBasketData';
import { getBasketContent } from 'src/selectors/getBasketContent';
import { getBasketConversationId } from 'src/selectors/getBasketConversationId';
import { getBasketCurrency } from 'src/selectors/getBasketCurrency';
import { call, select } from 'typed-redux-saga';

export function* saveBasketData(): SagaIterator<void> {
  const currency = yield* select(getBasketCurrency);
  const conversationId = yield* select(getBasketConversationId);
  const basket = yield* select(getBasketContent);

  yield* call(saveBasketStore, {
    currency: currency,
    conversationId: conversationId,

    products: basket.products,
    p2pTickets: basket.p2pTickets,
  });
}
