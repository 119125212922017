import { format } from 'date-fns/format';
import React from 'react';
import { useSelector } from 'react-redux';
import { FORMAT_TIME } from 'src/constants/format';
import { getDateLocale } from 'src/selectors/getDateLocale';
import { LocalTime } from 'src/types/LocalTime';

type Props = {
  readonly value: LocalTime;
};

export const FormattedTime = React.memo(({ value }: Props) => {
  const date = new Date(2000, 0, 1, value.hh, value.mm, value.ss);
  const locale = useSelector(getDateLocale);
  const formatted = format(date, FORMAT_TIME, { locale });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{formatted}</>;
});
