import { P2pTicketFormData } from 'src/types/P2pTicketFormData';
import { P2pTicketScreenData } from 'src/types/P2pTicketScreenData';
import { P2pTicketScreenType } from 'src/types/P2pTicketScreenType';
import { P2pTicketStation } from 'src/types/P2pTicketStation';
import { P2pTicketStationRequest } from 'src/types/P2pTicketStationRequest';
import { P2pTicketTripList } from 'src/types/P2pTicketTripList';
import { P2pTicketTripScrollDirection } from 'src/types/P2pTicketTripScroll';
import { P2pTicketTripSelection } from 'src/types/P2pTicketTripSelection';
import { createActions } from 'src/utils/createActions';
import { createTrigger } from 'src/utils/createTrigger';

export const P2P_TICKET_FETCH = createTrigger<P2pTicketScreenData>('P2P_TICKET_FETCH');
export const P2P_TICKET_RESET = createTrigger<void>('P2P_TICKET_RESET');

export const P2P_TICKET_RESET_SEARCH =
  createActions<void, P2pTicketScreenData>('P2P_TICKET_RESET_SEARCH');
export const P2P_TICKET_NAVIGATE_BACK =
  createActions<void, P2pTicketScreenData>('P2P_TICKET_NAVIGATE_BACK');
export const P2P_TICKET_NAVIGATE_TO =
  createActions<void, P2pTicketScreenData, P2pTicketScreenType>('P2P_TICKET_NAVIGATE_TO');

export const P2P_TICKET_STATION_SEARCH =
  createActions<void, ReadonlyArray<P2pTicketStation>, P2pTicketStationRequest>('P2P_TICKET_STATION_SEARCH');
export const P2P_TICKET_TRIP_CHANGE =
  createActions<P2pTicketFormData, P2pTicketScreenData>('P2P_TICKET_TRIP_CHANGE');
export const P2P_TICKET_TRIP_SCROLL =
  createActions<void, P2pTicketTripList, P2pTicketTripScrollDirection>('P2P_TICKET_TRIP_SCROLL');

export const P2P_TICKET_INITIAL_CHANGE =
  createActions<P2pTicketFormData, P2pTicketScreenData>('P2P_TICKET_INITIAL_CHANGE');
export const P2P_TICKET_INITIAL_SUBMIT =
  createActions<P2pTicketFormData, P2pTicketScreenData>('P2P_TICKET_INITIAL_SUBMIT');

export const P2P_TICKET_TRIP_OUTWARD_SUBMIT =
  createActions<P2pTicketTripSelection, P2pTicketScreenData>('P2P_TICKET_TRIP_OUTWARD_SUBMIT');
export const P2P_TICKET_TRIP_RETURN_SUBMIT =
  createActions<P2pTicketTripSelection, P2pTicketScreenData>('P2P_TICKET_TRIP_RETURN_SUBMIT');

export const P2P_TICKET_SUMMARY_CHANGE =
  createActions<P2pTicketFormData, P2pTicketScreenData>('P2P_TICKET_SUMMARY_CHANGE');
export const P2P_TICKET_SUMMARY_SUBMIT =
  createActions<P2pTicketFormData, void>('P2P_TICKET_SUMMARY_SUBMIT');
