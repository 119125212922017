import { pipe } from 'fp-ts/function';
import { or } from 'fp-ts/Refinement';
import * as O from 'monocle-ts/Optional';
import { Action } from 'redux';
import { P2P_TICKET_STATION_SEARCH } from 'src/actions/P2pTicketActions';
import { p2pTicketScreenGuard } from 'src/guards/p2pTicket';
import { P2P_TICKET_SCREEN } from 'src/optics/p2pTicket';
import { P2pTicketState } from 'src/types/P2pTicketState';
import { P2pTicketStationList } from 'src/types/P2pTicketStationList';

export function p2pTicketStationSearchReducer(state: P2pTicketState, action: Action): P2pTicketState {
  if (P2P_TICKET_STATION_SEARCH.isPending(action)) {
    return pipe(state, modifyLoading(true));
  }
  if (P2P_TICKET_STATION_SEARCH.isSuccess(action)) {
    return pipe(state, modifyStations({
      direction: action.meta.direction,
      query: action.meta.query,
      loading: false,
      stations: action.data,
    }));
  }
  if (P2P_TICKET_STATION_SEARCH.isFailure(action)) {
    return pipe(state, modifyStations({
      direction: action.meta.direction,
      query: action.meta.query,
      loading: false,
      stations: [],
    }));
  }

  return state;
}

const screenGuard = pipe(
  p2pTicketScreenGuard('initial'),
  or(p2pTicketScreenGuard('trip-outward')),
  or(p2pTicketScreenGuard('trip-return')),
);

function modifyLoading(loading: boolean): (state: P2pTicketState) => P2pTicketState {
  return pipe(
    P2P_TICKET_SCREEN,
    O.filter(screenGuard),
    O.prop('stations'),
    O.fromNullable,
    O.prop('loading'),
    O.modify(() => loading),
  );
}

function modifyStations(stations: P2pTicketStationList | null): (state: P2pTicketState) => P2pTicketState {
  return pipe(
    P2P_TICKET_SCREEN,
    O.filter(screenGuard),
    O.prop('stations'),
    O.modify(() => stations),
  );
}
