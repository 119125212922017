import './P2pTicketFormSelect.scss';
import classNames from 'classnames';
import React, { useCallback, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactSelect, { MenuProps, SelectInstance, components } from 'react-select';
import { P2pTicketFormDropdownAnimate } from 'src/components/p2p/P2pTicketFormDropdownAnimate';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { ListOption } from 'src/types/ListOption';

type Option<TValue> = {
  readonly value: TValue;
  readonly label: string;
};

type Props<TValue> = {
  readonly id: string;
  readonly name: string;
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly value: TValue | null;
  readonly options: ReadonlyArray<ListOption<TValue>>;
  readonly renderOption?: (option: ListOption<TValue>) => React.ReactNode;
  readonly onBlur?: () => void;
  readonly onChange: (value: TValue | null) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly placeholder?: React.ReactNode;
};

export function P2pTicketFormSelect<TValue>({
  id,
  name,
  layout,
  border,
  label,
  value,
  options,
  renderOption,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  placeholder,
}: Props<TValue>): React.ReactElement {
  const handleChange = useCallback((option: Option<TValue> | null) => {
    onChange(option?.value ?? null);
  }, [onChange]);

  const selectValue = useMemo((): Option<TValue> | null => {
    const option = options.find((it) => it.value === value);
    return option ? { value: option.value, label: option.title } : null;
  }, [value, options]);
  const selectOptions = useMemo((): ReadonlyArray<Option<TValue>> => options.map((it) => ({
    value: it.value,
    label: it.title,
  })), [options]);

  const noOptionsMessage = useCallback(() => (
    <FormattedMessage id="FormElements.Select.SelectValueFromList"/>
  ), []);

  const formatOptionLabel = useCallback((option: Option<TValue>) => {
    return renderOption?.({ value: option.value, title: option.label });
  }, [renderOption]);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  const extractRef = useCallback((select: SelectInstance<Option<TValue>, false, never> | null) => {
    // @ts-expect-error React threads the reference as readonly
    inputRef.current = select?.inputRef ?? null;
  }, []);

  return (
    <div
      data-invalid={invalid}
      data-disabled={disabled}
      className={classNames(
        'sts-ui-p2p-ticket-form-select',
        `sts-ui-p2p-ticket-form-select--layout-${layout}`,
        `sts-ui-p2p-ticket-form-select--border-${border}`,
      )}
    >
      <label htmlFor={id} className="sts-ui-p2p-ticket-form-select__label">
        {label}
      </label>
      <div className="sts-ui-p2p-ticket-form-select__input">
        <ReactSelect<Option<TValue>, false, never>
          inputId={id}
          ref={extractRef}
          name={name}
          className="sts-ui-p2p-ticket-form-select-input"
          classNamePrefix="sts-ui-p2p-ticket-form-select-input"
          value={selectValue}
          options={selectOptions}
          getOptionLabel={getOptionLabel}
          formatOptionLabel={renderOption ? formatOptionLabel : undefined}
          onBlur={onBlur}
          onChange={handleChange}
          isMulti={false}
          isClearable={false}
          isDisabled={disabled}
          isSearchable={false}
          menuPosition="absolute"
          openMenuOnFocus={true}
          backspaceRemovesValue={true}
          noOptionsMessage={noOptionsMessage}
          closeMenuOnScroll={true}
          closeMenuOnSelect={true}
          blurInputOnSelect={false}
          placeholder={placeholder ?? <FormattedMessage id="FormElements.Select.SelectPlaceholder"/>}
          components={{ Menu }}
        />
      </div>
    </div>
  );
}

function getOptionLabel<T>(option: Option<T>): string {
  return option.label;
}

function Menu<TValue>(props: MenuProps<TValue, false, never>): React.ReactElement {
  const { innerRef } = props;

  return (
    <P2pTicketFormDropdownAnimate show={true} content={innerRef}>
      <components.Menu<TValue, false, never> {...props}/>
    </P2pTicketFormDropdownAnimate>
  );
}
