import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { BASKET_PRODUCT } from 'src/codecs/BasketProduct';
import { ProductMap } from 'src/codecs/combinators/ProductMap';
import { P2P_TICKET_PRODUCT } from 'src/codecs/P2pTicketProduct';
import { BasketContent } from 'src/types/BasketContent';

export const BASKET_CONTENT: C.Codec<unknown, JsonRecord, BasketContent> = C.struct({
  products: ProductMap(BASKET_PRODUCT),
  p2pTickets: C.readonly(C.array(P2P_TICKET_PRODUCT)),
});
