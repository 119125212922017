import { FormRule } from 'src/forms/types/FormRule';
import * as R from 'src/forms/utils/rules';
import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketTripItem } from 'src/types/P2pTicketTripItem';
import { getTravelerMaxBirthDate, getTravelerMinBirthDate } from 'src/utils/p2p/getTravelerDetails';

export function createTravelerFormRule(
  trips: ReadonlyArray<P2pTicketTripItem>,
  traveler: P2pTicketFormDataTraveler,
): FormRule<P2pTicketFormDataTraveler> {
  const birthDateRule = R.allOf([
    R.gte(getTravelerMinBirthDate(trips, traveler)),
    R.lte(getTravelerMaxBirthDate(trips, traveler)),
  ]);

  return R.struct<P2pTicketFormDataTraveler>({
    id: R.pass(),
    ageGroup: R.pass(),
    discount: R.pass(),

    firstName: R.allOf([
      R.nonEmpty(),
      R.name(),
    ]),
    lastName: R.allOf([
      R.nonEmpty(),
      R.name(),
    ]),
    birthDate: R.allOf([
      R.required(),
      R.nullable(birthDateRule),
    ]),
  });
}
