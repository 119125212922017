import { FieldPath } from '@form-ts/core';
import { useFormField, useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import React, { useCallback } from 'react';
import { P2pTicketFormSelect } from 'src/components/p2p/P2pTicketFormSelect';
import { FormError } from 'src/forms/types/FormError';
import { ListOption } from 'src/types/ListOption';

type Props<TData, TValue> = {
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly field: FieldPath<TData, FormError, TValue | null>;
  readonly options: ReadonlyArray<ListOption<TValue>>;
  readonly renderOption?: (option: ListOption<TValue>) => React.ReactNode;
  readonly disabled?: boolean;
  readonly placeholder?: React.ReactNode;
  readonly formatError?: (error: FormError) => string;
};

export function P2pTicketFormSelectField<TData, TValue>({
  layout,
  border,
  label,
  field,
  options,
  renderOption,
  disabled,
  placeholder,
  formatError,
}: Props<TData, TValue>): React.ReactElement {
  const { meta, value, error } = useFormField(field);

  const submitted = useFormWatch(field.form, field.form.submitted.get);
  const invalid = error !== undefined && (meta.touched || submitted);
  const validity = error !== undefined && formatError !== undefined ? formatError(error) : undefined;

  const handleChange = useCallback((nextValue: TValue | null) => {
    field.form.change(pipe(
      field.form.currentState,
      field.value.set(nextValue),
    ));
  }, [field]);

  const handleBlur = useCallback(() => {
    field.form.change(pipe(
      field.form.currentState,
      field.touched.set(true),
    ));
  }, [field]);

  return (
    <P2pTicketFormSelect<TValue>
      id={`${field.form.name}.${field.path}`}
      name={field.path}
      layout={layout}
      border={border}
      label={label}
      value={value}
      options={options}
      renderOption={renderOption}
      onBlur={handleBlur}
      onChange={handleChange}
      invalid={invalid}
      validity={validity}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
}
