import { SagaIterator } from 'redux-saga';
import { delay as delayEffect, take as takeEffect } from 'redux-saga/effects';
import {
  NETWORK_ERROR_MODAL_OPEN,
  NETWORK_ERROR_MODAL_RETRY,
} from 'src/network/actions/NetworkActions';
import { checkNetworkConnection } from 'src/network/utils/checkNetworkConnection';
import { Transition } from 'src/routing/types/Transition';
import { call, put, race, take } from 'typed-redux-saga';

export function* networkErrorWatchSaga(
  transition: Transition,
): SagaIterator<void> {
  while (true) {
    const { timeout } = yield* race({
      timeout: delayEffect(10_000),
      retried: takeEffect(NETWORK_ERROR_MODAL_RETRY.is),
    });
    if (!timeout) {
      yield* take(NETWORK_ERROR_MODAL_OPEN.is);
      continue;
    }

    const connected = yield* call(checkNetworkConnection);
    if (connected) {
      yield* put(NETWORK_ERROR_MODAL_RETRY.trigger(transition));
      yield* take(NETWORK_ERROR_MODAL_OPEN.is);
    }
  }
}
