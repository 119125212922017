import { Eq } from 'fp-ts/Eq';
import { isEmpty } from 'fp-ts/ReadonlyRecord';
import * as E from 'io-ts/Eq';
import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_TRIP_CHANGE } from 'src/actions/P2pTicketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { validate } from 'src/forms/utils/validate';
import { getCurrentLanguage } from 'src/routing/selectors/getCurrentLanguage';
import { createP2pTicketScreenUid } from 'src/sagas/p2p/utils/createP2pTicketScreenUid';
import { createOutwardTripInfo, createReturnTripInfo } from 'src/sagas/p2p/utils/createTripInfo';
import { fetchP2pTripList } from 'src/sagas/p2p/utils/fetchP2pTripList';
import { saveP2pTicketData } from 'src/sagas/p2p/utils/saveP2pTicketData';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getP2pTicketConversationId } from 'src/selectors/getP2pTicketConversationId';
import { getP2pTicketTripScreen } from 'src/selectors/getP2pTicketTripScreen';
import { P2pTicketFormData, P2pTicketFormDataTraveler, P2pTicketFormDataTrip } from 'src/types/P2pTicketFormData';
import { P2pTicketScreenData } from 'src/types/P2pTicketScreenData';
import { P2pTicketStation } from 'src/types/P2pTicketStation';
import { GetRequestActionType } from 'src/utils/createActions';
import { Ord as DateOnlyOrd } from 'src/utils/dateOnly';
import { Ord as DateTimeOrd } from 'src/utils/localDateTime';
import { createInitialFormRule } from 'src/utils/p2p/createInitialFormRule';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* p2pTicketTripChangeSaga(
  action: GetRequestActionType<typeof P2P_TICKET_TRIP_CHANGE>,
): SagaIterator<void> {
  const formData = action.data;

  const formRule = yield* call(createInitialFormRule);
  const formErrors = yield* call(validate, formData, formRule);
  if (!isEmpty(formErrors)) {
    return;
  }

  try {
    yield* call(logDebug, 'Changing P2P Ticket trip…', formData);
    yield* put(P2P_TICKET_TRIP_CHANGE.pending());
    yield* delay(500);

    const currentScreen = yield* select(getP2pTicketTripScreen);
    const targetScreen = getTargetScreen(formData, currentScreen.form);

    const language = yield* select(getCurrentLanguage);
    const conversationId = yield* select(getP2pTicketConversationId);

    let updatedScreen: P2pTicketScreenData;
    if (currentScreen.type === 'trip-return' && targetScreen === 'return') {
      const tripInfo = yield* call(createReturnTripInfo, formData);
      const tripList = yield* call(
        fetchP2pTripList,
        language,
        formData.travelers,
        conversationId,
        tripInfo,
      );

      updatedScreen = {
        uid: yield* call(createP2pTicketScreenUid),
        type: 'trip-return',
        form: formData,

        outward: currentScreen.outward,
        return: null,

        tripInfo: tripInfo,
        tripList: tripList,

        minDate: currentScreen.minDate,
        stations: null,
        scrolling: null,

        action: null,
      };
    } else {
      const tripInfo = yield* call(createOutwardTripInfo, formData);
      const tripList = yield* call(
        fetchP2pTripList,
        language,
        formData.travelers,
        conversationId,
        tripInfo,
      );

      updatedScreen = {
        uid: yield* call(createP2pTicketScreenUid),
        type: 'trip-outward',
        form: formData,

        outward: null,
        return: null,

        tripInfo: tripInfo,
        tripList: tripList,

        minDate: currentScreen.minDate,
        stations: null,
        scrolling: null,

        action: null,
      };
    }

    yield* call(saveP2pTicketData, updatedScreen);

    yield* call(logDebug, 'Changing P2P Ticket trip… done', updatedScreen);
    yield* put(P2P_TICKET_TRIP_CHANGE.success(updatedScreen));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not change P2P Ticket trip',
      { formData },
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Changing P2P Ticket trip… error', error);
    yield* put(P2P_TICKET_TRIP_CHANGE.failure(NativeError.wrap(error)));
  }
}

function getTargetScreen(
  nextFormData: P2pTicketFormData,
  prevFormData: P2pTicketFormData,
): 'outward' | 'return' {
  const returnDateChangedOnly = (
    !E.nullable(DateTimeOrd).equals(nextFormData.trip.returnDate, prevFormData.trip.returnDate) &&
    EQ_FORM_DATA_NO_RETURN.equals(nextFormData, prevFormData)
  );
  return returnDateChangedOnly
    ? 'return'
    : 'outward';
}

const EQ_FORM_TRAVELER: Eq<P2pTicketFormDataTraveler> = E.struct({
  id: E.string,
  ageGroup: E.string,
  discount: E.string,
  firstName: E.string,
  lastName: E.string,
  birthDate: E.nullable(DateOnlyOrd),
});

const EQ_FORM_STATION: Eq<P2pTicketStation> = E.struct({
  id: E.string,
  name: E.string,
});

const EQ_FORM_TRIP_NO_RETURN: Eq<P2pTicketFormDataTrip> = E.struct({
  from: E.nullable(EQ_FORM_STATION),
  to: E.nullable(EQ_FORM_STATION),
  oneWay: E.boolean,
  departDate: E.nullable(DateTimeOrd),
  // returnDate: E.nullable(DateEq),
});

const EQ_FORM_DATA_NO_RETURN: Eq<P2pTicketFormData> = E.struct({
  trip: EQ_FORM_TRIP_NO_RETURN,
  travelers: E.readonly(E.array(EQ_FORM_TRAVELER)),
});
