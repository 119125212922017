import './P2pTicketScreenCommonTripVehicleType.scss';
import { ReadonlyRecord } from 'fp-ts/ReadonlyRecord';
import React from 'react';
import Sprite from 'src/assets/sprite/vehicle-type/sprite.svg';
import { P2pTicketTransportMode } from 'src/types/P2pTicketTransportMode';
import { P2pTicketTripLegServiceMode } from 'src/types/P2pTicketTripItem';

type Props = {
  readonly mode: P2pTicketTripLegServiceMode;
};

export function P2pTicketScreenCommonTripVehicleType({ mode }: Props): React.ReactElement {
  const spriteIcon = NAME_ICON_MAP[mode.shortName] ?? MODE_ICON_MAP[mode.ptMode];

  return (
    <svg
      className="sts-ui-p2p-ticket-screen-common-trip-vehicle-type"
      viewBox="0 0 24 24"
    >
      <use href={`${Sprite}#${encodeURIComponent(spriteIcon)}`}/>
    </svg>
  );
}

const NAME_ICON_MAP: Partial<ReadonlyRecord<string, string>> = {
  ASC: 'Lift',
  FUN: 'Funicular',
  GB: 'Gondola-lift',
  PB: 'Cableway',
  SL: 'Chair-lift',
};

const MODE_ICON_MAP: ReadonlyRecord<P2pTicketTransportMode, string> = {
  HIGH_SPEED_TRAIN: 'Train',
  HISTORIC_TRAIN: 'Train',
  INTERCITY: 'Train',
  REGIONAL: 'Train',
  INTERREGIONAL: 'Train',
  URBAN: 'Train',
  TRAIN: 'Train',
  TRAM: 'Tram',
  UNDERGROUND: 'Metro',
  NIGHT_TRAIN: 'Train',
  SHARED_TAXI: 'Taxi',
  MOTOR_RAIL: 'Train',
  MOUNTAIN_TRAIN: 'Funicular',
  PLANE: 'Aeroplane',
  COACH_GROUP: 'Train',
  SHIP: 'Jetty',
  BUS: 'Bus',
};
