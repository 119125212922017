// NOTE: the storage versions don't respect SemVer
// NOTE: the version 13.0.0 is just to keep compatibility with the first release

export const STORAGE_AFFILIATE_KEY = 'sts.affiliate';
export const STORAGE_AFFILIATE_VERSION = '13.0.0';

export const STORAGE_P2P_KEY = 'sts.p2p';
export const STORAGE_P2P_VERSION = '13.0.0';

export const STORAGE_BASKET_KEY = 'sts.basket';
export const STORAGE_BASKET_VERSION = '13.0.0';

export const STORAGE_CHECKOUT_KEY = 'sts.checkout';
export const STORAGE_CHECKOUT_VERSION = '13.0.0';

export const STORAGE_PAYMENT_KEY = 'sts.payment';
export const STORAGE_PAYMENT_VERSION = '13.0.0';
