import { createSelector } from 'reselect';
import { getBasketConversationId } from 'src/selectors/getBasketConversationId';
import { assertNotNull } from 'src/utils/assert';

export const getP2pTicketConversationId = createSelector([
  getBasketConversationId,
], (conversationId) => assertNotNull(
  conversationId,
  'Basket conversation ID is not initialized',
));
