import './BasketP2pTicketView.scss';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { FormattedTime } from 'src/components/common/FormattedTime';
import { P2pTicketName } from 'src/components/common/P2pTicketName';
import { Typography } from 'src/components/common/Typography';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { RemoveIcon } from 'src/components/icons/RemoveIcon';
import { BasketProductListItemP2pTicket } from 'src/types/BasketProductListItem';
import { Currency } from 'src/types/Currency';
import { bind } from 'src/utils/bind';
import { offerZones } from 'src/utils/product/offerZones';

type Props = {
  readonly currency: Currency;
  readonly product: BasketProductListItemP2pTicket;
  readonly onRemove: (product: BasketProductListItemP2pTicket) => void;
  readonly removing: boolean;
};

export class BasketP2pTicketView extends React.Component<Props> {
  public render(): React.ReactElement {
    const { product, removing, currency } = this.props;
    const zones = product.ticket.offers[0]?.offer && offerZones(product.ticket.offers[0]?.offer);

    return (
      <div className={classNames(
        'sts-ui-basket-p2p-ticket-view',
        product.invalid ? 'sts-ui-basket-p2p-ticket-view--invalid' : null,
      )}
      >
        <div className="sts-ui-basket-p2p-ticket-view__title">
          <span className="sts-ui-basket-p2p-ticket-view__direction">
            <P2pTicketName
              from={product.ticket.from}
              to={product.ticket.to}
            />
          </span>

          {product.invalid && (
            <span className="sts-ui-basket-p2p-ticket-view__alert">
              <FormattedMessage id="Basket.P2P.Expired"/>
            </span>
          )}
        </div>

        <div className="sts-ui-basket-p2p-ticket-view__details">
          <div className="sts-ui-basket-p2p-ticket-view__details-line">
            <div className="sts-ui-basket-p2p-ticket-view__details-line-label">
              <FormattedMessage id="Basket.P2P.Date"/>
            </div>
            <div className="sts-ui-basket-p2p-ticket-view__details-line-value">
              <FormattedDate value={product.ticket.date.date}/>
            </div>
          </div>
          <div className="sts-ui-basket-p2p-ticket-view__details-line">
            <div className="sts-ui-basket-p2p-ticket-view__details-line-label">
              <FormattedMessage id="Basket.P2P.Time"/>
            </div>
            <div className="sts-ui-basket-p2p-ticket-view__details-line-value">
              <FormattedTime value={product.ticket.date.time}/>
            </div>
          </div>
          <div className="sts-ui-basket-p2p-ticket-view__details-line">
            <div className="sts-ui-basket-p2p-ticket-view__details-line-label">
              <FormattedMessage id="Basket.P2P.Class"/>
            </div>
            <div className="sts-ui-basket-p2p-ticket-view__details-line-value">
              <FormattedMessage
                id="P2P.Common.ClassName"
                values={{ class: product.ticket.class }}
              />
            </div>
          </div>
          {zones && (
            <div className="sts-ui-basket-p2p-ticket-view__details-line">
              <div className="sts-ui-basket-p2p-ticket-view__details-line-label">
                <FormattedMessage id="Basket.P2P.Zones"/>
              </div>
              <div className="sts-ui-basket-p2p-ticket-view__details-line-value">
                {zones}
              </div>
            </div>
          )}
          <div className="sts-ui-basket-p2p-ticket-view__details-line">
            <div className="sts-ui-basket-p2p-ticket-view__details-line-label">
              <FormattedMessage id="Basket.P2P.Passengers"/>
            </div>
            <div className="sts-ui-basket-p2p-ticket-view__details-line-value">
              <FormattedMessage
                id="P2P.Common.Passengers"
                values={{
                  adult: product.ticket.offers.filter((it) => it.traveler.ageGroup === 'adult').length,
                  child: product.ticket.offers.filter((it) => it.traveler.ageGroup === 'child').length,
                }}
              />
            </div>
          </div>
        </div>

        <div className="sts-ui-basket-p2p-ticket-view__traveler-list">
          {product.ticket.offers.map((offer) => (
            <div key={offer.traveler.id} className="sts-ui-basket-p2p-ticket-view__traveler-item">
              <div className="sts-ui-basket-p2p-ticket-view__traveler-details">
                <div className="sts-ui-basket-p2p-ticket-view__traveler-name">
                  <FormattedMessage
                    id="Basket.P2P.Traveler"
                    values={{
                      firstName: offer.traveler.firstName,
                      lastName: offer.traveler.lastName,
                      birthDate: (
                        <span className="sts-ui-basket-p2p-ticket-view__traveler-date">
                          <FormattedDate value={offer.traveler.birthDate}/>
                        </span>
                      ),
                    }}
                  />
                </div>
                <div className="sts-ui-basket-p2p-ticket-view__traveler-discount">
                  <FormattedMessage
                    id="P2P.Common.Discount"
                    values={{ discount: offer.traveler.discount.replace(/-/g, '_') }}
                  />
                </div>
              </div>
              <div className="sts-ui-basket-p2p-ticket-view__traveler-price">
                <FormattedPrice value={offer.offer.prices[currency]}/>
              </div>
            </div>
          ))}
        </div>

        <div className="sts-ui-basket-p2p-ticket-view__info">
          <div className="sts-ui-basket-p2p-ticket-view__info-text">
            {this.renderValidity()}
            <Typography as="p" variant="text-secondary">
              <FormattedMessage id="Basket.P2P.InfoBlock"/>
            </Typography>
          </div>
        </div>

        <div className="sts-ui-basket-p2p-ticket-view__price">
          <div className="sts-ui-basket-p2p-ticket-view__price-label">
            <FormattedMessage id="Basket.Price"/>
          </div>
          <div className="sts-ui-basket-p2p-ticket-view__price-value">
            <FormattedPrice value={product.totalPrice}/>
          </div>
        </div>

        <div className="sts-ui-basket-p2p-ticket-view__actions">
          <button
            type="button"
            onClick={this.handleRemoveProduct}
            disabled={removing}
            className="sts-ui-ghost-button"
          >
            {removing
              ? <LoadingIcon/>
              : <RemoveIcon/>}
            <span><FormattedMessage id="Basket.RemoveTicket"/></span>
          </button>
        </div>
      </div>
    );
  }

  private renderValidity(): React.ReactElement | null {
    const { product } = this.props;

    const validity = product.ticket.offers[0]?.offer.ticketValidity;
    if (!validity) {
      return null;
    }

    const from = validity.from.time
      ? <><FormattedDate value={validity.from.date}/> <FormattedTime value={validity.from.time}/></>
      : <FormattedDate value={validity.from.date}/>;
    const to = validity.to.time
      ? <><FormattedDate value={validity.to.date}/> <FormattedTime value={validity.to.time}/></>
      : <FormattedDate value={validity.to.date}/>;

    return (
      <FormattedMessage
        id="Basket.P2P.Validity"
        values={{ from, to }}
      />
    );
  }

  @bind
  private handleRemoveProduct(): void {
    const { product, onRemove } = this.props;
    onRemove(product);
  }
}
