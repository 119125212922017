import { pipe } from 'fp-ts/function';
import * as N from 'fp-ts/number';
import * as O from 'fp-ts/Ord';
import { LocalTime } from 'src/types/LocalTime';

export const Ord: O.Ord<LocalTime> = pipe(
  N.Ord,
  O.contramap((value) => value.hh * 1_00_00 + value.mm * 1_00 + value.ss),
);

export function isValid(time: LocalTime): boolean {
  if (time.hh < 0 || time.hh > 23) {
    return false;
  }
  if (time.mm < 0 || time.mm > 59) {
    return false;
  }
  if (time.ss < 0 || time.ss > 59) {
    return false;
  }

  return true;
}
