import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_TRIP_LIST_SIZE } from 'src/constants/p2pTicket';
import { fetchP2pTripCollection } from 'src/sagas/p2p/utils/fetchP2pTripCollection';
import { Language } from 'src/types/Language';
import { P2pTicketTripFrame } from 'src/types/P2pTicketTripFrame';
import { P2pTicketTripInfo } from 'src/types/P2pTicketTripInfo';
import { concatTripCollection } from 'src/utils/p2p/tripCollection';
import { call } from 'typed-redux-saga';

export function* fetchP2pTripFrame(
  language: Language,
  tripInfo: P2pTicketTripInfo,
): SagaIterator<P2pTicketTripFrame> {
  let currCollection = yield* call(fetchP2pTripCollection, language, tripInfo, null);
  while (currCollection.trips.length < P2P_TICKET_TRIP_LIST_SIZE) {
    const nextContext = currCollection.nextTripsCollectionId;
    if (nextContext === null) {
      break;
    }

    const nextCollection = yield* call(fetchP2pTripCollection, language, tripInfo, {
      context: nextContext,
      direction: 'next',
    });

    currCollection = yield* call(
      concatTripCollection,
      currCollection,
      nextCollection,
    );
  }

  return {
    top: 0,
    bottom: Math.min(currCollection.trips.length, P2P_TICKET_TRIP_LIST_SIZE),
    collection: currCollection,
  } satisfies P2pTicketTripFrame;
}
