import './ProductTeaserList.scss';
import React from 'react';
import { ProductTeaserCrossProduct } from 'src/components/product/ProductTeaserCrossProduct';
import { ProductTeaserP2pTicket } from 'src/components/product/ProductTeaserP2pTicket';
import { ProductTeaserValidityMap } from 'src/components/product/ProductTeaserValidityMap';
import { ProductAttibutesTeasers } from 'src/types/ProductAttibutes';

type Props = {
  readonly teasers: ProductAttibutesTeasers;
};

export function ProductTeaserList({ teasers }: Props): React.ReactElement {
  return (
    <div className="sts-ui-product-teaser-list">
      {teasers.p2pTicket && (
        <div className="sts-ui-product-teaser-list__item">
          <ProductTeaserP2pTicket p2pTicket={teasers.p2pTicket}/>
        </div>
      )}
      {teasers.crossProduct && (
        <div className="sts-ui-product-teaser-list__item">
          <ProductTeaserCrossProduct crossProduct={teasers.crossProduct}/>
        </div>
      )}
      {teasers.validityMap && (
        <div className="sts-ui-product-teaser-list__item">
          <ProductTeaserValidityMap validityMap={teasers.validityMap}/>
        </div>
      )}
    </div>
  );
}
