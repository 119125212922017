import './P2pTicketScreenSummaryTravelersForm.scss';
import { FieldPath, FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { Typography } from 'src/components/common/Typography';
import { P2pTicketFormDatePickerField } from 'src/components/p2p/P2pTicketFormDatePickerField';
import { P2pTicketFormTextboxField } from 'src/components/p2p/P2pTicketFormTextboxField';
import { FormError } from 'src/forms/types/FormError';
import { Currency } from 'src/types/Currency';
import { P2pTicketFormData, P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketScreenDataSummary } from 'src/types/P2pTicketScreenData';
import { getSummaryTicketPrice, getSummaryTrips } from 'src/utils/p2p/getSummaryDetails';
import { getTravelerMaxBirthDate, getTravelerMinBirthDate } from 'src/utils/p2p/getTravelerDetails';

type Props = {
  readonly form: FormController<P2pTicketFormData, FormError>;
  readonly screen: P2pTicketScreenDataSummary;
  readonly currency: Currency;
};

export function P2pTicketScreenSummaryTravelersForm({
  form,
  screen,
  currency,
}: Props): React.ReactElement {
  const travelers = useFormWatch(form, form.field.at('travelers').value.get);
  const submitting = useFormWatch(form, form.submitting.get);

  return (
    <div className="sts-ui-p2p-ticket-screen-summary-travelers-form">
      <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__header">
        <Typography as="p" variant="block-title">
          <FormattedMessage id="P2P.Summary.Travelers"/>
        </Typography>
      </div>

      <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__content">
        {travelers.map((traveler, index) => (
          <div key={traveler.id} className="sts-ui-p2p-ticket-screen-summary-travelers-form__traveler">
            <P2pTicketScreenSummaryTravelersFormRow
              screen={screen}
              field={form.field.at('travelers').at(index)}
              disabled={submitting}
              currency={currency}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

type TravelerProps = {
  readonly screen: P2pTicketScreenDataSummary;
  readonly field: FieldPath<P2pTicketFormData, FormError, P2pTicketFormDataTraveler>;
  readonly currency: Currency;
  readonly disabled: boolean;
};

function P2pTicketScreenSummaryTravelersFormRow({
  screen,
  field,
  currency,
  disabled,
}: TravelerProps): React.ReactElement {
  const intl = useIntl();
  const traveler = useFormWatch(field.form, field.value.get);
  const trips = useMemo(() => getSummaryTrips(screen), [screen]);

  const minBirthDate = useMemo(() => getTravelerMinBirthDate(trips, traveler), [trips, traveler]);
  const maxBirthDate = useMemo(() => getTravelerMaxBirthDate(trips, traveler), [trips, traveler]);

  return (
    <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__line">
      <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__line-head">
        <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__title">
          <span className="sts-ui-p2p-ticket-screen-summary-travelers-form__age">
            <FormattedMessage
              id="P2P.Common.AgeGroup"
              values={{ ageGroup: traveler.ageGroup }}
            />
          </span>
          <span className="sts-ui-p2p-ticket-screen-summary-travelers-form__discount">
            <FormattedMessage
              id="P2P.Common.Discount"
              values={{ discount: traveler.discount.replace(/-/g, '_') }}
            />
          </span>
        </div>
      </div>
      <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__line-form">
        <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__field">
          <P2pTicketFormTextboxField
            layout="horizontal"
            border="thin"
            type="text"
            label={null}
            field={field.at('firstName')}
            disabled={disabled}
            placeholder={intl.formatMessage({ id: 'P2P.Form.Traveler.FirstName' })}
          />
        </div>
        <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__field">
          <P2pTicketFormTextboxField
            layout="horizontal"
            border="thin"
            type="text"
            label={null}
            field={field.at('lastName')}
            disabled={disabled}
            placeholder={intl.formatMessage({ id: 'P2P.Form.Traveler.LastName' })}
          />
        </div>
        <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__field">
          <P2pTicketFormDatePickerField
            layout="horizontal"
            border="thin"
            navigation="broad"
            defaultDate={maxBirthDate}
            label={null}
            field={field.at('birthDate')}
            min={minBirthDate}
            max={maxBirthDate}
            disabled={disabled}
            placeholder={intl.formatMessage({ id: 'P2P.Form.Traveler.BirthDate' })}
          />
        </div>
        <div className="sts-ui-p2p-ticket-screen-summary-travelers-form__price">
          <FormattedPrice value={getSummaryTicketPrice(screen, traveler, currency)}/>
        </div>
      </div>
    </div>
  );
}
