import './P2pTicketScreenSummary.scss';
import { useForm, useFormWatch } from '@form-ts/react';
import { isEmpty } from 'fp-ts/ReadonlyRecord';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { FormattedPrice } from 'src/components/common/FormattedPrice';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { ChevronLeftIcon } from 'src/components/icons/ChevronLeftIcon';
import { EditIcon } from 'src/components/icons/EditIcon';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { P2pTicketScreenCommonNavigation } from 'src/components/p2p/P2pTicketScreenCommonNavigation';
import { P2pTicketScreenSummaryTravelersForm } from 'src/components/p2p/P2pTicketScreenSummaryTravelersForm';
import { P2pTicketScreenSummaryTrip } from 'src/components/p2p/P2pTicketScreenSummaryTrip';
import { useFormValidator } from 'src/forms/hooks/useFormValidator';
import { FormError } from 'src/forms/types/FormError';
import { FormRule } from 'src/forms/types/FormRule';
import { validate } from 'src/forms/utils/validate';
import { Currency } from 'src/types/Currency';
import { P2pTicketFormData } from 'src/types/P2pTicketFormData';
import { P2pTicketScreenAction } from 'src/types/P2pTicketScreenAction';
import { P2pTicketScreenDataSummary } from 'src/types/P2pTicketScreenData';
import { P2pTicketScreenType } from 'src/types/P2pTicketScreenType';
import { getSummaryTotalPrice } from 'src/utils/p2p/getSummaryDetails';

type Props = {
  readonly currency: Currency;
  readonly screen: P2pTicketScreenDataSummary;
  readonly formRule: FormRule<P2pTicketFormData>;
  readonly onReset: () => void;
  readonly onGoBack: () => void;
  readonly onNavigate: (screen: P2pTicketScreenType) => void;
  readonly onChange: (formData: P2pTicketFormData) => void;
  readonly onSubmit: (formData: P2pTicketFormData) => void;
};

export function P2pTicketScreenSummary({
  currency,
  screen,
  formRule,
  onReset,
  onGoBack,
  onNavigate,
  onChange,
  onSubmit,
}: Props): React.ReactElement {
  const initialValues = screen.form;
  const initialErrors = useMemo(() => validate(initialValues, formRule), [initialValues, formRule]);

  const form = useForm<P2pTicketFormData, FormError>('p2p.summary.form', {
    reinitialize: false,
    initialValues: initialValues,
    initialErrors: initialErrors,
  });
  useFormValidator(form, formRule);

  const formErrors = useFormWatch(form, form.errors.get);
  const resetting = screen.action === P2pTicketScreenAction.RESET_SEARCH;
  const submitting = screen.action === P2pTicketScreenAction.SUBMIT_SCREEN;
  const disabled = submitting || !isEmpty(formErrors);

  useEffect(() => form.subscribe((prevState) => {
    const nextState = form.currentState;
    if (prevState.values !== nextState.values) {
      onChange(nextState.values);
    }
  }), [form, onChange]);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(form.currentState.values);
  }, [form, onSubmit]);
  const navigating = screen.action === P2pTicketScreenAction.GO_BACK;
  return (
    <form className="sts-ui-p2p-ticket-screen-summary" onSubmit={handleSubmit}>
      <div className="sts-ui-p2p-ticket-screen-summary__navigation">
        <P2pTicketScreenCommonNavigation
          current="summary"
          loading={NAV_ACTION_LOADING.get(screen.action) ?? null}
          oneWay={screen.form.trip.oneWay}
          onNavigate={onNavigate}
        />
      </div>
      <div className="sts-ui-p2p-ticket-screen-summary__reset">
        <Button
          as="button"
          variant="outlined"
          intent="primary"
          size="lg"
          type="button"
          disabled={resetting}
          onClick={onReset}
        >
          {resetting ? <LoadingIcon/> : <EditIcon/>}
          <FormattedMessage id="P2P.Summary.EditSearch"/>
        </Button>
      </div>

      <div className="sts-ui-p2p-ticket-screen-summary__block">
        <P2pTicketScreenSummaryTrip
          screen={screen}
          onBack={onGoBack}
        />
      </div>

      <div className="sts-ui-p2p-ticket-screen-summary__block">
        <P2pTicketScreenSummaryTravelersForm
          form={form}
          screen={screen}
          currency={currency}
        />
      </div>

      <div className="sts-ui-p2p-ticket-screen-summary__price">
        <div className="sts-ui-p2p-ticket-screen-summary__price-label">
          <FormattedMessage id="P2P.Summary.TotalPrice"/>
        </div>
        <div className="sts-ui-p2p-ticket-screen-summary__price-value">
          <FormattedPrice value={getSummaryTotalPrice(screen, currency)}/>
        </div>
      </div>

      <div className="sts-ui-p2p-ticket-screen-summary__footer">
        <div className="sts-ui-p2p-ticket-screen-summary__footer-back">
          <Button
            as="button"
            variant="outlined"
            intent="primary"
            size="sm"
            type="button"
            onClick={onGoBack}
            disabled={navigating}
          >
            {navigating ? <LoadingIcon/> : <ChevronLeftIcon/>}
            <span className="sts-ui-p2p-ticket-screen-summary-trip__header-reset-button-text">
              {screen.return !== null
                ? <FormattedMessage id="P2P.Summary.Trip.BackToReturn"/>
                : <FormattedMessage id="P2P.Summary.Trip.BackToOutward"/>}
            </span>
          </Button>
        </div>
        <div className="sts-ui-p2p-ticket-screen-summary__footer-submit">
          <Button
            as="button"
            variant="solid"
            intent="primary"
            size="lg"
            type="submit"
            disabled={disabled}
          >
            <FormattedMessage id="P2P.Summary.AddToBasket"/>
            {submitting ? <LoadingIcon/> : <ArrowRightIcon/>}
          </Button>
        </div>
      </div>
    </form>
  );
}

const NAV_ACTION_LOADING: ReadonlyMap<P2pTicketScreenAction | null, P2pTicketScreenType> = new Map([
  [P2pTicketScreenAction.NAVIGATE_SEARCH, 'initial'],
  [P2pTicketScreenAction.NAVIGATE_OUTWARD, 'trip-outward'],
  [P2pTicketScreenAction.NAVIGATE_RETURN, 'trip-return'],
]);
