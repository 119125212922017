import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { Optional } from 'src/codecs/combinators/Optional';
import { LOCAL_DATE_TIME } from 'src/codecs/LocalDateTime';
import { P2P_TICKET_TRANSPORT_MODE } from 'src/codecs/P2pTicketTransportMode';
import {
  P2pTicketTripContinuousLeg,
  P2pTicketTripItem,
  P2pTicketTripLeg,
  P2pTicketTripLegEnd,
  P2pTicketTripLegService,
  P2pTicketTripLegServiceMode,
  P2pTicketTripLegStart,
  P2pTicketTripLegTimeInfo,
  P2pTicketTripTimedLeg,
  P2pTicketTripTransferLeg,
} from 'src/types/P2pTicketTripItem';

const TRIP_LEG_TIME_INFO: C.Codec<unknown, JsonRecord, P2pTicketTripLegTimeInfo> = C.struct({
  timetabledTime: Optional(LOCAL_DATE_TIME),
  estimatedTime: Optional(LOCAL_DATE_TIME),
  observedTime: Optional(LOCAL_DATE_TIME),
});

const TRIP_TIME_LEG_START: C.Codec<unknown, JsonRecord, P2pTicketTripLegStart> = C.struct({
  stopPlaceName: C.string,
  serviceDeparture: TRIP_LEG_TIME_INFO,
  plannedStopPointName: Optional(C.string),
});

const TRIP_TIME_LEG_END: C.Codec<unknown, JsonRecord, P2pTicketTripLegEnd> = C.struct({
  stopPlaceName: Optional(C.string),
  serviceArrival: TRIP_LEG_TIME_INFO,
  plannedStopPointName: Optional(C.string),
});

const TRIP_LEG_SERVICE_MODE: C.Codec<unknown, JsonRecord, P2pTicketTripLegServiceMode> = C.struct({
  name: C.string,
  ptMode: P2P_TICKET_TRANSPORT_MODE,
  shortName: C.string,
});

const TRIP_LEG_SERVICE: C.Codec<unknown, JsonRecord, P2pTicketTripLegService> = C.struct({
  publishedServiceName: C.string,
  mode: TRIP_LEG_SERVICE_MODE,
  lineNumbers: C.readonly(C.array(Optional(C.string))),
});

const TRIP_TIMED_LEG: C.Codec<unknown, JsonRecord, P2pTicketTripTimedLeg> = C.struct({
  start: TRIP_TIME_LEG_START,
  end: TRIP_TIME_LEG_END,
  duration: Optional(C.string),
  service: TRIP_LEG_SERVICE,
  direction: Optional(C.string),
});

const TRIP_TRANSFER_LEG: C.Codec<unknown, JsonRecord, P2pTicketTripTransferLeg> = C.struct({
  duration: C.string,
  transferMode: C.string,
  timeWindowEnd: Optional(LOCAL_DATE_TIME),
});

const TRIP_CONTINUOUS_LEG: C.Codec<unknown, JsonRecord, P2pTicketTripContinuousLeg> = C.struct({
  duration: C.string,
});

const TRIP_LEG: C.Codec<unknown, JsonRecord, P2pTicketTripLeg> = C.struct({
  timedLeg: Optional(TRIP_TIMED_LEG),
  transferLeg: Optional(TRIP_TRANSFER_LEG),
  continuousLeg: Optional(TRIP_CONTINUOUS_LEG),
});

export const P2P_TICKET_TRIP_ITEM: C.Codec<unknown, JsonRecord, P2pTicketTripItem> = C.struct({
  id: C.string,
  transfers: C.number,
  legs: C.readonly(C.array(TRIP_LEG)),
  startTime: Optional(LOCAL_DATE_TIME),
  endTime: Optional(LOCAL_DATE_TIME),
  duration: C.string,
});
