import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { BASKET_CONTENT } from 'src/codecs/BasketContent';
import { CHECKOUT_FORM_DATA } from 'src/codecs/CheckoutFormData';
import { CHECKOUT_ORDER } from 'src/codecs/CheckoutOrder';
import { CHECKOUT_TOTAL } from 'src/codecs/CheckoutTotal';
import { PaymentStore } from 'src/types/PaymentStore';

export const PAYMENT_STORE: C.Codec<unknown, JsonRecord, PaymentStore> = C.struct({
  basket: BASKET_CONTENT,
  checkout: CHECKOUT_FORM_DATA,
  order: CHECKOUT_ORDER,
  total: CHECKOUT_TOTAL,
});
