import { SagaIterator } from 'redux-saga';
import {
  P2P_TICKET_TRIP_COLLECTION_REQUEST,
  P2P_TICKET_TRIP_COLLECTION_RESPONSE,
} from 'src/codecs/P2pTicketTripCollection';
import { HOST_API } from 'src/constants/application';
import { P2P_TICKET_LANGUAGE_MAP } from 'src/constants/p2pTicket';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { Language } from 'src/types/Language';
import { P2pTicketTripCollection } from 'src/types/P2pTicketTripCollection';
import { P2pTicketTripInfo } from 'src/types/P2pTicketTripInfo';
import { P2pTicketTripScroll } from 'src/types/P2pTicketTripScroll';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { isHttpError } from 'src/utils/error';
import { call } from 'typed-redux-saga';

export function* fetchP2pTripCollection(
  language: Language,
  tripInfo: P2pTicketTripInfo,
  tripScroll: P2pTicketTripScroll | null,
): SagaIterator<P2pTicketTripCollection> {
  try {
    const request = yield* call(P2P_TICKET_TRIP_COLLECTION_REQUEST.encode, {
      language: P2P_TICKET_LANGUAGE_MAP[language],

      origin: tripInfo.from.id,
      destination: tripInfo.to.id,
      date: tripInfo.date,

      scrollDirection: tripScroll?.direction ?? null,
      scrollContext: tripScroll?.context ?? null,
    });

    const response = yield* call(makeGetRequest, `${HOST_API}/p2p-trips`, request);
    const trips = yield* call(decodeOrThrow, P2P_TICKET_TRIP_COLLECTION_RESPONSE, response.content);

    return {
      trips: trips,

      prevTripsCollectionId: response.headers.get('Previous-Trips-Collection-Id'),
      nextTripsCollectionId: response.headers.get('Next-Trips-Collection-Id'),
    } satisfies P2pTicketTripCollection;
  } catch (error) {
    if (isHttpError(NativeError.wrap(error), 404)) {
      return NO_DATA;
    }

    throw new RuntimeError(
      'Could not fetch P2P Ticket trips collection',
      { language, tripInfo, tripScroll },
      NativeError.wrap(error),
    );
  }
}

const NO_DATA: P2pTicketTripCollection = {
  trips: [],

  nextTripsCollectionId: null,
  prevTripsCollectionId: null,
};
