import React from 'react';
import { useSelector } from 'react-redux';
import { RegionalPassesScreen } from 'src/components/regionalPasses/RegionalPassesScreen';
import { getBasketHasHalfFareCard } from 'src/selectors/getBasketHasHalfFareCard';
import { getRegionalPassesProducts } from 'src/selectors/getRegionalPassesProducts';

export function RegionalPassesPage(): React.ReactElement {
  const products = useSelector(getRegionalPassesProducts);
  const isHalfFareCardBooked = useSelector(getBasketHasHalfFareCard);

  return (
    <RegionalPassesScreen
      products={products}
      isHalfFareCardBooked={isHalfFareCardBooked}
    />
  );
}
