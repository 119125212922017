import { createSelector } from 'reselect';
import { getP2pTicketState } from 'src/selectors/getP2pTicketState';
import { assertNotEmpty } from 'src/utils/assert';

export const getP2pTicketScreen = createSelector([
  getP2pTicketState,
], (state) => assertNotEmpty(
  state.screen,
  'P2P state is not initialized',
  { state },
));
