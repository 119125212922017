import { pipe } from 'fp-ts/function';
import { JsonRecord } from 'fp-ts/Json';
import * as E from 'io-ts/Encoder';
import { LOCAL_DATE } from 'src/codecs/LocalDate';
import { UUID } from 'src/codecs/Uuid';
import { P2pTicketProduct } from 'src/types/P2pTicketProduct';
import { P2pTicketPassengerRequest, P2pTicketRequest } from 'src/types/P2pTicketRequest';

const TRANSFORMER: E.Encoder<P2pTicketRequest | null, ReadonlyArray<P2pTicketProduct>> = {
  encode: (value) => {
    if (typeof value[0] === 'undefined') {
      return null;
    }

    return {
      conversationId: value[0].conversationId,
      passengers: value.map((ticket) => ticket.offers.map((it): P2pTicketPassengerRequest => ({
        id: it.traveler.id,
        birthdate: it.traveler.birthDate,
        firstName: it.traveler.firstName,
        lastName: it.traveler.lastName,
      }))).flat(1),
      offerIds: value.map((ticket) => ticket.offers.map((it) => it.offer.offerIdentifier)).flat(1),
    };
  },
};

const PASSENGER_ENCODER: E.Encoder<JsonRecord, P2pTicketPassengerRequest> = E.struct({
  id: UUID,
  birthdate: LOCAL_DATE,
  firstName: E.id(),
  lastName: E.id(),
});

const ENCODER: E.Encoder<JsonRecord, P2pTicketRequest> = E.struct({
  conversationId: UUID,
  passengers: E.readonly(E.array(PASSENGER_ENCODER)),
  offerIds: E.readonly(E.array(E.id<string>())),
});

export const P2P_TICKET_REQUEST = pipe(
  TRANSFORMER,
  E.compose(E.nullable(ENCODER)),
);
