import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { P2P_TICKET_TRIP_OFFER } from 'src/codecs/P2pTicketTripOffer';
import { TICKET_CLASS } from 'src/codecs/TicketClass';
import { P2pTicketTripSelection } from 'src/types/P2pTicketTripSelection';

export const P2P_TICKET_TRIP_SELECTION: C.Codec<unknown, JsonRecord, P2pTicketTripSelection> = C.struct({
  offer: P2P_TICKET_TRIP_OFFER,
  class: TICKET_CLASS,
});
