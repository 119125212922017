import './Select.scss';
import React, { useCallback, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactSelect, { createFilter, SelectInstance } from 'react-select';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { ListOption } from 'src/types/ListOption';

type Option<TValue> = {
  readonly value: TValue;
  readonly label: string;
};

type Props<TValue> = {
  readonly id: string;
  readonly name: string;
  readonly value: TValue | null;
  readonly options: ReadonlyArray<ListOption<TValue>>;
  readonly onBlur?: () => void;
  readonly onChange: (value: TValue | null) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly clearable?: boolean;
  readonly searchable?: boolean;
  readonly placeholder?: React.ReactNode;
};

export function Select<TValue>({
  id,
  name,
  value,
  options,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  clearable,
  searchable,
  placeholder,
}: Props<TValue>): React.ReactElement {
  const handleChange = useCallback((option: Option<TValue> | null) => {
    onChange(option?.value ?? null);
  }, [onChange]);

  const selectValue = useMemo((): Option<TValue> | null => {
    const option = options.find((it) => it.value === value);
    return option ? { value: option.value, label: option.title } : null;
  }, [value, options]);
  const selectOptions = useMemo((): ReadonlyArray<Option<TValue>> => options.map((it) => ({
    value: it.value,
    label: it.title,
  })), [options]);

  const filterOption = useMemo(() => createFilter<Option<TValue>>({
    trim: true,
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: (option) => option.label,
  }), []);

  const noOptionsMessage = useCallback(() => (
    <FormattedMessage id="FormElements.Select.SelectValueFromList"/>
  ), []);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  const extractRef = useCallback((select: SelectInstance<Option<TValue>, false, never> | null) => {
    // @ts-expect-error React threads the reference as readonly
    inputRef.current = select?.inputRef ?? null;
  }, []);

  return (
    <ReactSelect<Option<TValue>, false, never>
      data-invalid={invalid}
      inputId={id}
      ref={extractRef}
      name={name}
      className="sts-ui-form-select"
      classNamePrefix="sts-ui-form-select"
      value={selectValue}
      options={selectOptions}
      filterOption={filterOption}
      getOptionLabel={(o) => o.label}
      onBlur={onBlur}
      onChange={handleChange}
      isMulti={false}
      isClearable={clearable}
      isDisabled={disabled}
      isSearchable={searchable}
      menuPosition="absolute"
      backspaceRemovesValue={true}
      noOptionsMessage={noOptionsMessage}
      closeMenuOnScroll={true}
      closeMenuOnSelect={true}
      placeholder={placeholder ?? <FormattedMessage id="FormElements.Select.SelectPlaceholder"/>}
    />
  );
}
