import * as C from 'io-ts/Codec';
import { P2pTicketTransportMode } from 'src/types/P2pTicketTransportMode';

export const P2P_TICKET_TRANSPORT_MODE: C.Codec<unknown, string, P2pTicketTransportMode> = C.literal(
  'HIGH_SPEED_TRAIN',
  'HISTORIC_TRAIN',
  'INTERCITY',
  'REGIONAL',
  'INTERREGIONAL',
  'URBAN',
  'TRAIN',
  'TRAM',
  'UNDERGROUND',
  'NIGHT_TRAIN',
  'SHARED_TAXI',
  'MOTOR_RAIL',
  'MOUNTAIN_TRAIN',
  'PLANE',
  'COACH_GROUP',
  'SHIP',
  'BUS',
);
