import { SagaIterator } from 'redux-saga';
import { BASKET_SET_CONVERSATION_ID } from 'src/actions/BasketActions';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { saveBasketData } from 'src/sagas/basket/utils/saveBasketData';
import { call, put } from 'typed-redux-saga';

export function* resetBasketConversationId(): SagaIterator<void> {
  try {
    yield* put(BASKET_SET_CONVERSATION_ID.trigger(null));
    yield* call(saveBasketData);
  } catch (error) {
    throw new RuntimeError('Could not reset conversation ID', {}, NativeError.wrap(error));
  }
}
