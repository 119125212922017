import './P2pTicketName.scss';
import React from 'react';
import { ArrowRightIcon } from 'src/components/icons/ArrowRightIcon';
import { P2pTicketStation } from 'src/types/P2pTicketStation';

type Props = {
  readonly from: P2pTicketStation;
  readonly to: P2pTicketStation;
};

export const P2pTicketName = React.memo(({ from, to }: Props) => {
  return (
    <span className="sts-ui-p2p-ticket-name">
      <span className="sts-ui-p2p-ticket-name__station">
        {from.name}
      </span>
      <span className="sts-ui-p2p-ticket-name__arrow">
        <ArrowRightIcon/>
      </span>
      <span className="sts-ui-p2p-ticket-name__station">
        {to.name}
      </span>
    </span>
  );
});
