import { ProductCode } from 'src/types/ProductCode';
import { ProductListItem } from 'src/types/ProductListItem';
import { isDefined } from 'src/utils/guard';

export function orderProducts(
  productsOrder: ReadonlyArray<ProductCode>,
  products: ReadonlyArray<ProductListItem>,
): ProductListItem[] {
  return productsOrder.map((productCode) => products.find((product) => product.code === productCode))
    .filter(isDefined);
}
