import { SagaIterator } from 'redux-saga';
import { createP2pTicketScreenUid } from 'src/sagas/p2p/utils/createP2pTicketScreenUid';
import { createOutwardTripInfo, createReturnTripInfo } from 'src/sagas/p2p/utils/createTripInfo';
import { fetchP2pTripList } from 'src/sagas/p2p/utils/fetchP2pTripList';
import { getP2pMinimalTripDate } from 'src/sagas/p2p/utils/getP2pMinimalTripDate';
import { Language } from 'src/types/Language';
import { P2pTicketScreenData } from 'src/types/P2pTicketScreenData';
import { P2pTicketStore } from 'src/types/P2pTicketStore';
import { P2pTicketTripDetails } from 'src/types/P2pTicketTripDetails';
import { Uuid } from 'src/types/Uuid';
import { assertNotEmpty } from 'src/utils/assert';
import { call } from 'typed-redux-saga';

export function* mapP2pTicketStore(
  language: Language,
  conversationId: Uuid,
  store: P2pTicketStore,
): SagaIterator<P2pTicketScreenData> {
  try {
    switch (store.screen) {
    case 'trip-outward': {
      const outwardTripInfo = yield* call(createOutwardTripInfo, store.form);
      const outwardTripList = yield* call(
        fetchP2pTripList,
        language,
        store.form.travelers,
        conversationId,
        outwardTripInfo,
      );

      return {
        uid: yield* call(createP2pTicketScreenUid),
        type: 'trip-outward',
        form: store.form,

        outward: null,
        return: null,

        tripInfo: outwardTripInfo,
        tripList: outwardTripList,

        minDate: yield* call(getP2pMinimalTripDate),
        stations: null,
        scrolling: null,

        action: null,
      } satisfies P2pTicketScreenData;
    }

    case 'trip-return': {
      const outwardTrip: P2pTicketTripDetails = {
        tripInfo: yield* call(createOutwardTripInfo, store.form),
        selected: assertNotEmpty(store.outward, 'Missing P2P Ticket outward store', { store }),
      };

      const returnTripInfo = yield* call(createReturnTripInfo, store.form);
      const returnTripList = yield* call(
        fetchP2pTripList,
        language,
        store.form.travelers,
        conversationId,
        returnTripInfo,
      );

      return {
        uid: yield* call(createP2pTicketScreenUid),
        type: 'trip-return',
        form: store.form,

        outward: outwardTrip,
        return: null,

        tripInfo: returnTripInfo,
        tripList: returnTripList,

        minDate: yield* call(getP2pMinimalTripDate),
        stations: null,
        scrolling: null,

        action: null,
      } satisfies P2pTicketScreenData;
    }

    case 'summary': {
      const outwardTrip: P2pTicketTripDetails = {
        tripInfo: yield* call(createOutwardTripInfo, store.form),
        selected: assertNotEmpty(store.outward, 'Missing P2P Ticket outward store', { store }),
      };
      const returnTrip: P2pTicketTripDetails | null = store.form.trip.oneWay ? null : {
        tripInfo: yield* call(createReturnTripInfo, store.form),
        selected: assertNotEmpty(store.return, 'Missing P2P Ticket return store', { store }),
      };

      return {
        uid: yield* call(createP2pTicketScreenUid),
        type: 'summary',
        form: store.form,

        outward: outwardTrip,
        return: returnTrip,

        action: null,
      } satisfies P2pTicketScreenData;
    }

    default:
      break;
    }
  } catch {
    // ignore errors
  }

  return {
    uid: yield* call(createP2pTicketScreenUid),
    type: 'initial',
    form: store.form,

    outward: null,
    return: null,

    minDate: yield* call(getP2pMinimalTripDate),
    stations: null,

    action: null,
  } satisfies P2pTicketScreenData;
}
