import { FormController } from '@form-ts/core';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CHECKOUT_PAYMENT_CHANGE, CHECKOUT_PAYMENT_SUBMIT } from 'src/actions/CheckoutActions';
import { CheckoutPaymentScreen } from 'src/components/checkout/CheckoutPaymentScreen';
import { FormError } from 'src/forms/types/FormError';
import { useAction } from 'src/hooks/useAction';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getAffiliateData } from 'src/selectors/getAffiliateData';
import { getCheckoutFormData } from 'src/selectors/getCheckoutFormData';
import { getCheckoutLoading } from 'src/selectors/getCheckoutLoading';
import { getCheckoutPaymentFormRule } from 'src/selectors/getCheckoutPaymentFormRule';
import { getCheckoutPaymentTypes } from 'src/selectors/getCheckoutPaymentTypes';
import { getCheckoutProductList } from 'src/selectors/getCheckoutProductList';
import { getCheckoutTotalPrice } from 'src/selectors/getCheckoutTotalPrice';
import { CheckoutPaymentFormData } from 'src/types/CheckoutFormData';

export function CheckoutPaymentPage(): React.ReactElement {
  const currency = useSelector(getCurrentCurrency);
  const loading = useSelector(getCheckoutLoading);
  const formData = useSelector(getCheckoutFormData);
  const formRule = useSelector(getCheckoutPaymentFormRule);
  const products = useSelector(getCheckoutProductList);
  const totalPrice = useSelector(getCheckoutTotalPrice);
  const paymentTypes = useSelector(getCheckoutPaymentTypes);
  const optInControl = useSelector(getAffiliateData).optIn;

  const changeForm = useAction(CHECKOUT_PAYMENT_CHANGE.request);
  const handleChange = useCallback((form: FormController<CheckoutPaymentFormData, FormError>) => {
    changeForm(form);
  }, [changeForm]);

  const submitForm = useAction(CHECKOUT_PAYMENT_SUBMIT.request);
  const handleSubmit = useCallback((form: FormController<CheckoutPaymentFormData, FormError>) => {
    submitForm(form);
  }, [submitForm]);

  return (
    <CheckoutPaymentScreen
      currency={currency}
      loading={loading}
      formData={formData.payment}
      formRule={formRule}
      products={products}
      personal={formData.personal}
      totalPrice={totalPrice}
      paymentTypes={paymentTypes}
      optInControl={optInControl}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
}
