import './P2pTicketFormDropdown.scss';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { P2pTicketFormDropdownAnimate } from 'src/components/p2p/P2pTicketFormDropdownAnimate';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { useOutsideClick } from 'src/hooks/useOutsideClick';
import { useOutsideFocus } from 'src/hooks/useOutsideFocus';

type Props = {
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly value: React.ReactNode;
  readonly onBlur?: () => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly children: React.ReactNode;
};

export function P2pTicketFormDropdown({
  layout,
  border,
  label,
  value,
  onBlur,
  invalid,
  validity,
  disabled,
  children,
}: Props): React.ReactElement {
  const [showOverlay, toggleOverlay] = useState(false);

  const handleButtonFocus = useCallback(() => {
    toggleOverlay(true);
  }, []);

  const container = useRef<HTMLDivElement>(null);
  const outsideClickHandler = useCallback(() => {
    if (!showOverlay) {
      return;
    }

    toggleOverlay(false);
    onBlur?.();
  }, [showOverlay, onBlur]);

  useOutsideClick(container, outsideClickHandler);
  useOutsideFocus(container, outsideClickHandler);

  const inputRef = useRef<HTMLButtonElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={container}
      data-invalid={invalid}
      data-disabled={disabled}
      className={classNames(
        'sts-ui-p2p-ticket-form-dropdown',
        `sts-ui-p2p-ticket-form-dropdown--layout-${layout}`,
        `sts-ui-p2p-ticket-form-dropdown--border-${border}`,
        showOverlay ? 'sts-ui-p2p-ticket-form-dropdown--open' : null,
      )}
    >
      <button
        ref={inputRef}
        type="button"
        className="sts-ui-p2p-ticket-form-dropdown__button"
        onClick={handleButtonFocus}
        onBlur={onBlur}
        onFocus={handleButtonFocus}
        disabled={disabled}
      >
        <div className="sts-ui-p2p-ticket-form-dropdown__label">
          {label}
        </div>
        <div className="sts-ui-p2p-ticket-form-dropdown__value">
          {value}
        </div>
      </button>

      <P2pTicketFormDropdownAnimate show={showOverlay} content={menuRef}>
        <div ref={menuRef} className="sts-ui-p2p-ticket-form-dropdown__menu">
          {children}
        </div>
      </P2pTicketFormDropdownAnimate>
    </div>
  );
}
