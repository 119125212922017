import { identity } from 'fp-ts/function';
import { Lens } from 'monocle-ts/Lens';
import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';

export const RESOLVE_TRAVELER_DISCOUNT: Lens<P2pTicketFormDataTraveler, P2pTicketFormDataTraveler> = {
  get: identity,
  set: (value) => () => resolveTravelerDiscount(value),
};

function resolveTravelerDiscount(traveler: P2pTicketFormDataTraveler): P2pTicketFormDataTraveler {
  return traveler.ageGroup === 'child' && traveler.discount !== 'none'
    ? { ...traveler, discount: 'none' }
    : traveler;
}
