import { Decimal } from 'decimal.js-light';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/ReadonlyArray';
import { createSelector } from 'reselect';
import { BASKET_PRODUCT_ORD } from 'src/constants/ord';
import { getCurrentCurrency } from 'src/routing/selectors/getCurrentCurrency';
import { getBasketData } from 'src/selectors/getBasketData';
import { getBasketShiftedProducts } from 'src/selectors/getBasketShiftedProducts';
import { getBasketUnavailableProducts } from 'src/selectors/getBasketUnavailableProducts';
import {
  BasketProductListItem,
  BasketProductListItemP2pTicket,
  BasketProductListItemProduct,
} from 'src/types/BasketProductListItem';

export const getBasketProductList = createSelector([
  getBasketData,
  getBasketShiftedProducts,
  getBasketUnavailableProducts,
  getCurrentCurrency,
], (data, shifted, unavailable, currency): ReadonlyArray<BasketProductListItem> => {
  return pipe(
    data.products,
    A.sort(BASKET_PRODUCT_ORD),
    A.map((product): BasketProductListItemProduct => ({
      type: 'product',
      uuid: `product-${product.code}`,

      code: product.code,
      form: product.form,

      prices: product.prices,
      totalPrice: product.prices.reduce((sum, it) => sum.add(it.price), new Decimal(0)),

      invalid: shifted.has(product.code),
      unavailable: unavailable.has(product.code),

      familyCardCount: product.familyCardCount,
      familyCardDiscount: product.familyCardDiscount,
    })),
    A.concatW(pipe(
      data.p2pTickets,
      A.map((p2pTicket): BasketProductListItemP2pTicket => ({
        type: 'p2p-ticket',
        uuid: p2pTicket.ticket.ticketIdentifier,

        invalid: p2pTicket.invalid,
        totalPrice: p2pTicket.ticket.offers.reduce((sum, it) => sum.add(it.offer.prices[currency]), new Decimal(0)),

        ticket: p2pTicket.ticket,
      })),
    )),
  );
});
