import './PaymentSuccessScreen.scss';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CheckoutPaymentP2pTicketPriceList } from 'src/components/checkout/CheckoutPaymentP2pTicketPriceList';
import { CheckoutPaymentTicketPriceList } from 'src/components/checkout/CheckoutPaymentTicketPriceList';
import { PaymentSuccessCrossSell } from 'src/components/payment/PaymentSuccessCrossSell';
import { PaymentSuccessSocialLinks } from 'src/components/payment/PaymentSuccessSocialLinks';
import { PaymentProduct } from 'src/types/PaymentProduct';
import { PaymentStore } from 'src/types/PaymentStore';
import { TicketType } from 'src/types/TicketType';

type Props = {
  readonly payment: PaymentStore;
  readonly products: ReadonlyArray<PaymentProduct>;
  readonly isIframe: boolean;
};

export function PaymentSuccessScreen({ payment, products, isIframe }: Props): React.ReactElement {
  const hasSwissPass = products.some((it) => it.type === 'product' && it.code === TicketType.SWISS_TRAVEL_PASS);

  return (
    <div className="sts-ui-payment-success-screen">
      <h3 className="sts-ui-payment-success-screen__title">
        <FormattedHTMLMessage id="Payment.ThanksForBooking"/>
      </h3>

      <div className="sts-ui-payment-success-screen__info">
        <p>
          <FormattedHTMLMessage
            id="Payment.ConfirmedReferenceNumber"
            values={{ orderId: payment.order.data.refno }}
          />
        </p>
        <p>
          <FormattedHTMLMessage
            id="Payment.ConfirmationSentToEmail"
            values={{ email: payment.checkout.personal.billing.email }}
          />
        </p>
      </div>

      <div className="sts-ui-payment-success-screen__info">
        <p>
          <FormattedHTMLMessage id="Payment.BookingQuestions"/>
        </p>
      </div>

      <div className="sts-ui-payment-success-screen__tickets">
        <h4 className="sts-ui-payment-success-screen__tickets-title">
          <FormattedMessage id="Payment.BookingInformation"/>
        </h4>
        {products.map((product) => (
          <div key={product.uuid} className="sts-ui-payment-success-screen__tickets-item">
            {product.type === 'product' && (
              <CheckoutPaymentTicketPriceList
                product={{ ...product, fields: {} }}
                tickets={product.prices}
              />
            )}
            {product.type === 'p2p-ticket' && (
              <CheckoutPaymentP2pTicketPriceList
                product={product}
                currency={payment.order.data.currency}
              />
            )}
          </div>
        ))}
      </div>

      <div className="sts-ui-payment-success-screen__delivery">
        <FormattedMessage id="Payment.TicketDelivery"/>
      </div>

      {!isIframe && hasSwissPass && (
        <div className="sts-ui-payment-success-screen__cross-sell">
          <PaymentSuccessCrossSell order={payment.order}/>
        </div>
      )}

      <div className="sts-ui-payment-success-screen__contact">
        <FormattedHTMLMessage id="Payment.Zurich.Contacts"/>
      </div>

      <div className="sts-ui-payment-success-screen__social">
        <PaymentSuccessSocialLinks/>
      </div>
    </div>
  );
}
