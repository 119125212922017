import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_TRIP_OUTWARD_SUBMIT, P2P_TICKET_TRIP_RETURN_SUBMIT } from 'src/actions/P2pTicketActions';
import { LogicError } from 'src/errors/LogicError';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { saveP2pTicketData } from 'src/sagas/p2p/utils/saveP2pTicketData';
import { submitTripReturnForm } from 'src/sagas/p2p/utils/submitTripReturnForm';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { getP2pTicketScreen } from 'src/selectors/getP2pTicketScreen';
import { GetRequestActionType } from 'src/utils/createActions';
import { sentryCatch } from 'src/utils/sentryCatch';
import { call, delay, put, select } from 'typed-redux-saga';

export function* p2pTicketTripReturnSubmitSaga(
  action: GetRequestActionType<typeof P2P_TICKET_TRIP_RETURN_SUBMIT>,
): SagaIterator<void> {
  const selectedTrip = action.data;

  try {
    yield* call(logDebug, 'Submitting P2P Ticket return trip data…', selectedTrip);
    yield* put(P2P_TICKET_TRIP_OUTWARD_SUBMIT.pending());
    yield* delay(500);

    const currentScreen = yield* select(getP2pTicketScreen);
    if (currentScreen.type !== 'trip-return') {
      throw new LogicError('Unexpected P2P Ticket screen', { currentScreen });
    }

    const updatedScreen = yield* call(submitTripReturnForm, currentScreen, selectedTrip);
    yield* call(saveP2pTicketData, updatedScreen);

    yield* call(logDebug, 'Submitting P2P Ticket return trip data… done', updatedScreen);
    yield* put(P2P_TICKET_TRIP_OUTWARD_SUBMIT.success(updatedScreen));
  } catch (error) {
    yield* call(sentryCatch, new RuntimeError(
      'Could not submit P2P Ticket return trip data',
      { selectedTrip },
      NativeError.wrap(error),
    ));

    yield* call(logError, 'Submitting P2P Ticket return trip data… error', error);
    yield* put(P2P_TICKET_TRIP_OUTWARD_SUBMIT.failure(NativeError.wrap(error)));
  }
}
