import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import { P2P_TICKET_OFFER } from 'src/codecs/P2pTicketOffer';
import { P2P_TICKET_TRIP_ITEM } from 'src/codecs/P2pTicketTripItem';
import { P2pTicketTripOffer } from 'src/types/P2pTicketTripOffer';

export const P2P_TICKET_TRIP_OFFER: C.Codec<unknown, JsonRecord, P2pTicketTripOffer> = C.struct({
  trip: P2P_TICKET_TRIP_ITEM,
  offers: C.readonly(C.array(P2P_TICKET_OFFER)),
});
