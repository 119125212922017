import { ROUTING_PRODUCTS } from 'src/routing/constants/RoutingProducts';

export const ROUTING_PUBLIC_PAGES = [
  'homepage',
  'excursions',
  'regionalpasses',

  'faq',
  'guide',
  'conditions',
  'contact',
  'newsletter',

  'p2p-ticket',

  ...ROUTING_PRODUCTS.map((productCode) => `product:${productCode}` as const),
] as const;

export const ROUTING_PRIVATE_PAGES = [
  'basket',

  'legalnotice',
  'privacypolicy',
  'gtc',
  'about',

  'checkout:personal',
  'checkout:payment',

  'payment:cancel',
  'payment:success',
  'payment:failure',
] as const;

export const ROUTING_INTERNAL_PAGES = [
  'notfound',
] as const;
