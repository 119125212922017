import { JsonRecord } from 'fp-ts/Json';
import * as C from 'io-ts/Codec';
import * as E from 'io-ts/Encoder';
import { SagaIterator } from 'redux-saga';
import { LOCAL_DATE_TIME } from 'src/codecs/LocalDateTime';
import { P2P_TICKET_LANGUAGE } from 'src/codecs/P2pTicketLanguage';
import { P2P_TICKET_OFFER } from 'src/codecs/P2pTicketOffer';
import { UUID } from 'src/codecs/Uuid';
import { HOST_API } from 'src/constants/application';
import { P2P_TICKET_LANGUAGE_MAP } from 'src/constants/p2pTicket';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { makeGetRequest } from 'src/sagas/utils/makeRequest';
import { Language } from 'src/types/Language';
import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketTripItem } from 'src/types/P2pTicketTripItem';
import { P2pTicketTripOffer } from 'src/types/P2pTicketTripOffer';
import { Uuid } from 'src/types/Uuid';
import { decodeOrThrow } from 'src/utils/decodeOrThrow';
import { isHttpError } from 'src/utils/error';
import { getTripDepartureTime } from 'src/utils/p2p/getTripDetails';
import { call } from 'typed-redux-saga';

export function* fetchP2pTripOffer(
  language: Language,
  travelers: ReadonlyArray<P2pTicketFormDataTraveler>,
  conversationId: Uuid,
  tripItem: P2pTicketTripItem,
): SagaIterator<P2pTicketTripOffer> {
  try {
    const request = yield* call(REQUEST.encode, {
      tripId: tripItem.id,
      conversationId: conversationId,
      travelers: travelers,
      language: P2P_TICKET_LANGUAGE_MAP[language],
      date: getTripDepartureTime(tripItem),
    });

    const response = yield* call(makeGetRequest, `${HOST_API}/p2p-trip-offers`, request);
    const offers = yield* call(decodeOrThrow, RESPONSE, response.content);
    return { trip: tripItem, offers: offers };
  } catch (error) {
    if (isHttpError(NativeError.wrap(error), 404)) {
      return { trip: tripItem, offers: [] };
    }

    throw new RuntimeError(
      'Could not fetch P2P Ticket trip offers',
      { language, conversationId, travelers, tripItem },
      NativeError.wrap(error),
    );
  }
}

// FIXME: clarify CHILD + DISCOUNT mapping
const REQUEST_TRAVELER_TYPE = {
  'child-none': 'child',
  'child-half-fare': 'child',
  'adult-none': 'adult',
  'adult-half-fare': 'adult-half',
};
const REQUEST_TRAVELER: E.Encoder<JsonRecord, P2pTicketFormDataTraveler> = {
  encode: (travaler) => ({
    id: travaler.id,
    type: REQUEST_TRAVELER_TYPE[`${travaler.ageGroup}-${travaler.discount}`],
  }),
};

const REQUEST = E.struct({
  language: P2P_TICKET_LANGUAGE,
  conversationId: UUID,
  tripId: C.string,
  date: LOCAL_DATE_TIME,
  travelers: E.readonly(E.array(REQUEST_TRAVELER)),
});
const RESPONSE = C.array(P2P_TICKET_OFFER);
