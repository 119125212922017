import { History } from 'history';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { Store } from 'redux';
import { ErrorBoundary } from 'src/components/error/ErrorBoundary';
import { NetworkErrorContainer } from 'src/network/containers/NetworkErrorContainer';
import { Initialization } from 'src/routing/containers/Initialization';
import { IntlProvider } from 'src/routing/containers/IntlProvider';
import { RouteSeo } from 'src/routing/containers/RouteSeo';
import { Sitemap } from 'src/routing/containers/Sitemap';
import { RootState } from 'src/types/RootState';

type Props = {
  readonly store: Store<RootState>;
  readonly history: History;
};

export function Root({ store, history }: Props): React.ReactElement {
  return (
    <React.StrictMode>
      <ErrorBoundary>
        <HelmetProvider>
          <Provider store={store}>
            <Router history={history}>
              <Initialization>
                <IntlProvider>
                  <RouteSeo/>
                  <Sitemap/>
                  <NetworkErrorContainer/>
                </IntlProvider>
              </Initialization>
            </Router>
          </Provider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
}
