import { SagaIterator } from 'redux-saga';
import { NETWORK_ERROR_MODAL_RETRY } from 'src/network/actions/NetworkActions';
import { ROUTE_CHANGE } from 'src/routing/actions/RouteChange';
import { GetTriggerActionType } from 'src/utils/createTrigger';
import { put } from 'typed-redux-saga';

export function* networkErrorRetrySaga(
  action: GetTriggerActionType<typeof NETWORK_ERROR_MODAL_RETRY>,
): SagaIterator<void> {
  const target = action.data.to;
  yield* put(ROUTE_CHANGE.request(undefined, {
    location: target.location,
    currency: target.currency,
  }));
}
