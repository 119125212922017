import { SagaIterator } from 'redux-saga';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketProduct } from 'src/types/P2pTicketProduct';
import { P2pTicketTripDetails } from 'src/types/P2pTicketTripDetails';
import { Uuid } from 'src/types/Uuid';
import { assertDefined, assertNotEmpty } from 'src/utils/assert';
import { getTripDepartureTime } from 'src/utils/p2p/getTripDetails';
import { uuidRandom } from 'src/utils/uuid';
import { call } from 'typed-redux-saga';

export function* createP2pTicketProduct(
  conversationId: Uuid,
  travelers: ReadonlyArray<P2pTicketFormDataTraveler>,
  tripDetails: P2pTicketTripDetails,
): SagaIterator<P2pTicketProduct> {
  try {
    return {
      ticketIdentifier: yield* call(uuidRandom),
      conversationId: conversationId,
      direction: tripDetails.tripInfo.direction,
      from: tripDetails.tripInfo.from,
      to: tripDetails.tripInfo.to,
      date: getTripDepartureTime(tripDetails.selected.offer.trip),
      trip: tripDetails.selected.offer.trip,
      class: tripDetails.selected.class,
      offers: travelers.map((traveler) => ({
        offer: assertDefined(
          tripDetails.selected.offer.offers.find((it) => (
            it.qualityOfService === tripDetails.selected.class &&
            it.passengerId === traveler.id
          )),
          'Could not find P2P traveler offer',
          { traveler, tripDetails },
        ),
        traveler: {
          id: traveler.id,
          ageGroup: traveler.ageGroup,
          discount: traveler.discount,
          firstName: traveler.firstName,
          lastName: traveler.lastName,
          birthDate: assertNotEmpty(
            traveler.birthDate,
            'Invalid P2P traveler data',
            { traveler },
          ),
        },
      })),
    } satisfies P2pTicketProduct;
  } catch (error) {
    throw new RuntimeError(
      'Could not create new P2P ticket product ID',
      { conversationId, travelers, tripDetails },
      NativeError.wrap(error),
    );
  }
}
