import './P2pTicketScreenSummaryTripDetails.scss';
import classNames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Collapsible } from 'src/components/common/Collapsible';
import { FormattedDate } from 'src/components/common/FormattedDate';
import { FormattedDateMiddle } from 'src/components/common/FormattedDateMiddle';
import { FormattedTime } from 'src/components/common/FormattedTime';
import { P2pTicketName } from 'src/components/common/P2pTicketName';
import { ChevronDownIcon } from 'src/components/icons/ChevronDownIcon';
import { P2pTicketScreenCommonTripLegList } from 'src/components/p2p/P2pTicketScreenCommonTripLegList';
import { P2pTicketTripDetails } from 'src/types/P2pTicketTripDetails';
import { Ord } from 'src/utils/dateOnly';
import { getTripDuration } from 'src/utils/p2p/duration';
import { getTripArrivalTime, getTripDepartureTime } from 'src/utils/p2p/getTripDetails';
import { offerZones } from 'src/utils/product/offerZones';

type Props = {
  readonly trip: P2pTicketTripDetails;
};

export function P2pTicketScreenSummaryTripDetails({ trip }: Props): React.ReactElement {
  const departureDate = useMemo(() => {
    return getTripDepartureTime(trip.selected.offer.trip);
  }, [trip.selected.offer.trip]);
  const arrivalDate = useMemo(() => {
    return getTripArrivalTime(trip.selected.offer.trip);
  }, [trip.selected.offer.trip]);
  const duration = useMemo(() => {
    return getTripDuration(departureDate, arrivalDate);
  }, [departureDate, arrivalDate]);

  const [expanded, setExpanded] = useState(false);
  const handleToggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const offer = trip.selected.offer.offers[0];
  const zones = useMemo(() => offer && offerZones(offer), [offer]);

  const ticketValidity = useMemo(() => {
    return offer?.ticketValidity && {
      from: {
        date: offer.ticketValidity.from.date,
        time: offer.ticketValidity.from.time,
      },
      to: {
        date: offer.ticketValidity.to.date,
        time: offer.ticketValidity.to.time,
      },
    };
  }, [offer]);

  return (
    <div className="sts-ui-p2p-ticket-screen-summary-trip-details">
      <div className="sts-ui-p2p-ticket-screen-summary-trip-details__direction">
        <P2pTicketName
          from={trip.tripInfo.from}
          to={trip.tripInfo.to}
        />
      </div>
      <div className="sts-ui-p2p-ticket-screen-summary-trip-details__duration">
        <div className="sts-ui-p2p-ticket-name__duration">
          <FormattedMessage
            id="P2P.TripInfo.Duration"
            values={{ hours: duration.hh, minutes: duration.mm }}
          />
        </div>
      </div>

      <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info">
        <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-label">
          <FormattedMessage id="P2P.Summary.Trip.Info.Date"/>
        </div>
        <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-value">
          <FormattedDate value={departureDate.date}/>
        </div>
      </div>
      <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info">
        <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-label">
          <FormattedMessage id="P2P.Summary.Trip.Info.Time"/>
        </div>
        <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-value">
          <FormattedTime value={departureDate.time}/>
        </div>
      </div>
      <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info">
        <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-label">
          <FormattedMessage id="P2P.Summary.Trip.Info.Class"/>
        </div>
        <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-value">
          <FormattedMessage id="P2P.Common.ClassName" values={{ class: trip.selected.class }}/>
        </div>
      </div>
      {ticketValidity && (
        <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info">
          <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-label">
            <FormattedMessage id="P2P.Summary.Trip.Info.ValidityLabel"/>
          </div>
          <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-value">
            <FormattedMessage
              id="P2P.Summary.Trip.Info.Validity"
              values={
                Ord.equals(ticketValidity.from.date, ticketValidity.to.date)
                  ? {
                    from: ticketValidity.from.time && (<FormattedTime value={ticketValidity.from.time}/>),
                    to: ticketValidity.to.time && (<FormattedTime value={ticketValidity.to.time}/>),
                  }
                  : {
                    from: (
                      <>
                        <FormattedDateMiddle value={ticketValidity.from.date}/>
                        {ticketValidity.from.time && (<> <FormattedTime value={ticketValidity.from.time}/></>)}
                      </>
                    ),
                    to: (
                      <>
                        <FormattedDateMiddle value={ticketValidity.to.date}/>
                        {ticketValidity.to.time && (<> <FormattedTime value={ticketValidity.to.time}/></>)}
                      </>
                    ),
                  }
              }
            />
          </div>
        </div>
      )}
      {zones && (
        <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info">
          <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-label">
            <FormattedMessage id="P2P.Summary.Trip.Info.Zones"/>
          </div>
          <div className="sts-ui-p2p-ticket-screen-summary-trip-details__info-value">
            {zones}
          </div>
        </div>
      )}
      <div className="sts-ui-p2p-ticket-screen-summary-trip-details__more">
        <button
          type="button"
          onClick={handleToggle}
          className="sts-ui-p2p-ticket-screen-summary-trip-details__more-toggle"
        >
          <span className="sts-ui-p2p-ticket-screen-summary-trip-details__more-toggle-text">
            <FormattedMessage id="P2P.Summary.Trip.MoreInfo.Toggle"/>
          </span>
          <span className={classNames({
            'sts-ui-p2p-ticket-screen-summary-trip-details__more-toggle-icon': true,
            'sts-ui-p2p-ticket-screen-summary-trip-details__more-toggle-icon--expanded': expanded,
          })}
          >
            <ChevronDownIcon/>
          </span>
        </button>

        <Collapsible expanded={expanded} duration={300}>
          <div className="sts-ui-p2p-ticket-screen-summary-trip-details__more-content">
            <P2pTicketScreenCommonTripLegList trip={trip.selected.offer.trip}/>
          </div>
        </Collapsible>
      </div>
    </div>
  );
}
