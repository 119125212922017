import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ListOption } from 'src/types/ListOption';
import { P2pTicketDiscount } from 'src/types/P2pTicketDiscount';

export function useDiscountOptions(): ReadonlyArray<ListOption<P2pTicketDiscount>> {
  const intl = useIntl();

  return useMemo(() => [
    {
      value: 'none',
      title: intl.formatMessage({ id: 'P2P.Common.Discount' }, { discount: 'none' }),
    },
    {
      value: 'half-fare',
      title: intl.formatMessage({ id: 'P2P.Common.Discount' }, { discount: 'half_fare' }),
    },
  ], [intl]);
}
