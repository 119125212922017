import { createSelector } from 'reselect';
import { getBasketP2pTickets } from 'src/selectors/getBasketP2pTickets';
import { getBasketProducts } from 'src/selectors/getBasketProducts';
import { BasketContent } from 'src/types/BasketContent';

export const getBasketContent = createSelector([
  getBasketProducts,
  getBasketP2pTickets,
], (products, p2pTickets): BasketContent => ({
  products,
  p2pTickets,
}));
