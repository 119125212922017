import './P2pTicketFormDatePicker.scss';
import classNames from 'classnames';
import { format } from 'date-fns/format';
import { constVoid } from 'fp-ts/function';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { CalendarIcon } from 'src/components/icons/CalendarIcon';
import { P2pTicketFormCalendar } from 'src/components/p2p/P2pTicketFormCalendar';
import { P2pTicketFormDropdownAnimate } from 'src/components/p2p/P2pTicketFormDropdownAnimate';
import { FORMAT_DATE_SHORT } from 'src/constants/format';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { useOutsideClick } from 'src/hooks/useOutsideClick';
import { useOutsideFocus } from 'src/hooks/useOutsideFocus';
import { DateOnly } from 'src/types/DateOnly';
import { toDate } from 'src/utils/dateOnly';

type Props = {
  readonly id: string;
  readonly name: string;
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly value: DateOnly | null;
  readonly onBlur?: () => void;
  readonly onChange: (value: DateOnly | null) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly min?: DateOnly;
  readonly max?: DateOnly;
  readonly navigation: 'narrow' | 'broad';
  readonly defaultDate: DateOnly;
};

export function P2pTicketFormDatePicker({
  id,
  name,
  layout,
  border,
  label,
  value,
  onBlur,
  onChange,
  invalid,
  validity,
  disabled,
  placeholder,
  min,
  max,
  navigation,
  defaultDate,
}: Props): React.ReactElement {
  const viewValue = useMemo(() => {
    return value ? format(toDate(value), FORMAT_DATE_SHORT) : '';
  }, [value]);

  const [showOverlay, toggleOverlay] = useState(false);

  const handleInputFocus = useCallback(() => {
    toggleOverlay(true);
  }, []);
  const handleSelectDate = useCallback((date: DateOnly) => {
    toggleOverlay(false);

    onChange(date);
    onBlur?.();
  }, [onChange, onBlur]);

  const container = useRef<HTMLDivElement>(null);
  const outsideClickHandler = useCallback(() => {
    if (!showOverlay) {
      return;
    }

    toggleOverlay(false);
    onBlur?.();
  }, [showOverlay, onBlur]);

  useOutsideClick(container, outsideClickHandler);
  useOutsideFocus(container, outsideClickHandler);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={container}
      data-invalid={invalid}
      data-disabled={disabled}
      className={classNames(
        'sts-ui-p2p-ticket-form-date-picker',
        `sts-ui-p2p-ticket-form-date-picker--layout-${layout}`,
        `sts-ui-p2p-ticket-form-date-picker--border-${border}`,
        showOverlay ? 'sts-ui-p2p-ticket-form-date-picker--open' : null,
      )}
    >
      <label htmlFor={id} className="sts-ui-p2p-ticket-form-date-picker__label">
        {label}
      </label>
      <input
        ref={inputRef}
        id={id}
        name={name}
        type="text"
        className="sts-ui-p2p-ticket-form-date-picker__input"
        value={viewValue}
        onChange={constVoid}
        onBlur={onBlur}
        onFocus={handleInputFocus}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={true}
      />
      <span className="sts-ui-p2p-ticket-form-date-picker__icon">
        <CalendarIcon/>
      </span>

      <P2pTicketFormDropdownAnimate show={showOverlay} content={menuRef}>
        <div ref={menuRef} className="sts-ui-p2p-ticket-form-date-picker__menu">
          <P2pTicketFormCalendar
            value={value}
            onChange={handleSelectDate}
            navigation={navigation}
            defaultDate={defaultDate}
            minDate={min}
            maxDate={max}
          />
        </div>
      </P2pTicketFormDropdownAnimate>
    </div>
  );
}
