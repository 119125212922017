import { hideLoadingIndicator, showLoadingIndicator } from 'src/loader/sagas/utils/loadingIndicator';
import { hideNetworkErrorModal } from 'src/network/sagas/utils/hideNetworkErrorModal';
import { errorTransitionHandling } from 'src/routing/hooks/errorTransitionHandling';
import { errorTransitionLogging } from 'src/routing/hooks/errorTransitionLogging';
import { createGlobalTransitionLoggingSaga } from 'src/routing/hooks/globalTransitionLogging';
import { ErrorHook } from 'src/routing/types/ErrorHook';
import { TransitionHook } from 'src/routing/types/TransitionHook';

export const ROUTING_PRE_HOOKS: ReadonlyArray<TransitionHook> = [
  createGlobalTransitionLoggingSaga('Starting transition'),
  showLoadingIndicator.bind(null, 'routing-transition'),
];

export const ROUTING_POST_HOOKS: ReadonlyArray<TransitionHook> = [
  createGlobalTransitionLoggingSaga('Finishing transition'),
  hideLoadingIndicator.bind(null, 'routing-transition'),
  hideNetworkErrorModal,
];

export const ROUTING_ERROR_HOOKS: ReadonlyArray<ErrorHook> = [
  errorTransitionLogging,
  errorTransitionHandling,
  hideLoadingIndicator.bind(null, 'routing-transition'),
];
