import * as C from 'io-ts/Codec';
import { SagaIterator } from 'redux-saga';
import { P2P_TICKET_STORE } from 'src/codecs/P2pTicketStore';
import { STORAGE_P2P_KEY, STORAGE_P2P_VERSION } from 'src/constants/storage';
import { NativeError } from 'src/errors/NativeError';
import { RuntimeError } from 'src/errors/RuntimeError';
import { logDebug, logError } from 'src/sagas/utils/logging';
import { storageGet, storageSet } from 'src/sagas/utils/storage';
import { P2pTicketStore } from 'src/types/P2pTicketStore';
import { decodeOrGet } from 'src/utils/decodeOrGet';
import { call } from 'typed-redux-saga';

export function* loadP2pTicketStore(): SagaIterator<P2pTicketStore | null> {
  try {
    yield* call(logDebug, 'Loading P2P Ticket store…');

    const storage = yield* call(storageGet, STORAGE_P2P_KEY);
    const decoded = yield* call(decodeOrGet, STORAGE_VAL, storage, null);

    yield* call(logDebug, 'Loading P2P Ticket store… done', decoded);
    return decoded?.content ?? null;
  } catch (error) {
    yield* call(logError, 'Loading P2P Ticket store… error', error);
    throw new RuntimeError('Could not load P2P Ticket store', {}, NativeError.wrap(error));
  }
}

export function* saveP2pTicketStore(store: P2pTicketStore | null): SagaIterator<void> {
  try {
    yield* call(logDebug, 'Saving P2P Ticket store…', store);

    const encoded = store === null
      ? undefined
      : yield* call(STORAGE_VAL.encode, { version: STORAGE_P2P_VERSION, content: store });
    yield* call(storageSet, STORAGE_P2P_KEY, encoded);

    yield* call(logDebug, 'Saving P2P Ticket store… done');
  } catch (error) {
    yield* call(logError, 'Saving P2P Ticket store… error', error);
    throw new RuntimeError('Could not save P2P Ticket store', { store }, NativeError.wrap(error));
  }
}

const STORAGE_VAL = C.struct({
  version: C.literal(STORAGE_P2P_VERSION),
  content: P2P_TICKET_STORE,
});
