import './P2pTicketFormDestination.scss';
import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactSelect, { components, InputActionMeta, MenuProps, SelectInstance } from 'react-select';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import { P2pTicketFormDropdownAnimate } from 'src/components/p2p/P2pTicketFormDropdownAnimate';
import { useCustomValidity } from 'src/forms/hooks/useCustomValidity';
import { P2pTicketStation } from 'src/types/P2pTicketStation';
import { P2pTicketStationList } from 'src/types/P2pTicketStationList';

type InternalOption = {
  readonly value: string | number;
  readonly label: string;
  readonly station: P2pTicketStation;
};

type Instance = SelectInstance<InternalOption, false, never>;

type Props = {
  readonly id: string;
  readonly name: string;
  readonly layout: 'vertical' | 'horizontal';
  readonly border: 'none' | 'thin';
  readonly label: React.ReactNode;
  readonly value: P2pTicketStation | null;
  readonly exclude: P2pTicketStation | null;
  readonly stations: P2pTicketStationList | null;
  readonly onBlur?: () => void;
  readonly onChange: (value: P2pTicketStation | null) => void;
  readonly onInput: (term: string) => void;
  readonly invalid?: boolean;
  readonly validity?: string;
  readonly clearable?: boolean;
  readonly disabled?: boolean;
  readonly placeholder?: React.ReactNode;
};

export function P2pTicketFormDestination({
  id,
  name,
  layout,
  border,
  label,
  value,
  exclude,
  stations,
  onBlur,
  onChange,
  onInput,
  invalid,
  validity,
  clearable,
  disabled,
  placeholder,
}: Props): React.ReactElement {
  const [hideSelectedValue, setHideSelectedValue] = useState<boolean>(false);

  const handleChange = useCallback((option: InternalOption | null) => {
    setHideSelectedValue(false);
    onChange(option?.station ?? null);
  }, [onChange]);

  const handleInput = React.useCallback((term: string, input: InputActionMeta) => {
    if (input.action === 'menu-close' || input.action === 'input-blur') {
      onInput('');
    } else {
      onInput(term);
    }
  }, [onInput]);

  const internalValue = useMemo((): InternalOption | null => {
    return value ? getStationOption(value) : null;
  }, [value]);

  const internalOptions = useMemo((): ReadonlyArray<InternalOption> => {
    if (stations === null) {
      return [];
    }

    return stations.stations
      .filter((it) => it.id !== exclude?.id)
      .map(getStationOption);
  }, [stations, exclude]);

  const noOptionsMessage = useCallback(() => {
    const query = stations?.query.trim() ?? '';
    if (query.length === 0) {
      return <FormattedMessage id="P2P.Form.Destination.EmptyRequest" values={{ min: 2 }}/>;
    } else if (query.length < 2) {
      return <FormattedMessage id="P2P.Form.Destination.TooShortRequest" values={{ min: 2 }}/>;
    } else {
      return <FormattedMessage id="P2P.Form.Destination.NoStationsFound" values={{ query }}/>;
    }
  }, [stations?.query]);

  const loadingMessage = useCallback(() => {
    return <FormattedMessage id="P2P.Form.Destination.Loading"/>;
  }, []);

  const inputRef = useRef<HTMLInputElement>(null);
  useCustomValidity(inputRef, validity ?? '');

  const extractRef = useCallback((instance: Instance | null) => {
    // @ts-expect-error React threads the reference as readonly
    inputRef.current = instance?.inputRef ?? null;
  }, []);

  const handleOnFocus = useCallback((): void => {
    setHideSelectedValue(true);
  }, []);

  const handleOnBlur = useCallback((): void => {
    setHideSelectedValue(false);

    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  return (
    <div
      data-invalid={invalid}
      data-disabled={disabled}
      className={classNames(
        'sts-ui-p2p-ticket-form-destination',
        `sts-ui-p2p-ticket-form-destination--layout-${layout}`,
        `sts-ui-p2p-ticket-form-destination--border-${border}`,
      )}
    >
      <label htmlFor={id} className="sts-ui-p2p-ticket-form-destination__label">
        {label}
      </label>
      <div className="sts-ui-p2p-ticket-form-destination__input">
        <ReactSelect<InternalOption, false, never>
          inputId={id}
          ref={extractRef}
          name={name}
          className="sts-ui-p2p-ticket-form-destination-input"
          classNamePrefix="sts-ui-p2p-ticket-form-destination-input"
          value={hideSelectedValue ? null : internalValue}
          options={internalOptions}
          isLoading={stations?.loading}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          onChange={handleChange}
          onInputChange={handleInput}
          isMulti={false}
          isClearable={clearable}
          isDisabled={disabled}
          menuPosition="absolute"
          backspaceRemovesValue={true}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          closeMenuOnScroll={true}
          closeMenuOnSelect={true}
          placeholder={placeholder ?? <FormattedMessage id="FormElements.Select.SelectPlaceholder"/>}
          components={{ Menu }}
        />
      </div>
    </div>
  );
}

function getStationOption(station: P2pTicketStation): InternalOption {
  return {
    value: station.id,
    label: station.name,
    station: station,
  };
}

function Menu<TValue, TGroup extends GroupBase<TValue>>(
  props: MenuProps<TValue, false, TGroup>,
): React.ReactElement {
  const { innerRef } = props;

  return (
    <P2pTicketFormDropdownAnimate show={true} content={innerRef}>
      <components.Menu<TValue, false, TGroup> {...props}/>
    </P2pTicketFormDropdownAnimate>
  );
}
