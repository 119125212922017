import './P2pTicketScreenInitialForm.scss';
import { FormController } from '@form-ts/core';
import { useFormWatch } from '@form-ts/react';
import { pipe } from 'fp-ts/function';
import { isEmpty } from 'fp-ts/ReadonlyRecord';
import { modify } from 'monocle-ts/Lens';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'src/components/common/Button';
import { Typography } from 'src/components/common/Typography';
import { LoadingIcon } from 'src/components/icons/LoadingIcon';
import { PlusCircleIcon } from 'src/components/icons/PlusCircleIcon';
import { P2pTicketScreenInitialFormTraveler } from 'src/components/p2p/P2pTicketScreenInitialFormTraveler';
import { P2pTicketScreenInitialFormTrip } from 'src/components/p2p/P2pTicketScreenInitialFormTrip';
import { FormError } from 'src/forms/types/FormError';
import { LocalDateTime } from 'src/types/LocalDateTime';
import { P2pTicketFormData, P2pTicketFormDataTraveler } from 'src/types/P2pTicketFormData';
import { P2pTicketStationList } from 'src/types/P2pTicketStationList';
import { P2pTicketStationRequest } from 'src/types/P2pTicketStationRequest';
import { createTraveler } from 'src/utils/p2p/createTraveler';

type Props = {
  readonly form: FormController<P2pTicketFormData, FormError>;
  readonly onSubmit: () => void;
  readonly submitting: boolean;

  readonly minDate: LocalDateTime;
  readonly stations: P2pTicketStationList | null;
  readonly onStationSearch: (request: P2pTicketStationRequest) => void;
};

export function P2pTicketScreenInitialForm({
  form,
  onSubmit,
  submitting,
  minDate,
  stations,
  onStationSearch,
}: Props): React.ReactElement {
  const errors = useFormWatch(form, form.errors.get);
  const invalid = !isEmpty(errors);
  const travelers = useFormWatch(form, form.field.at('travelers').value.get);

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  }, [onSubmit]);

  const handleAddTraveler = useCallback(() => {
    form.change(pipe(
      form.currentState,
      pipe(form.field.at('travelers').value, modify((list) => list.concat(createTraveler()))),
    ));
  }, [form]);

  const handleRemoveTraveler = useCallback((traveler: P2pTicketFormDataTraveler) => {
    form.change(pipe(
      form.currentState,
      pipe(form.field.at('travelers').value, modify((list) => list.filter((it) => it.id !== traveler.id))),
    ));
  }, [form]);

  return (
    <form onSubmit={handleSubmit} className="sts-ui-p2p-ticket-screen-initial-form">
      <div className="sts-ui-p2p-ticket-screen-initial-form__trip">
        <P2pTicketScreenInitialFormTrip
          field={form.field.at('trip')}
          minDate={minDate}
          stations={stations}
          onStationSearch={onStationSearch}
        />
      </div>

      <div className="sts-ui-p2p-ticket-screen-initial-form__divider">
        <div className="sts-ui-p2p-ticket-screen-initial-form__divider-text">
          <Typography as="div" variant="form-label">
            <FormattedMessage id="P2P.Form.Traveler.ListTitle"/>
          </Typography>
        </div>
        <div className="sts-ui-p2p-ticket-screen-initial-form__divider-line"/>
      </div>

      {travelers.map((traveler, index) => (
        <div key={traveler.id} className="sts-ui-p2p-ticket-screen-initial-form__traveler">
          <P2pTicketScreenInitialFormTraveler
            field={form.field.at('travelers').at(index)}
            number={index + 1}
            onRemove={index === 0 ? undefined : handleRemoveTraveler}
          />
        </div>
      ))}
      <div className="sts-ui-p2p-ticket-screen-initial-form__action">
        <Button
          as="button"
          variant="link"
          intent="primary"
          size="md"
          type="button"
          onClick={handleAddTraveler}
          disabled={travelers.length >= MAX_TRAVELERS}
        >
          <PlusCircleIcon/>
          <FormattedMessage id="P2P.Form.Traveler.AddTraveler"/>
        </Button>
      </div>

      <div className="sts-ui-p2p-ticket-screen-initial-form__submit">
        <Button
          as="button"
          variant="solid"
          intent="primary"
          size="lg"
          type="submit"
          disabled={invalid}
        >
          <FormattedMessage id="P2P.Search.Form.Submit"/>
          {submitting && <LoadingIcon/>}
        </Button>
      </div>
    </form>
  );
}

const MAX_TRAVELERS = 9;
